import React, { Dispatch, SetStateAction } from 'react';

import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';

import EditToolContent from './EditToolContent';
import { AdminToolImplementationType } from 'js/components/ManageEquipment/EditEquipment/useEquipmentTools';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
export interface EditToolDialogProps<ObjectType extends object> {
  tool: AdminToolImplementationType<ObjectType>;
  equipmentId?: string;
  hideEdit?(): void;
  editVisible?: boolean;
  save?(): void;
  isValidItem?: boolean;
  item?: ObjectType;
  setItem: Dispatch<SetStateAction<ObjectType>>;
  setIsValidItem: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
}

type ObjectWithProviderId = {
  providerId?: string;
};

const EditToolDialog = <ObjectType extends object>({
  tool,
  equipmentId,
  hideEdit,
  editVisible,
  save,
  isValidItem,
  item,
  setItem,
  setIsValidItem,
  isLoading
}: EditToolDialogProps<ObjectType>) => {
  const title = T.format(
    (item as ObjectWithProviderId)?.providerId
      ? T.admin.equipment.tool.dialog.edit.title
      : T.admin.equipment.tool.dialog.create.title,
    tool.name
  );

  return (
    <ActionModal
      onModalClose={hideEdit}
      isOpen={editVisible}
      title={title}
      headerIcon={Icons.Edit}
      isLoading={isLoading}
      disableActionButton={!isValidItem}
      onConfirmClick={save}
      helpPath={tool?.helpPath}
    >
      <EditToolContent
        equipmentId={equipmentId}
        item={item}
        setItem={setItem}
        setIsValidItem={setIsValidItem}
        isLoading={isLoading}
        model={tool.model}
      />
    </ActionModal>
  );
};

export default typedMemo(EditToolDialog);
