import { useState } from 'react';

export function useCallbackOnChange<ValueType>(
  isOpen: ValueType,
  onChange: () => void
) {
  const [wasOpen, setWasOpen] = useState(isOpen);

  if (wasOpen !== isOpen) {
    setWasOpen(isOpen);
    onChange();
  }
}
