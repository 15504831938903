import React, { useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import { KeyValueActionInput } from 'ecto-common/lib/KeyValueInput/KeyValueActionInput';
import {
  HardwareActionInputProps,
  hardwareActionPingUrl
} from 'js/components/EnergyManagers/HardwareActionInput/HardwareActionInputTypes';

const PingInput = ({ isLoading, action }: HardwareActionInputProps) => {
  const [pingUrl, setPingUrl] = useState(hardwareActionPingUrl);

  return (
    <KeyValueActionInput
      keyText={T.admin.iotdevicedetails.pingaction}
      buttonText={T.admin.iotdevicedetails.ping}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setPingUrl(e.target.value)
      }
      value={pingUrl}
      buttonDisabled={isLoading}
      onAction={() => action(pingUrl)}
    />
  );
};

export default PingInput;
