import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import T from 'ecto-common/lib/lang/Language';
import styles from './EditIoTDevice.module.css';
import Heading from 'ecto-common/lib/Heading/Heading';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';

import _ from 'lodash';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import HardwareActions, {
  HardwareActionEntry
} from 'js/components/EnergyManagers/HardwareActions';
import { IoTDeviceViewResponseModel } from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { KeyValueColumn } from 'ecto-common/lib/KeyValueInput/KeyValueColumn';
import { useMutation } from '@tanstack/react-query';

const isSuccessEntry = (entry: HardwareActionEntry) =>
  _.get(entry, 'data.data.success', false);

const formatLogEntry = (entry: HardwareActionEntry) => {
  const formatter = HardwareActions[entry.type].logFormats;
  if (entry.data.isLoading) {
    return formatter.loadingRequest(entry);
  } else if (entry.data.cancelled) {
    return formatter.cancelledRequest(entry);
  } else if (entry.data.isSuccessful) {
    // server request was successful but was the request on the device successful?

    if (isSuccessEntry(entry)) {
      return formatter.successEntry(entry);
    }
    return formatter.failureEntry(entry);
  }

  return formatter.failureRequest(entry);
};

const createErrorLog = (
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
): HardwareActionEntry => {
  return {
    type,
    data: {
      isLoading: false,
      hasError: true,
      isSuccessful: false,
      cancelled: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: null as any,
      error,
      params
    }
  };
};

const createSuccessLog = (
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): HardwareActionEntry => {
  return {
    type,
    data: {
      isLoading: false,
      hasError: false,
      isSuccessful: true,
      cancelled: false,
      data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: null as any,
      params
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createProgressLog = (type: string, params: any): HardwareActionEntry => {
  return {
    type,
    data: {
      isLoading: true,
      hasError: false,
      isSuccessful: false,
      cancelled: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: null as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: null as any,
      params
    }
  };
};

interface IoTHardwareActionsProps {
  device?: IoTDeviceViewResponseModel;
}

const IoTHardwareActions = ({ device }: IoTHardwareActionsProps) => {
  const [logs, setLogs] = useState<HardwareActionEntry[]>([]);
  const { contextSettings } = useContext(TenantContext);

  const handleSuccess = useCallback(
    (type: string, propertyName: string | number | [string, number]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (result: any, params: any) => {
        const data = _.get(result, propertyName);
        setLogs((oldLogs) => {
          const newLogs = [...oldLogs];
          newLogs.push(createSuccessLog(type, params, data));
          return newLogs;
        });
      };
    },
    []
  );

  const handleError = useCallback((type: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any, params: any) => {
      setLogs((oldLogs) => {
        const newLogs = [...oldLogs];
        newLogs.push(createErrorLog(type, params, error));
        return newLogs;
      });
    };
  }, []);

  const deviceCall = useCallback(
    (
      type: string,
      signal: AbortSignal,
      api: (
        contextSettings: ApiContextSettings,
        signal: AbortSignal,
        ioTDeviceId: string,
        ...args: unknown[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) => Promise<any>
    ) => {
      return (_contextSettings: ApiContextSettings, ...params: unknown[]) => {
        setLogs((oldLogs) => {
          const newLogs = [...oldLogs];
          newLogs.push(createProgressLog(type, params));
          return newLogs;
        });
        return api(contextSettings, signal, device?.ioTDevice?.id, ...params);
      };
    },
    [contextSettings, device?.ioTDevice?.id]
  );

  const pendingMutations = useRef<Record<string, AbortController>>({});

  useEffect(() => {
    return () => {
      _.forEach(pendingMutations.current, (abortController) => {
        abortController.abort('Component unmounted');
      });

      pendingMutations.current = {};
    };
  }, []);

  const actions = _.map(HardwareActions, (actionInfo, actionRequestName) => {
    const Component = actionInfo.component;
    // Can disable eslint here because we are generating same hooks everytime, so it is stable
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const loadMutation = useMutation({
      mutationFn: (...params: unknown[]) => {
        const abortController = new AbortController();
        pendingMutations.current[actionRequestName] = abortController;
        return deviceCall(
          actionRequestName,
          abortController.signal,
          actionInfo.api
        )(contextSettings, ...params);
      },

      onSuccess: (result, params) => {
        delete pendingMutations.current[actionRequestName];
        return handleSuccess(actionRequestName, actionInfo.responseProperty)(
          result,
          params
        );
      },

      onError: (error, params) => {
        delete pendingMutations.current[actionRequestName];
        return handleError(actionRequestName)(error, params);
      }
    });

    return {
      Component,
      action: loadMutation.mutate,
      isLoading: loadMutation.isPending,
      group: actionInfo.group
    };
  });

  const hasPendingLogRequest = _.some(actions, 'isLoading');

  const layout = _.groupBy(actions, 'group');

  return (
    <div>
      <KeyValueColumn>
        {_.flatMap(layout, (group, groupIndex) => {
          const renderGroup = _.map(group, ({ Component, action }, key) => (
            <Component
              key={groupIndex + '_' + key}
              isLoading={hasPendingLogRequest}
              action={action}
            />
          ));
          if (group.length > 1) {
            return [
              <KeyValueLine key={groupIndex}>{renderGroup}</KeyValueLine>
            ];
          }
          return renderGroup;
        })}
      </KeyValueColumn>
      {logs.length > 0 && (
        <Heading className={styles.logHeader} level={3}>
          {T.admin.iotdevicedetails.logs}
          {hasPendingLogRequest && <Spinner size={SpinnerSize.TINY} />}
        </Heading>
      )}
      <div className={styles.logLines}>
        {[...logs].reverse().map((e, idx) => (
          <div key={idx}>
            <strong>{HardwareActions[e.type].logFormats.type}:</strong>{' '}
            {formatLogEntry(e)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(IoTHardwareActions);
