import React, { useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import SearchableSelectableTable from 'ecto-common/lib/SearchableTable/SearchableSelectableTable';
import { getSignalTypeNameWithUnitFromMap } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { ItemWithSignalTypeId } from 'js/components/SelectSignalsFromNodeTableDialog/SelectSignalTypesFromNodeTableDialog';

interface SelectSignalTypesTableProps {
  isLoading: boolean;
  data: ItemWithSignalTypeId[];
  selectedData: ItemWithSignalTypeId[];
  onMarkedData(newItems: ItemWithSignalTypeId[]): void;
}

const SelectSignalTypesTable = ({
  isLoading,
  data,
  selectedData,
  onMarkedData
}: SelectSignalTypesTableProps) => {
  const signalTypesMap = useAdminSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useAdminSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const columns = useMemo(() => {
    return [
      {
        label: T.equipment.signaltype,
        dataKey: 'signalTypeId',
        dataFormatter: (signalTypeId: string) => {
          return (
            <span>
              {getSignalTypeNameWithUnitFromMap(
                signalTypeId,
                signalTypesMap,
                signalUnitTypesMap
              )}
            </span>
          );
        }
      }
    ];
  }, [signalTypesMap, signalUnitTypesMap]);

  return (
    <SearchableSelectableTable<ItemWithSignalTypeId, ItemWithSignalTypeId>
      dataKey={'signalTypeId'}
      noDataString={T.admin.selectsignals.noresult}
      searchPlaceholder={T.admin.selectsignals.searchplaceholder}
      isLoading={isLoading}
      data={data}
      columns={columns}
      selectedData={selectedData}
      onMarkedData={onMarkedData}
      filterPredicate={(searchString: string) => {
        const lowerCaseSearchString = searchString.toLowerCase();
        return (item) => {
          const name = getSignalTypeNameWithUnitFromMap(
            item.signalTypeId,
            signalTypesMap,
            signalUnitTypesMap
          );
          return name.toLowerCase().search(lowerCaseSearchString) !== -1;
        };
      }}
    />
  );
};

export default React.memo(SelectSignalTypesTable);
