import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import { HardwareActionInputProps } from 'js/components/EnergyManagers/HardwareActionInput/HardwareActionInputTypes';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

const Service = Object.freeze({
  iot_interface: 'IoT Service',
  control: 'Control',
  modbus_interface: 'Modbus Interface',
  wdt_checker: 'Watchdog Checker'
});
const SERVICE_OPTIONS = _.map(Service, (label, value) => ({ label, value }));

const RestartServiceInput = ({
  isLoading,
  action
}: HardwareActionInputProps) => {
  const [serviceToRestart, setServiceToRestart] = useState(
    Object.keys(Service)[0]
  );

  const selectedOption = useMemo(
    () => _.find(SERVICE_OPTIONS, { value: serviceToRestart }),
    [serviceToRestart]
  );

  return (
    <KeyValueSelectableInput
      options={SERVICE_OPTIONS}
      value={selectedOption}
      buttonDisabled={isLoading}
      onChange={(e: GenericSelectOption<string>) =>
        setServiceToRestart(e.value)
      }
      keyText={T.admin.iotdevicedetails.restartservice}
      onAction={() => action(serviceToRestart)}
    />
  );
};

export default RestartServiceInput;
