import IntegrationProxyPage from 'js/components/Integrations/IntegrationProxyPage';
import Integrations from 'js/components/Integrations/Integrations';
import { ItemPageParams } from 'js/utils/routeConstants';
import React from 'react';
import { useParams } from 'react-router-dom';

const IntegrationsPage = () => {
  const params = useParams<ItemPageParams>();
  return params.itemId ? <IntegrationProxyPage /> : <Integrations />;
};

export default React.memo(IntegrationsPage);
