import React, { useCallback, useContext, useMemo } from 'react';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import slugify from 'slugify';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { checkboxColumn } from 'ecto-common/lib/utils/dataTableUtils';
import _ from 'lodash';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import IdentityServiceAPIGen, {
  TenantModel
} from 'ecto-common/lib/API/IdentityServiceAPIGen';
import { ResourceModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { useMutation } from '@tanstack/react-query';

type ResourcesModelEditorProps = Omit<CustomModelEditorProps, 'rawValue'> & {
  rawValue: ResourceModel[];
};

const ResourcesModelEditor = ({
  rawValue,
  updateItem
}: ResourcesModelEditorProps) => {
  const { availableTenantResources } = useContext(TenantContext);
  const resourceIsChecked = useCallback(
    (res: ResourceModel) => _.find(rawValue, ['id', res.id]) != null,
    [rawValue]
  );

  const markResource = useCallback(
    (resource: ResourceModel) => {
      if (resourceIsChecked(resource)) {
        updateItem(
          rawValue.filter((otherResource) => otherResource.id !== resource.id)
        );
      } else {
        updateItem(rawValue.concat(resource));
      }
    },
    [rawValue, updateItem, resourceIsChecked]
  );

  const columns: DataTableColumnProps<ResourceModel>[] = [
    checkboxColumn({
      rowIsChecked: resourceIsChecked
    }),
    {
      dataKey: 'name'
    }
  ];

  return (
    <>
      <DataTable<ResourceModel>
        data={availableTenantResources}
        columns={columns}
        disableHeader
        onClickRow={markResource}
      />
    </>
  );
};

const getSections = (
  isNew: boolean
): ModelFormSectionType<Partial<TenantModel>>[] => [
  {
    label: T.admin.tenants.edit.sections.tenant,
    lines: [
      {
        models: [
          {
            key: (input) => input.name,
            modelType: ModelType.TEXT,
            label: T.admin.tenants.edit.fields.name,
            hasError: isNullOrWhitespace,
            onDidUpdate: (_unused: unknown, value) => {
              if (isNew) {
                const sluggedName = slugify(value, { lower: true });

                return [[(input) => input.id, sluggedName]];
              }
            }
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.id,
            modelType: ModelType.TEXT,
            label: T.admin.tenants.edit.fields.id,
            helpText: T.admin.tenants.edit.descriptions.id,
            enabled: isNew,
            hasError: (value: string) => {
              const regex = /^[a-z0-9][a-z0-9-]{5,49}[a-z0-9]$/g;
              return value == null || value.match(regex) == null;
            }
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.description,
            modelType: ModelType.TEXT,
            label: T.admin.tenants.edit.fields.description
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.iotHubHostName,
            modelType: ModelType.TEXT,
            label: T.admin.tenants.edit.fields.iothubhostname
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.timeZone,
            modelType: ModelType.TEXT,
            label: T.admin.tenants.edit.fields.timezone,
            helpText: T.admin.tenants.edit.descriptions.timezone,
            hasError: isNullOrWhitespace
          },
          {
            key: (input) => input.environment,
            modelType: ModelType.TEXT,
            label: T.admin.tenants.edit.fields.environment,
            helpText: T.admin.tenants.edit.descriptions.environment,
            hasError: isNullOrWhitespace,
            enabled: isNew
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.isDeactivated,
            modelType: ModelType.BOOL,
            label: T.admin.tenants.edit.fields.isdisabled,
            helpText: T.admin.tenants.edit.descriptions.isdisabled
          }
        ]
      }
    ]
  },
  {
    label: T.admin.tenants.edit.sections.resources,
    models: [
      {
        key: (input) => input.resources,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <ResourcesModelEditor {...props} model={model} />
        ),
        label: T.admin.tenants.edit.fields.resources
      }
    ]
  }
];

interface EditTenantDialogProps {
  tenant?: Partial<TenantModel>;
  onModalClose: () => void;
}

const EditTenantDialog = ({ onModalClose, tenant }: EditTenantDialogProps) => {
  const { contextSettings, reloadTenants } = useContext(TenantContext);
  const isNew = tenant?.id == null;

  const saveTenantMutation = useMutation({
    mutationFn: (tenantArg: TenantModel) =>
      isNew
        ? IdentityServiceAPIGen.Tenants.createTenant.promise(
            contextSettings,
            { tenant: tenantArg },
            null
          )
        : IdentityServiceAPIGen.Tenants.updateTenant.promise(
            contextSettings,
            { tenant: tenantArg },
            null
          ),

    onSuccess: () => {
      reloadTenants?.();
      onModalClose();
    },

    onError: (unused, _tenant) => {
      toastStore.addErrorToastForUpdatedItem(_tenant.name, isNew);
    }
  });

  const _sections = useMemo(() => getSections(isNew), [isNew]);

  const isObjectNew = useCallback(() => isNew, [isNew]);

  return (
    <ModelFormDialog
      addTitle={T.admin.tenants.edit.addtitle}
      editTitle={T.admin.tenants.edit.title}
      sections={_sections}
      onModalClose={onModalClose}
      input={tenant}
      saveInput={saveTenantMutation.mutate}
      isSavingInput={saveTenantMutation.isPending}
      saveAsArray={false}
      isObjectNew={isObjectNew}
    />
  );
};

export default React.memo(EditTenantDialog);
