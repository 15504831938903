import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  ChangeEventHandler,
  useEffect
} from 'react';
import { useNavigate } from 'react-router-dom';

import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { DEFAULT_LAT, DEFAULT_LNG } from 'ecto-common/lib/constants';
import DraggableMarkerMap from 'ecto-common/lib/Map/DraggableMarkerMap';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import HelpPaths from 'ecto-common/help/tocKeys';

import MeteorologyPoints from 'js/components/Meteorology/MeteorologyPoints';
import styles from 'js/components/Meteorology/ManageMeteorology.module.css';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { MapLocation } from 'ecto-common/lib/Map/Map';
import APIGen from 'ecto-common/lib/API/APIGen';

const getItemLink = (tenantId: string, id: string) =>
  `/${tenantId}/meteorology/${id}`;

const ManageMeteorology = () => {
  const navigate = useNavigate();
  const { tenantId } = useContext(TenantContext);

  const [coordinates, setCoordinates] = useState({
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG
  });
  const [name, setName] = useState('');

  const [isOpen, onShowDialog, onHideDialog] = useDialogState('show-add-point');

  const closeDialog = useCallback(() => {
    onHideDialog();
    setName('');
  }, [onHideDialog]);

  const toolbarItems = useMemo(
    () => [
      <ToolbarFlexibleSpace key="space" />,
      <ToolbarItem key="add">
        <AddButton onClick={onShowDialog}>
          {T.admin.meteorology.action.create}
        </AddButton>
      </ToolbarItem>
    ],
    [onShowDialog]
  );

  const onCoordinateChanged = useCallback((lat: number, lng: number) => {
    setCoordinates({ lat, lng });
  }, []);

  const createMutation =
    APIGen.Meteorology.addOrUpdateGeographicalPoint.useMutation({
      onSuccess: (response) => {
        setName('');
        navigate(`/${tenantId}/meteorology/${response.id}`);
        toastStore.addSuccessToast(T.admin.requests.addweatherpoint.success);
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.requests.addweatherpoint.failure);
      }
    });

  const onAddNewWeatherPoint = useCallback(() => {
    if (isNullOrWhitespace(name)) {
      toastStore.addErrorToast(T.admin.meteorology.error.entername);
      return;
    }

    onShowDialog();
    createMutation.mutate({
      name: name,
      longitude: coordinates.lng,
      latitude: coordinates.lat,
      nodeIds: []
    });
  }, [coordinates.lat, coordinates.lng, createMutation, name, onShowDialog]);

  const [hasUserInput, setHasUserInput] = useState(false);
  const onTextInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setName(event.target.value);
      setHasUserInput(!isNullOrWhitespace(event.target.value));
    },
    []
  );

  const onSelectLocationFromSearch = useCallback(
    ({ lat, lng }: MapLocation, locationName: string) => {
      setCoordinates({ lat, lng });

      if (!hasUserInput) {
        setName(locationName);
      }
    },
    [setCoordinates, hasUserInput]
  );

  useEffect(() => {
    document.title = T.admin.meteorology.overview.header;
  }, []);

  return (
    <ToolbarContentPage
      title={T.admin.meteorology.overview.header}
      toolbarItems={toolbarItems}
      showLocationPicker={false}
      wrapContent={false}
      helpPath={HelpPaths.docs.admin.manage.weather}
    >
      <MeteorologyPoints itemLink={getItemLink} />

      <ActionModal
        className={styles.modal}
        isOpen={isOpen}
        onModalClose={closeDialog}
        onConfirmClick={onAddNewWeatherPoint}
        actionText={T.admin.meteorology.action.create}
        title={T.admin.meteorology.createnew.header}
        headerIcon={Icons.Add}
        isLoading={createMutation.isPending}
      >
        <div className={styles.map}>
          <DraggableMarkerMap
            onCoordinateChanged={onCoordinateChanged}
            initialLatitude={coordinates.lat}
            initialLongitude={coordinates.lng}
            initialZoom={16}
            searchable
            onSelectLocationFromSearch={onSelectLocationFromSearch}
          />
        </div>

        <TextInput
          placeholder={T.admin.meteorology.input.name}
          wrapperClassName={styles.nameInput}
          value={name}
          onChange={onTextInputChange}
          autoFocus
        />
      </ActionModal>
    </ToolbarContentPage>
  );
};

export default ManageMeteorology;
