import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import IntegrationAPIGen, {
  IntegrationProxyResponse
} from 'ecto-common/lib/API/IntegrationAPIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useNavigate, generatePath } from 'react-router-dom';
import _ from 'lodash';
import DataTableLoadMoreFooter from 'ecto-common/lib/DataTable/DataTableLoadMoreFooter';
import { IntegrationsRoute } from 'js/utils/routeConstants';

const Integrations = () => {
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    IntegrationAPIGen.IntegrationProxies.listIntegrationProxies.useInfiniteQuery(
      {
        $orderby: 'name asc'
      }
    );

  const { tenantId } = useContext(TenantContext);
  const navigate = useNavigate();

  const columns: DataTableColumnProps<IntegrationProxyResponse>[] = [
    {
      dataKey: 'name',
      label: T.admin.integrations.integrationproxyname,
      linkColumn: true
    }
  ];

  const onClickRow = useCallback(
    (row: IntegrationProxyResponse) => {
      navigate(
        generatePath(IntegrationsRoute.path, { itemId: row.id, tenantId })
      );
    },
    [navigate, tenantId]
  );

  const allItems = useMemo(() => {
    return _.flatMap(data?.pages, (page) => page.items);
  }, [data]);

  useEffect(() => {
    document.title = T.admin.tabs.integrations;
  }, []);

  return (
    <ToolbarContentPage
      title={T.admin.tabs.integrations}
      showLocationPicker={false}
      wrapContent={false}
    >
      <DataTable<IntegrationProxyResponse>
        columns={columns}
        data={allItems}
        onClickRow={onClickRow}
        isLoading={isLoading}
      />
      <DataTableLoadMoreFooter
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </ToolbarContentPage>
  );
};

Integrations.propTypes = {};

export default React.memo(Integrations);
