import _ from 'lodash';
import waitUntil from 'ecto-common/lib/utils/waitUntil';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import APIGen, { IoTDeviceViewResponseModel } from 'ecto-common/lib/API/APIGen';

const getUptime = (results: IoTDeviceViewResponseModel[]) =>
  _.get(results, '[0].reportedSystem.uptime');

const syncAndGetUptime = (
  contextSettings: ApiContextSettings,
  ioTDeviceId: string,
  signal: AbortSignal
) => {
  return APIGen.AdminIoTDevices.syncDeviceTwins
    .promise(
      contextSettings,
      {
        ids: [ioTDeviceId]
      },
      signal
    )
    .then(() => {
      return waitUntil(new Date().getTime() + 5000);
    })
    .then(() => {
      return APIGen.AdminIoTDevices.getIoTDeviceViewByIoTDeviceId.promise(
        contextSettings,
        {
          Ids: [ioTDeviceId]
        },
        signal
      );
    })
    .then((response) => {
      const uptime = getUptime(response);
      if (uptime != null) {
        return Promise.resolve(uptime);
      }

      throw new Error('Failed to read uptime');
    });
};

const checkIfRebootUpdatedPromise = (
  contextSettings: ApiContextSettings,
  ioTDeviceId: string,
  initialUptime: string,
  signal: AbortSignal
) => {
  const then = new Date().getTime() + 60 * 1000;

  return waitUntil(then)
    .then(() => {
      return syncAndGetUptime(contextSettings, ioTDeviceId, signal);
    })
    .then((newUptime) => {
      // New uptime less than initial uptime means that the uptime has been reset
      return Promise.resolve(newUptime < initialUptime);
    });
};

const checkRebootStatusTwicePromise = (
  contextSettings: ApiContextSettings,
  ioTDeviceId: string,
  initialUptime: string,
  signal: AbortSignal
) => {
  return checkIfRebootUpdatedPromise(
    contextSettings,
    ioTDeviceId,
    initialUptime,
    signal
  )
    .then((result: boolean) => {
      if (result) {
        return Promise.resolve(result);
      }

      return checkIfRebootUpdatedPromise(
        contextSettings,
        ioTDeviceId,
        initialUptime,
        signal
      );
    })
    .then((secondResult) => {
      if (secondResult) {
        return Promise.resolve(secondResult);
      }

      // Device was not confirmed online after two tries, throw error
      throw Error('error');
    });
};

const rebootDevice = (
  contextSettings: ApiContextSettings,
  signal: AbortSignal,
  ioTDeviceId: string
) => {
  let initialUptime: string = null;
  return syncAndGetUptime(contextSettings, ioTDeviceId, signal)
    .then((uptime) => {
      initialUptime = uptime;

      return APIGen.AdminIoTDevices.rebootDevice.promise(
        contextSettings,
        {
          id: ioTDeviceId
        },
        signal
      );
    })
    .then(() => {
      return checkRebootStatusTwicePromise(
        contextSettings,
        ioTDeviceId,
        initialUptime,
        signal
      );
    });
};

export default rebootDevice;
