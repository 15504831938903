import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { text } from 'ecto-common/lib/ModelForm/formUtils';
import _ from 'lodash';
import useFormInputWithValidation from 'ecto-common/lib/ModelForm/useFormInputWithValidation';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import T from 'ecto-common/lib/lang/Language';
import {
  comfortSignal,
  comfortEquipment
} from 'js/components/ManageEquipment/EditEquipment/Comfort/comfortModelFormUtils';
import { SignalTypeIds } from 'ecto-common/lib/utils/constants';
import { ComfortHeatingProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

const errorIfEmpty = _.isEmpty;

const model: ModelDefinition<ComfortHeatingProviderResponseModel>[] = [
  text<ComfortHeatingProviderResponseModel>(
    (input) => input.displayName,
    T.admin.comfort.name,
    errorIfEmpty
  ),
  comfortSignal(
    (input) => input.meanTemperatureSignalId,
    T.admin.comfort.signals.roomtemperature,
    errorIfEmpty
  ),
  comfortSignal(
    (input) => input.outTemperatureSignalId,
    T.admin.comfort.signals.outdoortemperature,
    errorIfEmpty,
    [SignalTypeIds.OUTDOOR_SENSOR_TEMPERATURE_SIGNAL_TYPE_ID]
  ),
  comfortEquipment(
    (input) => input.tapWaterEquipmentId,
    T.admin.comfort.signals.tapwater
  ),
  comfortSignal(
    (input) => input.windSpeedSignalId,
    T.admin.comfort.signals.windspeed
  )
];

interface EditComfortProps {
  item?: ComfortHeatingProviderResponseModel;
  setItem: Dispatch<SetStateAction<ComfortHeatingProviderResponseModel>>;
  setIsValidItem(isValid: boolean): void;
  availableSignals?: object;
}

const EditComfort = ({
  item,
  setItem,
  setIsValidItem,
  availableSignals
}: EditComfortProps) => {
  const onUpdateInput = useFormInputWithValidation(
    item,
    setItem,
    setIsValidItem,
    model
  );
  const environment = useMemo(() => ({ availableSignals }), [availableSignals]);

  return (
    <ModelForm
      environment={environment}
      input={item}
      models={model}
      onUpdateInput={onUpdateInput}
    />
  );
};

export default React.memo(EditComfort);
