import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import styles from 'js/components/EnergyManagers/EditIoTDevice.module.css';
import React, { useMemo } from 'react';
import EditIoTDeviceHardwareSettingsModal from 'js/components/EnergyManagers/EditIoTDeviceHardwareSettingsModal';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import {
  IoTDeviceWithDeviceAndTags,
  UseEditIoTDeviceLoadingInfo
} from 'js/components/EnergyManagers/useEditIoTDevice';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

const createModels = (
  availableTags: string[],
  loadingInfo: UseEditIoTDeviceLoadingInfo
): ModelDefinition<IoTDeviceWithDeviceAndTags>[] => [
  {
    key: (input) => input.ioTDevice.tags,
    modelType: ModelType.OPTIONS,
    label: T.admin.iotdevicedetails.tags,
    isMultiOption: true,
    options: _.map(availableTags, (tag) => ({ label: tag, value: tag })),
    isLoading: loadingInfo.isLoadingAvailableTags,
    enabled: !(loadingInfo.isSaving || loadingInfo.isLoadingAvailableTags),
    showOptionWhenEmpty: false,
    isClearable: true,
    withCreatableOption: true
  },
  {
    key: (input) => input.ioTDevice.manufacturer,
    modelType: ModelType.LABEL,
    label: T.admin.iotdevicedetails.manufacturer,
    enabled: false
  },
  {
    key: (input) => input.ioTDevice.model,
    modelType: ModelType.LABEL,
    label: T.admin.iotdevicedetails.model,
    enabled: false
  },
  {
    key: (input) => input.reportedSystem.simICCID,
    modelType: ModelType.LABEL,
    label: T.admin.iotdevicedetails.sim,
    enabled: false,
    isLoading: loadingInfo.isLoadingDeviceInfo
  },
  {
    key: (input) => input.ioTDevice.notes,
    modelType: ModelType.TEXT,
    label: T.admin.iotdevicedetails.notes,
    enabled: !loadingInfo.isSaving,
    isLoading: loadingInfo.isSaving
  }
];

interface DeviceDetailsProps {
  device?: IoTDeviceWithDeviceAndTags;
  unlinkButton?: React.ReactNode;
  actionButtons?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeviceDataChanged?(key: string[], value: any): void;
  loadingInfo?: UseEditIoTDeviceLoadingInfo;
}

const DeviceDetails = ({
  device,
  unlinkButton,
  actionButtons,
  loadingInfo,
  onDeviceDataChanged
}: DeviceDetailsProps) => {
  const [networkDialogIsOpen, showNetworkDialog, hideNetworkDialog] =
    useSimpleDialogState();

  // Add all tags from the system as an option for the device
  const models: ModelDefinition<IoTDeviceWithDeviceAndTags>[] = useMemo(
    () => createModels(device?.availableTags, loadingInfo),
    [device, loadingInfo]
  );

  if (device?.ioTDevice == null) {
    return <div />;
  }
  return (
    <div>
      {unlinkButton && (
        <KeyValueLine>
          <KeyValueGeneric keyText={T.admin.iotdevicedetails.pairactions}>
            {unlinkButton}
          </KeyValueGeneric>
        </KeyValueLine>
      )}

      <div className={styles.keyValues}>
        <KeyValueLine>
          <KeyValueGeneric keyText={T.admin.iotdevicedetails.devicename}>
            <CopyToClipboardTooltip valueToCopy={device.ioTDevice.deviceName}>
              <span className={styles.value}>
                {device.ioTDevice.deviceName}
              </span>
            </CopyToClipboardTooltip>
          </KeyValueGeneric>
          <KeyValueGeneric keyText={T.admin.iotdevicedetails.networksettings}>
            <Button onClick={showNetworkDialog}>
              <Icons.Settings />
              {T.admin.iotdevicedetails.editnetworksettings}
            </Button>
          </KeyValueGeneric>
        </KeyValueLine>
        <ModelForm
          models={models}
          input={device}
          onUpdateInput={onDeviceDataChanged}
        />
      </div>
      {actionButtons}
      <EditIoTDeviceHardwareSettingsModal
        iotDevice={device?.ioTDevice}
        isOpen={networkDialogIsOpen}
        onModalClose={hideNetworkDialog}
      />
    </div>
  );
};

export default React.memo(DeviceDetails);
