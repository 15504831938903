import React, { memo, useEffect } from 'react';

import AdminPage from 'js/components/AdminPage';
import BuildingsList from 'js/components/Buildings/BuildingsList';
import T from 'ecto-common/lib/lang/Language';

const Buildings = () => {
  useEffect(() => {
    document.title = T.admin.buildings.title;
  }, []);

  return <AdminPage content={<BuildingsList />} />;
};

export default memo(Buildings);
