import React, { useCallback, useEffect, useState } from 'react';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ManageUsers, {
  useManageUsersPaging
} from 'js/components/ManageUsers/ManageUsers';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { DEFAULT_TABLE_PAGE_SIZE_MODAL } from 'ecto-common/lib/utils/constants';
import T from 'ecto-common/lib/lang/Language';
import { TenantUserModel } from 'ecto-common/lib/API/IdentityServiceAPIGen';
import { TenantModel } from 'ecto-common/lib/API/IdentityServiceAPIGen';

interface ManageUsersDialogProps {
  tenant?: TenantModel;
  onModalClose: () => void;
}

const ManageUsersDialog = ({
  tenant,
  onModalClose
}: ManageUsersDialogProps) => {
  const [newUser, setNewUser] = useState<TenantUserModel>(null);
  const { paging, setSearchFilter, setPageNumber } = useManageUsersPaging(
    DEFAULT_TABLE_PAGE_SIZE_MODAL
  );

  const onShowAddUser = useCallback(() => {
    setNewUser({
      userId: null
    });
  }, []);

  useEffect(() => {
    setSearchFilter(null);
  }, [tenant, setSearchFilter]);

  return (
    <ActionModal
      headerIcon={Icons.User}
      wide
      disableCancel
      actionText={T.common.done}
      title={T.format(
        T.admin.tenants.editusers.titleformat,
        tenant?.name ?? ''
      )}
      isOpen={tenant != null}
      withSearchField
      searchText={paging.filter}
      onModalClose={onModalClose}
      onConfirmClick={onModalClose}
      onSearchTextChanged={setSearchFilter}
      searchFieldButtons={
        <AddButton onClick={onShowAddUser}>
          {T.admin.tenants.editusers.add}
        </AddButton>
      }
    >
      <ManageUsers
        newUser={newUser}
        setNewUser={setNewUser}
        paging={paging}
        setPageNumber={setPageNumber}
        tenantId={tenant?.id}
      />
    </ActionModal>
  );
};

export default React.memo(ManageUsersDialog);
