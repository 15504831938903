import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import KeyValueInternalTextInput, {
  KeyValueInternalTextInputProps
} from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalTextInput';
import classNames from 'classnames';
import Button from 'ecto-common/lib/Button/Button';
import React from 'react';

type KeyValueActionInputProps = KeyValueInternalTextInputProps & {
  /**
   * Whether or not the button should be disable
   */
  buttonDisabled?: boolean;
  /**
   * Title for the button. If not set then the key text will be used.
   */
  buttonText?: string;
  /**
   * Action that should be triggered when the button is pressed. First argument is the value of the input field.
   */
  onAction?(value: string): void;

  keyText: string;

  help?: string;
};

/**
 *  This class shows a text field with an action button to the right of it. Could be used to send messages or commands to specific URL:s etc.
 */
export const KeyValueActionInput = ({
  onChange,
  value,
  disabled,
  buttonDisabled,
  keyText,
  buttonText,
  className,
  placeholder,
  autoFocus,
  type,
  onAction,
  help,
  hasError
}: KeyValueActionInputProps) => {
  return (
    <div className={styles.keyValueContainer}>
      <KeyValueLabel>{keyText}</KeyValueLabel>
      <div className={styles.keyValueActionContainer}>
        <KeyValueInternalTextInput
          className={classNames(styles.valueItem, className)}
          onChange={onChange}
          value={value}
          disabled={disabled}
          onEnterKeyPressed={() => onAction(value)}
          autoFocus={autoFocus}
          placeholder={placeholder}
          type={type}
          hasError={hasError}
        />
        <Button
          disabled={buttonDisabled || disabled}
          onClick={() => onAction(value)}
        >
          {buttonText || keyText}
        </Button>
      </div>
      {help && <div className={styles.helpText}>{help}</div>}
    </div>
  );
};
