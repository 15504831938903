import React, { useMemo } from 'react';
import pageStyles from 'js/components/PageStyle.module.css';
import zindex from 'ecto-common/lib/styles/variables/zindex';
import Select, {
  GenericSelectOption,
  SelectProps
} from 'ecto-common/lib/Select/Select';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { ToolEnumTranslations } from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import { ToolType } from 'ecto-common/lib/API/APIGen';

type ToolSelectorProps = SelectProps<GenericSelectOption<string>, true> & {
  toolTemplates: string[];
  values: string[];
};

const ToolSelector = ({
  toolTemplates,
  values,
  onChange,
  ...otherProps
}: ToolSelectorProps) => {
  const options = useMemo(
    () =>
      _.map(toolTemplates, (toolTemplate: ToolType) => ({
        value: toolTemplate,
        label: ToolEnumTranslations[toolTemplate] ?? toolTemplate
      })),
    [toolTemplates]
  );

  const selectedOptions = options.filter((option) =>
    values.find((x) => x === option.value)
  );

  return (
    <Select<GenericSelectOption<string>, true>
      name="form-field-name"
      placeholder={T.admin.toolselector.placeholder}
      isMulti
      maxMenuHeight={250}
      className={pageStyles.smallSelector}
      value={selectedOptions}
      onChange={onChange}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: zindex.modalOverlayZIndex
        })
      }}
      menuPortalTarget={document.body}
      isSearchable={false}
      options={options}
      {...otherProps}
    />
  );
};

export default ToolSelector;
