import { PowerControlAlgorithmType } from 'ecto-common/lib/constants';
import React, { useEffect } from 'react';
import {
  EditState,
  EditStateData
} from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import { EditEquipmentToolsActions } from 'js/modules/editEquipmentTools/editEquipmentTools';
import EditPowerControlDialog from 'js/components/ManageEquipment/EditEquipment/EditPowerControlDialog';
import { ToolSignalProviders } from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { PowerControlType } from 'ecto-common/lib/API/APIGen';

const DEFAULT_POWER_CONTROL_TYPE = PowerControlType.Heating;
const DEFAULT_ALGORITHM_TYPE = PowerControlAlgorithmType.TRACKING;

interface EditPowerControlToolProps {
  editState?: EditStateData;
  setEditState: (toolType: ToolSignalProviders, data: EditStateData) => void;
}

const EditPowerControlTool = ({
  editState,
  setEditState
}: EditPowerControlToolProps) => {
  const dispatch = useAdminDispatch();
  const deviceId = useAdminSelector(
    (state) => state.editEquipmentTools.deviceId
  );
  const equipmentId = useAdminSelector(
    (state) => state.editEquipmentTools.equipmentId
  );
  const editablePowerControl = useAdminSelector(
    (state) => state.editEquipmentTools.editablePowerControl
  );

  useEffect(() => {
    switch (editState.state) {
      case EditState.IDLE:
        dispatch(EditEquipmentToolsActions.setEditablePowerControl(null));
        break;
      case EditState.EDIT:
        dispatch(
          EditEquipmentToolsActions.setEditablePowerControl(editState.data)
        );
        break;
      case EditState.CREATE:
        dispatch(
          EditEquipmentToolsActions.setEditablePowerControl({
            powerControlType: DEFAULT_POWER_CONTROL_TYPE,
            algorithmType: DEFAULT_ALGORITHM_TYPE,
            nodeId: equipmentId,
            deviceId
          })
        );
        break;
      default:
        break;
    }
  }, [editState, deviceId, equipmentId, dispatch]);

  useEffect(() => {
    if (editablePowerControl === null) {
      setEditState(ToolSignalProviders.POWER_CONTROL, {
        state: EditState.IDLE,
        data: null
      });
    }
  }, [editablePowerControl, setEditState]);

  return (
    <>
      <EditPowerControlDialog
        isOpen={editablePowerControl != null}
        powerControl={editablePowerControl}
      />
    </>
  );
};

export default React.memo(EditPowerControlTool);
