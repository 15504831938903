import React, { useEffect, useMemo, useState } from 'react';
import {
  number,
  useUpdateModelFormInput
} from 'ecto-common/lib/ModelForm/formUtils';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import AlarmConfigurationsModelEditor from 'js/components/ManageEquipment/EditEquipment/Comfort/AlarmConfigurationsModelEditor';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { modelFormSectionsAreValid } from 'ecto-common/lib/ModelForm/validateForm';
import APIGen, {
  AddOrUpdateComfortToolSetResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ComfortEqOrAdminSignalWithSignalProviderName } from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditComfortTool';
import {
  ModelDefinition,
  ModelFormSectionStyle,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  ComfortEnvironment,
  SignalWithSignalProviderName
} from 'js/components/ManageEquipment/EditEquipment/Comfort/comfortModelFormUtils';

const signOption = (
  key: (input: AddOrUpdateComfortToolSetResponseModel) => string,
  label: string
): ModelDefinition<AddOrUpdateComfortToolSetResponseModel> => {
  return {
    key,
    label,
    modelType: ModelType.OPTIONS,
    options: [
      {
        label: T.admin.comfort.enum.sign.negative,
        value: 'Negative'
      },
      {
        label: T.admin.comfort.enum.sign.positive,
        value: 'Positive'
      }
    ]
  };
};

type IntegralGainProperty =
  | 'integralGainSchedulingOutTemp'
  | 'integralGainSchedulingTimeOfDayWeekday'
  | 'integralGainSchedulingTimeOfDayWeekend'
  | 'integralGainSchedulingWindFactor';

const comfortNumber = number<AddOrUpdateComfortToolSetResponseModel>;

const integralGainSection = (label: string, prefix: IntegralGainProperty) => ({
  label,
  initiallyCollapsed: true,

  lines: [
    {
      models: [
        comfortNumber(
          (input) => input[prefix].integralGain,
          T.admin.comfort.parameters.integralgain.integralgain
        ),
        comfortNumber(
          (input) => input[prefix].integralTime,
          T.admin.comfort.parameters.integralgain.integraltime
        )
      ]
    },
    {
      models: [
        comfortNumber(
          (input) => input[prefix].timeConstant,
          T.admin.comfort.parameters.integralgain.timeconstant
        ),
        comfortNumber(
          (input) => input[prefix].maxOutTempValue,
          T.admin.comfort.parameters.integralgain.maxouttempvalue
        )
      ]
    },
    {
      models: [
        comfortNumber(
          (input) => input[prefix].outTempMeanHours,
          T.admin.comfort.parameters.integralgain.outtempmeanhours
        ),
        comfortNumber(
          (input) => input[prefix].outTempMeanMaxIntegration,
          T.admin.comfort.parameters.integralgain.outtempmeanmaxintegration
        )
      ]
    },
    {
      models: [
        comfortNumber(
          (input) => input[prefix].minOutputValue,
          T.admin.comfort.parameters.integralgain.minoutputvalue
        ),
        comfortNumber(
          (input) => input[prefix].maxOutputValue,
          T.admin.comfort.parameters.integralgain.maxoutputvalue
        )
      ]
    },
    {
      models: [
        comfortNumber(
          (input) => input[prefix].minRoomTempValue,
          T.admin.comfort.parameters.integralgain.minroomtempvalue
        ),
        comfortNumber(
          (input) => input[prefix].maxRoomTempValue,
          T.admin.comfort.parameters.integralgain.maxroomtempvalue
        )
      ]
    },
    {
      models: [
        comfortNumber(
          (input) => input[prefix].minRoomTempSetpointValue,
          T.admin.comfort.parameters.integralgain.minroomtempsetpointvalue
        ),
        comfortNumber(
          (input) => input[prefix].maxRoomTempSetpointValue,
          T.admin.comfort.parameters.integralgain.maxroomtempsetpointvalue
        )
      ]
    }
  ]
});

const getIntegralGainWindspeedSection = (
  label: string,
  prefix: IntegralGainProperty
) => {
  const section = integralGainSection(label, prefix);
  section.lines.push({
    models: [
      comfortNumber(
        (input) => input.integralGainSchedulingWindFactor.minWindSpeedValue,
        T.admin.comfort.parameters.integralgain.minwindspeedvalue
      ),
      comfortNumber(
        (input) => input.integralGainSchedulingWindFactor.maxWindSpeedValue,
        T.admin.comfort.parameters.integralgain.maxwindspeedvalue
      )
    ]
  });

  return section;
};

const pidSection: ModelFormSectionType<AddOrUpdateComfortToolSetResponseModel> =
  {
    label: T.admin.comfort.parameters.pid.sectiontitle,
    lines: [
      {
        models: [
          comfortNumber(
            (input) => input.pidControl.derivativeTime,
            T.admin.comfort.parameters.pid.derivativetime
          ),
          comfortNumber(
            (input) => input.pidControl.integralTime,
            T.admin.comfort.parameters.pid.integraltime
          )
        ]
      },
      {
        models: [
          comfortNumber(
            (input) => input.pidControl.inputDeadbandDiffLower,
            T.admin.comfort.parameters.pid.inputdeadbanddifflower,
            null,
            0.1
          ),
          comfortNumber(
            (input) => input.pidControl.inputDeadbandDiffUpper,
            T.admin.comfort.parameters.pid.inputdeadbanddiffupper,
            null,
            0.1
          )
        ]
      },
      {
        models: [
          comfortNumber(
            (input) => input.pidControl.minOutputValue,
            T.admin.comfort.parameters.pid.minoutputvalue,
            null,
            0.1
          ),
          comfortNumber(
            (input) => input.pidControl.maxOutputValue,
            T.admin.comfort.parameters.pid.maxoutputvalue,
            null,
            0.1
          )
        ]
      },
      {
        models: [
          comfortNumber(
            (input) => input.pidControl.gain,
            T.admin.comfort.parameters.pid.gain
          )
        ]
      },
      {
        models: [
          signOption(
            (input) => input.pidControl.sign,
            T.admin.comfort.parameters.pid.sign
          )
        ]
      }
    ]
  };

const highpassSection: ModelFormSectionType<AddOrUpdateComfortToolSetResponseModel> =
  {
    label: T.admin.comfort.parameters.highpass.sectiontitle,
    initiallyCollapsed: true,
    lines: [
      {
        models: [
          comfortNumber(
            (input) => input.highPassControl.timeConstant,
            T.admin.comfort.parameters.highpass.timeconstant
          )
        ]
      },
      {
        models: [
          comfortNumber(
            (input) => input.highPassControl.minOutputValue,
            T.admin.comfort.parameters.highpass.minoutputvalue,
            null,
            0.1
          ),
          comfortNumber(
            (input) => input.highPassControl.maxOutputValue,
            T.admin.comfort.parameters.highpass.maxoutputvalue,
            null,
            0.1
          )
        ]
      },
      {
        models: [
          signOption(
            (input) => input.highPassControl.sign,
            T.admin.comfort.parameters.highpass.sign
          )
        ]
      }
    ]
  };
const integralGainOutTempSection = integralGainSection(
  T.admin.comfort.parameters.integralgain.outtempsectiontitle,
  'integralGainSchedulingOutTemp'
);
const integralGainWeekdaySection = integralGainSection(
  T.admin.comfort.parameters.integralgain.weekdaysectiontitle,
  'integralGainSchedulingTimeOfDayWeekday'
);
const integralGainWeekendSection = integralGainSection(
  T.admin.comfort.parameters.integralgain.weekendsectiontitle,
  'integralGainSchedulingTimeOfDayWeekend'
);
const integralGainWindspeedSection = getIntegralGainWindspeedSection(
  T.admin.comfort.parameters.integralgain.windspeedsectiontitle,
  'integralGainSchedulingWindFactor'
);

const getAlarmConfigurationSection = (): ModelFormSectionType<
  AddOrUpdateComfortToolSetResponseModel,
  ComfortEnvironment
> => {
  return {
    label: T.admin.comfort.parameters.alarmconfiguration.sectiontitle,
    lines: [
      {
        models: [
          {
            modelType: ModelType.CUSTOM,
            render: (props, model) => (
              <AlarmConfigurationsModelEditor {...props} model={model} />
            ),
            key: (input) => input.alarmConfigurations
          }
        ]
      }
    ]
  };
};

interface EditComfortParametersProps {
  comfortToolsetParameters: AddOrUpdateComfortToolSetResponseModel;
  onComfortToolsetParametersChanged: (
    params: AddOrUpdateComfortToolSetResponseModel
  ) => void;
  signalData: Record<string, ComfortEqOrAdminSignalWithSignalProviderName>;
  setConfigIsValid?: (isValid: boolean) => void;
}

const EditComfortParameters = ({
  comfortToolsetParameters,
  onComfortToolsetParametersChanged,
  signalData,
  setConfigIsValid
}: EditComfortParametersProps) => {
  const [settingsInput, setSettingsInput] =
    useState<AddOrUpdateComfortToolSetResponseModel>(null);
  const onSetInput = useUpdateModelFormInput(setSettingsInput);

  useEffect(() => {
    onComfortToolsetParametersChanged(settingsInput);
  }, [settingsInput, onComfortToolsetParametersChanged]);

  const [alarmConfigurationSection, setAlarmConfigurationSection] =
    useState<ModelFormSectionType<AddOrUpdateComfortToolSetResponseModel>>(
      null
    );

  const requestSignalIds = useMemo(() => {
    const newSettingsInput = _.cloneDeep(comfortToolsetParameters);
    setSettingsInput(newSettingsInput);
    const alarmConfigurations = newSettingsInput.alarmConfigurations;
    setAlarmConfigurationSection(getAlarmConfigurationSection());
    const signalConfigs = _.flatMapDeep(
      alarmConfigurations,
      (config) => config.inputSignalConfigurations
    );
    // Double flat maps due to flatMapDeep not having correct return signature
    return _.flatMap(
      _(signalConfigs)
        .map((signalConfig) => [
          signalConfig.inputSignalId,
          signalConfig.inputSetSignalId
        ])
        .flatMapDeep()
        .compact()
        .uniq()
        .value()
    );
  }, [comfortToolsetParameters]);

  const getSignalInfoQuery =
    APIGen.AdminSignals.getProvidersBySignalIds.useQuery({
      signalIds: requestSignalIds
    });

  const fetchedSignalData: Record<string, SignalWithSignalProviderName> =
    useMemo(() => {
      if (getSignalInfoQuery.data == null) {
        return {};
      }

      const signalList: SignalWithSignalProviderName[] = _.flatMap(
        getSignalInfoQuery.data,
        (provider) =>
          provider.signals.map((signal) => ({
            ...signal,
            signalProviderName: provider.signalProviderName
          }))
      );
      return _.keyBy(signalList, 'signalId');
    }, [getSignalInfoQuery.data]);

  const environment = useMemo(
    () => ({
      signalInfo: _.merge({}, fetchedSignalData, signalData),
      getSignalInfoIsLoading: getSignalInfoQuery.isLoading
    }),
    [fetchedSignalData, signalData, getSignalInfoQuery.isLoading]
  );

  const sections: ModelFormSectionType<AddOrUpdateComfortToolSetResponseModel>[] =
    useMemo(() => {
      return _.compact([
        pidSection,
        highpassSection,
        integralGainWeekdaySection,
        integralGainWeekendSection,
        integralGainOutTempSection,
        comfortToolsetParameters.integralGainSchedulingWindFactor != null &&
          integralGainWindspeedSection,
        alarmConfigurationSection
      ]);
    }, [alarmConfigurationSection, comfortToolsetParameters]);

  useEffect(() => {
    if (setConfigIsValid != null) {
      setConfigIsValid(
        modelFormSectionsAreValid(sections, settingsInput, environment)
      );
    }
  }, [settingsInput, sections, setConfigIsValid, environment]);

  return (
    <>
      <ModelForm
        input={settingsInput}
        sections={sections}
        onUpdateInput={onSetInput}
        environment={environment}
        sectionStyle={ModelFormSectionStyle.SELECT_CONTROL}
        sectionsTitle={T.admin.comfort.parameters.sectionstitle}
      />
    </>
  );
};

export default React.memo(EditComfortParameters);
