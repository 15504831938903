import React, { useCallback, useEffect, useState } from 'react';
import {
  EMPTY_INPUT,
  NotificationModelsWithNodePicker,
  NotificationModels
} from './NotificationModels';
import styles from './EditNotification.module.css';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import _ from 'lodash';
import UUID from 'uuidjs';
import { AlarmNotificationResponseModel } from 'ecto-common/lib/API/APIGen';

interface EditNotificationProps {
  nodeId?: string;
  notification?: AlarmNotificationResponseModel;
  isLoading?: boolean;
  onUpdateNotification?(newNotification: AlarmNotificationResponseModel): void;
}

const EditNotification = ({
  nodeId,
  notification,
  onUpdateNotification,
  isLoading
}: EditNotificationProps) => {
  const [input, setInput] = useState<AlarmNotificationResponseModel>(
    notification
      ? { ...notification }
      : { ...EMPTY_INPUT, nodeIds: _.compact([nodeId]) }
  );

  useEffect(() => {
    if (notification != null) {
      setInput({ ...notification });
    } else {
      setInput({
        ...EMPTY_INPUT,
        id: UUID.generate(),
        nodeIds: _.compact([nodeId])
      });
    }
  }, [notification, nodeId]);

  const onUpdateInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string[], value: any) => {
      setInput((oldInput) => {
        const newInput = { ...oldInput };
        _.set(newInput, name, value);

        // Patch updated input if we got specific nodeId by property
        // and we did not receive a specific notification (create mode = null, edit mode = notification)
        if (nodeId && notification == null) {
          onUpdateNotification({ ...newInput, nodeIds: [nodeId] });
        } else {
          onUpdateNotification(newInput);
        }

        return newInput;
      });
    },
    [onUpdateNotification, nodeId, notification]
  );

  return (
    <div className={styles.inputTable}>
      <ModelForm
        input={input}
        isLoading={isLoading}
        models={
          nodeId == null ? NotificationModelsWithNodePicker : NotificationModels
        }
        onUpdateInput={onUpdateInput}
      />
    </div>
  );
};

export default EditNotification;
