import React, { useCallback } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';

import TagsSelector from 'js/components/TagsSelector/TagsSelector';
import styles from 'js/components/EnergyManagers/EnergyManagersPage.module.css';
import {
  IoTQueryTypes,
  IoTStatusQueryTypes
} from 'js/components/EnergyManagers/energyManagersUtil';
import AddButton from 'ecto-common/lib/Button/AddButton';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import AddDeviceModal from 'js/components/EnergyManagers/AddDeviceModal';

export type DeviceListFilterParams = {
  searchPhrase: string;
  page: number;
  filteredTags: string[];
  queryType: IoTQueryTypes;
  statusQueryType: IoTStatusQueryTypes;
};

const queryOptions: GenericSelectOption<IoTQueryTypes>[] = [
  {
    value: IoTQueryTypes.ALL,
    label: T.admin.provisioning.query.all
  },
  {
    value: IoTQueryTypes.UNPAIRED,
    label: T.admin.provisioning.query.unpaired
  },
  {
    value: IoTQueryTypes.PAIRED,
    label: T.admin.provisioning.query.paired
  }
];

const statusQueryOptions: GenericSelectOption<IoTStatusQueryTypes>[] = [
  {
    value: IoTStatusQueryTypes.ALL,
    label: T.admin.provisioning.query.all
  },
  {
    value: IoTStatusQueryTypes.ONLINE,
    label: T.admin.provisioning.query.online
  },
  {
    value: IoTStatusQueryTypes.OFFLINE,
    label: T.admin.provisioning.query.offline
  }
];

interface DeviceListFilterProps {
  filterParams: DeviceListFilterParams;
  tags?: string[];
  onQueryTypeChange?(queryType: IoTQueryTypes): void;
  onStatusQueryTypeChange?(statusQueryType: IoTStatusQueryTypes): void;
  onSearchPhraseChange?(searchPhrase: string): void;
  onFilteredTagsChange?(newTags: string[]): void;
  useAsSelector?: boolean;
}

const DeviceListFilter = ({
  filterParams,
  tags,
  onSearchPhraseChange,
  onQueryTypeChange,
  onStatusQueryTypeChange,
  useAsSelector,
  onFilteredTagsChange
}: DeviceListFilterProps) => {
  const selectedQueryOption = _.find(queryOptions, {
    value: filterParams?.queryType
  });
  const selectedStatusQueryOption = _.find(statusQueryOptions, {
    value: filterParams?.statusQueryType
  });

  const _onQueryTypeChange = useCallback(
    (event: GenericSelectOption<IoTQueryTypes>) => {
      onQueryTypeChange(event.value);
    },
    [onQueryTypeChange]
  );

  const _onStatusQueryTypeChange = useCallback(
    (event: GenericSelectOption<IoTStatusQueryTypes>) => {
      onStatusQueryTypeChange(event.value);
    },
    [onStatusQueryTypeChange]
  );

  const onFilterTagsUpdate = useCallback(
    (event: GenericSelectOption<string>[]) => {
      // Transform filter tags to a string for dependency comparison
      onFilteredTagsChange(_.map(event, 'value'));
    },
    [onFilteredTagsChange]
  );

  const _onSearchPhraseChange = useCallback(
    (_searchPhrase: string) => {
      onSearchPhraseChange(_.trim(_searchPhrase));
    },
    [onSearchPhraseChange]
  );

  const [isShowDeviceDialogOpen, showAddDeviceDialog, hideAddDeviceDialog] =
    useDialogState('show-add-device-dialog');
  const onAddUser = useCallback(() => {
    showAddDeviceDialog();
  }, [showAddDeviceDialog]);

  return (
    <Toolbar isPageHeadingToolbar className={styles.filterToolbar}>
      <AddDeviceModal
        onModalClose={hideAddDeviceDialog}
        isOpen={isShowDeviceDialogOpen}
      />
      <ToolbarFlexibleSpace key="space" />
      {!useAsSelector && (
        <>
          <ToolbarItem className={styles.filterItem}>
            <KeyValueLabel>{T.common.status}</KeyValueLabel>

            <Select
              className={styles.typeSelect}
              options={queryOptions}
              value={selectedQueryOption}
              onChange={_onQueryTypeChange}
              isSearchable={false}
            />
          </ToolbarItem>

          <ToolbarItem className={styles.filterItem}>
            <KeyValueLabel>
              {T.admin.provisioning.connectionstatus}
            </KeyValueLabel>

            <Select
              className={styles.typeSelect}
              options={statusQueryOptions}
              value={selectedStatusQueryOption}
              onChange={_onStatusQueryTypeChange}
              isSearchable={false}
            />
          </ToolbarItem>
        </>
      )}

      <ToolbarItem className={styles.filterItem}>
        <TagsSelector
          selectedTags={filterParams?.filteredTags}
          availableTags={tags}
          onChange={onFilterTagsUpdate}
        />
      </ToolbarItem>

      <ToolbarItem className={styles.filterItem}>
        <ToolbarSearch
          onChange={_onSearchPhraseChange}
          value={filterParams?.searchPhrase}
        />
      </ToolbarItem>
      <ToolbarItem className={styles.filterItem}>
        <AddButton onClick={onAddUser}>{T.common.add}</AddButton>
      </ToolbarItem>
    </Toolbar>
  );
};

export default React.memo(DeviceListFilter);
