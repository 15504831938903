import { AlarmSignalGroupTemplateIds } from 'ecto-common/lib/utils/constants';
import _ from 'lodash';
import { EquipmentTypes } from 'ecto-common/lib/utils/equipmentTypeUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { AdminAlarmSignalGroupTemplateResponseModel } from 'ecto-common/lib/API/APIGen';

export const getValidAlarmSignalGroupTemplates = (
  allTemplates: AdminAlarmSignalGroupTemplateResponseModel[],
  equipmentTypeId: string
) => {
  let availableTemplates = allTemplates;

  if (equipmentTypeId === EquipmentTypes.ENERGY_MANAGER) {
    availableTemplates = _.filter(
      availableTemplates,
      (template) =>
        template.alarmSignalGroupTemplateId ===
        AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS
    );
  } else {
    availableTemplates = _.reject(
      availableTemplates,
      (template) =>
        template.alarmSignalGroupTemplateId ===
        AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS
    );
  }

  return sortByLocaleCompare(
    _.map(availableTemplates, (alarmSignalTemplate) => ({
      value: alarmSignalTemplate.alarmSignalGroupTemplateId,
      label: alarmSignalTemplate.name
    })),
    'label'
  );
};
