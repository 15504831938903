import React from 'react';
import EctotableAPIGen, {
  ScreenContentsResponse
} from 'ecto-common/lib/API/EctotableAPIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';

const screenContentColumns: DataTableColumnProps<ScreenContentsResponse>[] = [
  {
    label: T.admin.ectotable.name,
    dataKey: 'name',
    linkColumn: true
  }
];

type EctotableScreenContentListProps = {
  onClickRow: (row: ScreenContentsResponse) => void;
};

const EctotableScreenContentList = ({
  onClickRow
}: EctotableScreenContentListProps) => {
  const { data: screenContents, isLoading: isLoadingScreenContents } =
    EctotableAPIGen.ScreenContentAdmin.listScreenContent.useQuery({
      $orderby: 'name'
    });

  return (
    <DataTable<ScreenContentsResponse>
      isLoading={isLoadingScreenContents}
      columns={screenContentColumns}
      data={screenContents?.items}
      onClickRow={onClickRow}
    />
  );
};

export default React.memo(EctotableScreenContentList);
