import React, { memo, useEffect, useCallback, useContext } from 'react';
import T from 'ecto-common/lib/lang/Language';
import Button from 'ecto-common/lib/Button/Button';
import { BuildingActions } from 'js/modules/buildings/buildings';
import styles from './Buildings.module.css';
import _ from 'lodash';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const BuildingsExport = () => {
  const dispatch = useAdminDispatch();
  const { contextSettings } = useContext(TenantContext);
  const exportBuildings = useCallback(
    () => dispatch(BuildingActions.exportBuildings(contextSettings)),
    [contextSettings, dispatch]
  );
  const data = useAdminSelector(
    (state) => state.buildings.getBuildingsReqState.data
  );
  const isLoading = useAdminSelector(
    (state) => state.buildings.exportBuildingsReqState.isLoading
  );

  useEffect(() => {
    return () => {
      BuildingActions.cancelExportBuildings();
    };
  });

  return (
    <Button
      onClick={exportBuildings}
      disabled={
        isLoading || (data != null && _.get(data, 'buildings.length', 0) <= 0)
      }
      className={styles.exportButton}
    >
      {T.admin.buildings.export}
    </Button>
  );
};

export default memo(BuildingsExport);
