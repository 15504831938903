import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  ROOT_NODE_ID
} from 'ecto-common/lib/constants';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import {
  CreateError,
  updateNodeTreeIncrementally
} from 'js/modules/provisioningCommon/provisioningCommon';
import { EquipmentTemplateFormActions } from 'js/modules/equipmentTemplateForm/equipmentTemplateForm';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import UUID from 'uuidjs';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { AdminDispatch, AdminGetState } from 'js/reducers/storeAdmin';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import APIGen, {
  AddGeographicalPointRequestModel,
  BuildingStatus,
  GeographicalPointResponseModel,
  NodeResponseModel,
  NodeType
} from 'ecto-common/lib/API/APIGen';
import PresentationAPIGen, {
  AssignTenantDashboardToNodeRequest
} from 'ecto-common/lib/API/PresentationAPIGen';

const RESET_NODE_FORM = 'RESET_NODE_FORM';
const SET_NODE_FORM_NAME = 'SET_NODE_FORM_NAME';
const SET_NODE_FORM_STREET = 'SET_NODE_FORM_STREET';
const SET_NODE_FORM_ADD_ANOTHER = 'SET_NODE_FORM_ADD_ANOTHER';
const SET_NODE_FORM_TAGS = 'SET_NODE_FORM_TAGS';
const RESET_METEOROLOGY_FORM = 'RESET_METEOROLOGY_FORM';

const SET_NODE_FORM_COORD = 'SET_NODE_FORM_COORD';
const SET_NODE_FORM_DASHBOARD_COLLECTION = 'SET_NODE_FORM_DASHBOARD_COLLECTION';
const SET_NODE_FORM_TYPE = 'SET_NODE_FORM_TYPE';

const SET_CREATE_NODE_STATE = 'SET_CREATE_NODE_STATE';
const SET_CREATED_NODE = 'SET_CREATED_NODE';
const SET_NODE_FORM_WEATHER_POINT = 'SET_NODE_FORM_WEATHER_POINT';
const SET_NODE_FORM_EDIT_WEATHER_POINT = 'SET_NODE_FORM_EDIT_WEATHER_POINT';

const SET_NODE_FORM_SHOW_DASHBOARD_COLLECTION_DIALOG =
  'SET_NODE_FORM_SHOW_DASHBOARD_COLLECTION_DIALOG';

const SET_NODE_FORM_SHOW_WEATHER_POINT_DIALOG =
  'SET_NODE_FORM_SHOW_WEATHER_POINT_DIALOG';

/**
 * Holds node creation state. Each state represent client server interaction.
 * @type {Readonly<{ADDING_TEMPLATE: string, ADDING_WEATHER: string, ADDING_CONNECTION: string, NODE_CREATED: string, CREATING_NODE: string, ERROR: string}>}
 */
export enum CreatingNodeState {
  CREATING_BUILDING = 'CREATING_BUILDING',
  CREATING_SITE = 'CREATING_SITE',
  ADDING_TEMPLATE = 'ADDING_TEMPLATE',
  ADDING_CONNECTION = 'ADDING_CONNECTION',
  ADDING_WEATHER = 'ADDING_WEATHER',
  ADDING_DASHBOARD_COLLECTION_RELATION = 'ADDING_DASHBOARD_COLLECTION_RELATION',
  UPDATING_WEATHER = 'UPDATING_WEATHER',
  NODE_CREATED = 'NODE_CREATED',
  NODE_UPDATED = 'NODE_UPDATED',
  ERROR = 'ERROR'
}

const _addWeatherAPI = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  weatherPoint:
    | AddGeographicalPointRequestModel
    | GeographicalPointResponseModel
) => {
  if (!weatherPoint) {
    return;
  }

  let nodeIds = weatherPoint.nodeIds ?? [];

  if (!nodeIds.includes(nodeId)) {
    nodeIds = [...nodeIds, nodeId];
  }

  const newPoint = {
    ...weatherPoint,
    nodeIds
  };

  await APIGen.Meteorology.addOrUpdateGeographicalPoint.promise(
    contextSettings,
    newPoint as AddGeographicalPointRequestModel,
    null
  );
};

const _removeWeatherAPI = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  weatherPoints: GeographicalPointResponseModel[]
) => {
  for (const weatherPoint of weatherPoints) {
    if (
      weatherPoint &&
      weatherPoint.nodeIds != null &&
      weatherPoint.nodeIds.includes(nodeId)
    ) {
      const nodeIds = weatherPoint.nodeIds.filter(
        (id: string) => id !== nodeId
      );
      const newPoint = {
        ...weatherPoint,
        nodeIds
      };

      await APIGen.Meteorology.addOrUpdateGeographicalPoint.promise(
        contextSettings,
        newPoint as AddGeographicalPointRequestModel,
        null
      );
    }
  }
};

const _updateWeatherPointAPI = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  previousWeatherPoints: GeographicalPointResponseModel[],
  newWeatherPoint: GeographicalPointResponseModel
) => {
  try {
    await _removeWeatherAPI(contextSettings, nodeId, previousWeatherPoints);
    await _addWeatherAPI(contextSettings, nodeId, newWeatherPoint);
    return true;
  } catch (e) {
    return false;
  }
};

type CreateNodeFormReducerProps = {
  name: string;
  street: string;
  nodeType: NodeType;
  createRootNode: boolean;
  latitude: number;
  longitude: number;
  tags: string[];
  addAnother: boolean;
  showNodeForm: boolean;
  showDashboardCollectionDialog: boolean;
  showWeatherPointDialog: boolean;

  createNodeState: CreatingNodeState | null;
  createdNode: NodeResponseModel;
  createNodeError: CreateError | null;

  dashboards: AssignTenantDashboardToNodeRequest[];

  weatherPoint: GeographicalPointResponseModel;

  // For editing weather point
  editWeatherPoint: GeographicalPointResponseModel;
  previousWeatherPoints: GeographicalPointResponseModel[];
};

const initialState: CreateNodeFormReducerProps = {
  name: '',
  street: '',
  nodeType: NodeType.Site,
  createRootNode: true,
  latitude: DEFAULT_LAT,
  longitude: DEFAULT_LNG,
  tags: [],
  addAnother: false,
  showNodeForm: false,
  showDashboardCollectionDialog: false,
  showWeatherPointDialog: false,

  createNodeState: null,
  createdNode: null,
  createNodeError: null,

  dashboards: null,

  weatherPoint: null,

  // For editing weather point
  editWeatherPoint: null,
  previousWeatherPoints: []
};

const _createLocation = async (
  contextSettings: ApiContextSettings,
  parentLocation: SingleGridNode,
  state: CreateNodeFormReducerProps,
  dispatch: AdminDispatch
) => {
  const { nodeType, createRootNode, name, street, latitude, longitude, tags } =
    state;

  const grids = [parentLocation.grid];
  const parentIds =
    createRootNode || parentLocation.nodeId.startsWith(ROOT_NODE_ID)
      ? []
      : [parentLocation.nodeId];

  const sharedData = {
    parentIds,
    nodeType,
    grids,
    name,
    street,
    latitude,
    longitude,
    tags
  };

  let fullData;
  if (nodeType === NodeTypes.BUILDING) {
    fullData = {
      ...sharedData,
      buildingInfo: { buildingStatus: BuildingStatus.Created }
    };
  } else {
    fullData = sharedData;
  }

  const node = await APIGen.AdminNodes.addOrUpdateNode.promise(
    contextSettings,
    fullData,
    null
  );

  return await updateNodeTreeIncrementally(
    contextSettings,
    node.nodeId,
    dispatch
  );
};

const _createNode = (
  contextSettings: ApiContextSettings,
  parentLocation: SingleGridNode
) => {
  return async (dispatch: AdminDispatch, getState: AdminGetState) => {
    const state = getState();
    const { createNodeForm, equipmentTemplateForm } = state;
    const setNodeState = (
      stateArg: CreatingNodeState,
      createNodeError: CreateError | string = undefined
    ) => {
      dispatch(_setCreateNodeState(stateArg, createNodeError));
    };

    try {
      if (createNodeForm.nodeType === NodeTypes.BUILDING) {
        setNodeState(CreatingNodeState.CREATING_BUILDING);
      } else if (createNodeForm.nodeType === NodeTypes.SITE) {
        setNodeState(CreatingNodeState.CREATING_SITE);
      }

      const node = await _createLocation(
        contextSettings,
        parentLocation,
        { ...createNodeForm },
        dispatch
      );

      const tags = await APIGen.AdminNodes.getNodeTags.promise(
        contextSettings,
        null
      );
      dispatch(setNodeTags(tags));

      if (
        createNodeForm.nodeType === NodeTypes.BUILDING &&
        equipmentTemplateForm.equipmentTemplateGroupId
      ) {
        const {
          equipmentTemplateGroupId,
          equipmentTemplateInstanceOverrides,
          connectionModbusConfigOverride,
          existingEnergyManagerDeviceId
        } = equipmentTemplateForm;

        const targetTemplates =
          await APIGen.AdminBuildings.getAddOrUpdateBuildingsByTemplates.promise(
            contextSettings,
            [
              {
                nodeId: node.nodeId,
                equipmentTemplateGroupId,
                deviceId: existingEnergyManagerDeviceId ?? UUID.generate(),
                connectionModbusConfigOverride,
                equipmentTemplateInstanceOverrides
              }
            ],
            null
          );

        await APIGen.AdminBuildings.addOrUpdateBuildingsByTemplates.promise(
          contextSettings,
          targetTemplates,
          null
        );

        dispatch(_setCreatedNode(node));
        await updateNodeTreeIncrementally(
          contextSettings,
          node.nodeId,
          dispatch
        );
      } else {
        dispatch(_setCreatedNode(node));
      }

      if (createNodeForm.weatherPoint) {
        setNodeState(CreatingNodeState.ADDING_WEATHER);
        await _addWeatherAPI(
          contextSettings,
          node.nodeId,
          createNodeForm.weatherPoint as AddGeographicalPointRequestModel
        );
      }

      if (createNodeForm.dashboards) {
        setNodeState(CreatingNodeState.ADDING_DASHBOARD_COLLECTION_RELATION);
        await PresentationAPIGen.Nodes.assignTenantDashboards.promise(
          contextSettings,
          {
            nodeId: node.nodeId
          },
          createNodeForm.dashboards,
          null
        );
      }

      setNodeState(CreatingNodeState.NODE_CREATED);
    } catch (e) {
      console.error(e);
      if (e.response && e.response?.status === HttpStatus.CONFLICT) {
        if (createNodeForm.nodeType === NodeTypes.BUILDING) {
          setNodeState(
            CreatingNodeState.ERROR,
            CreateError.BUILDING_NAMING_CONFLICT
          );
        } else if (createNodeForm.nodeType === NodeTypes.SITE) {
          setNodeState(
            CreatingNodeState.ERROR,
            CreateError.SITE_NAMING_CONFLICT
          );
        }
      } else if (typeof e === 'string') {
        setNodeState(CreatingNodeState.ERROR, e);
      } else {
        setNodeState(CreatingNodeState.ERROR, CreateError.GENERIC);
      }
    }
  };
};

const _updateWeatherPoint = (
  contextSettings: ApiContextSettings,
  currentNodeId: string
) => {
  return async (dispatch: AdminDispatch, getState: AdminGetState) => {
    dispatch(_setCreateNodeState(CreatingNodeState.UPDATING_WEATHER));
    const { createNodeForm } = getState();
    const { editWeatherPoint, previousWeatherPoints } = createNodeForm;

    const res = await _updateWeatherPointAPI(
      contextSettings,
      currentNodeId,
      previousWeatherPoints,
      editWeatherPoint
    );

    if (!res) {
      dispatch(_setCreateNodeState(CreatingNodeState.ERROR));
    } else {
      dispatch(_setCreateNodeState(CreatingNodeState.NODE_UPDATED));
    }
  };
};

export default createReducer(initialState, {
  [SET_NODE_FORM_TAGS]: (state, { tags }) => {
    return { ...state, tags };
  },

  [RESET_NODE_FORM]: () => {
    return Object.assign({}, initialState);
  },
  [RESET_METEOROLOGY_FORM]: (state) => {
    return { ...state, editWeatherPoint: null, previousWeatherPoints: [] };
  },
  [SET_NODE_FORM_SHOW_DASHBOARD_COLLECTION_DIALOG]: (
    state,
    { showDashboardCollectionDialog }
  ) => {
    return { ...state, showDashboardCollectionDialog };
  },
  [SET_NODE_FORM_SHOW_WEATHER_POINT_DIALOG]: (
    state,
    { showWeatherPointDialog }
  ) => {
    return { ...state, showWeatherPointDialog };
  },
  [SET_NODE_FORM_ADD_ANOTHER]: (state, { addAnother }) => {
    return { ...state, addAnother };
  },
  [SET_NODE_FORM_DASHBOARD_COLLECTION]: (state, { dashboards }) => {
    return { ...state, dashboards };
  },
  [SET_NODE_FORM_NAME]: (state, { name }) => {
    return { ...state, name };
  },
  [SET_NODE_FORM_STREET]: (state, { street }) => {
    return { ...state, street };
  },
  [SET_NODE_FORM_TYPE]: (state, { nodeType, createRootNode }) => {
    return { ...state, nodeType, createRootNode };
  },
  [SET_NODE_FORM_COORD]: (state, { latitude, longitude }) => {
    return { ...state, latitude, longitude };
  },
  [SET_CREATE_NODE_STATE]: (state, { createNodeState, createNodeError }) => {
    if (
      state.addAnother &&
      createNodeState === CreatingNodeState.NODE_CREATED
    ) {
      return {
        ...state,
        name: '',
        street: '',
        createNodeState,
        createNodeError
      };
    }

    return { ...state, createNodeState, createNodeError };
  },
  [SET_CREATED_NODE]: (state, { createdNode }) => {
    return { ...state, createdNode };
  },
  [SET_NODE_FORM_WEATHER_POINT]: (state, { weatherPoint }) => {
    return { ...state, weatherPoint };
  },
  [SET_NODE_FORM_EDIT_WEATHER_POINT]: (
    state,
    { editWeatherPoint, previousWeatherPoints }
  ) => {
    return { ...state, editWeatherPoint, previousWeatherPoints };
  }
});

const _setCreatedNode = createAction(SET_CREATED_NODE, 'createdNode');

const _setCreateNodeState = (
  createNodeState: CreatingNodeState,
  createNodeError: CreateError | string = undefined
) => {
  if (createNodeState === CreatingNodeState.ERROR) {
    return {
      type: SET_CREATE_NODE_STATE,
      payload: { createNodeState, createNodeError }
    };
  }

  return { type: SET_CREATE_NODE_STATE, payload: { createNodeState } };
};

export const CreateNodeActions = {
  resetForm: () => {
    return (dispatch: AdminDispatch) => {
      dispatch({ type: RESET_NODE_FORM });
      dispatch(EquipmentTemplateFormActions.resetForm());
    };
  },

  resetMeteorologyForm: createAction(RESET_METEOROLOGY_FORM),

  setAddAnother: createAction(SET_NODE_FORM_ADD_ANOTHER, 'addAnother'),

  setName: createAction(SET_NODE_FORM_NAME, 'name'),
  setStreet: createAction(SET_NODE_FORM_STREET, 'street'),
  setType: createAction(SET_NODE_FORM_TYPE, 'nodeType', 'createRootNode'),
  setTags: createAction(SET_NODE_FORM_TAGS, 'tags'),
  setCoordinates: createAction(SET_NODE_FORM_COORD, 'latitude', 'longitude'),
  setDashboards: createAction(SET_NODE_FORM_DASHBOARD_COLLECTION, 'dashboards'),

  createNode: _createNode,

  clearCreateNodeState: () => _setCreateNodeState(null),

  selectWeatherPoint: createAction(SET_NODE_FORM_WEATHER_POINT, 'weatherPoint'),

  editWeatherPoint: createAction(
    SET_NODE_FORM_EDIT_WEATHER_POINT,
    'editWeatherPoint',
    'previousWeatherPoints'
  ),
  showDashboardCollectionDialog: createAction(
    SET_NODE_FORM_SHOW_DASHBOARD_COLLECTION_DIALOG,
    'showDashboardCollectionDialog'
  ),
  showWeatherPointDialog: createAction(
    SET_NODE_FORM_SHOW_WEATHER_POINT_DIALOG,
    'showWeatherPointDialog'
  ),
  confirmEditWeatherPoint: _updateWeatherPoint
};
