import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import T from 'ecto-common/lib/lang/Language';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import {
  getEquipmentNode,
  getNodeFromMap
} from 'ecto-common/lib/utils/locationUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';

import styles from './SignalMappingList.module.css';
import DeleteSignalMappingModal from 'js/components/SignalMapping/DeleteSignalMappingModal';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import _ from 'lodash';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import { getLocationRoute } from 'js/utils/routeConstants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import {
  SignalWithProvider,
  SingleGridNode
} from 'ecto-common/lib/types/EctoCommonTypes';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import APIGen, {
  DestinationConfigSignalResponseModel,
  MappingResponseModel,
  SourceConfigSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useQuery } from '@tanstack/react-query';

const getMapsPromise = (
  contextSettings: ApiContextSettings,
  nodeId: string,
  abortSignal: AbortSignal
) => {
  const mapsPromise =
    nodeId != null
      ? APIGen.AdminSignals.getSignalMappingsById.promise(
          contextSettings,
          {
            nodeIds: [nodeId],
            onlyInternalMappings: false
          },
          abortSignal
        )
      : APIGen.AdminSignals.getAllSignalMappings.promise(
          contextSettings,
          abortSignal
        );

  return mapsPromise.then((result) => {
    const sourceSignals = _.flatMap(result, 'sourceConfig.configSignals');
    const destSignals = _.flatMap(result, 'destinationConfig.configSignals');
    const signalIds = _.uniq(
      _.map([...sourceSignals, ...destSignals], 'signalId')
    );

    if (signalIds.length === 0) {
      return Promise.all([Promise.resolve(result), Promise.resolve([])]);
    }

    return Promise.all([
      Promise.resolve(result),
      APIGen.AdminSignals.getProvidersBySignalIds.promise(
        contextSettings,
        {
          signalIds
        },
        abortSignal
      )
    ] as const);
  });
};

interface SignalMappingListProps {
  selectedLocation?: SingleGridNode;
  onItemSelected(mapping: MappingResponseModel): void;
  reloadTrigger?: number;
}

const SignalMappingList = ({
  selectedLocation,
  onItemSelected,
  reloadTrigger: externalReloadTrigger
}: SignalMappingListProps) => {
  const nodeId = useAdminSelector((state) => state.general.nodeId);
  const [deleteItem, setDeleteItem] = useState<MappingResponseModel>(null);
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const { contextSettings, tenantId } = useContext(TenantContext);

  const getQuery = useQuery({
    queryKey: ['signalMappingsList', selectedLocation?.nodeId],

    queryFn: ({ signal }) => {
      return getMapsPromise(contextSettings, selectedLocation.nodeId, signal);
    },

    enabled: !selectedLocation.nodeId.startsWith(ROOT_NODE_ID)
  });

  const lastReloadTrigger = useRef(externalReloadTrigger);
  useEffect(() => {
    if (externalReloadTrigger !== lastReloadTrigger.current) {
      lastReloadTrigger.current = externalReloadTrigger;
      getQuery.refetch();
    }
  }, [externalReloadTrigger, getQuery]);

  const signalData: Record<string, SignalWithProvider> = useMemo(() => {
    if (getQuery.data == null) {
      return {};
    }

    const [_signalMappings, _providers] = getQuery.data;
    const signalsWithProviders: Record<string, SignalWithProvider> = _(
      _providers
    )
      .flatMap((provider) =>
        _.map(provider.signals, (signal) => ({ ...signal, provider }))
      )
      .keyBy('signalId')
      .value();

    return signalsWithProviders;
  }, [getQuery.data]);

  const signalMappings = useMemo(() => {
    if (getQuery.data == null) {
      return [];
    }
    const [_signalMappings] = getQuery.data;

    return sortByLocaleCompare(_signalMappings, 'name');
  }, [getQuery.data]);

  const columns: DataTableColumnProps<MappingResponseModel>[] = useMemo(() => {
    const signalFormatter = (
      signals: (
        | SourceConfigSignalResponseModel
        | DestinationConfigSignalResponseModel
      )[],
      _unused: MappingResponseModel,
      signalIdx: number
    ) => {
      const items = _.map(signals, (signal) => {
        const signalInfo = signalData[signal.signalId];
        let title = null;
        let nodeNameSuffix = null;
        let signalNodeId = null;

        if (signalInfo != null) {
          title =
            signalInfo.name + ' - ' + signalInfo.provider.signalProviderName;
        }

        if (signalInfo?.provider?.nodeIds.length === 1) {
          const id = _.head(signalInfo.provider.nodeIds);
          const eqNode = getEquipmentNode(id, nodeMap, equipmentMap);
          const node = eqNode ?? getNodeFromMap(nodeMap, id);

          if (node != null && node.nodeId !== nodeId) {
            nodeNameSuffix = node.name;
            signalNodeId = node.nodeId;
          }
        }

        if (nodeNameSuffix != null) {
          return (
            <TableColumn
              key={signalIdx}
              title={title}
              subtitle={nodeNameSuffix}
              subtitleLink={getLocationRoute(
                tenantId,
                signalNodeId,
                'signalmapping'
              )}
            />
          );
        }

        return title;
      });

      return <>{items}</>;
    };

    return [
      {
        label: T.admin.signalmapping.common.name,
        dataKey: 'name',
        minWidth: 120
      },
      {
        label: T.admin.signalmapping.source.title,
        dataKey: 'sourceConfig.configSignals',
        dataFormatter: signalFormatter
      },
      {
        label: T.admin.signalmapping.destination.title,
        dataKey: 'destinationConfig.configSignals',
        dataFormatter: signalFormatter
      },
      ...standardColumns({
        onEdit: onItemSelected,
        onDelete: setDeleteItem
      })
    ];
  }, [
    onItemSelected,
    setDeleteItem,
    signalData,
    nodeMap,
    equipmentMap,
    nodeId,
    tenantId
  ]);

  return (
    <>
      <DataTable<MappingResponseModel>
        className={styles.listTable}
        isLoading={getQuery.isLoading}
        data={signalMappings}
        hasError={getQuery.error != null}
        columns={columns}
        onClickRow={onItemSelected}
        noDataText={T.admin.signalmapping.nodata}
        numRowsToShowPerScroll={100}
      />
      <DeleteSignalMappingModal
        deleteItem={deleteItem}
        onSuccess={getQuery.refetch}
        onModalClose={() => setDeleteItem(null)}
      />
    </>
  );
};

export default React.memo(SignalMappingList);
