import React, { useCallback, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';

import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';

import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import { SignalProviderType } from 'ecto-common/lib/API/APIGen';
import styles from './EditSignalProviderType.module.css';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { usePromptMessage } from 'ecto-common/lib/hooks/useBlockerListener';

interface EditSignalProviderTypeDetailsProps {
  onTemplateNameChanged(newName: string): void;
  templateName: string;
  hasUnsavedChanges: boolean;
  onDeleteTemplate(): void;
  onSaveDetails(): void;
  signalProviderType: string;
  isLoading: boolean;
}

const EditSignalProviderTypeDetails = ({
  onTemplateNameChanged,
  templateName,
  hasUnsavedChanges,
  onDeleteTemplate,
  onSaveDetails,
  signalProviderType,
  isLoading
}: EditSignalProviderTypeDetailsProps) => {
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  const deleteEquipment = useCallback(() => {
    setShowConfirmDeleteDialog(false);
    onDeleteTemplate();
  }, [setShowConfirmDeleteDialog, onDeleteTemplate]);

  const onModalClose = useCallback(
    () => setShowConfirmDeleteDialog(false),
    [setShowConfirmDeleteDialog]
  );

  usePromptMessage(T.admin.form.unsavedstate, hasUnsavedChanges);
  return (
    <>
      <KeyValueLine>
        <KeyValueInput
          className={styles.nameInput}
          onChange={(e) => onTemplateNameChanged(e.target.value)}
          value={templateName}
          keyText={T.admin.alarmtemplates.displayname}
          placeholder={T.admin.alarmtemplates.displayname}
        />
      </KeyValueLine>

      <div className={styles.buttonContainer}>
        {signalProviderType === SignalProviderType.Alarm && (
          <DeleteButton onClick={() => setShowConfirmDeleteDialog(true)}>
            {T.admin.alarmtemplates.delete}
          </DeleteButton>
        )}

        <LocalizedButtons.Save
          disabled={!hasUnsavedChanges || isLoading}
          onClick={onSaveDetails}
        />

        <ConfirmDeleteDialog
          isOpen={showConfirmDeleteDialog}
          onDelete={deleteEquipment}
          onModalClose={onModalClose}
          itemName={templateName}
        />
      </div>
    </>
  );
};

export default EditSignalProviderTypeDetails;
