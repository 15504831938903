import React, { useState } from 'react';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import T from 'ecto-common/lib/lang/Language';
import styles from './EditComfortDeployTools.module.css';
import DeployEnergyManager from 'js/components/ManageEquipment/DeployEnergyManager/DeployEnergyManager';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import EditComfortSignals from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortSignals';
import classNames from 'classnames';
import _ from 'lodash';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { ComfortHeatingProviderResponseModel } from 'ecto-common/lib/API/APIGen';

interface EditComfortDeployToolsProps {
  deviceStatusReloadTrigger?: number;
  tools?: ComfortHeatingProviderResponseModel[];
}

const EditComfortDeployTools = ({
  tools,
  deviceStatusReloadTrigger
}: EditComfortDeployToolsProps) => {
  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const equipmentId = _.find(tools, 'equipmentId')?.equipmentId;
  // OK to use any of the equipments since they should share the same EM.
  const equipment = equipmentId
    ? getNodeFromMap(equipmentMap, equipmentId)
    : null;
  const [deviceInSync, setDeviceInSync] = useState(false);

  return (
    <>
      {equipment && (
        <DeployEnergyManager
          equipment={equipment}
          showFileHashes={false}
          showInfoButtons={false}
          setDeviceInSync={setDeviceInSync}
          deviceStatusReloadTrigger={deviceStatusReloadTrigger}
        />
      )}
      <div className={classNames(!deviceInSync && styles.disabled)}>
        <KeyValueGeneric
          keyText={
            tools.length > 1
              ? T.format(
                  T.admin.comfort.signals.signalgroupformat,
                  tools.length
                )
              : T.admin.comfort.signals.signalgroup
          }
        >
          <EditComfortSignals tools={tools} />
        </KeyValueGeneric>
      </div>
    </>
  );
};

export default EditComfortDeployTools;
