import React, { useState, useCallback } from 'react';
import classNames from 'classnames';

import Button from 'ecto-common/lib/Button/Button';
import GreyButton from 'ecto-common/lib/Button/GreyButton';

import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { AddIcon } from 'ecto-common/lib/Icon';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';

import styles from './AddEquipmentTypeDialog.module.css';
import { AddOrUpdateEquipmentTypeRequestModel } from 'ecto-common/lib/API/APIGen';

interface AddEquipmentTypeDialogProps {
  onModalClose: () => void;
  onAddEquipmentType?(typeReq: AddOrUpdateEquipmentTypeRequestModel): void;
  isOpen?: boolean;
  isLoading?: boolean;
}

const AddEquipmentTypeDialog = ({
  onModalClose,
  onAddEquipmentType,
  isOpen,
  isLoading
}: AddEquipmentTypeDialogProps) => {
  const [name, setName] = useState('');

  const addEquipment = useCallback(() => {
    if (name === '') {
      return;
    }

    onAddEquipmentType({
      name
    });
  }, [name, onAddEquipmentType]);

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setName(event.target.value);
    },
    []
  );

  const _onModalClose = useCallback(() => {
    setName('');
    onModalClose();
  }, [onModalClose]);

  return (
    <Modal
      className={styles.dialog}
      onModalClose={_onModalClose}
      isOpen={isOpen}
      disableClose={isLoading}
    >
      <ModalHeader titleIcon={AddIcon}>
        <span>{T.admin.equipmentsignaltemplates.addnew}</span>
      </ModalHeader>

      <ModalBody loading={isLoading} className={classNames(styles.settingBody)}>
        <TextInput
          onChange={onNameChange}
          value={name}
          autoFocus
          wrapperClassName={styles.inputField}
          placeholder={T.admin.equipmentsignaltemplates.equipmentname}
        />
      </ModalBody>

      <ModalFooter>
        <ModalSpace />

        <Button
          disabled={isNullOrWhitespace(name) || isLoading}
          onClick={addEquipment}
        >
          {T.common.iconbutton.add.title}
        </Button>

        <GreyButton disabled={isLoading} onClick={_onModalClose}>
          {T.common.actionmodal.cancel}
        </GreyButton>
      </ModalFooter>
    </Modal>
  );
};

export default AddEquipmentTypeDialog;
