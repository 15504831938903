import React, { memo } from 'react';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import HelpPaths from 'ecto-common/help/tocKeys';

import BuildingsSearchOptions from './BuildingsSearchOptions';
import BuildingsResultTable from './BuildingsResultTable';

const BuildingsList = () => {
  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      toolbarItems={<BuildingsSearchOptions />}
      title={T.admin.buildings.title}
      helpPath={HelpPaths.docs.admin.installation.buildings}
    >
      <BuildingsResultTable />
    </ToolbarContentPage>
  );
};

export default memo(BuildingsList);
