import React, { useState, useCallback, useEffect } from 'react';

import Map, { MapLocation } from 'ecto-common/lib/Map/Map';
import MapMarker from 'ecto-common/lib/Map/MapMarker';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import _ from 'lodash';

const initialMapZoom = 14;

interface DraggableMarkerMapProps {
  onCoordinateChanged(lat: number, lng: number): void;
  initialLatitude: number;
  initialLongitude: number;
  initialZoom?: number;
  disableMarker?: boolean;
  onMapsLoaded?(): void;
  searchable?: boolean;
  onSelectLocationFromSearch?(location: MapLocation, street: string): void;
}

const DraggableMarkerMap = ({
  onCoordinateChanged,
  initialLatitude,
  initialLongitude,
  onMapsLoaded = null,
  disableMarker = false,
  initialZoom = initialMapZoom,
  searchable,
  onSelectLocationFromSearch
}: DraggableMarkerMapProps) => {
  const language = useCommonSelector((state) => state.general.language);
  const [center, setCenter] = useState([initialLatitude, initialLongitude]);
  const [markerCenter, setMarkerCenter] = useState([
    initialLatitude,
    initialLongitude
  ]);

  useEffect(() => {
    setCenter([initialLatitude, initialLongitude]);
    setMarkerCenter([initialLatitude, initialLongitude]);
  }, [initialLatitude, initialLongitude]);

  const onDragEnd = useCallback(
    (_e: MouseEvent, drag: { latLng: { lat: number; lng: number } }) => {
      setMarkerCenter([drag.latLng.lat, drag.latLng.lng]);
      onCoordinateChanged(drag.latLng.lat, drag.latLng.lng);
    },
    [onCoordinateChanged]
  );

  return (
    <Map
      zoom={initialZoom}
      center={center}
      onMapsLoaded={onMapsLoaded}
      searchable={searchable}
      setMarkerCenter={setMarkerCenter}
      onSelectLocationFromSearch={onSelectLocationFromSearch}
      language={language}
    >
      {!disableMarker && (
        <MapMarker
          lat={markerCenter[0]}
          lng={markerCenter[1]}
          draggable
          onDragStart={_.noop}
          onDrag={_.noop}
          onDragEnd={onDragEnd}
        />
      )}
    </Map>
  );
};

export default DraggableMarkerMap;
