import { ROW_HEIGHT } from 'js/components/EnergyManagers/DeviceListTable';
import Modal from 'ecto-common/lib/Modal/Modal';
import styles from 'js/components/ManageEquipment/ProvisionDevice/ProvisionDevice.module.css';
import { ProvisionDeviceActions } from 'js/modules/provisionDevice/provisionDevice';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import DeviceList from 'js/components/EnergyManagers/DeviceList';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import React, { useState } from 'react';
import { IoTDeviceViewResponseModel } from 'ecto-common/lib/API/APIGen';
import { useAdminDispatch } from 'js/reducers/storeAdmin';

interface SelectNewDeviceDialogProps {
  iotDevice?: IoTDeviceViewResponseModel;
  showEnergyManagerDialog?: boolean;
}

/**
 * Dialog for selecting a new iot device for the current energy manager equipment
 */
const SelectNewDeviceDialog = ({
  iotDevice,
  showEnergyManagerDialog
}: SelectNewDeviceDialogProps) => {
  const dispatch = useAdminDispatch();
  const reservedSpace = 460;
  const availableHeight = document.documentElement.clientHeight - reservedSpace;
  const pageSize = Math.max(3, Math.floor(availableHeight / ROW_HEIGHT));
  const [filterParams, setFilterParams] = useState(undefined);

  return (
    <Modal
      className={styles.provisioningModal}
      isOpen={showEnergyManagerDialog}
      onModalClose={() =>
        dispatch(ProvisionDeviceActions.showEnergyManagerDialog(false))
      }
    >
      <ModalHeader titleIcon={Icons.Connect}>
        {iotDevice == null
          ? T.admin.energymanager.selectiotdevice
          : T.admin.energymanager.changeiotdevice}
      </ModalHeader>
      <ModalBody className={styles.provisioningBody}>
        <div className={styles.provisioningContainer}>
          <DeviceList
            onSearch={setFilterParams}
            pageSize={pageSize}
            onDeviceSelected={(device) => {
              dispatch(
                ProvisionDeviceActions.setNewDeviceToConfirm(device.ioTDevice)
              );
            }}
            useAsSelector
            key={showEnergyManagerDialog + ''}
            filterParams={filterParams}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        {iotDevice && (
          <DeleteButton
            onClick={() =>
              dispatch(ProvisionDeviceActions.setShouldConfirmUnlink(true))
            }
          >
            {T.admin.energymanager.unlinkiotdevice}
          </DeleteButton>
        )}
        <ModalSpace />
        <GreyButton
          onClick={() =>
            dispatch(ProvisionDeviceActions.showEnergyManagerDialog(false))
          }
        >
          {T.common.cancel}
        </GreyButton>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectNewDeviceDialog);
