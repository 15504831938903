import React from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import HelpPaths from 'ecto-common/help/tocKeys';
import { adminHomeUrlBuilder } from 'js/utils/linkUtil';

interface EquipmentsToolbarPageProps {
  toolbarItems?: React.ReactNode;
  children?: React.ReactNode;
}

const EquipmentsToolbarPage = ({
  children,
  toolbarItems = []
}: EquipmentsToolbarPageProps) => (
  <ToolbarContentPage
    title={T.admin.tabs.locations}
    selectEquipment
    urlBuilder={adminHomeUrlBuilder}
    toolbarItems={toolbarItems}
    helpPath={HelpPaths.docs.admin.manage.equipment.equipment}
  >
    {children}
  </ToolbarContentPage>
);

export default EquipmentsToolbarPage;
