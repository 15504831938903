/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ByteReadOnlyMemory {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
  span?: ByteReadOnlySpan;
}

export interface ByteReadOnlySpan {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
}

export interface EctocloudDashboardContentRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  tenantId: string;
  /** @minLength 1 */
  nodeTenantId: string;
  /** @minLength 1 */
  gridId: string;
  /** @minLength 1 */
  nodeId: string;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface RoomPatchRequest {
  name?: string | null;
  company?: string | null;
  location?: string | null;
  contactPerson?: string | null;
  tenantId?: string | null;
  screenContentsIds?: string[] | null;
}

export interface RoomRequest {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  company: string;
  /** @minLength 1 */
  location: string;
  /** @minLength 1 */
  contactPerson: string;
  /** @minLength 1 */
  tenantId: string;
  screenContentsIds?: string[];
}

export interface RoomResponse {
  id?: string;
  name?: string;
  company?: string;
  location?: string;
  contactPerson?: string;
  tenantId?: string;
  screenContentsIds?: string[];
}

export interface RoomResponseListResponse {
  items?: RoomResponse[];
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface RoomScreenUpdateRequest {
  screenContentsIds?: string[];
}

export interface RoomUpdateRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  company: string;
  /** @minLength 1 */
  location: string;
  /** @minLength 1 */
  contactPerson: string;
}

export interface ScreenContentTextRequest {
  /** @minLength 1 */
  text: string;
}

export enum ScreenContentType {
  Image = 'Image',
  Video = 'Video',
  Text = 'Text',
  EctocloudDashboard = 'EctocloudDashboard',
  WebPage = 'WebPage'
}

export interface ScreenContentWebPageRequest {
  /** @format uri */
  webPageUri: string;
}

export interface ScreenContentsRequest {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  tenantId: string;
}

export interface ScreenContentsResponse {
  id?: string;
  name?: string;
  tenantId?: string;
  screen1?: ScreenContentsResponseScreenContent | null;
  screen2?: ScreenContentsResponseScreenContent | null;
  screen3?: ScreenContentsResponseScreenContent | null;
  fullWallScreen?: ScreenContentsResponseScreenContent | null;
}

export interface ScreenContentsResponseListResponse {
  items?: ScreenContentsResponse[];
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface ScreenContentsResponseScreenContent {
  screenContentType?: ScreenContentType;
  text?: string;
  filename?: string;
  /** @format uri */
  contentUri?: string | null;
  /** @format date-time */
  upladed?: string;
  uploadedBy?: string;
  ectocloudDashboard?: ScreenContentsResponseScreenContentEctocloudDashboard | null;
}

export interface ScreenContentsResponseScreenContentEctocloudDashboard {
  name?: string;
  tenantId?: string;
  nodeTenantId?: string;
  gridId?: string;
  nodeId?: string;
}

export interface ListRoomsParams {
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface ListScreenContentForRoomParams {
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
  id: string;
}

export interface ListScreenContentParams {
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

/**
 * @title Administration Structure API
 * @version admin
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.EctotableAPIGen;

export interface AdminRoomPartialUpdatePath {
  id: string;
}

export interface AdminRoomUpdatePath {
  id: string;
}

export interface GetRoomPath {
  id: string;
}

export interface AdminRoomScreensUpdatePath {
  id: string;
}

export interface ListScreenContentForRoomPath {
  id: string;
}

export interface GetScreenContentConfigurationPath {
  id: string;
}

export interface PostScreenContentFilePath {
  id: string;
  /** @pattern ^(1|2|3|fullwall)$ */
  index: string;
}

export interface PostScreenContentTextPath {
  id: string;
  /** @pattern ^(1|2|3|fullwall)$ */
  index: string;
}

export interface PostScreenContentWebPagePath {
  id: string;
  /** @pattern ^(1|2|3|fullwall)$ */
  index: string;
}

export interface PostScreenEctocloudDashboardContentPath {
  id: string;
  /** @pattern ^(1|2|3|fullwall)$ */
  index: string;
}

const EctotableAPIGen = {
  RoomAdmin: {
    adminRoomCreate: apiEndpoint<
      RoomRequest,
      RoomResponse,
      ProblemDetails | void
    >(
      Method.POST,
      `/api/admin/room`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Lists all operations for a room, including references to screens and ectocloud * @summary Lists all registred rooms for a tenant
     */
    listRooms: apiEndpointEmptyUsingQueryParams<
      ListRoomsParams,
      RoomResponseListResponse,
      void | ProblemDetails
    >(
      Method.GET,
      `/api/admin/room`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    adminRoomPartialUpdate: apiEndpointWithPath<
      RoomPatchRequest,
      RoomResponse,
      ProblemDetails | void,
      AdminRoomPartialUpdatePath
    >(
      Method.PATCH,
      ({ id }: AdminRoomPartialUpdatePath) => `/api/admin/room/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    adminRoomUpdate: apiEndpointWithPath<
      RoomUpdateRequest,
      RoomResponse,
      ProblemDetails | void,
      AdminRoomUpdatePath
    >(
      Method.PUT,
      ({ id }: AdminRoomUpdatePath) => `/api/admin/room/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getRoom: apiEndpointEmptyWithPath<
      RoomResponse,
      void | ProblemDetails,
      GetRoomPath
    >(
      Method.GET,
      ({ id }: GetRoomPath) => `/api/admin/room/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    adminRoomScreensUpdate: apiEndpointWithPath<
      RoomScreenUpdateRequest,
      RoomResponse,
      ProblemDetails | void,
      AdminRoomScreensUpdatePath
    >(
      Method.PUT,
      ({ id }: AdminRoomScreensUpdatePath) => `/api/admin/room/${id}/screens`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listScreenContentForRoom: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<ListScreenContentForRoomParams, keyof ListScreenContentForRoomPath>,
      ScreenContentsResponseListResponse,
      void | ProblemDetails,
      ListScreenContentForRoomPath
    >(
      Method.GET,
      ({ id }: ListScreenContentForRoomPath) => `/api/admin/room/${id}/screens`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  ScreenContentAdmin: {
    adminScreencontentCreate: apiEndpoint<
      ScreenContentsRequest,
      ScreenContentsResponseScreenContent,
      ProblemDetails | void
    >(
      Method.POST,
      `/api/admin/screencontent`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listScreenContent: apiEndpointEmptyUsingQueryParams<
      ListScreenContentParams,
      ScreenContentsResponseListResponse,
      void | ProblemDetails
    >(
      Method.GET,
      `/api/admin/screencontent`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getScreenContentConfiguration: apiEndpointEmptyWithPath<
      ScreenContentsResponse,
      void | ProblemDetails,
      GetScreenContentConfigurationPath
    >(
      Method.GET,
      ({ id }: GetScreenContentConfigurationPath) =>
        `/api/admin/screencontent/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    postScreenContentFile: apiEndpointWithPath<
      {
        /** @format binary */
        file?: File;
      },
      ScreenContentsResponse,
      ProblemDetails | void,
      PostScreenContentFilePath
    >(
      Method.POST,
      ({ id, index }: PostScreenContentFilePath) =>
        `/api/admin/screencontent/${id}/screen/${index}/file`,
      apiTitle,
      ContentType.FormData,
      ContentType.Json,
      ContentType.Json
    ),
    postScreenContentText: apiEndpointWithPath<
      ScreenContentTextRequest,
      ScreenContentsResponse,
      ProblemDetails | void,
      PostScreenContentTextPath
    >(
      Method.POST,
      ({ id, index }: PostScreenContentTextPath) =>
        `/api/admin/screencontent/${id}/screen/${index}/text`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    postScreenContentWebPage: apiEndpointWithPath<
      ScreenContentWebPageRequest,
      ScreenContentsResponse,
      ProblemDetails | void,
      PostScreenContentWebPagePath
    >(
      Method.POST,
      ({ id, index }: PostScreenContentWebPagePath) =>
        `/api/admin/screencontent/${id}/screen/${index}/webpage`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    postScreenEctocloudDashboardContent: apiEndpointWithPath<
      EctocloudDashboardContentRequest,
      ScreenContentsResponse,
      ProblemDetails | void,
      PostScreenEctocloudDashboardContentPath
    >(
      Method.POST,
      ({ id, index }: PostScreenEctocloudDashboardContentPath) =>
        `/api/admin/screencontent/${id}/screen/${index}/ectoclouddashboard`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmpty<object, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  }
};

export default EctotableAPIGen;
