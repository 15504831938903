import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import Button from 'ecto-common/lib/Button/Button';
import React from 'react';

interface KeyValueButtonProps {
  /**
   * Text describing the button. Shown above the button with a small font.
   */
  keyText: string;
  /**
   * Title of the button
   */
  title?: string;
  /**
   * Optional icon for button
   */
  icon?: React.ReactNode;
  /**
   * Callback function which is triggered when the button is clicked.
   */
  onClick?(): void;
  /**
   * Whether or not the button is disabled.
   */
  disabled?: boolean;
}

/**
 * Annotates a button with a key title, similar to KeyValueInput.
 *
 * TODO: Is this really necessary? KeyValueGeneric + Button might be better. Also the button expands to take 100% width which is unexpected.
 */
export const KeyValueButton = ({
  keyText,
  disabled,
  onClick,
  title,
  icon
}: KeyValueButtonProps) => {
  return (
    <div className={styles.keyValueContainer}>
      <KeyValueLabel>{keyText}</KeyValueLabel>
      <Button
        className={styles.keyValueButton}
        disabled={disabled}
        onClick={() => onClick()}
      >
        {icon} {title}
      </Button>
    </div>
  );
};
