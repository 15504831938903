import React, { useMemo } from 'react';
import UUID from 'uuidjs';
import { getButtonCSSStyles } from 'ecto-common/lib/Button/Button';

type FileButtonProps = {
  children: React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
  disabled?: boolean;
};

const FileButton = ({
  children,
  onChange,
  isLoading,
  disabled
}: FileButtonProps) => {
  const buttonId = useMemo(() => {
    return 'file-input' + UUID.generate();
  }, []);

  const buttonClassNames = getButtonCSSStyles(
    isLoading,
    disabled,
    false,
    false,
    false,
    false
  );
  return (
    <>
      <label className={buttonClassNames} htmlFor={buttonId}>
        {children}
      </label>
      <input
        disabled={disabled || isLoading}
        id={buttonId}
        type="file"
        onChange={onChange}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default React.memo(FileButton);
