import React, { useCallback, useEffect, useState } from 'react';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import ManageUsers, {
  useManageUsersPaging
} from 'js/components/ManageUsers/ManageUsers';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { DEFAULT_TABLE_PAGE_SIZE_PAGE } from 'ecto-common/lib/utils/constants';
import T from 'ecto-common/lib/lang/Language';
import AdminPage from 'js/components/AdminPage';

const ManageUsersPageContent = () => {
  const [newUser, setNewUser] = useState<object>(null);
  const { paging, setSearchFilter, setPageNumber } = useManageUsersPaging(
    DEFAULT_TABLE_PAGE_SIZE_PAGE
  );

  const onShowAddUser = useCallback(() => {
    setNewUser({
      userId: null
    });
  }, []);

  const toolbarItems = (
    <>
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <AddButton onClick={onShowAddUser}>
          {T.admin.tenants.editusers.add}
        </AddButton>
      </ToolbarItem>
      <ToolbarSearch value={paging.filter} onChange={setSearchFilter} />
    </>
  );

  useEffect(() => {
    document.title = T.admin.tenants.editusers.title;
  }, []);

  return (
    <ToolbarContentPage
      title={T.admin.tenants.editusers.title}
      showLocationPicker={false}
      wrapContent={false}
      toolbarItems={toolbarItems}
    >
      <ManageUsers
        newUser={newUser}
        setNewUser={setNewUser}
        paging={paging}
        setPageNumber={setPageNumber}
        tenantId={null} // Set explicitly so that we use the "current tenant" API:s
      />
    </ToolbarContentPage>
  );
};

const ManageUsersPage = () => {
  return <AdminPage content={<ManageUsersPageContent />} />;
};

export default React.memo(ManageUsersPage);
