import admin from './admin';
import reqState from './reqState';
import editEquipmentTools from '../modules/editEquipmentTools/editEquipmentTools';
import provisionDevice from '../modules/provisionDevice/provisionDevice';
import createNodeForm from '../modules/createNodeForm/createNodeForm';
import equipmentTemplateForm from '../modules/equipmentTemplateForm/equipmentTemplateForm';
import instantiateEmptyBuilding from '../modules/instantiateEmptyBuilding/instantiateEmptyBuilding';
import addEquipmentForm from '../modules/addEquipmentForm/addEquipmentForm';
import signals from 'ecto-common/lib/modules/signals/signals';
import general from 'ecto-common/lib/reducers/general';
import buildings from '../modules/buildings/buildings';
import { CLEAR_REDUX_STATE } from 'ecto-common/lib/actions/actionTypes';
import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const appReducer = combineReducers({
  admin,
  general,
  reqState,
  editEquipmentTools,
  provisionDevice,
  createNodeForm,
  equipmentTemplateForm,
  instantiateEmptyBuilding,
  addEquipmentForm,
  signals,
  buildings
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AdminRootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: AdminRootState, action) => {
  if (action.type === CLEAR_REDUX_STATE) {
    state = {} as AdminRootState;
  }

  return appReducer(state, action as never);
};

const store = configureStore({
  reducer: rootReducer,
  // Disable as dev tools do not work well, and they slow everything down
  devTools: false,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    });
  }
});

export type AdminGetState = () => AdminRootState;
export type AdminDispatch = typeof store.dispatch;
export const useAdminDispatch: () => AdminDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAdminSelector: TypedUseSelectorHook<AdminRootState> =
  useSelector;

export default store;
