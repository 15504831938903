const waitUntil = (then: number) => {
  let interval: ReturnType<typeof setInterval>;

  const promise = new Promise((resolve) => {
    interval = setInterval(() => {
      const now = new Date().getTime();

      if (now >= then) {
        clearInterval(interval);
        resolve();
      }
    }, 1000);
  }) as Promise<void>;

  return promise;
};

export default waitUntil;
