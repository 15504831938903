import React, { useMemo } from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import Grid from 'ecto-common/lib/Grid/Grid';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import EditIotDeviceNetworkStatusHeader from 'js/components/EnergyManagers/EditIotDeviceNetworkStatusHeader';
import SignalStrengthIllustration from 'ecto-common/lib/SignalStrengthIllustration/SignalStrengthIllustration';
import {
  HardwareReportedWirelessSettings,
  DefaultWirelessNetworkInterfaceModel,
  NetworkInterfaceType
} from 'js/components/EnergyManagers/Models/HardwareSettingsModel';
import { IoTDeviceSettingsResponseModel } from 'ecto-common/lib/API/APIGen';
import getRangesBasedOnAccessTechnology from 'ecto-common/lib/SignalStrengthIllustration/getRangesBasedOnAccessTechnology';

const WirelessNetworkSection = ({
  formState,
  onUpdateInput
}: {
  formState: IoTDeviceSettingsResponseModel;
  onUpdateInput: (dataKey: string[], value: unknown) => void;
}) => {
  const signalStrength = formState?.reportedSystem?.signalStrength;
  const accessTechnology = formState?.reportedSystem?.accessTechnology;

  const isPpp0DefaultConnection =
    formState?.reportedSystem?.defaultNetworkInterface ===
    NetworkInterfaceType.PPP0;

  const ranges = useMemo(
    () => getRangesBasedOnAccessTechnology(accessTechnology),
    [accessTechnology]
  );

  return (
    <>
      <EditIotDeviceNetworkStatusHeader
        header={T.admin.iotdevicedetails.wirelessconnection}
        isConnected={isPpp0DefaultConnection}
      />
      <div>
        <ModelForm
          models={DefaultWirelessNetworkInterfaceModel}
          onUpdateInput={onUpdateInput}
          input={formState}
        />
      </div>
      <div>
        <ModelForm
          models={HardwareReportedWirelessSettings}
          onUpdateInput={_.noop}
          input={formState}
        >
          {(renderChildModel) => (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {renderChildModel(
                  (formInput) => formInput.reportedSystem.ppp0.ipAddress
                )}
              </Grid>

              <Grid item xs={4}>
                {renderChildModel(
                  (formInput) => formInput.reportedSystem.ppp0.mask
                )}
              </Grid>

              {signalStrength && accessTechnology && (
                <Grid item xs={4}>
                  <KeyValueGeneric
                    keyText={T.admin.iotdevicedetails.signalstrength}
                  >
                    <SignalStrengthIllustration
                      ranges={ranges}
                      signalStrength={signalStrength}
                    />
                  </KeyValueGeneric>
                </Grid>
              )}
            </Grid>
          )}
        </ModelForm>
      </div>
    </>
  );
};

export default WirelessNetworkSection;
