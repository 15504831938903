import { BuildingTemplateResponseModel } from 'ecto-common/lib/API/APIGen';
import {
  PATCH_EQUIPMENT_GROUP_TEMPLATES,
  SET_EQUIPMENT_GROUP_TEMPLATES
} from 'js/actions/actionTypes';

export function patchEquipmentGroupTemplates(
  payload: BuildingTemplateResponseModel[]
) {
  return { type: PATCH_EQUIPMENT_GROUP_TEMPLATES, payload };
}

export function setEquipmentGroupTemplates(
  payload: BuildingTemplateResponseModel[]
) {
  return { type: SET_EQUIPMENT_GROUP_TEMPLATES, payload };
}
