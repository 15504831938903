import _ from 'lodash';
import { EmptySignalType } from 'ecto-common/lib/utils/constants';
import { getSignalTypeNameWithUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { useMemo } from 'react';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

type SignalWithSignalTypeId = {
  signalTypeId: string;
};

/**
 * Endpoints do not embed signal type objects or unit objects as part of their response.
 * Use this method to expand the signals object to contain signal type, unit and formatted
 * name.
 */
export function useAnnotatedSignals<SignalType extends SignalWithSignalTypeId>(
  signals: SignalType[]
) {
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  return useMemo(
    () =>
      _.map(signals, (signal) => {
        const signalType =
          signalTypesMap[signal.signalTypeId] ?? EmptySignalType;
        const unit = signalUnitTypesMap[signalType.unitId];
        return {
          ...signal,
          signalType,
          unit,
          formattedTypeName: getSignalTypeNameWithUnit(signalType, unit)
        };
      }),
    [signalTypesMap, signalUnitTypesMap, signals]
  );
}
