import _ from 'lodash';
import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  AllAlarmSeverities,
  AlarmSeverityText
} from 'ecto-common/lib/constants';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { AlarmNotificationResponseModel } from 'ecto-common/lib/API/APIGen';
import AdminModelEditorUsersFromNode from 'js/components/AdminModelForm/Plugins/AdminModelEditorUsersFromNode';
import AdminModelEditorNotificationAlarmSignals from 'js/components/AdminModelForm/Plugins/AdminModelEditorNotificationAlarmSignals';

export const EMPTY_INPUT: AlarmNotificationResponseModel = {
  id: undefined,
  userIds: [],
  nodeIds: [],
  signalTypeIds: []
};

const createModels = (
  enableNodePicker: boolean
): ModelDefinition<AlarmNotificationResponseModel>[] => {
  let models: ModelDefinition<AlarmNotificationResponseModel>[] = [
    {
      key: (input) => input.name,
      modelType: ModelType.TEXT,
      label: T.admin.notifications.header.name,
      placeholder: T.admin.notifications.header.name
    },
    {
      key: (input) => input.severities,
      modelType: ModelType.OPTIONS,
      label: T.admin.notifications.header.severities,
      placeholder: T.admin.notifications.header.severities,
      options: AllAlarmSeverities.map((severity) => ({
        label: _.defaultTo(AlarmSeverityText[severity], _.toString(severity)),
        value: severity
      })),
      isMultiOption: true
    }
  ];

  if (enableNodePicker) {
    models = [
      ...models,
      {
        key: (input) => input.nodeIds,
        label: T.admin.notifications.header.location,
        modelType: ModelType.NODE_LIST,
        multiSelect: false,
        onDidUpdate: () => {
          return [
            [(input) => input.userIds, []],
            [(input) => input.signalTypeIds, []]
          ];
        }
      }
    ];
  }

  return [
    ...models,
    {
      key: (input) => input.userIds,
      modelType: ModelType.CUSTOM,
      render: (props, model, input) => {
        return (
          <AdminModelEditorUsersFromNode
            {...props}
            model={model}
            nodeId={_.head(input.nodeIds)}
            selectLabel={T.admin.notifications.selectusers}
          />
        );
      },
      label: T.admin.notifications.header.users,
      enabled: (input) => !_.isEmpty(input.nodeIds)
    },
    {
      key: (input) => input.signalTypeIds,
      modelType: ModelType.CUSTOM,
      render: (props, model, input) => {
        return (
          <AdminModelEditorNotificationAlarmSignals
            {...props}
            model={model}
            nodeId={_.head(input.nodeIds)}
            selectLabel={T.admin.notifications.selectsignals}
          />
        );
      },
      label: T.admin.notifications.header.signals,
      enabled: (input) => !_.isEmpty(input.nodeIds)
    }
  ];
};

export const NotificationModels = createModels(false);
export const NotificationModelsWithNodePicker = createModels(true);
