import React from 'react';
import styles from 'js/components/ManageEquipment/ProvisionDevice/ProvisionDevice.module.css';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { IoTDeviceViewResponseModel } from 'ecto-common/lib/API/APIGen';

interface DeviceActionsProps {
  iotDevice?: IoTDeviceViewResponseModel;
  onSave(): void;
  hasChanges: boolean;
  isLoading: boolean;
  onSelect(): void;
}

/**
 * Show a save and/or select/change device actions for current iotDevice on the current energy manager equipment
 */
const DeviceActions = ({
  iotDevice,
  hasChanges,
  isLoading,
  onSave,
  onSelect
}: DeviceActionsProps) => {
  return (
    <div className={styles.deviceDetailsActions}>
      {iotDevice && (
        <Button onClick={onSave} disabled={!hasChanges || isLoading}>
          <Icons.Save />
          {T.admin.energymanager.saveiotdevice}
        </Button>
      )}
      <Button disabled={isLoading} onClick={onSelect}>
        <Icons.EnergyManager />
        {iotDevice == null
          ? T.admin.energymanager.selectiotdevice
          : T.admin.energymanager.changeiotdevice}
      </Button>
    </div>
  );
};

export default React.memo(DeviceActions);
