import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants';
import _ from 'lodash';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import NetmoreIntegrationAccounts from 'js/components/IntegrationAccounts/NetmoreIntegration/NetmoreIntegrationAccounts';

const IntegrationAccounts = () => {
  const { tenantResources } = useContext(TenantContext);
  const hasAccessToNetmore = hasAccessToResource(
    ResourceType.SMART_COMFORT,
    tenantResources
  );

  const sections = useMemo(() => {
    return _.compact([
      hasAccessToNetmore && {
        title: T.admin.integration.netmore.title,
        key: 'netmore',
        view: NetmoreIntegrationAccounts
      }
    ]);
  }, [hasAccessToNetmore]);

  const [curSection, setCurSection] = useState(sections[0]);

  const segmentControl = (
    <SegmentControl>
      {sections.map((section) => (
        <SegmentControlItem
          key={section.key}
          active={curSection === section}
          onClick={() => setCurSection(section)}
        >
          {section.title}
        </SegmentControlItem>
      ))}
    </SegmentControl>
  );

  useEffect(() => {
    document.title = T.admin.tabs.integrationaccounts;
  }, []);

  if (curSection?.view == null) {
    return null;
  }

  return (
    <>
      {React.createElement(curSection.view, {
        segmentControl
      })}
    </>
  );
};

export default memo(IntegrationAccounts);
