import React from 'react';
import classNames from 'classnames';

import Heading from 'ecto-common/lib/Heading/Heading';

import T from 'ecto-common/lib/lang/Language';
import styles from 'js/components/EnergyManagers/EditIotDeviceNetworkStatusHeader.module.css';

interface EditIotDeviceNetworkStatusHeaderProps {
  header: string;
  isConnected: boolean;
}

const EditIotDeviceNetworkStatusHeader = ({
  header,
  isConnected
}: EditIotDeviceNetworkStatusHeaderProps) => (
  <div className={styles.container}>
    <Heading className={styles.header} level={3}>
      {header}
    </Heading>

    <span className={styles.dashDivider}>{' - '}</span>

    <span className={styles.statusContainer}>
      <span>
        {isConnected
          ? T.admin.iotdevicedetails.activeinternetroute
          : T.admin.iotdevicedetails.notactiveinternetroute}
      </span>

      <div
        className={classNames(
          styles.statusBall,
          isConnected && styles.isActive
        )}
      />
    </span>
  </div>
);

export default EditIotDeviceNetworkStatusHeader;
