// This file is based directly on Material UI:s implementation https://github.com/mui-org/material-ui/tree/master/packages/material-ui/src/Grid
// CSS lifted from generated JSS in browser; code from repo. Theming was removed otherwise most of the source is identical.

// See https://material-ui.com/components/grid/ for usage instructions

import React from 'react';
import styles from 'ecto-common/lib/Grid/Grid.module.css';
import classNames from 'classnames';

interface GridProps {
  /**
   * Defines the `align-content` style property.
   * It's applied for all screen sizes.
   */
  alignContent?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  /**
   * Defines the `align-items` style property.
   * It's applied for all screen sizes.
   */
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * @ignore
   */
  className?: string;
  /**
   * If `true`, the component will have the flex *container* behavior.
   * You should be wrapping *items* with a *container*.
   */
  container?: boolean;
  /**
   * Defines the `flex-direction` style property.
   * It is applied for all screen sizes.
   */
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  /**
   * If `true`, the component will have the flex *item* behavior.
   * You should be wrapping *items* with a *container*.
   */
  item?: boolean;
  /**
   * Defines the `justify-content` style property.
   * It is applied for all screen sizes.
   */
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `lg` breakpoint and wider screens if not overridden.
   */
  lg?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `md` breakpoint and wider screens if not overridden.
   */
  md?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `sm` breakpoint and wider screens if not overridden.
   */
  sm?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /**
   * Defines the space between the type `item` component.
   * It can only be used on a type `container` component.
   */
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /**
   * Defines the `flex-wrap` style property.
   * It's applied for all screen sizes.
   */
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `xl` breakpoint and wider screens.
   */
  xl?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for all the screen sizes with the lowest priority.
   */
  xs?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /**
   * If `true`, it sets `min-width: 0` on the item.
   * Refer to the limitations section of the documentation to better understand the use case.
   */
  zeroMinWidth?: boolean;
}

const Grid = (props: GridProps) => {
  const {
    alignContent = 'stretch',
    alignItems = 'stretch',
    className: classNameProp,
    container = false,
    direction = 'row',
    item = false,
    justify = 'flex-start',
    lg = false,
    md = false,
    sm = false,
    spacing = 0,
    wrap = 'wrap',
    xl = false,
    xs = false,
    zeroMinWidth = false,
    ...other
  } = props;

  const className = classNames(
    styles.root,
    {
      [styles.container]: container,
      [styles.item]: item,
      [styles.zeroMinWidth]: zeroMinWidth,
      [styles[`spacing-xs-${String(spacing)}`]]: container && spacing !== 0,
      [styles[`direction-xs-${String(direction)}`]]: direction !== 'row',
      [styles[`wrap-xs-${String(wrap)}`]]: wrap !== 'wrap',
      [styles[`align-items-xs-${String(alignItems)}`]]:
        alignItems !== 'stretch',
      [styles[`align-content-xs-${String(alignContent)}`]]:
        alignContent !== 'stretch',
      [styles[`justify-xs-${String(justify)}`]]: justify !== 'flex-start',
      [styles[`grid-xs-${String(xs)}`]]: xs !== false,
      [styles[`grid-sm-${String(sm)}`]]: sm !== false,
      [styles[`grid-md-${String(md)}`]]: md !== false,
      [styles[`grid-lg-${String(lg)}`]]: lg !== false,
      [styles[`grid-xl-${String(xl)}`]]: xl !== false
    },
    classNameProp
  );

  return <div className={className} {...other} />;
};

export default Grid;
