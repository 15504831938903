import React, { useCallback } from 'react';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import DeleteModal from 'ecto-common/lib/ConfirmDeleteDialog/DeleteModal';
import APIGen, { MappingResponseModel } from 'ecto-common/lib/API/APIGen';

interface DeleteSignalMappingModalProps {
  deleteItem?: MappingResponseModel;
  onModalClose: () => void;
  onSuccess?(): void;
}

const DeleteSignalMappingModal = ({
  deleteItem,
  onModalClose,
  onSuccess
}: DeleteSignalMappingModalProps) => {
  const deleteMutation = APIGen.AdminSignals.deleteSignalMappings.useMutation({
    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.signalmapping.request.delete.success);
      onModalClose();
      onSuccess?.();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.signalmapping.request.delete.failed);
    }
  });

  const deleteMaps = useCallback(
    () => deleteMutation.mutate([deleteItem]),
    [deleteItem, deleteMutation]
  );

  return (
    <DeleteModal
      isLoading={deleteMutation.isPending}
      deleteItem={deleteItem}
      onConfirmDelete={deleteMaps}
      onModalClose={onModalClose}
      itemName={deleteItem?.name}
    />
  );
};

export default React.memo(DeleteSignalMappingModal);
