/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ListDevicesResponse {
  devices?: DeviceModel[] | null;
}

export interface DeviceModel {
  id?: string | null;
  status?: DeviceStatus | null;
  /** @format date-time */
  statusUpdatedTime?: string | null;
  connectionState?: DeviceConnectionState | null;
  /** @format date-time */
  lastActivityTime?: string | null;
  /** @format int64 */
  version?: number | null;
  tags?: any[] | null;
  desired?: any[] | null;
  reported?: any[] | null;
}

export enum DeviceStatus {
  Enabled = 'enabled',
  Disabled = 'disabled'
}

export enum DeviceConnectionState {
  Disconnected = 'disconnected',
  Connected = 'connected'
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

/** This enumeration contains the different states that a device can have. */
export enum DeviceState {
  Manufacturing = 'manufacturing',
  Ready = 'ready',
  Provisioned = 'provisioned',
  Deprovisioned = 'deprovisioned'
}

export interface CommissionDeviceResponse {
  /** Gets the primary key of the symmetric key attestation pair. */
  primarySymmetricKey?: string | null;
  /** Gets the DPS global device endpoint. */
  dpsGlobalDeviceEndpoint?: string | null;
  /** Gets the DPS identifier scope. */
  dpsIdScope?: string | null;
}

export interface CommissionDeviceRequest {
  /**
   * Gets the device identifier. For an energy manager this is the MAC address of
   * the device and for an EIS Gateway it is the serial number. This value will
   * be used as the identifier for the enrollment i.e. the registration identifier.
   * @minLength 1
   */
  deviceId: string;
  /**
   * Gets the tags. This model contain values that will be sent to the Ectocloud gateway
   * when a device is provisioned in the IoT hub.
   */
  tags?: TagsTwinCollectionModel | null;
}

/**
 * This model contains the data that will be stored in the enrollment tags and
 * then sent to the ectocloud gateway when a device is provisioned in the IoT hub.
 * This class will be serialized and put inside the tags in an IndividualEnrollment.
 */
export interface TagsTwinCollectionModel {
  /**
   * Gets the manufacturer.
   * @minLength 1
   */
  manufacturer: string;
  /**
   * Get the date the device was manufactured.
   * @format date-time
   */
  manufacturingDate?: string;
  /**
   * Gets the model.
   * @minLength 1
   */
  model: string;
  /** Gets the Integrated Circuit Card Identifier. */
  iccid?: string | null;
  /** Gets the SIM Access Point Name (APN). */
  simApn?: string | null;
  /** Gets the notes. */
  notes?: string | null;
  /** Gets or sets the state of the device. */
  deviceState?: DeviceState;
}

export interface BulkSetDevicesToReadyRequest {
  /** A list with the device identifiers that should have their status updated to Ready. */
  deviceIds?: string[] | null;
}

export interface RegenerateSymmetricKeysResponse {
  /** Gets the primary key of the symmetric key attestation pair. */
  primaryKey?: string | null;
  /** Gets the secondary key of the symmetric key attestation pair. */
  secondaryKey?: string | null;
}

export interface DeprovisionDeviceRequest {
  /** Gets or sets the reason for deprovision. */
  reason: DeprovisionReason;
  /** Gets or sets an optional note describing why the device was deprovisioned. */
  notes?: string | null;
}

/** This enumeration contains the different reasons that we have for deprovisioning a device. */
export enum DeprovisionReason {
  Faulty = 'faulty',
  Other = 'other'
}

export interface ListDeviceModulesResponse {
  modules?: ModuleModel[] | null;
}

export interface ModuleModel {
  name?: string | null;
  /** @format date-time */
  lastStartTimeUtc?: string;
  /** @format date-time */
  lastExitTimeUtc?: string;
  /** @format date-time */
  lastRestartTimeUtc?: string;
  version?: string | null;
  /** @format int32 */
  exitCode?: number;
  runtimeStatus?: string | null;
}

export interface DevicesListDevicesParams {
  /** State of the device. */
  deviceState?: DeviceState;
}

/**
 * @title Device Management API
 * @version v1
 * @baseUrl https://app-ec-devicemanagement-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.DeviceAPIGen;

export interface DevicesGetDeviceByIdPath {
  /** The device identifier. */
  deviceId: string | null;
}

export interface DevicesCommissionDevicePath {
  commission: string;
}

export interface DevicesDecommissionDevicePath {
  /** The device identifier. */
  deviceId: string | null;
  decommission: string;
}

export interface DevicesBulkSetDevicesToReadyPath {
  bulkSetToReady: string;
}

export interface DevicesRegenerateSymmetricKeysPath {
  /** The identifier. The device identifier is the same as the registration identifier. */
  deviceId: string | null;
  regenerateSymmetricKeys: string;
}

export interface DevicesProvisionDevicePath {
  /** The device identifier. */
  deviceId: string | null;
  provision: string;
}

export interface DevicesDeprovisionDevicePath {
  /** The identifier. The device identifier is the same as the registration identifier. */
  deviceId: string | null;
  deprovision: string;
}

export interface DevicesGetModulesByDeviceIdPath {
  /** Device id */
  deviceId: string | null;
}

const DeviceAPIGen = {
  Devices: {
    /**
     * @description The underlying Azure API doesn't support paging or sorting so that is why this method is a bit different than the normal list methods. This may be modified in the future if we decide to implement server side paging. * @summary Lists all the devices matching the supplied filter.
     */
    listDevices: apiEndpointEmptyUsingQueryParams<
      DevicesListDevicesParams,
      ListDevicesResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/devices`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the device matching the supplied identifier.
     */
    getDeviceById: apiEndpointEmptyWithPath<
      DeviceModel,
      ProblemDetails,
      DevicesGetDeviceByIdPath
    >(
      Method.GET,
      ({ deviceId }: DevicesGetDeviceByIdPath) => `/api/v1/devices/${deviceId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Commissions a new device, i.e. creates new enrollment in our DPS and registers the device in the manufacturing IoT hub.
     */
    commissionDevice: apiEndpoint<
      CommissionDeviceRequest,
      CommissionDeviceResponse,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/devices:commission`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Decommissions a device, i.e. removes the device from the IoT hub and also removes the enrollment from
the DPS. If needed it will also delete the BLOB with the SSH configuration.
*/
    decommissionDevice: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      Omit<DevicesDecommissionDevicePath, 'decommission'>
    >(
      Method.POST,
      ({ deviceId }: Omit<DevicesDecommissionDevicePath, 'decommission'>) =>
        `/api/v1/devices/${deviceId}:decommission`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Updates the state to Ready for all the supplied device identifiers.
     */
    bulkSetDevicesToReady: apiEndpoint<
      BulkSetDevicesToReadyRequest,
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/devices:bulkSetToReady`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Regenerates and updates the symmetric keys for an existing enrollment.
     */
    regenerateSymmetricKeys: apiEndpointEmptyWithPath<
      RegenerateSymmetricKeysResponse,
      ProblemDetails,
      Omit<DevicesRegenerateSymmetricKeysPath, 'regenerateSymmetricKeys'>
    >(
      Method.POST,
      ({
        deviceId
      }: Omit<DevicesRegenerateSymmetricKeysPath, 'regenerateSymmetricKeys'>) =>
        `/api/v1/devices/${deviceId}:regenerateSymmetricKeys`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Provisions the device in the provisioning service. It also registers the device in ectocloud
with the values that were supplied when the enrollment was created.
*/
    provisionDevice: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      Omit<DevicesProvisionDevicePath, 'provision'>
    >(
      Method.POST,
      ({ deviceId }: Omit<DevicesProvisionDevicePath, 'provision'>) =>
        `/api/v1/devices/${deviceId}:provision`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
 * @summary Deprovisions the device in the provisioning service and also removes the device from the IoT hub.
It also removes the device from ectocloud.
*/
    deprovisionDevice: apiEndpointWithPath<
      DeprovisionDeviceRequest,
      void,
      ProblemDetails,
      Omit<DevicesDeprovisionDevicePath, 'deprovision'>
    >(
      Method.POST,
      ({ deviceId }: Omit<DevicesDeprovisionDevicePath, 'deprovision'>) =>
        `/api/v1/devices/${deviceId}:deprovision`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the information about a device's modules.
     */
    getModulesByDeviceId: apiEndpointEmptyWithPath<
      ListDeviceModulesResponse,
      ProblemDetails,
      DevicesGetModulesByDeviceIdPath
    >(
      Method.GET,
      ({ deviceId }: DevicesGetModulesByDeviceIdPath) =>
        `/api/v1/devices/${deviceId}/modules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default DeviceAPIGen;
