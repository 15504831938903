import React, { useCallback, useMemo } from 'react';
import styles from './AlarmConfigurationsModelEditor.module.css';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { number } from 'ecto-common/lib/ModelForm/formUtils';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { ComfortEnvironment } from 'js/components/ManageEquipment/EditEquipment/Comfort/comfortModelFormUtils';

const signalInfo = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: (array: ComfortAlarmConfiguration[]) => any,
  label: string,
  environment: ComfortEnvironment
): ModelDefinition<ComfortAlarmConfiguration[]> => {
  return {
    key,
    label,
    modelType: ModelType.DISABLED_TEXT,
    dataFormatter: (signalId: string) => {
      const signal = environment.signalInfo[signalId];

      if (signal != null) {
        return signal.name + ' - ' + signal.signalProviderName;
      }

      return '';
    }
  };
};

const signalNumber = number<ComfortAlarmConfiguration[]>;

const SignalSection = (
  environment: ComfortEnvironment,
  alarmIdx: number,
  idx: number
): ModelFormSectionType<ComfortAlarmConfiguration[]> => ({
  lines: [
    {
      models: [
        signalInfo(
          (array) =>
            array[alarmIdx].inputSignalConfigurations[idx].inputSignalId,
          T.admin.comfort.parameters.alarmconfiguration.inputconfiguration
            .signal,
          environment
        )
      ]
    },
    {
      models: [
        signalNumber(
          (array) => array[alarmIdx].inputSignalConfigurations[idx].minValue,
          T.admin.comfort.parameters.alarmconfiguration.inputconfiguration
            .minvalue
        ),
        signalNumber(
          (array) => array[alarmIdx].inputSignalConfigurations[idx].maxValue,
          T.admin.comfort.parameters.alarmconfiguration.inputconfiguration
            .maxvalue
        )
      ]
    }
  ]
});

const SignalSectionTwoSignals = (
  environment: ComfortEnvironment,
  alarmIdx: number,
  idx: number
): ModelFormSectionType<ComfortAlarmConfiguration[]> => ({
  lines: [
    {
      models: [
        signalInfo(
          (array) =>
            array[alarmIdx].inputSignalConfigurations[idx].inputSignalId,
          T.admin.comfort.parameters.alarmconfiguration.inputconfiguration
            .signal,
          environment
        ),
        signalInfo(
          (array) =>
            array[alarmIdx].inputSignalConfigurations[idx].inputSetSignalId,
          T.admin.comfort.parameters.alarmconfiguration.inputconfiguration
            .setsignal,
          environment
        )
      ]
    },
    {
      models: [
        signalNumber(
          (array) =>
            array[alarmIdx].inputSignalConfigurations[idx].minDiffValue,
          T.admin.comfort.parameters.alarmconfiguration.inputconfiguration
            .mindiffvalue
        ),
        signalNumber(
          (array) =>
            array[alarmIdx].inputSignalConfigurations[idx].maxDiffValue,
          T.admin.comfort.parameters.alarmconfiguration.inputconfiguration
            .maxdiffvalue
        )
      ]
    }
  ]
});

type AlarmConfigurationsModelEditorProps = Omit<
  CustomModelEditorProps<object, ComfortEnvironment>,
  'rawValue'
> & {
  rawValue: ComfortAlarmConfiguration[];
};

const AlarmConfigurationsModelEditor = ({
  rawValue: alarmConfigurations,
  environment,
  updateItem
}: AlarmConfigurationsModelEditorProps) => {
  const sections: ModelFormSectionType<
    ComfortAlarmConfiguration[],
    ComfortEnvironment
  >[] = useMemo(() => {
    return _.flatMap(alarmConfigurations, (alarmConfiguration, alarmIdx) => {
      return {
        label: alarmConfiguration.comfortAlarmType,
        initiallyCollapsed: true,
        lines: [
          {
            models: [
              signalNumber(
                (array) => array[alarmIdx].timeDelay,
                T.admin.comfort.parameters.alarmconfiguration.timedelay
              )
            ]
          },
          ...alarmConfiguration.inputSignalConfigurations.map(
            (signalConfiguration, idx) => {
              return signalConfiguration.inputSetSignalId != null
                ? SignalSectionTwoSignals(environment, alarmIdx, idx)
                : SignalSection(environment, alarmIdx, idx);
            }
          )
        ]
      };
    });
  }, [alarmConfigurations, environment]);

  const onUpdateInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string[], value: any) => {
      const newConfiguration = [...alarmConfigurations];
      _.set(newConfiguration, key, value);
      updateItem(newConfiguration);
    },
    [updateItem, alarmConfigurations]
  );

  return (
    <div className={styles.editor}>
      <ModelForm
        isLoading={environment.getSignalInfoIsLoading}
        sections={sections}
        input={alarmConfigurations}
        onUpdateInput={onUpdateInput}
        environment={environment}
      />
    </div>
  );
};

export type ComfortAlarmInputSignalConfigurations = {
  inputSetSignalId: string;
  inputSignalId: string;
  minValue: number;
  maxValue: number;
  minDiffValue: number;
  maxDiffValue: number;
};

export type ComfortAlarmConfiguration = {
  comfortAlarmType: string;
  timeDelay: number;
  inputSignalConfigurations: ComfortAlarmInputSignalConfigurations[];
};

export default AlarmConfigurationsModelEditor;
