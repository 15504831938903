import React, { useCallback, useMemo } from 'react';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import _ from 'lodash';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import APIGen, {
  SignalCategoryResponseModel
} from 'ecto-common/lib/API/APIGen';

const categoriesToOptions = (categories: SignalCategoryResponseModel[]) => {
  return _.map(categories, (category) => ({
    label: category.name,
    value: category.id
  }));
};

interface SignalCategoriesPickerProps {
  selectedCategoryIds: string[];
  onChange(categoryIds: string[]): void;
}

const SignalCategoriesPicker = ({
  selectedCategoryIds,
  onChange
}: SignalCategoriesPickerProps) => {
  const getQuery =
    APIGen.AdminSignalCategories.getAllSignalCategories.useQuery();
  const categories = useMemo(() => {
    if (getQuery.data == null) {
      return [];
    }

    return categoriesToOptions(getQuery.data);
  }, [getQuery.data]);

  const selectedOptions = useMemo(() => {
    return _.filter(categories, (category) =>
      selectedCategoryIds.includes(category.value)
    );
  }, [categories, selectedCategoryIds]);

  const _onChange = useCallback(
    (newVal: GenericSelectOption<string>[]) => {
      onChange(_.map(newVal, 'value'));
    },
    [onChange]
  );

  if (getQuery.error != null) {
    return <ErrorNotice> {T.admin.signalcategories.loaderror}</ErrorNotice>;
  }

  return (
    <Select<GenericSelectOption<string>, true>
      options={categories}
      value={selectedOptions}
      onChange={_onChange}
      isMulti
      disabled={getQuery.isLoading}
      isLoading={getQuery.isLoading}
    />
  );
};

export default React.memo(SignalCategoriesPicker);
