import React, { useCallback, useMemo, useState } from 'react';
import queryString from 'query-string';
import _ from 'lodash';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';

import DeviceListFilter, {
  DeviceListFilterParams
} from 'js/components/EnergyManagers/DeviceListFilter';
import DeviceListTable from 'js/components/EnergyManagers/DeviceListTable';
import EnergyManagerDetailsModal from 'js/components/EnergyManagers/EnergyManagerDetailsModal';
import {
  IoTQueryTypes,
  IoTStatusQueryTypes
} from 'js/components/EnergyManagers/energyManagersUtil';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { useLocation } from 'react-router';
import APIGen, {
  IoTDeviceViewResponseModel,
  IoTDeviceWithDeviceResponseModel
} from 'ecto-common/lib/API/APIGen';

const initialFilterParamState: DeviceListFilterParams = {
  searchPhrase: '',
  page: 0,
  filteredTags: [],
  queryType: IoTQueryTypes.UNPAIRED,
  statusQueryType: IoTStatusQueryTypes.ALL
};

type LocationSearchQueryType = {
  filteredTags?: string | string[];
  page?: string;
};

const getFilterParams = (locationSearch: LocationSearchQueryType) => {
  if (locationSearch.filteredTags) {
    return _.isArray(locationSearch?.filteredTags)
      ? locationSearch.filteredTags
      : [locationSearch.filteredTags];
  }

  return initialFilterParamState.filteredTags;
};

export const parseLocationSearch = (
  location: ReturnType<typeof useLocation>
): DeviceListFilterParams => {
  const parsedLocationSearch = queryString.parse(
    location?.search
  ) as LocationSearchQueryType;

  return {
    ...initialFilterParamState,
    ...parsedLocationSearch,
    filteredTags: getFilterParams(parsedLocationSearch),
    page: parsedLocationSearch?.page
      ? Number(parsedLocationSearch?.page)
      : initialFilterParamState.page
  };
};

const DELAY = 10000;

interface DeviceListProps {
  onSearch?(newParams: Partial<DeviceListFilterParams>): void;
  useAsSelector?: boolean;
  onDeviceSelected?(newDevice: IoTDeviceViewResponseModel): void;
  pageSize?: number;
  filterParams: DeviceListFilterParams;
}

const emptyDevices: IoTDeviceWithDeviceResponseModel[] = [];

/**
 * Shows a list of all IoT devices in the system
 * @param onSearch
 * @param onDeviceSelected
 * @param useAsSelector
 * @param pageSize
 * @param filterParams
 * @constructor
 */
const DeviceList = ({
  onSearch,
  onDeviceSelected,
  useAsSelector = false,
  pageSize = 15,
  filterParams = initialFilterParamState
}: DeviceListProps) => {
  const [deviceDetailId, setDeviceDetailId] = useSearchParamState(
    'edit-device-id',
    null
  );

  // Store devices in cache, otherwise a a refresh might make the
  // detail device disappear from devices variable
  const [deviceCache, setDeviceCache] = useState<
    Record<string, IoTDeviceWithDeviceResponseModel>
  >({});

  const searchQuery = APIGen.AdminIoTDevices.getIoTDevicesSearch.useQuery(
    {
      FilteredTags: filterParams.filteredTags,
      Page: filterParams.page,
      PageSize: pageSize,
      QueryType: filterParams.queryType,
      SearchPhrase: filterParams.searchPhrase,
      StatusQueryType: filterParams.statusQueryType
    },
    {
      refetchInterval: DELAY
    }
  );

  const devices = searchQuery.data?.results ?? emptyDevices;
  const summary = searchQuery.data?.summary;

  let refreshDeviceCache = false;
  for (const device of devices) {
    if (!deviceCache[device.ioTDevice?.id]) {
      refreshDeviceCache = true;
      break;
    }
  }
  if (refreshDeviceCache) {
    setDeviceCache((oldDevices) => {
      const newDevices = { ...oldDevices };
      for (const device of devices) {
        newDevices[device.ioTDevice?.id] = device;
      }
      return newDevices;
    });
  }
  const detailDevice = deviceCache[deviceDetailId];

  const getTagsQuery = APIGen.AdminIoTDevices.getIoTDeviceTags.useQuery();

  const tags = useMemo(() => {
    return sortByLocaleCompare(getTagsQuery.data ?? []);
  }, [getTagsQuery.data]);

  const isLoading = searchQuery.isLoading || getTagsQuery.isLoading;
  const lastMutationRef = React.useRef(searchQuery);
  lastMutationRef.current = searchQuery;

  const _onDeviceSelected = useCallback(
    (device: IoTDeviceWithDeviceResponseModel) => {
      if (onDeviceSelected != null) {
        onDeviceSelected(device);
      } else {
        setDeviceDetailId(device.ioTDevice?.id);
      }
    },
    [onDeviceSelected, setDeviceDetailId]
  );

  const _onModalClose = useCallback(() => {
    setDeviceDetailId(null);
  }, [setDeviceDetailId]);

  const updatesFilterParams = useCallback(
    (newValue: Partial<DeviceListFilterParams>) => {
      if (onSearch != null) {
        const newState = { ...filterParams, page: 0, ...newValue };

        onSearch(newState);
      }
    },
    [filterParams, onSearch]
  );

  const onSearchPhraseChange = useCallback(
    (searchPhrase: string) => {
      updatesFilterParams({ searchPhrase });
    },
    [updatesFilterParams]
  );

  const onQueryTypeChange = useCallback(
    (queryType: IoTQueryTypes) => {
      updatesFilterParams({ queryType });
    },
    [updatesFilterParams]
  );

  const onStatusQueryTypeChange = useCallback(
    (statusQueryType: IoTStatusQueryTypes) => {
      updatesFilterParams({ statusQueryType });
    },
    [updatesFilterParams]
  );

  const onFilteredTagsChange = useCallback(
    (filteredTags: string[]) => {
      updatesFilterParams({ filteredTags });
    },
    [updatesFilterParams]
  );

  const onPageChange = useCallback(
    (page: number) => {
      updatesFilterParams({ page });
    },
    [updatesFilterParams]
  );

  return (
    <>
      <DeviceListFilter
        filterParams={filterParams}
        tags={tags}
        onSearchPhraseChange={onSearchPhraseChange}
        onQueryTypeChange={onQueryTypeChange}
        onStatusQueryTypeChange={onStatusQueryTypeChange}
        onFilteredTagsChange={onFilteredTagsChange}
        useAsSelector={useAsSelector}
      />

      <DeviceListTable
        filterParams={filterParams}
        devices={devices}
        summary={summary}
        onDeviceSelected={_onDeviceSelected}
        useAsSelector={useAsSelector}
        pageSize={pageSize}
        onPageChange={onPageChange}
        isLoading={isLoading}
        hasError={searchQuery.isError || getTagsQuery.isError}
      />

      <EnergyManagerDetailsModal
        detailDevice={detailDevice}
        isOpen={detailDevice != null}
        onModalClose={_onModalClose}
      />
    </>
  );
};

export default React.memo(DeviceList);
