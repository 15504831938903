import React, { memo } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import IntegrationAccountList from 'js/components/IntegrationAccounts/Generic/IntegrationAccountList';
import T from 'ecto-common/lib/lang/Language';
import NetmoreAPIGen, {
  AccountResponse
} from 'ecto-common/lib/API/NetmoreAPIGen';

const columns = [
  {
    label: T.admin.integration.netmore.form.username,
    dataKey: 'username',
    linkColumn: true,
    minWidth: 120,
    dataFormatter: (value: string) => (
      <>
        <Icons.User /> {value}{' '}
      </>
    )
  },
  {
    label: T.admin.integration.netmore.form.description,
    dataKey: 'description',
    minWidth: 110
  }
];

interface NetmoreAccountsListProps {
  onSelectAccount?(account: AccountResponse): void;
  reloadAccounts?: number;
  pageSize?: number;
}

const NetmoreAccountsList = ({
  onSelectAccount,
  reloadAccounts,
  pageSize = 20
}: NetmoreAccountsListProps) => {
  return (
    <IntegrationAccountList<AccountResponse>
      onSelectAccount={onSelectAccount}
      reloadAccounts={reloadAccounts}
      pageSize={pageSize}
      columns={columns}
      promise={NetmoreAPIGen.Accounts.getAllAccounts.promise}
      sortColumn="username"
      name="netmore"
    />
  );
};

export default memo(NetmoreAccountsList);
