import React, { useState } from 'react';
import { ScreenContentsResponse } from 'ecto-common/lib/API/EctotableAPIGen';
import EditEctotableScreen from 'js/components/Ectotable/EditEctotableScreen';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import styles from './EditEctotableRoom.module.css';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';

type EditEctotableScreenContentDialogProps = {
  screenContents?: ScreenContentsResponse;
  onScreenContentsUpdated: (screenContents: ScreenContentsResponse) => void;
  onModalClose: () => void;
};

type EditEctotableScreenContentNameProps = {
  screenContents: ScreenContentsResponse;
};

const EditEctotableScreenContentName = ({
  screenContents
}: EditEctotableScreenContentNameProps) => {
  const [name, setName] = useState<string | null>(screenContents.name);

  return (
    <PlainBox className={styles.box}>
      <div>
        <KeyValueInput
          value={name}
          keyText={T.admin.ectotable.screenname}
          onChange={(e) => setName(e.target.value)}
          disabled
        />
      </div>
    </PlainBox>
  );
};

const EditEctotableScreenContentDialog = ({
  screenContents = null,
  onScreenContentsUpdated,
  onModalClose
}: EditEctotableScreenContentDialogProps) => {
  return (
    <ActionModal
      isOpen={screenContents != null}
      onModalClose={onModalClose}
      onConfirmClick={onModalClose}
      title={T.admin.ectotable.editscreencontent}
      disableCancel
      actionText={T.common.done}
      headerIcon={Icons.Edit}
    >
      {screenContents && (
        <>
          <EditEctotableScreenContentName
            screenContents={screenContents}
            key={screenContents.id}
          />
          <PlainBox className={styles.box}>
            <EditEctotableScreen
              screen={screenContents.screen1}
              index="1"
              screenContentId={screenContents.id}
              onScreenContentsUpdated={onScreenContentsUpdated}
            />
          </PlainBox>
          <PlainBox className={styles.box}>
            <EditEctotableScreen
              screen={screenContents.screen2}
              index="2"
              screenContentId={screenContents.id}
              onScreenContentsUpdated={onScreenContentsUpdated}
            />
          </PlainBox>
          <PlainBox className={styles.box}>
            <EditEctotableScreen
              screen={screenContents.screen3}
              index="3"
              screenContentId={screenContents.id}
              onScreenContentsUpdated={onScreenContentsUpdated}
            />
          </PlainBox>
          <PlainBox className={styles.box}>
            <EditEctotableScreen
              screen={screenContents.fullWallScreen}
              index="fullwall"
              screenContentId={screenContents.id}
              onScreenContentsUpdated={onScreenContentsUpdated}
            />
          </PlainBox>
        </>
      )}
    </ActionModal>
  );
};

export default React.memo(EditEctotableScreenContentDialog);
