import React, { Fragment, memo, useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import {
  BuildingStatusOptions,
  BuildingStatusText,
  BuildingStatusTimestampComparisonType,
  BuildingStatusTimestampComparisonTypeText
} from 'ecto-common/lib/utils/buildingStatusUtil';

import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';

import BuildingsExport from './BuildingsExport';

import {
  BuildingActions,
  BuildingsSearchQuery
} from 'js/modules/buildings/buildings';
import TagsSelector from 'js/components/TagsSelector/TagsSelector';
import BuildingsDatePicker from './BuildingsDatePicker';
import styles from './Buildings.module.css';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const createBuildingsOptions = (key: string) => ({
  value: key,
  label: BuildingStatusText[key]
});

const createBuildingsTimestampOptions = (
  key: BuildingStatusTimestampComparisonType
): GenericSelectOption<BuildingStatusTimestampComparisonType> => ({
  value: key,
  label: BuildingStatusTimestampComparisonTypeText[key]
});

const BuildingsSearchOptions = () => {
  const dispatch = useAdminDispatch();

  const tags = useAdminSelector((state) => state.general.nodeTags);
  const searchQuery = useAdminSelector((state) => state.buildings.searchQuery);
  const exportBuildingsReqState = useAdminSelector(
    (state) => state.buildings.exportBuildingsReqState
  );
  const selectedTags = searchQuery.tags;
  const { contextSettings } = useContext(TenantContext);

  const buildingStatusTimestamp = useMemo(() => {
    if (searchQuery.buildingStatusTimestamp == null) {
      return null;
    }

    return moment.tz(searchQuery.buildingStatusTimestamp, DEFAULT_TIMEZONE);
  }, [searchQuery.buildingStatusTimestamp]);

  const buildingStatusTimestampComparisonTypesOptions: GenericSelectOption<BuildingStatusTimestampComparisonType>[] =
    useMemo(
      () =>
        _.map(
          enumValues(BuildingStatusTimestampComparisonType),
          createBuildingsTimestampOptions
        ),
      []
    );
  const selectedBuildingStatusTimestampComparisonType = useMemo(
    () =>
      searchQuery.buildingStatusTimestampComparisonType != null
        ? createBuildingsTimestampOptions(
            searchQuery.buildingStatusTimestampComparisonType
          )
        : buildingStatusTimestampComparisonTypesOptions[0],
    [
      searchQuery.buildingStatusTimestampComparisonType,
      buildingStatusTimestampComparisonTypesOptions
    ]
  );

  const selectedBuildingStatusesOptions = useMemo(
    () => _.map(searchQuery.buildingStatuses, createBuildingsOptions),
    [searchQuery.buildingStatuses]
  );

  const newSearch = useCallback(
    (search: Partial<BuildingsSearchQuery>) =>
      dispatch(BuildingActions.getBuildings(contextSettings, search)),
    [contextSettings, dispatch]
  );

  const onUpdateStatusDateType = useCallback(
    (type: GenericSelectOption<BuildingStatusTimestampComparisonType>) =>
      newSearch({
        buildingStatusTimestampComparisonType: type.value,
        page: 0
      }),
    [newSearch]
  );

  const onUpdateStatusDate = useCallback(
    (date: Moment) => {
      return newSearch({
        buildingStatusTimestamp: date?.toISOString(),
        page: 0
      });
    },
    [newSearch]
  );

  const onUpdateSearchPhrase = useCallback(
    (newSearchPhrase: string) =>
      newSearch({
        searchPhrase: newSearchPhrase,
        page: 0
      }),
    [newSearch]
  );

  const onUpdateTagsFilter = useCallback(
    (data: GenericSelectOption<string>[]) =>
      newSearch({
        tags: _.map(data, 'value'),
        page: 0
      }),
    [newSearch]
  );

  const updateBuildingStatuses = useCallback(
    (data: GenericSelectOption<BuildingStatus>[]) =>
      newSearch({
        buildingStatuses: _.map(data, 'value'),
        page: 0
      }),
    [newSearch]
  );

  return (
    <Fragment>
      <ToolbarFlexibleSpace className={styles.flexSpace} />
      {exportBuildingsReqState.isLoading && (
        <ToolbarItem>
          <Spinner size={SpinnerSize.SMALL} />
        </ToolbarItem>
      )}
      <ToolbarItem>
        <Select<GenericSelectOption, true>
          className={styles.tagSelect}
          isMulti
          onChange={updateBuildingStatuses}
          options={BuildingStatusOptions}
          placeholder={T.admin.buildings.statusplaceholder}
          value={selectedBuildingStatusesOptions}
          isDisabled={exportBuildingsReqState.isLoading}
        />
      </ToolbarItem>
      <ToolbarItem>
        <BuildingsDatePicker
          buildingStatusTimestamp={buildingStatusTimestamp}
          onChangeTimeStamp={onUpdateStatusDate}
          timeStampTypeOptions={buildingStatusTimestampComparisonTypesOptions}
          selectedTimeStampType={selectedBuildingStatusTimestampComparisonType}
          onChangeTimeStampType={onUpdateStatusDateType}
          isDisabled={exportBuildingsReqState.isLoading}
        />
      </ToolbarItem>
      <ToolbarItem>
        <TagsSelector
          onChange={onUpdateTagsFilter}
          selectedTags={selectedTags}
          availableTags={tags}
          isDisabled={exportBuildingsReqState.isLoading}
        />
      </ToolbarItem>
      <ToolbarItem>
        <ToolbarSearch
          value={searchQuery.searchPhrase}
          onChange={onUpdateSearchPhrase}
          disabled={exportBuildingsReqState.isLoading}
        />
      </ToolbarItem>
      <ToolbarItem>
        <BuildingsExport />
      </ToolbarItem>
    </Fragment>
  );
};

export default memo(BuildingsSearchOptions);
