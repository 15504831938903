/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IntegrationPointResponse {
  /**
   * Integration Point Identifier
   * @minLength 1
   */
  id: string;
  /** @minLength 1 */
  integrationProxyId: string;
  /** @minLength 1 */
  name: string;
  /**
   * Tenant identifier
   * @minLength 1
   */
  tenantId: string;
  nodeIds?: string[];
  configuration?: Record<string, ConfigurationValue>;
  dataPoints?: Record<string, IntegrationPointResponseDataPoint>;
  /** Some proxies load some intital data at first execution. This can be like historical data. If this is true, initial data has been loaded. */
  initialDataLoaded?: boolean;
}

export interface ConfigurationValue {
  stringValue?: string | null;
  /** @format int64 */
  integerValue?: number | null;
  /** @format double */
  floatValue?: number | null;
}

export interface IntegrationPointResponseDataPoint {
  /** @format guid */
  signalId?: string;
  name?: string;
  description?: string;
  /** @format guid */
  signalTypeId?: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export enum GraphicalRepresentation {
  Unknown = 'Unknown',
  Event = 'Event',
  Step = 'Step',
  Linear = 'Linear',
  Spline = 'Spline'
}

export interface ErrorMessage {
  type?: string;
  title?: string;
  /** @format int32 */
  status?: number;
  traceId?: string;
  errors?: Record<string, string[]>;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface IntegrationPointCreateRequest {
  /** @minLength 1 */
  integrationProxyId: string;
  /** @minLength 1 */
  name: string;
  nodeIds?: string[];
  configuration?: Record<string, ConfigurationValue>;
}

export type ListResponseOfIntegrationPointResponse = ListResponse & {
  items?: IntegrationPointResponse[];
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
};

export type ListResponse = object;

export interface IntegrationPointUpdateRequest {
  /** @minLength 1 */
  name: string;
  nodeIds?: string[];
  initialDataLoaded?: boolean;
  datapoints?: Record<string, IntegrationPointItemDataPoint>;
  configuration?: Record<string, ConfigurationValue>;
}

export interface IntegrationPointItemDataPoint {
  /** @format guid */
  signalId?: string;
  name?: string;
  description?: string;
  /** @format guid */
  signalTypeId?: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationProxyResponse {
  id?: string;
  name?: string;
  configuration?: IntegrationProxyResponseConfiguration[];
  datapoints?: IntegrationProxyResponseDataPoint[];
}

export interface IntegrationProxyResponseConfiguration {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  name: string;
  format?: ConfigurationFormat;
  required?: boolean;
  floatConfiguration?: IntegrationProxyResponseConfigurationFloat | null;
  integerConfiguration?: IntegrationProxyResponseConfigurationInteger | null;
  stringConfiguration?: IntegrationProxyResponseConfigurationString | null;
}

export enum ConfigurationFormat {
  String = 'String',
  Integer = 'Integer',
  Float = 'Float'
}

export interface IntegrationProxyResponseConfigurationFloat {
  /** @format float */
  minValue?: number;
  /** @format float */
  maxValue?: number;
}

export interface IntegrationProxyResponseConfigurationInteger {
  /** @format int32 */
  minValue?: number;
  /** @format int32 */
  maxValue?: number;
}

export interface IntegrationProxyResponseConfigurationString {
  /** @minLength 1 */
  regexFormat: string;
}

export interface IntegrationProxyResponseDataPoint {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  defaultName: string;
  /** @format guid */
  signalTypeId?: string;
  required?: boolean;
  /** @minLength 1 */
  defaultDescription: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationProxyCreateRequest {
  /** @minLength 1 */
  name: string;
  description?: string;
  /** @minLength 1 */
  signalProviderType: string;
  /** @minItems 1 */
  dataPoints?: IntegrationProxyCreateRequestDataPoint[];
  /** @minItems 1 */
  configuration?: IntegrationProxyCreateRequestConfiguration[];
}

export interface IntegrationProxyCreateRequestDataPoint {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  defaultName: string;
  /** @format guid */
  signalTypeId?: string;
  required?: boolean;
  /** @minLength 1 */
  defaultDescription: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationProxyCreateRequestConfiguration {
  code?: string;
  /** @minLength 1 */
  name: string;
  format?: ConfigurationFormat;
  required?: boolean;
  floatConfiguration?: IntegrationProxyCreateRequestConfigurationFloat | null;
  integerConfiguration?: IntegrationProxyCreateRequestConfigurationInteger | null;
  stringConfiguration?: IntegrationProxyCreateRequestConfigurationString | null;
}

export interface IntegrationProxyCreateRequestConfigurationFloat {
  /** @format float */
  minValue?: number;
  /** @format float */
  maxValue?: number;
}

export interface IntegrationProxyCreateRequestConfigurationInteger {
  /** @format int32 */
  minValue?: number;
  /** @format int32 */
  maxValue?: number;
}

export interface IntegrationProxyCreateRequestConfigurationString {
  /** @minLength 1 */
  regexFormat: string;
}

export type ListResponseOfIntegrationProxyResponse = ListResponse & {
  items?: IntegrationProxyResponse[];
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
};

export interface IntegrationPointsListIntegrationPointsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface IntegrationProxiesListIntegrationProxiesParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface IntegrationProxiesListIntegrationPointsForIntegrationProxyParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
  proxyId: string;
}

/**
 * @title General Integration API
 * @version v2
 * @baseUrl https://app-ec-integration-dev-weu-001-7xkz.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.IntegrationAPIGen;

export interface IntegrationPointsGetIntegrationPointPath {
  pointId: string;
}

export interface IntegrationPointsUpdateIntegrationPointPath {
  pointId: string;
}

export interface IntegrationPointsDeleteIntegrationPointPath {
  pointId: string;
}

export interface IntegrationPointsAddNodeToIntegrationPointPath {
  pointId: string;
  nodeId: string;
}

export interface IntegrationPointsDeleteNodeFromIntegrationPointPath {
  pointId: string;
  nodeId: string;
}

export interface IntegrationProxiesGetIntegrationProxyPath {
  proxyId: string;
}

export interface IntegrationProxiesListIntegrationPointsForIntegrationProxyPath {
  proxyId: string;
}

const IntegrationAPIGen = {
  IntegrationPoints: {
    createIntegrationPoint: apiEndpoint<
      IntegrationPointCreateRequest,
      IntegrationPointResponse,
      ErrorMessage | ProblemDetails
    >(
      Method.POST,
      `/v2/IntegrationPoints`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listIntegrationPoints: apiEndpointEmptyUsingQueryParams<
      IntegrationPointsListIntegrationPointsParams,
      ListResponseOfIntegrationPointResponse,
      ProblemDetails
    >(
      Method.GET,
      `/v2/IntegrationPoints`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIntegrationPoint: apiEndpointEmptyWithPath<
      IntegrationPointResponse,
      ProblemDetails,
      IntegrationPointsGetIntegrationPointPath
    >(
      Method.GET,
      ({ pointId }: IntegrationPointsGetIntegrationPointPath) =>
        `/v2/IntegrationPoints/${pointId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateIntegrationPoint: apiEndpointWithPath<
      IntegrationPointUpdateRequest,
      void,
      ProblemDetails,
      IntegrationPointsUpdateIntegrationPointPath
    >(
      Method.PUT,
      ({ pointId }: IntegrationPointsUpdateIntegrationPointPath) =>
        `/v2/IntegrationPoints/${pointId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    deleteIntegrationPoint: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      IntegrationPointsDeleteIntegrationPointPath
    >(
      Method.DELETE,
      ({ pointId }: IntegrationPointsDeleteIntegrationPointPath) =>
        `/v2/IntegrationPoints/${pointId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    addNodeToIntegrationPoint: apiEndpointEmptyWithPath<
      IntegrationPointResponse,
      ProblemDetails,
      IntegrationPointsAddNodeToIntegrationPointPath
    >(
      Method.POST,
      ({ pointId, nodeId }: IntegrationPointsAddNodeToIntegrationPointPath) =>
        `/v2/IntegrationPoints/${pointId}/nodes/${nodeId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteNodeFromIntegrationPoint: apiEndpointEmptyWithPath<
      IntegrationPointResponse,
      ProblemDetails,
      IntegrationPointsDeleteNodeFromIntegrationPointPath
    >(
      Method.DELETE,
      ({
        pointId,
        nodeId
      }: IntegrationPointsDeleteNodeFromIntegrationPointPath) =>
        `/v2/IntegrationPoints/${pointId}/nodes/${nodeId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  IntegrationProxies: {
    createIntegrationProxy: apiEndpoint<
      IntegrationProxyCreateRequest,
      IntegrationProxyResponse,
      ErrorMessage
    >(
      Method.POST,
      `/v2/IntegrationProxies`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listIntegrationProxies: apiEndpointEmptyUsingQueryParams<
      IntegrationProxiesListIntegrationProxiesParams,
      ListResponseOfIntegrationProxyResponse,
      ProblemDetails
    >(
      Method.GET,
      `/v2/IntegrationProxies`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIntegrationProxy: apiEndpointEmptyWithPath<
      IntegrationProxyResponse,
      ProblemDetails,
      IntegrationProxiesGetIntegrationProxyPath
    >(
      Method.GET,
      ({ proxyId }: IntegrationProxiesGetIntegrationProxyPath) =>
        `/v2/IntegrationProxies/${proxyId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listIntegrationPointsForIntegrationProxy:
      apiEndpointEmptyWithPathUsingQueryParams<
        Omit<
          IntegrationProxiesListIntegrationPointsForIntegrationProxyParams,
          keyof IntegrationProxiesListIntegrationPointsForIntegrationProxyPath
        >,
        ListResponseOfIntegrationPointResponse,
        ProblemDetails,
        IntegrationProxiesListIntegrationPointsForIntegrationProxyPath
      >(
        Method.GET,
        ({
          proxyId
        }: IntegrationProxiesListIntegrationPointsForIntegrationProxyPath) =>
          `/v2/IntegrationProxies/${proxyId}/integrationpoints`,
        apiTitle,
        ContentType.Json,
        ContentType.Json,
        ContentType.Json
      )
  }
};

export default IntegrationAPIGen;
