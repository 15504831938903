import React, { useState } from 'react';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import TableSearchInput from 'ecto-common/lib/SearchableTable/TableSearchInput';
import AddButton from 'ecto-common/lib/Button/AddButton';
import HelpPaths from 'ecto-common/help/tocKeys';

import NotificationTable from './NotificationTable';
import styles from './NotificationsDialog.module.css';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';

interface NotificationsDialogProps {
  nodeId: string;
  onModalClose: () => void;
  isOpen?: boolean;
}

const NotificationsDialog = ({
  nodeId,
  onModalClose,
  isOpen
}: NotificationsDialogProps) => {
  const [searchString, onSearch] = useState('');
  const [editItemId, setEditItemId] = useSearchParamState(
    'add-edit-notification',
    null
  );

  return (
    <ActionModal
      className={styles.notificationsDialog}
      messageBodyClassName={styles.notificationModal}
      onModalClose={onModalClose}
      isOpen={isOpen}
      headerIcon={Icons.Notification}
      title={T.admin.notifications.title}
      actionText={T.common.ok}
      onConfirmClick={onModalClose}
      disableCancel
      helpPath={
        HelpPaths.docs.admin.manage.locations.add_edit_notification_dialog
      }
    >
      <div className={styles.dialogContent}>
        <TableSearchInput searchString={searchString} onSearch={onSearch}>
          <AddButton
            className={styles.topInputButton}
            onClick={() => setEditItemId('new')}
          >
            {T.admin.notifications.addnotification}
          </AddButton>
        </TableSearchInput>
        <div className={styles.tableContainer}>
          {isOpen && (
            <NotificationTable
              nodeId={nodeId}
              searchString={searchString}
              editItemId={editItemId}
              setEditItemId={setEditItemId}
            />
          )}
        </div>
      </div>
    </ActionModal>
  );
};

export default NotificationsDialog;
