import React, { useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import ConfirmRebootDialog from 'js/components/EnergyManagers/ConfirmRebootDialog';
import { KeyValueButton } from 'ecto-common/lib/KeyValueInput/KeyValueButton';
import { HardwareActionInputProps } from 'js/components/EnergyManagers/HardwareActionInput/HardwareActionInputTypes';

const RebootInput = ({ isLoading, action }: HardwareActionInputProps) => {
  const [showConfirmReboot, setShowConfirmReboot] = useState(false);

  return (
    <>
      <KeyValueButton
        keyText={T.admin.iotdevicedetails.reboot}
        title={T.admin.iotdevicedetails.reboot}
        onClick={() => setShowConfirmReboot(true)}
        disabled={isLoading}
        icon={<Icons.Reboot />}
      />
      <ConfirmRebootDialog
        isOpen={showConfirmReboot}
        onModalClose={() => setShowConfirmReboot(false)}
        onReboot={action}
      />
    </>
  );
};

export default RebootInput;
