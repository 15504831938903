import {
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';
import { CommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { getAPIFetch } from 'ecto-common/lib/utils/APIFetchInstance';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

export function updateObject<ArgType, ResponseType>(
  contextSettings: ApiContextSettings,
  path: string,
  method: string,
  reqStateConstant: string,
  payload: ArgType,
  successText: string,
  failText: string,
  blocking: boolean,
  emptyResponse = false,
  id: string = undefined
) {
  return (dispatch: CommonDispatch) => {
    dispatch({
      type: reqStateConstant,
      payload: {
        state: REQ_STATE_PENDING,
        payload: null,
        statusText: null,
        blocking,
        id
      }
    });
    const _apiFetch = getAPIFetch();

    return _apiFetch(contextSettings, path, {
      method: method,
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response: Response) => {
        if (emptyResponse) {
          return Promise.resolve({});
        }

        return response.json() as ResponseType;
      })
      .then((json: ResponseType) => {
        return dispatch({
          type: reqStateConstant,
          payload: {
            state: REQ_STATE_SUCCESS,
            payload: json,
            statusText: successText,
            blocking,
            id
          }
        });
      })
      .catch((e: Error) => {
        return dispatch({
          type: reqStateConstant,
          payload: {
            state: REQ_STATE_ERROR,
            payload: e,
            statusText: failText,
            blocking,
            id
          }
        });
      });
  };
}
