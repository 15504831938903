import React from 'react';
import setup, {
  AccessControlledRoute,
  ApplicationRoot,
  FallbackRouteHandler
} from 'ecto-common/lib/Application/Application';
import store from 'js/reducers/storeAdmin';
import {
  getAlarmUrl,
  getAlarmUrlV2,
  getSignalsUrl,
  LocationRoute
} from 'js/utils/routeConstants';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import _ from 'lodash';
import DocumentationInApp from '../../ecto-common/lib/Documentation/DocumentationInApp';
import AdminContainer from 'js/containers/AdminContainer';
import { adminRoutes } from 'js/containers/AdminRoutes';

const urlData = {
  getSignalsUrl: getSignalsUrl,
  getAlarmUrl: getAlarmUrl,
  getAlarmUrlV2: getAlarmUrlV2,
  locationRoute: LocationRoute
};

const router = createBrowserRouter([
  {
    path: '/:tenantId?',
    element: (
      <ApplicationRoot
        store={store}
        urlData={urlData}
        children={<AdminContainer />}
      />
    ),
    children: _.compact([
      process.env.DEV_BUILD && {
        path: 'documentation/:category?/*',
        element: <DocumentationInApp />
      },
      ...adminRoutes.map((route) => ({
        ...route,
        path: route.path.replace('/:tenantId/', ''),
        element: (
          <AccessControlledRoute
            route={route}
            allRoutes={adminRoutes}
            allowNoLocation
          />
        )
      })),
      {
        path: '*',
        element: (
          <FallbackRouteHandler allRoutes={adminRoutes} allowNoLocation />
        )
      },
      {
        element: <FallbackRouteHandler allRoutes={adminRoutes} />,
        index: true
      }
    ])
  }
]);

const AppContainer = () => {
  return <RouterProvider router={router} />;
};

setup(<AppContainer />);
