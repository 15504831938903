import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import AddButton from 'ecto-common/lib/Button/AddButton';
import styles from './AddEquipmentTool.module.css';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ComponentRow from 'ecto-common/lib/ComponentRow/ComponentRow';
import {
  EditState,
  EditStateData
} from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import { AdminToolType } from 'js/components/ManageEquipment/EditEquipment/useEquipmentTools';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { ToolSignalProviders } from 'js/components/ManageEquipment/EditEquipment/toolTypes';

interface AddEquipmentToolProps {
  availableTools: AdminToolType[];
  setEditStates: Dispatch<SetStateAction<Record<string, EditStateData>>>;
}

/**
 * Shows a list of available tools and calls the create function for the tool
 * @param availableTools
 * @param editStates
 * @param setEditStates
 * @returns {*}
 * @constructor
 */
const AddEquipmentTool = ({
  availableTools,
  setEditStates
}: AddEquipmentToolProps) => {
  const [selectedType, setSelectedType] = useState(
    _.head(availableTools)?.type
  );

  // Update selected type if available tools changes and selected type is not in the collection
  useEffect(() => {
    if (
      selectedType == null ||
      !_.some(availableTools, { type: selectedType })
    ) {
      setSelectedType(_.head(availableTools)?.type);
    }
  }, [availableTools, setSelectedType, selectedType]);

  // Create sorted options
  const options: GenericSelectOption<ToolSignalProviders>[] = useMemo(
    () =>
      sortByLocaleCompare(
        _.map(availableTools, (item) => {
          return { label: item.name, value: item.type };
        }),
        'label'
      ),
    [availableTools]
  );

  const selectedOption = _.find(options, { value: selectedType });

  // Calls 'onCreate' on the selected tool
  const addTool = useCallback(() => {
    setEditStates((oldValues) => ({
      ...oldValues,
      [selectedType]: { state: EditState.CREATE, data: null }
    }));
  }, [selectedType, setEditStates]);

  const onChangeSelectedType = useCallback(
    ({ value }: GenericSelectOption<ToolSignalProviders>) =>
      setSelectedType(value),
    [setSelectedType]
  );

  return (
    <ComponentRow>
      {!_.isEmpty(options) && (
        <>
          <Select<GenericSelectOption<ToolSignalProviders>, false>
            className={styles.selectable}
            options={options}
            value={selectedOption}
            onChange={onChangeSelectedType}
          />
          <AddButton onClick={addTool}>{T.common.add}</AddButton>
        </>
      )}
    </ComponentRow>
  );
};

export default React.memo(AddEquipmentTool);
