import React, { useCallback, useContext, useEffect } from 'react';
import EditIoTDevice from 'js/components/EnergyManagers/EditIoTDevice';

import T from 'ecto-common/lib/lang/Language';
import {
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS,
  REQ_STATE_ERROR
} from 'ecto-common/lib/utils/requestStatus';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import Notice from 'ecto-common/lib/Notice/Notice';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';

import { ProvisionDeviceActions } from 'js/modules/provisionDevice/provisionDevice';
import ConfirmUnlinkDialog from 'js/components/ManageEquipment/ProvisionDevice/ConfirmUnlinkDialog';
import RelinkDialog from 'js/components/ManageEquipment/ProvisionDevice/RelinkDialog';
import SelectNewDeviceDialog from 'js/components/ManageEquipment/ProvisionDevice/SelectNewDeviceDialog';
import DeviceActions from 'js/components/ManageEquipment/ProvisionDevice/DeviceActions';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import styles from 'js/components/ManageEquipment/ProvisionDevice/ProvisionDevice.module.css';
import useEditIoTDevice from 'js/components/EnergyManagers/useEditIoTDevice';
import {
  IoTDeviceViewResponseModel,
  NodeEquipmentResponseModel
} from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface ProvisionDeviceProps {
  equipment: NodeEquipmentResponseModel;
}

const ProvisionDevice = ({ equipment }: ProvisionDeviceProps) => {
  const dispatch = useAdminDispatch();
  const iotDevice: IoTDeviceViewResponseModel = useAdminSelector(
    (state) => state.provisionDevice.iotDevice
  );
  const showEnergyManagerDialog: boolean = useAdminSelector(
    (state) => state.provisionDevice.showEnergyManagerDialog
  );
  const getRequest = useAdminSelector(
    (state) => state.provisionDevice.getRequest
  );
  const pairRequest = useAdminSelector(
    (state) => state.provisionDevice.pairRequest
  );
  const unpairRequest = useAdminSelector(
    (state) => state.provisionDevice.unpairRequest
  );
  const { contextSettings } = useContext(TenantContext);

  const isLoading = getRequest.state === REQ_STATE_PENDING;
  const isSuccessful = getRequest.state === REQ_STATE_SUCCESS;
  const hasError = getRequest.state === REQ_STATE_ERROR;

  useEffect(() => {
    dispatch(
      ProvisionDeviceActions.setEnergyManagerEquipmentId(
        contextSettings,
        equipment?.equipmentId ?? null
      )
    );

    return () => {
      dispatch(
        ProvisionDeviceActions.setEnergyManagerEquipmentId(
          contextSettings,
          null
        )
      );
    };
  }, [dispatch, equipment?.equipmentId, contextSettings]);

  useEffect(() => {
    if (pairRequest.state === REQ_STATE_SUCCESS) {
      toastStore.addSuccessToast(T.admin.energymanager.pairingsuccess);
    } else if (pairRequest.state === REQ_STATE_ERROR) {
      toastStore.addErrorToast(T.admin.energymanager.pairingerror);
    }
  }, [pairRequest.state]);

  useEffect(() => {
    if (unpairRequest.state === REQ_STATE_SUCCESS) {
      toastStore.addSuccessToast(T.admin.energymanager.unpairingsuccess);
    } else if (unpairRequest.state === REQ_STATE_ERROR) {
      toastStore.addErrorToast(T.admin.energymanager.unpairingerror);
    }
  }, [unpairRequest.state]);

  const [
    editDevice,
    hasChanges,
    loadingInfo,
    saveChanges,
    ,
    onDeviceDataChanged
  ] = useEditIoTDevice(iotDevice);

  const onSelectDevice = useCallback(
    () => dispatch(ProvisionDeviceActions.showEnergyManagerDialog(true)),
    [dispatch]
  );

  return (
    <div>
      <KeyValueGeneric keyText={T.admin.energymanager.title}>
        <div className={styles.deviceDetails}>
          <>
            {isLoading && <Spinner />}

            {hasError && <ErrorNotice>{T.common.unknownerror}</ErrorNotice>}

            {isSuccessful && iotDevice == null && (
              <>
                <Notice className={styles.section}>
                  {T.admin.energymanager.nopairinginfo}
                </Notice>

                <DeviceActions
                  iotDevice={iotDevice}
                  onSave={saveChanges}
                  isLoading={loadingInfo.isSaving}
                  hasChanges={hasChanges}
                  onSelect={onSelectDevice}
                />
              </>
            )}

            {isSuccessful && iotDevice != null && (
              <div className={styles.section}>
                <EditIoTDevice
                  loadingInfo={loadingInfo}
                  editIoTDevice={editDevice}
                  onDeviceDataChanged={onDeviceDataChanged}
                  actionButtons={
                    <DeviceActions
                      iotDevice={iotDevice}
                      onSave={saveChanges}
                      isLoading={loadingInfo.isSaving}
                      hasChanges={hasChanges}
                      onSelect={onSelectDevice}
                    />
                  }
                />
              </div>
            )}
          </>
        </div>
      </KeyValueGeneric>

      <SelectNewDeviceDialog
        iotDevice={iotDevice}
        showEnergyManagerDialog={showEnergyManagerDialog}
      />

      <ConfirmUnlinkDialog iotDevice={iotDevice} />

      <RelinkDialog iotDevice={iotDevice} />
    </div>
  );
};

export default ProvisionDevice;
