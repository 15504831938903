import React, { useCallback } from 'react';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';

interface ConfirmRebootDialogProps {
  onModalClose: () => void;
  isOpen?: boolean;
  onReboot(): void;
}

const ConfirmRebootDialog = ({
  isOpen,
  onModalClose,
  onReboot
}: ConfirmRebootDialogProps) => {
  const rebootDevice = useCallback(() => {
    onReboot();
    onModalClose();
  }, [onReboot, onModalClose]);

  return (
    <ActionModal
      compact
      onModalClose={onModalClose}
      isOpen={isOpen}
      headerIcon={Icons.Tool}
      title={T.admin.iotdevicedetails.reboot}
      actionText={T.common.ok}
      onConfirmClick={rebootDevice}
    >
      {T.admin.iotdevicedetails.rebootconfirm}
    </ActionModal>
  );
};

export default ConfirmRebootDialog;
