import { RESET_COMPLETED_REQUESTS } from 'js/actions/actionTypes';

import {
  initialReqState,
  REQ_STATE_DEFAULT,
  REQ_STATE_ERROR,
  REQ_STATE_SUCCESS,
  RequestStatusRawProp
} from 'ecto-common/lib/utils/requestStatus';

export * from 'ecto-common/lib/utils/reqState';

import {
  SET_LINEAR_OPTIMISATION_REQ_STATE,
  SET_POWER_CONTROL_REQ_STATE
} from 'js/modules/editEquipmentTools/editEquipmentTools';
import { GenericActionType } from 'ecto-common/lib/types/EctoCommonTypes';
import {
  LinearOptimisationProviderResponseModel,
  PowerControlProviderResponseModel
} from 'ecto-common/lib/API/APIGen';

const setters = [
  { type: SET_POWER_CONTROL_REQ_STATE, key: 'updatePowerControl' },
  { type: SET_LINEAR_OPTIMISATION_REQ_STATE, key: 'updateLinearOptimisation' }
];

type AdminReqStateReducer = {
  updatePowerControl: RequestStatusRawProp<PowerControlProviderResponseModel[]>;
  updateLinearOptimisation: RequestStatusRawProp<
    LinearOptimisationProviderResponseModel[]
  >;
};

const initialState = {
  updatePowerControl: { ...initialReqState },
  updateLinearOptimisation: { ...initialReqState }
};

function reqState(
  state = initialState,
  action: GenericActionType = {}
): AdminReqStateReducer {
  if (action.type === RESET_COMPLETED_REQUESTS) {
    const update: Record<string, Partial<RequestStatusRawProp<unknown>>> = {};

    for (const setter of setters) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const curState = (state as any)[setter.key].state;

      if (curState === REQ_STATE_SUCCESS || curState === REQ_STATE_ERROR) {
        update[setter.key] = { state: REQ_STATE_DEFAULT, payload: undefined };
      }
    }

    return { ...state, ...update };
  }

  for (const setter of setters) {
    if (action.type === setter.type) {
      const update: Record<string, { timestamp: number }> = {};
      update[setter.key] = Object.assign(
        { timestamp: new Date().getTime() },
        action.payload
      );
      return { ...state, ...update };
    }
  }

  return state;
}

export default reqState;
