import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import PagingFooter from 'ecto-common/lib/PagingFooter/PagingFooter';
import T from 'ecto-common/lib/lang/Language';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';

import {
  BuildingActions,
  BuildingsSearchQuery
} from 'js/modules/buildings/buildings';
import createBuildingsListColumns from './BuildingsListColumns';
import styles from './Buildings.module.css';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';

const BuildingsResultTable = () => {
  const dispatch = useAdminDispatch();
  const { getBuildings, cancelGetBuildings } = BuildingActions;
  const result = useAdminSelector(
    (state) => state.buildings.getBuildingsReqState.data
  );
  const isLoading = useAdminSelector(
    (state) => state.buildings.getBuildingsReqState.isLoading
  );
  const searchQuery = useAdminSelector((state) => state.buildings.searchQuery);
  const request = useAdminSelector(
    (state) => state.buildings.getBuildingsReqState
  );
  const sortColumn = useMemo(
    () => searchQuery.sortColumn,
    [searchQuery.sortColumn]
  );
  const sortOrder = useMemo(
    () => searchQuery.sortOrder,
    [searchQuery.sortOrder]
  );
  const buildings = useAdminSelector((state) => state.buildings.buildings);
  const page = useMemo(() => Number(searchQuery.page), [searchQuery]);
  const { tenantId, contextSettings } = useContext(TenantContext);

  const [totalPages, setTotalPages] = useState(0);

  const columns = useMemo(() => {
    return createBuildingsListColumns(tenantId);
  }, [tenantId]);

  useEffect(() => {
    if (!isLoading) {
      setTotalPages(_.get(result, 'totalPages', 1));
    }
  }, [isLoading, result]);

  const newSearch = useCallback(
    (search: Partial<BuildingsSearchQuery>) =>
      dispatch(getBuildings(contextSettings, search)),
    [contextSettings, getBuildings, dispatch]
  );

  useEffect(() => {
    dispatch(getBuildings(contextSettings));

    return () => {
      cancelGetBuildings();
    };
  }, [contextSettings, dispatch, cancelGetBuildings, getBuildings]);

  const onSortChange = useCallback(
    (orderBy: string, sortDirection: string) =>
      newSearch({
        sortColumn: orderBy,
        sortOrder: sortDirection
      }),
    [newSearch]
  );

  const onPageChange = useCallback(
    (newPage: number) =>
      newSearch({
        page: newPage,
        pageSize: searchQuery.pageSize
      }),
    [searchQuery, newSearch]
  );

  return (
    <div>
      <div className={styles.summary}>
        <div className={styles.summaryItem}>
          <label>{T.admin.buildings.numberofbuildings}:</label>

          {request.isLoading && (
            <div className={styles.summaryItemSpinner}>
              <Spinner size={SpinnerSize.SMALL} />
            </div>
          )}

          {result && (
            <span className={styles.summaryText}>{result.totalRecords}</span>
          )}
        </div>
      </div>

      <DataTable
        columns={columns}
        data={buildings}
        isLoading={isLoading}
        hasError={request.hasError}
        onSortChange={onSortChange}
        noDataText={T.admin.buildings.nodatatext}
        sortBy={sortColumn}
        sortDirection={sortOrder as SortDirectionType}
      />

      <PagingFooter
        disableBackNavigation={request.isLoading}
        disableForwardNavigation={request.isLoading}
        onPageChange={onPageChange}
        totalPages={totalPages}
        page={page}
      />
    </div>
  );
};

export default memo(BuildingsResultTable);
