import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Icons from 'ecto-common/lib/Icons/Icons';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import HelpPaths from 'ecto-common/help/tocKeys';

import AddAlarmSignalGroupTemplateDialog from 'js/components/ManageTemplates/AlarmSignalTemplates/AddAlarmSignalGroupTemplateDialog';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, {
  AdminAlarmSignalGroupTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';

const _columns: DataTableColumnProps<AdminAlarmSignalGroupTemplateResponseModel>[] =
  [
    {
      dataKey: 'name',
      flexGrow: 1,
      width: 100,
      linkColumn: true,
      dataFormatter: (name: string) => (
        <>
          <Icons.File /> {name}{' '}
        </>
      )
    }
  ];

const AlarmSignalGroupTemplates = () => {
  const navigate = useNavigate();
  const [addDialogVisible, showAddDialog, hideAddDialog] = useDialogState(
    'show-add-alarm-group-dialog'
  );
  const { tenantId } = useContext(TenantContext);

  const getTemplatesQuery =
    APIGen.AdminAlarms.getAllAlarmSignalGroupTemplates.useQuery({});

  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const createMutation =
    APIGen.AdminAlarms.addOrUpdateAlarmSignalGroupTemplate.useMutation({
      onSuccess: (_unused, args) => {
        hideAddDialog();
        queryClient.invalidateQueries({
          queryKey:
            APIGen.AdminAlarms.getAllAlarmSignalGroupTemplates.path(
              contextSettings
            )
        });
        toastStore.addSuccessToast(T.admin.requests.createtemplate.success);
        navigate(
          `/${tenantId}/templateManagement/alarms/${args.alarmSignalGroupTemplateId}`
        );
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.requests.createtemplate.failure);
      }
    });

  const templates = useMemo(() => {
    if (!getTemplatesQuery.data) {
      return [];
    }
    return getTemplatesQuery.data.sort((a, b) => a.name.localeCompare(b.name));
  }, [getTemplatesQuery.data]);

  const onClickRow = useCallback(
    (template: AdminAlarmSignalGroupTemplateResponseModel) => {
      navigate(
        `/${tenantId}/templateManagement/alarms/${template.alarmSignalGroupTemplateId}`
      );
    },
    [navigate, tenantId]
  );
  useEffect(() => {
    document.title = T.admin.alarmtemplates.header;
  }, []);

  return (
    <ToolbarContentPage
      wrapContent={false}
      showLocationPicker={false}
      title={T.admin.alarmtemplates.header}
      addActionTitle={T.admin.alarmtemplates.addnew}
      addAction={showAddDialog}
      helpPath={HelpPaths.docs.admin.templates.alarms_types}
    >
      <AddAlarmSignalGroupTemplateDialog
        isLoading={createMutation.isPending}
        isOpen={addDialogVisible}
        onAddAlarmTemplate={createMutation.mutate}
        onModalClose={hideAddDialog}
      />
      <DataTable<AdminAlarmSignalGroupTemplateResponseModel>
        hasError={getTemplatesQuery.error != null}
        isLoading={getTemplatesQuery.isLoading}
        disableHeader
        onClickRow={onClickRow}
        data={templates}
        columns={_columns}
      />
    </ToolbarContentPage>
  );
};

export default AlarmSignalGroupTemplates;
