import React from 'react';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModbusLayout from 'js/components/ModbusLayout/ModbusLayout';
import styles from './EnergyManagerFilesModel.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';

interface EnergyManagerFilesModalProps {
  isOpen?: boolean;
  onModalClose: () => void;
  deviceId?: string;
}

const EnergyManagerFilesModal = ({
  isOpen,
  onModalClose,
  deviceId
}: EnergyManagerFilesModalProps) => {
  return (
    <Modal
      className={styles.modbusModal}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={Icons.FileTypes.Text}>
        {T.admin.equipment.files.title}
      </ModalHeader>
      <ModalBody className={styles.modbusModalBody}>
        <ModbusLayout deviceId={deviceId} />
      </ModalBody>
    </Modal>
  );
};

export default EnergyManagerFilesModal;
