import {
  alarmSignalInputs,
  EMPTY_SIGNAL_INPUT as EMPTY_ALARM_SIGNAL_INPUT
} from './AlarmSignalTemplates/alarmSignalInputs';
import {
  equipmentSignalInputs,
  EMPTY_SIGNAL_INPUT
} from './EquipmentSignalTemplates/equipmentSignalInputs';
import { SignalProviderType } from 'ecto-common/lib/API/APIGen';
import { SignalProviderInput } from 'js/components/ManageTemplates/manageTemplatesTypes';

export const signalProviderInputs: Record<string, SignalProviderInput> = {
  [SignalProviderType.Equipment]: {
    inputs: equipmentSignalInputs,
    emptySignal: EMPTY_SIGNAL_INPUT,
    type: SignalProviderType.Equipment
  },
  [SignalProviderType.Alarm]: {
    inputs: alarmSignalInputs,
    emptySignal: EMPTY_ALARM_SIGNAL_INPUT,
    type: SignalProviderType.Alarm
  }
};
