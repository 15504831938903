import React, { useEffect } from 'react';
import {
  EditState,
  EditStateData
} from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import { EditEquipmentToolsActions } from 'js/modules/editEquipmentTools/editEquipmentTools';
import EditLinearOptimisationDialog from 'js/components/ManageEquipment/EditEquipment/EditLinearOptimisationDialog';
import { ToolSignalProviders } from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';

interface EditLinearOptimizationToolProps {
  editState?: EditStateData;
  setEditState: (toolType: ToolSignalProviders, data: EditStateData) => void;
}

const EditLinearOptimizationTool = ({
  editState,
  setEditState
}: EditLinearOptimizationToolProps) => {
  const editableLinearOptimisation = useAdminSelector(
    (state) => state.editEquipmentTools.editableLinearOptimisation
  );
  const deviceId = useAdminSelector(
    (state) => state.editEquipmentTools.deviceId
  );
  const equipmentId = useAdminSelector(
    (state) => state.editEquipmentTools.equipmentId
  );
  const dispatch = useAdminDispatch();

  useEffect(() => {
    switch (editState.state) {
      case EditState.IDLE:
        dispatch(EditEquipmentToolsActions.setEditableLinearOptimisation(null));
        break;
      case EditState.EDIT:
        dispatch(
          EditEquipmentToolsActions.setEditableLinearOptimisation(
            editState.data
          )
        );
        break;
      case EditState.CREATE:
        dispatch(
          EditEquipmentToolsActions.setEditableLinearOptimisation({
            numberOfPoints: 10,
            nodeId: equipmentId,
            deviceId
          })
        );
        break;
      default:
        break;
    }
  }, [editState, deviceId, dispatch, equipmentId]);

  useEffect(() => {
    if (editableLinearOptimisation === null) {
      setEditState(ToolSignalProviders.LINEAR_OPTIMIZATION, {
        state: EditState.IDLE,
        data: null
      });
    }
  }, [editableLinearOptimisation, setEditState]);

  return (
    <EditLinearOptimisationDialog
      isOpen={editableLinearOptimisation != null}
      linearOptimisation={editableLinearOptimisation}
    />
  );
};

export default React.memo(EditLinearOptimizationTool);
