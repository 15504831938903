import React from 'react';
import classNames from 'classnames';

import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import staticDataTableStyles from 'ecto-common/lib/StaticDataTable/StaticDataTable.module.css';
import EditButton from 'ecto-common/lib/Button/EditButton';
import {
  ConnectionModbusConfigTemplateResponseModel,
  ConnectionResponseModel
} from 'ecto-common/lib/API/APIGen';

type NestedConnectionData = {
  connectionModbusConfig: ConnectionModbusConfigTemplateResponseModel;
};

interface EditEnergyManagerSettingsProps {
  connectionData?: NestedConnectionData | ConnectionResponseModel;
  onEditConnection?(): void;
}

const EditEnergyManagerSettings = ({
  connectionData,
  onEditConnection
}: EditEnergyManagerSettingsProps) => {
  return (
    <>
      {connectionData?.connectionModbusConfig == null ? (
        <div>{T.common.unknownerror}</div>
      ) : (
        <div>
          <table className={classNames(staticDataTableStyles.staticDataTable)}>
            <tbody>
              <tr>
                <td>
                  <Icons.Connection light />{' '}
                  {T.admin.equipmentgroup.energymanager.connection}{' '}
                </td>

                <td className={staticDataTableStyles.minWidthColumn}>
                  <EditButton isIconButton onClick={onEditConnection} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default EditEnergyManagerSettings;
