import React, { useCallback, useEffect, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import Icons from 'ecto-common/lib/Icons/Icons';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';

import SelectUsers from './SelectUsers';
import styles from './SelectUsersDialog.module.css';
import IdentityServiceAPIGen, {
  TenantUserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGen';

interface SelectUsersDialogProps {
  isOpen?: boolean;
  onModalClose: () => void;
  addUsers(userIds: string[]): void;
  nodeId?: string;
  selectedUserIds?: string[];
}
const emptyUsers: TenantUserModel[] = [];

const SelectUsersDialog = ({
  isOpen,
  onModalClose,
  addUsers,
  nodeId,
  selectedUserIds
}: SelectUsersDialogProps) => {
  const usersQuery = IdentityServiceAPIGen.Tenant.listTenantUsers.useQuery(
    {},
    {
      enabled: nodeId != null && isOpen
    }
  );

  const users = usersQuery.data?.tenantUsers ?? emptyUsers;
  const [markedUsers, setMarkedUsers] = useState<string[]>([]);

  useEffect(() => {
    setMarkedUsers(selectedUserIds);
  }, [selectedUserIds, isOpen]);

  const onOk = useCallback(() => {
    addUsers(markedUsers);
  }, [addUsers, markedUsers]);

  const isLoading = usersQuery.isLoading;

  return (
    <Modal
      className={styles.selectUsersModal}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={Icons.User}>
        {T.selectusersdialog.title}
      </ModalHeader>

      <ModalBody className={styles.modalBody}>
        <div className={styles.dialogContent}>
          <SelectUsers
            key={isOpen + ''}
            data={users}
            selectedData={markedUsers}
            onMarkedData={setMarkedUsers}
            isLoading={isLoading}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <LocalizedButtons.Ok onClick={onOk} disabled={isLoading} />
        <LocalizedButtons.Cancel onClick={onModalClose} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectUsersDialog);
