import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import EditButton from 'ecto-common/lib/Button/EditButton';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';

import pageStyles from 'js/components/PageStyle.module.css';
import styles from 'js/components/EditBuildingData/EditEquipmentGroup.module.css';
import { getSignalTypeName } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { AlarmOrEqSignalTemplateWithProviderType } from 'ecto-common/lib/utils/equipmentTypeUtils';
import {
  EquipmentSignalTemplateOverrideResponseModel,
  SignalTemplateOverrideResponseModel
} from 'ecto-common/lib/API/APIGen';

interface EditEquipmentSignalRowProps {
  onSetNameValue(index: number, id: string, value: string): void;
  onSetDescriptionValue(index: number, id: string, value: string): void;
  onSetAddressValue(index: number, id: string, value: string): void;
  onEditModbus(index: number, equipmentIndex: number): void;
  equipmentIdx: number;
  modbusMode?: string;
  signal?: AlarmOrEqSignalTemplateWithProviderType;
  index?: number;
  signalTemplateOverrideObject?: EquipmentSignalTemplateOverrideResponseModel;
  signalTemplateOverride?: SignalTemplateOverrideResponseModel;
}

type EditEquipmentSignalRowItem = AlarmOrEqSignalTemplateWithProviderType & {
  signalIdx: number;
  signalTemplateOverrideObject?: EquipmentSignalTemplateOverrideResponseModel;
  signalTemplateOverride?: SignalTemplateOverrideResponseModel;
};

const EditEquipmentSignalRow = ({
  onSetNameValue,
  onSetDescriptionValue,
  onSetAddressValue,
  onEditModbus,
  equipmentIdx,
  modbusMode,
  signal,
  index,
  signalTemplateOverrideObject,
  signalTemplateOverride
}: EditEquipmentSignalRowProps) => {
  const signalTypesMap = useAdminSelector(
    (state) => state.general.signalTypesMap
  );

  const item: EditEquipmentSignalRowItem = useMemo(() => {
    return {
      ...signal,
      signalIdx: index,
      signalTemplateOverrideObject,
      signalTemplateOverride
    };
  }, [signal, signalTemplateOverride, signalTemplateOverrideObject, index]);

  const setNameValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetNameValue(equipmentIdx, item.id, event.target.value);
    },
    [equipmentIdx, onSetNameValue, item]
  );

  const setDescriptionValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetDescriptionValue(equipmentIdx, item.id, event.target.value);
    },
    [equipmentIdx, onSetDescriptionValue, item]
  );

  const setAddressValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetAddressValue(equipmentIdx, item.id, event.target.value);
    },
    [equipmentIdx, onSetAddressValue, item]
  );

  const editModbus = useCallback(() => {
    onEditModbus(index, equipmentIdx);
  }, [equipmentIdx, onEditModbus, index]);

  const signalType = useMemo(() => {
    // If there are any override values then use them
    // else take signal values from equipment type
    const modbusTypePath = ['signalSettings', modbusMode, 'type'];
    const modbusDisabledPath = ['signalSettings', modbusMode, 'disabled'];

    const overrideSignalSettingsModbusType = _.get(
      item.signalTemplateOverride,
      modbusTypePath
    );
    const overrideSignalSettingsModbusDisabled = _.get(
      item.signalTemplateOverride,
      modbusDisabledPath,
      false
    );

    if (overrideSignalSettingsModbusDisabled) {
      return undefined;
    }

    if (overrideSignalSettingsModbusType !== undefined) {
      return overrideSignalSettingsModbusType;
    }

    if (!_.get(signal, modbusDisabledPath, false)) {
      return _.get(signal, modbusTypePath);
    }

    return undefined;
  }, [modbusMode, signal, item]);

  return (
    <tr key={item?.id}>
      <td className={styles.nameColumn}>
        <Icons.Signal light />{' '}
        {getSignalTypeName(item.signalTypeId, signalTypesMap)}
      </td>

      <td className={styles.nameColumn}>
        <TextInput
          wrapperClassName={styles.nameColumn}
          placeholder={item.name || T.admin.equipmentsignal.displayname}
          value={item?.signalTemplateOverrideObject?.name}
          onChange={(event) => setNameValue(event)}
        />
      </td>

      <td colSpan={3}>
        <TextInput
          placeholder={item.description || T.admin.equipmentsignal.description}
          value={item?.signalTemplateOverrideObject?.description}
          onChange={(event) => setDescriptionValue(event)}
        />
      </td>

      <td className={pageStyles.minWidthColumn}>{signalType}</td>

      <td className={pageStyles.minWidthColumn}>
        {signalType && (
          <TextInput
            wrapperClassName={styles.addressInput}
            value={_.get(
              item?.signalTemplateOverride,
              ['signalSettings', 'modbusAddress'],
              ''
            )}
            onChange={(event) => setAddressValue(event)}
          />
        )}
      </td>
      <td className={pageStyles.minWidthColumn}>
        <EditButton onClick={editModbus} isIconButton />
      </td>
    </tr>
  );
};

export default React.memo(EditEquipmentSignalRow);
