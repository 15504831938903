import React, { useMemo, useEffect, useRef, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import { isEquipmentEnergyManager } from 'ecto-common/lib/utils/equipmentTypeUtils';
import Notice from 'ecto-common/lib/Notice/Notice';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import AddButton from 'ecto-common/lib/Button/AddButton';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { AddEquipmentFormActions } from 'js/modules/addEquipmentForm/addEquipmentForm';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import { NodeTypes } from 'ecto-common/lib/utils/constants';

import {
  energyManagerSubpages,
  EquipmentSubpage,
  equipmentSubpages
} from 'js/utils/LocationEndpoints';
import { InstantiatingTemplateState } from 'js/modules/instantiateEmptyBuilding/instantiateEmptyBuilding';
import EquipmentsToolbarPage from 'js/components/Equipments/EquipmentsToolbarPage';
import NewEquipment from 'js/components/ManageEquipment/NewEquipment/NewEquipment';
import EquipmentsContent from 'js/components/Equipments/EquipmentsContent';
import { getEquipmentPageUrl } from 'js/utils/linkUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { NodeEquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { EquipmentTypeResponseModel } from 'ecto-common/lib/API/APIGen';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';

export const organizeEquipments = (
  equipments: NodeEquipmentResponseModel[],
  equipmentTypes: EquipmentTypeResponseModel[]
): {
  energyManagers: NodeEquipmentResponseModel[];
  sortedEquipments: NodeEquipmentResponseModel[];
} => {
  const energyManagers = equipments.filter((e) =>
    isEquipmentEnergyManager(e, equipmentTypes)
  );
  const otherEquipments = _.sortBy(
    equipments.filter((e) => !isEquipmentEnergyManager(e, equipmentTypes)),
    'equipmentTypeId',
    'name'
  );
  const sortedEquipments = energyManagers.concat(otherEquipments);
  return { energyManagers, sortedEquipments };
};

interface EquipmentsPageContentProps {
  energyManagers?: NodeEquipmentResponseModel[];
  sortedEquipments?: NodeEquipmentResponseModel[];
  selectedLocation?: SingleGridNode;
  selectedEquipment?: NodeEquipmentResponseModel;
}

const EquipmentsPageContent = ({
  energyManagers,
  selectedLocation,
  selectedEquipment,
  sortedEquipments
}: EquipmentsPageContentProps) => {
  const isRoot = selectedLocation.nodeId.startsWith(ROOT_NODE_ID);
  const isBuilding = selectedLocation.nodeType === NodeTypes.BUILDING;
  if (isRoot) {
    return <NoDataMessage message={T.admin.equipment.error.nogridequipment} />;
  } else if (!isBuilding) {
    return (
      <Notice showHeader>{T.admin.equipment.error.nositeequipment}</Notice>
    );
  }

  return (
    <>
      {selectedEquipment ? (
        <EquipmentsContent
          key={selectedEquipment?.equipmentId}
          selectedEquipment={selectedEquipment}
          equipments={sortedEquipments}
        />
      ) : (
        <div>
          <Notice showHeader>
            {sortedEquipments.length === 0
              ? T.admin.equipment.error.nodevice
              : T.admin.equipment.selectequipment}
          </Notice>
        </div>
      )}

      <NewEquipment devices={energyManagers} />
    </>
  );
};

const EquipmentsPageContentMemo = React.memo(EquipmentsPageContent);

interface EquipmentsProps {
  onTitleChanged?: (title: string[]) => void;
  equipments: NodeEquipmentResponseModel[];
  selectedLocation?: SingleGridNode;
}

const Equipments = ({
  onTitleChanged,
  equipments,
  selectedLocation
}: EquipmentsProps) => {
  const params = useParams<NodeParams>();
  const dispatch = useAdminDispatch();
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const templateState = useAdminSelector(
    (state) => state.instantiateEmptyBuilding.templateState
  );

  const localTemplateStateRef = useRef(undefined);

  useEffect(() => {
    if (templateState !== localTemplateStateRef.current) {
      if (templateState === InstantiatingTemplateState.ERROR) {
        if (
          localTemplateStateRef.current ===
          InstantiatingTemplateState.INSTANTIATING_TEMPLATE
        ) {
          toastStore.addErrorToast(T.admin.equipment.addfromtemplate.error);
        } else if (
          localTemplateStateRef.current ===
          InstantiatingTemplateState.ADDING_CONNECTION
        ) {
          toastStore.addErrorToast(
            T.admin.equipment.addfromtemplate.errorconnection
          );
        } else if (localTemplateStateRef.current === null) {
          toastStore.addErrorToast(T.common.unknownerror);
        } else {
          toastStore.addErrorToast(
            T.admin.equipment.addfromtemplate.errorafterinit
          );
        }
      }

      if (templateState === InstantiatingTemplateState.NODE_UPDATED) {
        toastStore.addSuccessToast(T.admin.equipment.addfromtemplate.success);
      }

      localTemplateStateRef.current = templateState;
    }
  }, [templateState]);

  const { energyManagers, sortedEquipments } = useMemo(
    () => organizeEquipments(equipments, equipmentTypes),
    [equipments, equipmentTypes]
  );

  const forceAddEnergyManager = equipments.length === 0;
  const selectedEquipment = sortedEquipments.find(
    (e) => e.equipmentId === params?.itemId
  );
  const isEnergyManager =
    selectedEquipment &&
    isEquipmentEnergyManager(selectedEquipment, equipmentTypes);
  const { tenantId } = useContext(TenantContext);

  usePageTitleCallback({
    mainTitle: T.admin.tabs.locations,
    subTitle: selectedEquipment?.name,
    onTitleChanged
  });

  if (
    params?.itemId &&
    (isNullOrWhitespace(params?.subPage) ||
      (isEnergyManager && !energyManagerSubpages.includes(params?.subPage)) ||
      (!isEnergyManager && !equipmentSubpages.includes(params?.subPage)))
  ) {
    return (
      <Navigate
        to={getEquipmentPageUrl(
          tenantId,
          params?.nodeId,
          params?.itemId,
          EquipmentSubpage.DETAILS
        )}
      />
    );
  }

  const toolbarItems = [
    <ToolbarFlexibleSpace key="toolbar-item-equipment-flex-space" />,
    <ToolbarItem key="toolbar-item-equipment-add-new">
      <AddButton
        onClick={() =>
          dispatch(
            AddEquipmentFormActions.setShowDialog(
              true,
              forceAddEnergyManager,
              energyManagers,
              equipmentTypes,
              selectedLocation.nodeId
            )
          )
        }
      >
        {T.admin.equipment.add}
      </AddButton>
    </ToolbarItem>
  ];

  const isRoot = selectedLocation.nodeId.startsWith(ROOT_NODE_ID);
  const isBuilding = selectedLocation.nodeType === NodeTypes.BUILDING;
  const showToolbar = !isRoot && isBuilding;
  return (
    <>
      <EquipmentsToolbarPage toolbarItems={showToolbar && toolbarItems}>
        <EquipmentsPageContentMemo
          energyManagers={energyManagers}
          sortedEquipments={sortedEquipments}
          selectedLocation={selectedLocation}
          selectedEquipment={selectedEquipment}
        />
      </EquipmentsToolbarPage>
    </>
  );
};

export default Equipments;
