import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import {
  AlarmOrEqSignalTemplateWithProviderType,
  beautifyEquipmentName,
  getEquipmentTypeName,
  isEquipmentEnergyManager
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import staticDataTableStyles from 'ecto-common/lib/StaticDataTable/StaticDataTable.module.css';
import { getSignalsForEquipmentType } from 'ecto-common/lib/utils/equipmentTypeUtils';

import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import ToolSelector from 'js/components/EditBuildingData/ToolSelector';
import AlarmSelector from 'js/components/EditBuildingData/AlarmSelector';
import EditEquipmentSignalRow from 'js/components/EditBuildingData/EditEquipmentSignalRow';
import styles from 'js/components/EditBuildingData/EditEquipmentGroup.module.css';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { EquipmentTemplateResponseModel } from 'ecto-common/lib/API/APIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

interface EquipmentSignalsEditorProps {
  equipment?: EquipmentTemplateResponseModel;
  index?: number;
  equipmentIndex?: number;
  modbusMode?: string;
  onEditModbus(signalIdx: number, equipmentIdx: number): void;
  onRemoveEquipment(equipmentIdx: number): void;
  onSetAddressValue(
    equipmentIdx: number,
    signalTemplateId: string,
    value: string
  ): void;
  onSetAlarmTemplateValue(equipmentIdx: number, value: string): void;
  onSetDescriptionValue(
    equipmentIdx: number,
    signalTemplateId: string,
    value: string
  ): void;
  onSetNameValue(
    equipmentIdx: number,
    signalTemplateId: string,
    value: string
  ): void;
  onSetEquipmentProperty(
    equipmentIdx: number,
    propertyName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): void;
  onSetToolTemplateValue(equipmentIdx: number, value: string[]): void;
}

const EquipmentSignalsEditor = ({
  equipment,
  index,
  equipmentIndex,
  modbusMode,
  onEditModbus,
  onRemoveEquipment,
  onSetAddressValue,
  onSetAlarmTemplateValue,
  onSetDescriptionValue,
  onSetNameValue,
  onSetEquipmentProperty,
  onSetToolTemplateValue
}: EquipmentSignalsEditorProps) => {
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const signalTemplates = useAdminSelector(
    (state) => state.admin.signalTemplates
  );
  const toolTemplates = useAdminSelector(
    (state) => state.general.enums.toolTypes
  );

  const signals: AlarmOrEqSignalTemplateWithProviderType[] = useMemo(() => {
    return getSignalsForEquipmentType(
      equipment.equipmentTypeId,
      equipment.alarmSignalGroupTemplateId,
      signalTemplates
    );
  }, [
    equipment.equipmentTypeId,
    equipment.alarmSignalGroupTemplateId,
    signalTemplates
  ]);

  const alarmSignalTemplate = _.find(signalTemplates.alarmSignalTemplates, {
    alarmSignalGroupTemplateId: equipment.alarmSignalGroupTemplateId
  });

  const setEquipmentName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetEquipmentProperty(index, 'name', event.target.value);
    },
    [onSetEquipmentProperty, index]
  );

  const setEquipmentDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetEquipmentProperty(index, 'description', event.target.value);
    },
    [onSetEquipmentProperty, index]
  );

  const setAlarmTemplateValue = useCallback(
    (event: GenericSelectOption<string>) => {
      onSetAlarmTemplateValue(index, event?.value);
    },
    [onSetAlarmTemplateValue, index]
  );

  const setToolTemplateValue = useCallback(
    (event: GenericSelectOption<string>[]) => {
      onSetToolTemplateValue(index, _.map(event, 'value'));
    },
    [onSetToolTemplateValue, index]
  );

  const removeIndex = useCallback(
    (indexToRemove: number) => {
      onRemoveEquipment(indexToRemove);
    },
    [onRemoveEquipment]
  );

  const isEM = isEquipmentEnergyManager(equipment, equipmentTypes);

  return (
    <>
      <tr
        className={styles.equipmentHeader}
        key={equipment.equipmentTypeId + index * 2}
      >
        <td className={staticDataTableStyles.minWidthColumn}>
          {isEM ? <Icons.EnergyManager light /> : <Icons.Equipment light />}

          <span className={styles.equipmentNameColumn}>
            &nbsp;
            {beautifyEquipmentName(
              getEquipmentTypeName(equipment.equipmentTypeId, equipmentTypes)
            )}
            &nbsp;{equipmentIndex + 1}
          </span>
        </td>

        <td className={styles.equipmentHeaderMidColumn}>
          <label>{T.admin.equipmentgroup.equipment.name}</label>

          <TextInput
            wrapperClassName={styles.equipmentPropertyColumn}
            placeholder={T.admin.equipmentgroup.equipment.name}
            value={equipment.name}
            onChange={setEquipmentName}
          />

          <br />

          <label>{T.admin.equipmentgroup.equipment.alarms}</label>

          <AlarmSelector
            equipmentTypeId={equipment.equipmentTypeId}
            signalTemplates={signalTemplates}
            onChange={setAlarmTemplateValue}
            value={alarmSignalTemplate?.alarmSignalGroupTemplateId}
          />
        </td>

        <td className={styles.equipmentHeaderMidColumn}>
          <label>{T.admin.equipmentgroup.equipment.description}</label>

          <TextInput
            wrapperClassName={styles.equipmentPropertyColumn}
            placeholder={T.admin.equipmentgroup.equipment.description}
            value={equipment.description}
            onChange={setEquipmentDescription}
          />

          <br />

          <label>{T.admin.equipmentgroup.equipment.tools}</label>

          <ToolSelector
            toolTemplates={toolTemplates}
            values={equipment.toolTypes || []}
            onChange={setToolTemplateValue}
          />
        </td>

        <td
          className={classNames(
            staticDataTableStyles.minWidthColumn,
            staticDataTableStyles.alignRight
          )}
        >
          <DeleteButton isIconButton onClick={() => removeIndex(index)} />
        </td>
      </tr>

      {signals.length > 0 && (
        <tr
          className={styles.equipmentSignalsList}
          key={equipment.equipmentTypeId + index * 2 + 1}
        >
          <td colSpan={8}>
            <table
              className={classNames(staticDataTableStyles.staticDataTable)}
            >
              <thead>
                <tr>
                  <th>{T.admin.equipmentsignal.signaltype}</th>
                  <th>{T.admin.equipmentsignal.displayname}</th>
                  <th colSpan={3}>{T.admin.equipmentsignal.description}</th>
                  <th>{T.admin.equipmentsignal.type}</th>
                  <th>{T.admin.equipmentsignal.address}</th>
                </tr>
              </thead>
              <tbody>
                {signals.map((signal, idx) => {
                  const signalTemplateOverrideObject = _.find(
                    equipment?.signalTemplateOverrides,
                    { signalTemplateId: signal.id }
                  );
                  const signalTemplateOverride = _.get(
                    signalTemplateOverrideObject,
                    'signalTemplateOverride'
                  );

                  return (
                    <EditEquipmentSignalRow
                      key={idx}
                      onSetNameValue={onSetNameValue}
                      onSetDescriptionValue={onSetDescriptionValue}
                      onSetAddressValue={onSetAddressValue}
                      onEditModbus={onEditModbus}
                      equipmentIdx={index}
                      signal={signal}
                      index={idx}
                      modbusMode={modbusMode}
                      signalTemplateOverrideObject={
                        signalTemplateOverrideObject
                      }
                      signalTemplateOverride={signalTemplateOverride}
                    />
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default React.memo(EquipmentSignalsEditor);
