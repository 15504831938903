import T from 'ecto-common/lib/lang/Language';

export const Action = Object.freeze({
  NO_ACTION: 'NoAction',
  SEND_ON_PUSH: 'SendOnPush',
  SEND_BASED_ON_CYCLE_TIME: 'SendBasedOnCycleTime'
});

export const ActionText = Object.freeze({
  [Action.NO_ACTION]: T.admin.signalmapping.action.noaction,
  [Action.SEND_ON_PUSH]: T.admin.signalmapping.action.sendonpush,
  [Action.SEND_BASED_ON_CYCLE_TIME]:
    T.admin.signalmapping.action.sendbasedoncycletime
});
