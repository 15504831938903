import { useCallback, useContext, useEffect } from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  AdminToolImplementationType,
  ToolProvider
} from 'js/components/ManageEquipment/EditEquipment/useEquipmentTools';
import { useQuery } from '@tanstack/react-query';

/**
 * Loads tool for current equipment and node
 * @param tool tool definition
 * @param nodeId node id to get the tool for
 * @param equipmentId equipment id to load the node for
 * @returns {[currentTool, isLoadingTool, triggerReload]}
 */
const useToolLoader = <ObjectType extends object>(
  tool: AdminToolImplementationType<ObjectType>,
  nodeId: string,
  equipmentId: string
): [
  currentTool: ToolProvider,
  getProvidersIsLoading: boolean,
  triggerReload: () => void
] => {
  const { tenantResources } = useContext(TenantContext);
  let canUseTool = _.every(tool.accessRights, (accessRight) =>
    hasAccessToResource(accessRight, tenantResources)
  );
  canUseTool =
    canUseTool &&
    (tool.available ? tool.available(tool, nodeId, equipmentId) : true);

  const { contextSettings } = useContext(TenantContext);

  const providersQuery = useQuery({
    queryKey: ['useToolLoader', tool.type, equipmentId],
    queryFn: ({ signal }) => tool.load(contextSettings, [equipmentId], signal),
    enabled: canUseTool
  });

  const triggerReload = useCallback(() => {
    providersQuery.refetch();
  }, [providersQuery]);

  const currentTool = _.find(providersQuery.data, ['nodeId', equipmentId]);

  useEffect(() => {
    if (providersQuery.error) {
      toastStore.addErrorToast(
        T.format(T.admin.equipmenttools.error.signalfetchfailed, tool.name)
      );
    }
  }, [providersQuery.error, tool.name]);

  return [currentTool, providersQuery.isLoading, triggerReload];
};

export default useToolLoader;
