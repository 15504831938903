import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';

import T from 'ecto-common/lib/lang/Language';
import staticDataTableStyles from 'ecto-common/lib/StaticDataTable/StaticDataTable.module.css';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Icons from 'ecto-common/lib/Icons/Icons';
import SegmentedContentView from 'ecto-common/lib/SegmentControl/SegmentedContentView';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';

import {
  TemplateManagementParams,
  getTemplateManagementRoute
} from 'js/utils/routeConstants';
import EditSignalProviderTypeDetails from './EditSignalProviderTypeDetails';
import EditSignalProviderTypeSignalTemplate from './EditSignalProviderTypeSignalTemplate';
import {
  AlarmOrEqTemplate,
  SignalProviderInput
} from 'js/components/ManageTemplates/manageTemplatesTypes';
import { SignalProviderType } from 'ecto-common/lib/API/APIGen';

interface EditSignalProviderTypeProps {
  editedSignalTemplates?: AlarmOrEqTemplate[];
  initialSignals?: AlarmOrEqTemplate[];
  isLoading: boolean;
  onDeleteTemplate(): void;
  onSaveDetails(): void;
  onSaveTemplates(): void;
  onSignalsChanged?: (signals: AlarmOrEqTemplate[]) => void;
  onTemplateNameChanged(newName: string): void;
  originalName: string;
  signalInputProvider: SignalProviderInput;
  signalInputsToHideForTable?: string[];
  signalProviderType: SignalProviderType;
  templateName: string;
  title: React.ReactNode;
  hasNoData?: boolean;
  helpPath?: string;
  allowDeleteForSignal?: (signal: AlarmOrEqTemplate) => boolean;
}

const EditSignalProviderType = ({
  editedSignalTemplates,
  initialSignals,
  isLoading,
  onDeleteTemplate,
  onSaveDetails,
  onSaveTemplates,
  onSignalsChanged,
  onTemplateNameChanged,
  originalName,
  signalInputProvider,
  signalInputsToHideForTable = [],
  signalProviderType,
  templateName,
  title,
  allowDeleteForSignal,
  hasNoData,
  helpPath
}: EditSignalProviderTypeProps) => {
  const params = useParams<TemplateManagementParams>();

  const sections = [
    {
      icon: <Icons.Equipment />,
      title: T.admin.equipment.section.details,
      key: 'details',
      view: (
        <EditSignalProviderTypeDetails
          hasUnsavedChanges={templateName !== originalName}
          isLoading={isLoading}
          onDeleteTemplate={onDeleteTemplate}
          onSaveDetails={onSaveDetails}
          onTemplateNameChanged={onTemplateNameChanged}
          signalProviderType={signalProviderType}
          templateName={templateName}
        />
      )
    },
    {
      icon: <Icons.Graph />,
      title: T.admin.equipmenttypes.signaltemplates,
      key: 'signals',
      view: (
        <EditSignalProviderTypeSignalTemplate
          hasUnsavedChanges={editedSignalTemplates != null}
          initialSignals={initialSignals}
          isLoading={isLoading}
          onSaveTemplates={onSaveTemplates}
          onSignalsChanged={onSignalsChanged}
          signalInputProvider={signalInputProvider}
          signalInputsToHideForTable={signalInputsToHideForTable}
          signalProviderType={signalProviderType}
          allowDeleteForSignal={allowDeleteForSignal}
        />
      )
    }
  ].map((item) => ({
    ...item,
    url: getTemplateManagementRoute(
      params.tenantId,
      params.page,
      params.itemId,
      item.key
    )
  }));

  let startIndex = _.findIndex(sections, { key: params.subPage });

  if (startIndex === -1) {
    startIndex = 0;
  }

  return (
    <>
      <ToolbarContentPage
        title={title}
        showLocationPicker={false}
        helpPath={helpPath}
      >
        <LoadingContainer
          className={classNames(
            isLoading && staticDataTableStyles.loadingContainer
          )}
          isLoading={isLoading}
        >
          {hasNoData && !isLoading ? (
            <NoDataMessage />
          ) : (
            <SegmentedContentView sections={sections} index={startIndex} />
          )}
        </LoadingContainer>
      </ToolbarContentPage>
    </>
  );
};

export default memo(EditSignalProviderType);
