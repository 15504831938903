import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import AdminPage from 'js/components/AdminPage';
import EnergyManagersPage from 'js/components/EnergyManagers/EnergyManagersPage';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ProvisioningParams } from 'js/utils/routeConstants';

const EnergyManagers = () => {
  const params = useParams<ProvisioningParams>();
  const { tenantId } = useContext(TenantContext);

  if (!params.page) {
    return <Navigate to={`/${tenantId}/provisioning/devices?queryType=All`} />;
  }

  return <AdminPage content={<EnergyManagersPage />} />;
};

export default EnergyManagers;
