import React, { MouseEventHandler, useCallback, useContext } from 'react';
import APIGen, {
  AddOrUpdateTemperatureImpactProviderRequestModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import T from 'ecto-common/lib/lang/Language';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { calculateDataTableMinHeight } from 'ecto-common/lib/utils/dataTableUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import _ from 'lodash';
import UUID from 'uuidjs';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

type TempImpactTableEntry = {
  tool: string;
  status: string;
  action: MouseEventHandler<HTMLButtonElement>;
};

const columns: DataTableColumnProps<TempImpactTableEntry>[] = [
  {
    label: T.admin.editlocation.tools.status,
    dataKey: 'status'
  },
  {
    label: T.admin.editlocation.tools.manage,
    dataKey: 'action',
    width: 100,
    flexGrow: 0,
    align: HorizontalAlignments.CENTER,
    dataFormatter: (onClick: MouseEventHandler<HTMLButtonElement>) => {
      return (
        <LocalizedButtons.Add onClick={onClick} disabled={onClick === null} />
      );
    }
  }
];

interface ManageTemperatureImpactProps {
  nodeId?: string;
}

const ManageTemperatureImpact = ({ nodeId }: ManageTemperatureImpactProps) => {
  const getProvidersQuery = APIGen.AdminSignals.getSignalsByNode.useQuery({
    nodesIds: [nodeId],
    signalProviderTypes: [SignalProviderType.TemperatureImpact]
  });

  const getProviderSignalsQuery =
    APIGen.AdminTemperatureImpact.getTemperatureImpactProviderSignals.useQuery({
      nodeIds: [nodeId]
    });

  const hasTemperatureImpact =
    getProvidersQuery.isSuccess && getProvidersQuery.data?.length > 0;
  const temperatureImpactParams = getProviderSignalsQuery.data?.[0];

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);
  const getToolsIsLoading =
    getProvidersQuery.isLoading || getProviderSignalsQuery.isLoading;

  const addToolMutation =
    APIGen.AdminTemperatureImpact.addOrUpdateTemperatureImpactProviders.useMutation(
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey:
              APIGen.AdminTemperatureImpact.getTemperatureImpactProviderSignals.path(
                contextSettings
              )
          });
          queryClient.invalidateQueries({
            queryKey: APIGen.AdminSignals.getSignalsByNode.path(contextSettings)
          });
        },
        onError: () => {
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    );

  const supportsAddingTemperatureImpact =
    temperatureImpactParams?.powerControlGlobalAmplitudeDesiredSignalId !=
      null && temperatureImpactParams?.meteorologyTSignalId != null;

  let temperatureImpactStatus = '';
  let temperatureImpactAction = null;

  const addTemperatureImpact = useCallback(() => {
    // Cast due to response model having optional fields - verified to be set by form.
    addToolMutation.mutate([
      {
        nodeId,
        providerId: UUID.generate(),
        ...temperatureImpactParams
      } as AddOrUpdateTemperatureImpactProviderRequestModel
    ]);
  }, [addToolMutation, nodeId, temperatureImpactParams]);

  if (getToolsIsLoading || addToolMutation.isPending) {
    temperatureImpactStatus = T.common.loading;
  } else if (hasTemperatureImpact) {
    temperatureImpactStatus = T.admin.editlocation.tools.configured;
  } else if (supportsAddingTemperatureImpact) {
    temperatureImpactStatus = T.admin.editlocation.tools.readytoadd;
    temperatureImpactAction = addTemperatureImpact;
  } else {
    temperatureImpactStatus = T.admin.editlocation.tools.unsupported;
  }

  const data: TempImpactTableEntry[] = [
    {
      tool: T.admin.editlocation.tools.temperatureimpact,
      status: temperatureImpactStatus,
      action: temperatureImpactAction
    }
  ];

  return (
    <div>
      <DataTable
        isLoading={getToolsIsLoading || addToolMutation.isPending}
        data={data}
        columns={columns}
        minHeight={calculateDataTableMinHeight({
          pageSize: 1,
          showNoticeHeaders: false
        })}
      />
    </div>
  );
};

export default ManageTemperatureImpact;
