import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import T from 'ecto-common/lib/lang/Language';
import { updateObject } from 'js/actions/updateObject';
import queryString from 'query-string';
import {
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS,
  REQ_STATE_ERROR,
  RequestStatusRawProp
} from 'ecto-common/lib/utils/requestStatus';
import _ from 'lodash';
import { updateNodeTreeIncrementally } from 'js/modules/provisioningCommon/provisioningCommon';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';

import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants';
import UUID from 'uuidjs';
import {
  AddOrUpdateLinearOptimisationRequestModel,
  AddOrUpdatePowerControlProviderRequestModel,
  DeviceInfoResponseModel,
  LinearOptimisationProviderResponseModel,
  LinearOptimisationResponseModel,
  NodeEquipmentResponseModel,
  PowerControlProviderResponseModel,
  PowerControlResponseModel
} from 'ecto-common/lib/API/APIGen';
import { AdminDispatch } from 'js/reducers/storeAdmin';
import { getAPIFetch } from 'ecto-common/lib/utils/APIFetchInstance';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { ResourceModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';

const SET_EDIT_TOOLS_EQUIPMENT_ID = 'SET_EDIT_TOOLS_EQUIPMENT_ID';
const SET_EDITABLE_POWER_CONTROL = 'SET_EDITABLE_POWER_CONTROL';
const SET_EDITABLE_LINEAR_OPTIMISATION = 'SET_EDITABLE_LINEAR_OPTIMISATION';
export const SET_POWER_CONTROL_REQ_STATE = 'SET_POWER_CONTROL_REQ_STATE';
export const SET_LINEAR_OPTIMISATION_REQ_STATE =
  'SET_LINEAR_OPTIMISATION_REQ_STATE';
export const SET_EQUIPMENT_TOOLS_REQ_STATE = 'SET_EQUIPMENT_TOOLS_REQ_STATE';

interface EditEquipmentReducerProps {
  errorText: string;
  editablePowerControl: PowerControlResponseModel;
  editableLinearOptimisation: AddOrUpdateLinearOptimisationRequestModel;
  powerControls: PowerControlResponseModel[];
  linearOptimisations: AddOrUpdateLinearOptimisationRequestModel[];
  loading: boolean;
  equipmentId: string;
  nodeId: string;
  deviceId: string;
}

const initialState: EditEquipmentReducerProps = {
  errorText: null,
  editablePowerControl: null,
  editableLinearOptimisation: null,
  powerControls: [],
  linearOptimisations: [],
  loading: false,
  equipmentId: null,
  nodeId: null,
  deviceId: null
};

type SetLinearOptimisationReqStateAction = {
  type: typeof SET_LINEAR_OPTIMISATION_REQ_STATE;
} & RequestStatusRawProp<AddOrUpdateLinearOptimisationRequestModel[]>;

type SetPowerControlReqStateAction = {
  type: typeof SET_POWER_CONTROL_REQ_STATE;
} & RequestStatusRawProp<PowerControlProviderResponseModel[]>;

type SetEquipmentToolsReqStateAction = {
  type: typeof SET_EQUIPMENT_TOOLS_REQ_STATE;
  id: string;
} & RequestStatusRawProp<{
  deviceInfo: DeviceInfoResponseModel;
  powerControls: PowerControlProviderResponseModel[];
  linearOptimisations: LinearOptimisationResponseModel[];
}>;

type SetEquipmentIdAction = {
  type: typeof SET_EDIT_TOOLS_EQUIPMENT_ID;
  equipmentId: string;
};

type SetEditablePowerControlAction = {
  type: typeof SET_EDITABLE_POWER_CONTROL;
  editablePowerControl: PowerControlResponseModel;
};

type SetEditableLinearOptimisationAction = {
  type: typeof SET_EDITABLE_LINEAR_OPTIMISATION;
  editableLinearOptimisation: AddOrUpdateLinearOptimisationRequestModel;
};

export default createReducer<EditEquipmentReducerProps>(initialState, {
  [SET_LINEAR_OPTIMISATION_REQ_STATE]: (
    state,
    action: SetLinearOptimisationReqStateAction
  ) => {
    if (action.state === REQ_STATE_SUCCESS) {
      const newLinOpt = _.cloneDeep(action.payload[0]);
      const oldLinOptIdx = state.linearOptimisations.findIndex(
        (pwc) => pwc.signalProviderId === newLinOpt.signalProviderId
      );
      const newLinOpts = _.cloneDeep(state.linearOptimisations);

      if (oldLinOptIdx !== -1) {
        newLinOpts[oldLinOptIdx] = newLinOpt;
      } else {
        newLinOpts.push(newLinOpt);
      }

      return { ...state, linearOptimisations: newLinOpts };
    }

    return state;
  },
  [SET_POWER_CONTROL_REQ_STATE]: (
    state,
    action: SetPowerControlReqStateAction
  ) => {
    if (action.state === REQ_STATE_SUCCESS) {
      const newPowerControl = _.cloneDeep(action.payload[0]);
      const oldPowerControlIdx = state.powerControls.findIndex(
        (pwc) => pwc.signalProviderId === newPowerControl.signalProviderId
      );
      const newPowerControls = _.cloneDeep(state.powerControls);

      if (oldPowerControlIdx !== -1) {
        newPowerControls[oldPowerControlIdx] = newPowerControl;
      } else {
        newPowerControls.push(newPowerControl);
      }

      return { ...state, powerControls: newPowerControls };
    }

    return state;
  },
  [SET_EDIT_TOOLS_EQUIPMENT_ID]: (
    _state,
    { equipmentId }: SetEquipmentIdAction
  ) => {
    return { ...initialState, equipmentId };
  },
  [SET_EQUIPMENT_TOOLS_REQ_STATE]: (
    state,
    action: SetEquipmentToolsReqStateAction
  ) => {
    if (action.id !== state.equipmentId) {
      return state;
    }

    if (action.state === REQ_STATE_SUCCESS) {
      return {
        ...state,
        loading: false,
        deviceId: action.payload.deviceInfo.deviceId,
        powerControls: action.payload.powerControls,
        // Adapt so that the format matches what the PUT request expects, i.e. numberOfPoints instead of array with points
        linearOptimisations: action.payload.linearOptimisations.map(
          ({
            deviceId,
            signalProviderId,
            nodeId,
            remoteOptimisationPoints
          }) => {
            return {
              deviceId,
              signalProviderId,
              nodeId,
              numberOfPoints: remoteOptimisationPoints.length
            };
          }
        )
      };
    } else if (action.state === REQ_STATE_PENDING) {
      return { ...state, loading: true };
    } else if (action.state === REQ_STATE_ERROR) {
      return {
        ...state,
        loading: false,
        errorText: T.admin.equipment.request.fetchtoolsfailed
      };
    }

    return state;
  },
  [SET_EDITABLE_POWER_CONTROL]: (
    state,
    { editablePowerControl }: SetEditablePowerControlAction
  ) => {
    return { ...state, editablePowerControl };
  },
  [SET_EDITABLE_LINEAR_OPTIMISATION]: (
    state,
    { editableLinearOptimisation }: SetEditableLinearOptimisationAction
  ) => {
    return { ...state, editableLinearOptimisation };
  }
});

const _getToolsForEquipment = (
  contextSettings: ApiContextSettings,
  equipment: NodeEquipmentResponseModel,
  energyManagerEquipmentId: string,
  tenantResources: ResourceModel[]
) => {
  const deviceQuery = queryString.stringify({
    equipmentId: energyManagerEquipmentId
  });

  const reqStateConstant = SET_EQUIPMENT_TOOLS_REQ_STATE;
  const _apiFetch = getAPIFetch();

  return (dispatch: AdminDispatch) => {
    dispatch({
      type: reqStateConstant,
      payload: {
        state: REQ_STATE_PENDING,
        payload: null,
        id: equipment.equipmentId,
        statusText: null,
        blocking: false
      }
    });
    let powerControlPromise = Promise.resolve([]);

    if (
      equipment.powerControls.length > 0 &&
      hasAccessToResource(ResourceType.POWER_CONTROL, tenantResources)
    ) {
      const query = queryString.stringify({
        providerIds: equipment.powerControls.map((pc) => pc.signalGroupId)
      });

      powerControlPromise = Promise.all([
        _apiFetch(contextSettings, '/powercontrols/providers?' + query)
      ])
        .then((responses) => {
          return Promise.all(responses.map((response) => response.json()));
        })
        .catch((error) => {
          if (error?.response?.status === HttpStatus.FORBIDDEN) {
            return [];
          }
          throw error;
        });
    }

    let linOptPromise = Promise.resolve([]);

    if (
      equipment.linearOptimisations.length > 0 &&
      hasAccessToResource(ResourceType.CORE, tenantResources)
    ) {
      const query = queryString.stringify({
        providerIds: equipment.linearOptimisations.map((pc) => pc.signalGroupId)
      });

      linOptPromise = Promise.all([
        _apiFetch(
          contextSettings,
          '/remoteoptimisations/linearoptimisations/providers?' + query
        )
      ])
        .then((responses) => {
          return Promise.all(responses.map((response) => response.json()));
        })
        .catch((error) => {
          if (error?.response?.status === HttpStatus.FORBIDDEN) {
            return [];
          }
          throw error;
        });
    }

    const deviceInfoPromise = getAPIFetch()(
      contextSettings,
      '/admin/deviceInfo?' + deviceQuery
    ).then((response) => response.json());

    return Promise.all([deviceInfoPromise, powerControlPromise, linOptPromise])
      .then(([deviceInfo, powerControls, linearOptimisations]) => {
        return dispatch({
          type: reqStateConstant,
          payload: {
            state: REQ_STATE_SUCCESS,
            id: equipment.equipmentId,
            payload: {
              deviceInfo,
              powerControls: _.flatten(powerControls),
              linearOptimisations: _.flatten(linearOptimisations)
            },
            blocking: false
          }
        });
      })
      .catch((e) => {
        return dispatch({
          type: reqStateConstant,
          payload: {
            state: REQ_STATE_ERROR,
            id: equipment.equipmentId,
            payload: e,
            blocking: false
          }
        });
      });
  };
};

export const EditEquipmentToolsActions = {
  setEditablePowerControl: createAction(
    SET_EDITABLE_POWER_CONTROL,
    'editablePowerControl'
  ),
  setEditableLinearOptimisation: createAction(
    SET_EDITABLE_LINEAR_OPTIMISATION,
    'editableLinearOptimisation'
  ),
  setEquipment: (
    contextSettings: ApiContextSettings,
    equipment: NodeEquipmentResponseModel,
    energyManagerEquipmentId: string,
    tenantResources: ResourceModel[]
  ) => {
    return (dispatch: AdminDispatch) => {
      dispatch({
        type: SET_EDIT_TOOLS_EQUIPMENT_ID,
        payload: { equipmentId: equipment.equipmentId }
      });

      dispatch(
        _getToolsForEquipment(
          contextSettings,
          equipment,
          energyManagerEquipmentId,
          tenantResources
        )
      );
    };
  },
  updatePowerControl: (
    contextSettings: ApiContextSettings,
    {
      signalProviderId,
      nodeId,
      powerControlType,
      algorithmType,
      deviceId
    }: PowerControlProviderResponseModel,
    parentNodeId: string
  ) => {
    const updateAction = updateObject<
      AddOrUpdatePowerControlProviderRequestModel[],
      PowerControlProviderResponseModel[]
    >(
      contextSettings,
      '/powercontrols',
      'PUT',
      SET_POWER_CONTROL_REQ_STATE,
      [{ signalProviderId, nodeId, powerControlType, algorithmType, deviceId }],
      T.admin.equipment.request.updatedtool,
      T.admin.equipment.request.updatetoolfailed,
      true
    );

    // Make sure node tree tools are synced
    return (dispatch: AdminDispatch) => {
      updateAction(dispatch).then(() => {
        return updateNodeTreeIncrementally(
          contextSettings,
          parentNodeId,
          dispatch
        );
      });
    };
  },
  updateLinearOptimisation: (
    contextSettings: ApiContextSettings,
    {
      signalProviderId,
      nodeId,
      deviceId,
      numberOfPoints
    }: LinearOptimisationProviderResponseModel,
    parentNodeId: string
  ) => {
    const updateAction = updateObject<
      AddOrUpdateLinearOptimisationRequestModel[],
      LinearOptimisationProviderResponseModel[]
    >(
      contextSettings,
      '/remoteoptimisations/providers',
      'PUT',
      SET_LINEAR_OPTIMISATION_REQ_STATE,
      [
        {
          signalProviderId: signalProviderId ?? UUID.generate(),
          nodeId,
          numberOfPoints,
          deviceId
        }
      ],
      T.admin.equipment.request.updatedtool,
      T.admin.equipment.request.updatetoolfailed,
      true
    );

    return (dispatch: AdminDispatch) => {
      updateAction(dispatch).then(() => {
        return updateNodeTreeIncrementally(
          contextSettings,
          parentNodeId,
          dispatch
        );
      });
    };
  }
};
