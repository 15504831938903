import {
  ToolSignalProviders,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import HelpPaths from 'ecto-common/help/tocKeys';
import EditNetmoreTool from 'js/components/ManageEquipment/EditEquipment/Netmore/EditNetmoreTool';
import { AdminToolImplementationType } from '../useEquipmentTools';
import NetmoreAPIGen from 'ecto-common/lib/API/NetmoreAPIGen';
import { ResourceType } from 'ecto-common/lib/constants';

const NetmoreTool: AdminToolImplementationType<object> = {
  type: ToolSignalProviders.NETMORE,
  name: ToolSignalProviderTranslations[ToolSignalProviders.NETMORE],
  accessRights: [ResourceType.SMART_COMFORT],
  allowDelete: true,
  allowDeploy: false,
  model: [],
  emptyInput: {},
  dialog: EditNetmoreTool,
  save: NetmoreAPIGen.Providers.addOrUpdateProviders.promise,
  delete: (contextSettings, providerIds, abortSignal) =>
    NetmoreAPIGen.Providers.deleteProviders.promise(
      contextSettings,
      { providerIds },
      abortSignal
    ),
  load: (contextSettings, equipmentIds, abortSignal) =>
    NetmoreAPIGen.Providers.getProvidersByNodeIds.promise(
      contextSettings,
      { nodeIds: equipmentIds },
      abortSignal
    ),
  helpPath: HelpPaths.docs.admin.manage.equipment.tools.netmore,
  idKey: 'id',
  includeDeviceId: false
};

export default NetmoreTool;
