import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import T from 'ecto-common/lib/lang/Language';

export function getRelativeTimeString(
  date: Date | string,
  fallbackFormat: string
) {
  const momentDate = moment.tz(date, DEFAULT_TIMEZONE);
  const diff = moment().diff(moment(date));
  const diffSeconds = Math.round(diff / 1000);
  const diffMinutes = Math.round(diffSeconds / 60);
  const diffHours = Math.round(diffMinutes / 60);

  if (diffSeconds < 60) {
    return T.relativetime.lessthanoneminutago;
  } else if (diffMinutes === 1) {
    return T.relativetime.oneminuteago;
  } else if (diffMinutes < 60) {
    return T.format(T.relativetime.minutesagoformat, diffMinutes);
  } else if (diffHours === 1) {
    return T.relativetime.onehourago;
  } else if (diffHours < 24) {
    return T.format(T.relativetime.hoursagoformat, diffHours);
  }

  return momentDate.format(fallbackFormat);
}

export function getDurationTimestring(date: Date) {
  const diff = -moment().diff(moment(date));
  const diffSeconds = Math.round(diff / 1000);
  const diffMinutes = Math.round(diffSeconds / 60);
  const diffHours = Math.round(diffMinutes / 60);

  if (diffSeconds < 60) {
    return T.durationtime.lessthanoneminute;
  } else if (diffMinutes === 1) {
    return T.durationtime.oneminute;
  } else if (diffMinutes < 60) {
    return T.format(T.durationtime.minutesformat, diffMinutes);
  } else if (diffHours === 1) {
    return T.durationtime.onehour;
  } else if (diffHours < 24) {
    return T.format(T.durationtime.hoursformat, diffHours);
  }

  return T.format(T.durationtime.daysformat, Math.floor(diffHours / 24));
}
