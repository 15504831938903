import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import styles from './EnergyManagerVersionsModal.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';

const programColumns = [
  {
    label: T.admin.energymanager.application,
    dataKey: 'program',
    width: '50%'
  },
  {
    label: T.admin.energymanager.version,
    dataKey: 'version',
    width: '25%'
  }
];

interface EnergyManagerVersionsModalProps {
  isLoading?: boolean;
  versionsData?: {
    program?: string;
    version?: string;
  }[];
  isOpen?: boolean;
  onModalClose: () => void;
}

const EnergyManagerVersionsModal = ({
  isLoading,
  versionsData,
  isOpen,
  onModalClose
}: EnergyManagerVersionsModalProps) => {
  return (
    <Modal
      className={styles.versionModal}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={Icons.EnergyManager}>
        {T.admin.energymanager.versions}
      </ModalHeader>
      <ModalBody className={styles.versionModalBody}>
        <DataTable
          isLoading={isLoading}
          data={versionsData}
          columns={programColumns}
        />
      </ModalBody>
    </Modal>
  );
};

export default EnergyManagerVersionsModal;
