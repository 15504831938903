import { useContext } from 'react';
import _ from 'lodash';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, {
  ComfortHeatingProviderResponseModel
} from 'ecto-common/lib/API/APIGen';

export const EMPTY_INPUT = Object.freeze({
  displayName: null,
  meanTemperatureSignalId: null,
  outTemperatureSignalId: null,
  tapWaterEquipmentId: null,
  windSpeedSignalId: null
});

/**
 * Loads comfort tool for current equipment and node
 * @param nodeId
 * @param equipmentId
 * @returns {[currentTool, isLoadingTool, reloadToolFunc]}
 */
const useComfortTools = (
  nodeId: string,
  equipmentId: string
): [
  currentTool: ComfortHeatingProviderResponseModel,
  isLoadingTool: boolean,
  reloadTools: () => void
] => {
  const { tenantResources } = useContext(TenantContext);
  const canUseTool = hasAccessToResource(
    ResourceType.SMART_COMFORT,
    tenantResources
  );

  const loadQuery =
    APIGen.AdminComfort.getComfortHeatingProvidersByNodeId.useQuery(
      {
        nodeIds: [nodeId]
      },
      {
        enabled: canUseTool
      }
    );

  const currentTool = _.find(loadQuery.data, ['equipmentId', equipmentId]);

  return [currentTool, loadQuery.isLoading && canUseTool, loadQuery.refetch];
};

export default useComfortTools;
