import React, { useState } from 'react';

import MeteorologyPoints from 'js/components/Meteorology/MeteorologyPoints';
import T from 'ecto-common/lib/lang/Language';
import Heading from 'ecto-common/lib/Heading/Heading';
import styles from './LocationForm.module.css';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import Icons from 'ecto-common/lib/Icons/Icons';
import { GeographicalPointResponseModel } from 'ecto-common/lib/API/APIGen';

interface SelectMeteorologyPointProps {
  onSelectedItem(
    item: GeographicalPointResponseModel,
    originallyChecked: GeographicalPointResponseModel[]
  ): void;
  currentNodeId?: string;
  checkedIds?: string[];
  setCheckedIds?(newCheckedIds: string[]): void;
  onPointsLoaded?(points: GeographicalPointResponseModel[]): void;
}

const SelectMeteorologyPoint = ({
  onSelectedItem,
  currentNodeId,
  checkedIds,
  setCheckedIds,
  onPointsLoaded
}: SelectMeteorologyPointProps) => {
  const [searchString, setSearchString] = useState<string>(null);

  return (
    <div className={styles.dialogContent}>
      <div className={styles.searchContainer}>
        <Heading level={3}>
          {T.admin.createlocation.configureweather.title}
        </Heading>

        <TextInput
          wrapperClassName={styles.searchField}
          autoFocus
          placeholder={
            T.admin.createlocation.configureweather.searchplaceholder
          }
          value={searchString}
          icon={<Icons.Search />}
          onChange={(event) => setSearchString(event.target.value)}
        />
      </div>

      <div className={styles.tableContainer}>
        <MeteorologyPoints
          nameOnly
          searchString={searchString}
          className={styles.meteorologyTable}
          onClickRow={onSelectedItem}
          currentNodeId={currentNodeId}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          onPointsLoaded={onPointsLoaded}
          checkable
        />
      </div>
    </div>
  );
};

export default React.memo(SelectMeteorologyPoint);
