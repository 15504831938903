import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  ModelDefinition,
  ModelDynamicBoolProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { ComfortHeatingProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import { SignalProviderSignalResponseModel } from 'ecto-common/lib/API/APIGen';
import { ComfortEqOrAdminSignalWithSignalProviderName } from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditComfortTool';

// Create a select a single signal model
export const comfortSignal = <ValueType>(
  key: (input: ComfortHeatingProviderResponseModel) => ValueType,
  label: string,
  hasError: ModelDynamicBoolProperty<
    ComfortHeatingProviderResponseModel,
    object,
    ValueType
  > = false,
  validSignalTypeIds: string[] = null
) => {
  return {
    key,
    label,
    placeholder: label,
    modelType: ModelType.SIGNAL,
    hasError,
    selectFromCurrentNodeOnly: true,
    validSignalTypeIds
  };
};

export type SignalWithSignalProviderName = SignalProviderSignalResponseModel & {
  signalProviderName: string;
};

export type ComfortEnvironment = {
  signalInfo: Record<string, SignalWithSignalProviderName> &
    Record<string, ComfortEqOrAdminSignalWithSignalProviderName>;
  getSignalInfoIsLoading: boolean;
};

export const comfortEquipment = (
  key: (input: ComfortHeatingProviderResponseModel) => string,
  label: string,
  hasError = false
): ModelDefinition<ComfortHeatingProviderResponseModel> => {
  return {
    key,
    label,
    placeholder: label,
    modelType: ModelType.EQUIPMENT,
    hasError,
    selectFromCurrentNodeOnly: true
  };
};

const IntegralGainShape = {
  integralGain: 0,
  integralTime: 0,
  maxOutTempValue: 0,
  maxOutputValue: 0,
  maxRoomTempSetpointValue: 0,
  maxRoomTempValue: 0,
  minOutputValue: 0,
  minRoomTempSetpointValue: 0,
  minRoomTempValue: 0,
  timeConstant: 0
};

export const COMFORT_DOMAIN_MODELS = {
  IntegralGainShape,
  IntegralGainWindspeed: {
    ...IntegralGainShape,
    maxWindSpeedValue: 0,
    minWindSpeedValue: 0
  },
  Highpass: {
    maxOutputValue: 0,
    minOutputValue: 0,
    sign: '',
    timeConstant: 0
  },
  PidControl: {
    derivativeTime: 0,
    inputDeadbandDiffLower: 0,
    inputDeadbandDiffUpper: 0,
    integralTime: 0,
    maxOutputValue: 0,
    minOutputValue: 0,
    sign: '',
    gain: 0
  }
};
