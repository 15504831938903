import T from 'ecto-common/lib/lang/Language';
import { isValidIP } from 'ecto-common/lib/utils/stringUtils';
import {
  text,
  option,
  label,
  disabledText,
  getPathStringFromModelKeyFunc
} from 'ecto-common/lib/ModelForm/formUtils';
import _ from 'lodash';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  IoTDeviceSettingsResponseModel,
  IoTDeviceViewResponseModel
} from 'ecto-common/lib/API/APIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';

export const WiredInterfaces = {
  ETH0: 'eth0',
  ETH1: 'eth1'
} as const;

export type WiredInterface = ObjectValues<typeof WiredInterfaces>;

export const NetworkInterfaceType = Object.freeze({
  PPP0: 'ppp0',
  ...WiredInterfaces
} as const);

const IoTNetworkMode = {
  DHCP: 'DHCP',
  STATIC: 'Static',
  UNKNOWN: 'Unknown'
} as const;

type IoTNetworkModes = ObjectValues<typeof IoTNetworkMode>;

const NetworkInterfaceModel = (
  targetValue: string
): ModelDefinition<IoTDeviceSettingsResponseModel>[] => [
  {
    key: (input) => input.desiredSettings.defaultNetworkInterface,
    label: T.admin.iotdevicedetails.desiredinternetroute,
    modelType: ModelType.RADIO,
    isChecked: (
      value: string,
      input: IoTDeviceViewResponseModel | IoTDeviceSettingsResponseModel
    ) => {
      let _value = value;
      if (value === undefined) {
        _value = input.reportedSettings?.defaultNetworkInterface;
      }

      return _value === targetValue;
    },
    targetValue
  }
];

export const DefaultWiredNetworkInterfaceModel = NetworkInterfaceModel;
export const DefaultWirelessNetworkInterfaceModel = NetworkInterfaceModel(
  NetworkInterfaceType.PPP0
);

const DesiredNetworkTypeOptions: GenericSelectOption<IoTNetworkModes>[] = [
  { value: IoTNetworkMode.DHCP, label: T.admin.iotdevicedetails.dhcp },
  { value: IoTNetworkMode.STATIC, label: T.admin.iotdevicedetails.static }
];

const isStatic = (
  wiredInterface: WiredInterface,
  input: IoTDeviceViewResponseModel | IoTDeviceSettingsResponseModel
) =>
  _.get(input, ['desiredSettings', wiredInterface, 'type']) ===
  IoTNetworkMode.STATIC;

const desiredInputIsValid =
  (wiredInterface: WiredInterface) =>
  (input: IoTDeviceViewResponseModel | IoTDeviceSettingsResponseModel) =>
    isStatic(wiredInterface, input);

const desiredStaticInputHasError =
  (wiredInterface: WiredInterface) =>
  (
    input: string,
    model: IoTDeviceViewResponseModel | IoTDeviceSettingsResponseModel
  ) => {
    if (!isStatic(wiredInterface, model)) {
      return false;
    }

    return !isValidIP(input);
  };

type HardwareModelItem = ModelDefinition<IoTDeviceSettingsResponseModel> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
};

export const getHardwareWiredSettings = (
  interfaceName: WiredInterface
): HardwareModelItem[] => [
  {
    ...option(
      (input: IoTDeviceSettingsResponseModel) =>
        input.desiredSettings?.[interfaceName]?.type,
      T.admin.iotdevicedetails.iptype,
      DesiredNetworkTypeOptions
    ),
    defaultValue: IoTNetworkMode.DHCP
  },
  {
    ...text(
      (input: IoTDeviceSettingsResponseModel) =>
        input.desiredSettings?.[interfaceName]?.ipAddress,
      T.admin.iotdevicedetails.ipaddress,
      desiredStaticInputHasError(interfaceName)
    ),
    defaultValue: null,
    visible: desiredInputIsValid(interfaceName)
  },
  {
    ...text(
      (input: IoTDeviceSettingsResponseModel) =>
        input.desiredSettings?.[interfaceName]?.gateway,
      T.admin.iotdevicedetails.gateway,
      desiredStaticInputHasError(interfaceName)
    ),
    defaultValue: null,
    visible: desiredInputIsValid(interfaceName)
  },
  {
    ...text(
      (input: IoTDeviceSettingsResponseModel) =>
        input.desiredSettings?.[interfaceName]?.mask,
      T.admin.iotdevicedetails.netmask,
      desiredStaticInputHasError(interfaceName)
    ),
    defaultValue: null,
    visible: desiredInputIsValid(interfaceName)
  },
  disabledText(
    (input: IoTDeviceSettingsResponseModel) =>
      input.reportedSettings?.[interfaceName]?.type,
    T.admin.iotdevicedetails.currentiptype
  ),
  disabledText(
    (input: IoTDeviceSettingsResponseModel) =>
      input.reportedSystem?.[interfaceName]?.ipAddress,
    T.admin.iotdevicedetails.currentipaddress
  ),
  disabledText(
    (input: IoTDeviceSettingsResponseModel) =>
      input.reportedSystem?.[interfaceName]?.gateway,
    T.admin.iotdevicedetails.currentgateway
  ),
  disabledText(
    (input: IoTDeviceSettingsResponseModel) =>
      input.reportedSystem?.[interfaceName]?.mask,
    T.admin.iotdevicedetails.currentnetmask
  )
];

const defaultNetworkInterfaceModel: Partial<HardwareModelItem> = {
  key: (input) => input.desiredSettings.defaultNetworkInterface,
  defaultValue: WiredInterfaces.ETH0
};

export const initialFormState = (
  wiredInterfaces: WiredInterface[]
): IoTDeviceSettingsResponseModel => ({
  ioTDeviceId: null,
  // Append special case for defaultNetworkInterface
  // It does not exist on newly created devices but the default is eth0
  ..._.reduce(
    [
      ..._.flatMap(wiredInterfaces, getHardwareWiredSettings),
      defaultNetworkInterfaceModel
    ],
    (defaultValues, model) =>
      _.set(
        defaultValues,
        getPathStringFromModelKeyFunc(model.key),
        model.defaultValue
      ),
    {}
  )
});

export const HardwareReportedWirelessSettings = [
  label(
    (input: IoTDeviceSettingsResponseModel) =>
      input.reportedSystem.ppp0.ipAddress,
    T.admin.iotdevicedetails.ipaddress
  ),
  label(
    (input: IoTDeviceSettingsResponseModel) => input.reportedSystem.ppp0.mask,
    T.admin.iotdevicedetails.netmask
  )
];
