import React, { useContext } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import { InstantiateBuildingActions } from 'js/modules/instantiateEmptyBuilding/instantiateEmptyBuilding';
import T from 'ecto-common/lib/lang/Language';
import { translationsForTemplateState } from 'js/components/InitEquipmentTemplate/translations';
import ConfigureTemplateEquipmentDialog from 'js/components/InitEquipmentTemplate/ConfigureTemplateEquipmentDialog';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { requestIsLoading } from 'ecto-common/lib/utils/requestStatus';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface InstantiateEmptyBuildingProps {
  parentLocation?: SingleGridNode;
  location: SingleGridNode;
}

const InstantiateEmptyBuilding = ({
  location,
  parentLocation
}: InstantiateEmptyBuildingProps) => {
  const dispatch = useAdminDispatch();

  const loading = useAdminSelector(
    (state) => state.instantiateEmptyBuilding.loading
  );
  const templateState = useAdminSelector(
    (state) => state.instantiateEmptyBuilding.templateState
  );
  const initDeviceConfigReqState = useAdminSelector(
    (state) => state.equipmentTemplateForm.initDeviceConfigReqState
  );
  const { contextSettings } = useContext(TenantContext);

  return (
    <ConfigureTemplateEquipmentDialog
      onConfirmClick={() =>
        dispatch(
          InstantiateBuildingActions.performAdd(contextSettings, location)
        )
      }
      confirmText={T.common.add}
      confirmIcon={<Icons.Add />}
      onModalClose={() => dispatch(InstantiateBuildingActions.cancel())}
      isLoading={requestIsLoading(initDeviceConfigReqState) || loading}
      loadingText={templateState && translationsForTemplateState[templateState]}
      parentLocation={parentLocation}
    />
  );
};

export default React.memo(InstantiateEmptyBuilding);
