import { ResourceType } from 'ecto-common/lib/constants';
import { Route } from 'ecto-common/lib/types/Route';

export const BuildingsRoute: Route = {
  path: '/:tenantId/buildings',
  exact: false,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const GlobalTenantAdminRoute: Route = {
  path: '/:tenantId/tenants',
  exact: false,
  resourceTypes: [ResourceType.TENANT_MANAGEMENT]
};
export const TenantUsersAdminRoute: Route = {
  path: '/:tenantId/tenantUsers',
  exact: false,
  resourceTypes: [ResourceType.USER_MANAGEMENT]
};

export const DashboardsManagementRoute: Route = {
  path: '/:tenantId/dashboards/:itemId?/:subPage?',
  exact: true,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.ECTOPLANNER]
};

export const ProcessMapsManagementRoute: Route = {
  path: '/:tenantId/processmaps/:itemId?/:subPage?',
  exact: true,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.ECTOPLANNER]
};

// The template management tenant needs to have the core resource type set in order for certain things to work properly in the backend. However,
// there are lots of pages that are traditionally shown when you have the core resource type that should not be visible to the template management
// tenant. This is why we have the hideIfHasResourceTypes property.
export const NotificationsRoute: Route = {
  path: '/:tenantId/notifications',
  exact: true,
  resourceTypes: [ResourceType.CORE, ResourceType.USER_MANAGEMENT],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const NotificationsV2Route: Route = {
  path: '/:tenantId/notifications-v2',
  exact: true,
  resourceTypes: [ResourceType.CORE, ResourceType.USER_MANAGEMENT],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const AlarmRulesRoute: Route = {
  path: '/:tenantId/alarmrules',
  exact: true,
  resourceTypes: [ResourceType.CORE, ResourceType.USER_MANAGEMENT],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const EctotableRoute: Route = {
  path: '/:tenantId/ectotable',
  exact: false,
  resourceTypes: [ResourceType.ECTOTABLE_MANAGEMENT]
};
export const MeteorologyRoute: Route = {
  path: '/:tenantId/meteorology/:itemId?',
  exact: false,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const IntegrationsRoute: Route = {
  path: '/:tenantId/integrations/:itemId?',
  exact: false,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};

export const TemplateManagementRoute: Route = {
  path: '/:tenantId/templateManagement/:page?/:itemId?/:subPage?',
  exact: false,
  resourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const ProvisioningRoute: Route = {
  path: '/:tenantId/provisioning/:page?/:itemId?',
  exact: false,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const LocationRoute: Route = {
  path: '/:tenantId/home/:nodeId/:page/:itemId?/:subPage?/*',
  exact: false,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const NoLocationsRoute: Route = {
  path: '/:tenantId/noLocations',
  exact: true,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};

export const IntegrationAccountsRoute: Route = {
  path: '/:tenantId/integration-accounts/:page?/:itemId?',
  exact: false,
  resourceTypes: [ResourceType.SMART_COMFORT]
};
export const HelpRoute: Route = { path: '/:tenantId/help' };

export type ItemPageParams = {
  tenantId: string;
  itemId?: string;
};

export type ProvisioningParams = {
  tenantId: string;
  page?: string;
  itemId?: string;
};

export type TemplateManagementParams =
  | 'tenantId'
  | 'page'
  | 'itemId'
  | 'subPage';

import { generatePath } from 'react-router-dom';

export const getLocationRoute = (
  tenantId: string,
  nodeId: string,
  page = 'dashboard'
) => {
  return generatePath(LocationRoute.path, { tenantId, nodeId, page });
};

export const getProcessMapsManagementRoute = (
  tenantId: string,
  itemId: string = undefined,
  subPage: string = undefined
) => {
  return generatePath(ProcessMapsManagementRoute.path, {
    tenantId,
    itemId,
    subPage
  });
};

export const getTemplateManagementRoute = (
  tenantId: string,
  page: string,
  itemId: string = undefined,
  subPage: string = undefined
) => {
  return generatePath(TemplateManagementRoute.path, {
    tenantId,
    page,
    itemId,
    subPage
  });
};

export const getAlarmUrl = () => {
  return '';
};

export const getAlarmUrlV2 = () => {
  return '';
};

export const getSignalsUrl = () => {
  return '';
};
