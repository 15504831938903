import React, { Dispatch, SetStateAction, useMemo } from 'react';
import useFormInputWithValidation from 'ecto-common/lib/ModelForm/useFormInputWithValidation';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

interface EditToolContentProps<ObjectType extends object> {
  equipmentId?: string;
  item?: object;
  setItem: Dispatch<SetStateAction<ObjectType>>;
  setIsValidItem: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
  model: ModelDefinition<ObjectType>[];
}

const EditToolContent = <ObjectType extends object>({
  equipmentId,
  item,
  setItem,
  setIsValidItem,
  isLoading,
  model
}: EditToolContentProps<ObjectType>) => {
  const environment = useMemo(() => ({ equipmentId }), [equipmentId]);
  const onUpdateInput = useFormInputWithValidation(
    item,
    setItem,
    setIsValidItem,
    model
  );

  return (
    <ModelForm
      input={item}
      isLoading={isLoading}
      models={model}
      environment={environment}
      onUpdateInput={onUpdateInput}
    />
  );
};

export default React.memo(EditToolContent);
