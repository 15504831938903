import React from 'react';
import moment from 'moment';
import T from 'ecto-common/lib/lang/Language';
import { NavLink } from 'react-router-dom';

import { getNodePage } from 'ecto-common/lib/utils/commonLinkUtil';
import { BuildingStatusText } from 'ecto-common/lib/utils/buildingStatusUtil';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

const createBuildingsListColumns = (tenantId: string) => [
  {
    label: T.admin.buildings.column.status,
    dataKey: 'buildingInfo.buildingStatus',
    minWidth: 100,
    sortable: true,
    dataFormatter: (status: BuildingStatus) => (
      <div>{BuildingStatusText[status]}</div>
    )
  },
  {
    label: T.admin.buildings.column.statusdate,
    dataKey: 'buildingInfo.buildingStatusTimestamp',
    minWidth: 100,
    sortable: true,
    dataFormatter: (date: string) => (
      <div>{moment(date).format('YYYY-MM-DD')}</div>
    )
  },
  {
    label: T.admin.buildings.column.districtheatingfacilityid,
    dataKey: 'buildingInfo.districtHeatingFacilityId',
    minWidth: 100,
    sortable: false
  },
  {
    label: T.admin.buildings.column.name,
    dataKey: 'name',
    width: 500,
    minWidth: 100,
    sortable: true,
    dataFormatter: (_name: string, node: SingleGridNode, index: number) => (
      <NavLink
        to={getNodePage(tenantId, node)}
        key={'nodelink_' + node.nodeId + '_' + index}
      >
        {node.name}
      </NavLink>
    )
  },
  {
    label: T.admin.buildings.column.tags,
    dataKey: 'tags',
    width: 300,
    minWidth: 100,
    dataFormatter: (tags: string[], unused: unknown, index: number) => {
      return (
        <span key={index}>
          <TagsGroup tags={tags} />
        </span>
      );
    }
  }
];

export default createBuildingsListColumns;
