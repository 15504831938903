import React from 'react';
import styles from './StatusCircle.module.css';
import classNames from 'classnames';

export const STATUS_CIRCLE_OK = 'statusOk';
export const STATUS_CIRCLE_ERROR = 'statusError';
export const STATUS_CIRCLE_WARNING = 'statusWarning';
export const STATUS_CIRCLE_IDLE = 'statusIdle';

// TODO: Convert status to enum
interface StatusCircleProps {
  /**
   * The status of the circle. Affects the background color of the circle.
   */
  status?: 'statusOk' | 'statusError' | 'statusWarning' | 'statusIdle';
}

/**
 * Use this component to indicate the status of an item. Typically used in table layouts.
 */
const StatusCircle = ({ status }: StatusCircleProps) => {
  return <div className={classNames(styles.statusCircle, styles[status])} />;
};

export default React.memo(StatusCircle);
