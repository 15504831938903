import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  ROOT_NODE_ID
} from 'ecto-common/lib/constants';
import Button from 'ecto-common/lib/Button/Button';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import HelpPaths from 'ecto-common/help/tocKeys';

import { CreateNodeActions } from 'js/modules/createNodeForm/createNodeForm';
import NotificationsDialog from 'js/components/Notifications/NotificationsDialog';
import CreateLocationDialog from 'js/components/EditLocation/CreateLocationDialog';
import LocationForm, {
  LocationFormData
} from 'js/components/EditLocation/LocationForm';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import EditMeteorologyPointDialog from './EditMeteorologyPointDialog';
import AddLogEntryDialog from 'js/components/EditLocation/AddLogEntryDialog';
import ManageNodeTools from 'js/components/EditLocation/Tools/ManageNodeTools';
import EditLocationParentsDialog from 'js/components/EditLocation/EditLocationParentsDialog';
import { updateNodeTreeIncrementallyFromDelete } from 'js/modules/provisioningCommon/provisioningCommon';
import { getLocationRoute } from 'js/utils/routeConstants';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { patchNodes } from 'ecto-common/lib/actions/getNodes';
import { useAdminDispatch, useAdminSelector } from 'js/reducers/storeAdmin';
import APIGen, {
  AddOrUpdateNodeRequestModel,
  NodeType,
  GridType
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import EditIntegrationPointsForNode from '../Integrations/EditIntegrationPointsForNode';
import { AdminDispatch } from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation } from '@tanstack/react-query';
import { usePromptMessage } from 'ecto-common/lib/hooks/useBlockerListener';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import { adminHomeUrlBuilder } from 'js/utils/linkUtil';
import { organizeEquipments } from 'js/components/Equipments/Equipments';
import NewEquipment from 'js/components/ManageEquipment/NewEquipment/NewEquipment';
import AddButton from 'ecto-common/lib/Button/AddButton';
import { AddEquipmentFormActions } from 'js/modules/addEquipmentForm/addEquipmentForm';
import { InstantiateBuildingActions } from 'js/modules/instantiateEmptyBuilding/instantiateEmptyBuilding';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import InstantiateEmptyBuilding from 'js/components/InstantiateEmptyBuilding/InstantiateEmptyBuilding';
import SelectDashboardRelationDialog from '../Dashboards/SelectDashboardRelationDialog';
import SelectFilesDialog from 'ecto-common/lib/SelectFilesDialog/SelectFilesDialog';

const updateLocationPromise = (
  contextSettings: ApiContextSettings,
  location: AddOrUpdateNodeRequestModel
) => {
  return APIGen.AdminNodes.addOrUpdateNode
    .promise(contextSettings, location, null)
    .then((response) => {
      return Promise.all([
        APIGen.AdminNodes.getNodes.promise(
          contextSettings,
          { NodeId: response.nodeId },
          null
        ),
        APIGen.AdminNodes.getNodeTags.promise(contextSettings, null)
      ] as const);
    });
};

const FORM_DATA_INITIAL_STATE: LocationFormData = {
  name: '',
  street: '',
  nodeType: NodeType.Site,
  latitude: DEFAULT_LAT,
  longitude: DEFAULT_LNG,
  buildingInfo: null,
  tags: [],
  grids: [GridType.Heating]
};

// NOTE: We don't patch the node tree in this promise since that can lead to the component
// rendering the editor being unmounted before the onSuccess callback can be called.
const deleteLocationPromise = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  dispatch: AdminDispatch
) => {
  return APIGen.AdminNodes.deleteNode
    .promise(contextSettings, { nodeId }, null)
    .then(() => APIGen.AdminNodes.getNodeTags.promise(contextSettings, null))
    .then((nodeTags: string[]) => {
      dispatch(setNodeTags(nodeTags));
    });
};

interface EditLocationProps {
  onTitleChanged: (title: string[]) => void;
  selectedLocation: SingleGridNode;
}

const FakeRootNode: SingleGridNode = {
  children: [],
  grid: GridType.Heating,
  grids: [],
  nodeId: ROOT_NODE_ID,
  nodeType: NodeType.Site,
  numberOfActiveAlarms: 0,
  parentId: null
};

const EditLocation = ({
  onTitleChanged,
  selectedLocation: selectedLocationArg
}: EditLocationProps) => {
  const dispatch = useAdminDispatch();
  const navigate = useNavigate();
  const [isShowingNodeForm, showNodeForm, hideNodeForm] =
    useSimpleDialogState();
  const [isShowingSelectFiles, showSelectFiles, hideSelectFiles] =
    useSimpleDialogState();

  const creatRootNodeForm = useAdminSelector(
    (state) => state.createNodeForm.createRootNode
  );

  const selectedLocation = selectedLocationArg ?? FakeRootNode;

  const { tenantId } = useContext(TenantContext);

  const [hasChanges, setHasChanges] = useState(false);
  const [formData, setFormData] = useState<LocationFormData>(
    FORM_DATA_INITIAL_STATE
  );
  const [selectedParentIds, setSelectedParentIds] = useState(
    selectedLocation.parentIds
  );

  const [showConfirmDelete, onShowConfirmDelete, onHideConfirmDelete] =
    useDialogState('show-confirm-delete');

  const [showEditTools, onShowEditTools, onHideEditTools] =
    useDialogState('show-edit-tools');

  const [
    showEditDashboardCollection,
    onShowEditDashboardCollection,
    onHideEditDashboardCollection
  ] = useDialogState('show-edit-dashboard-collection');

  const [showEditProcessMap, onShowEditProcessMap, onHideEditProcessMap] =
    useDialogState('show-edit-process-map');

  const [
    showEditMeteorologyDialog,
    onShowEditMeteorologyDialog,
    onHideEditMeteorologyDialog
  ] = useDialogState('show-edit-meteorology');

  const [
    showEditNotifications,
    onShowEditNotifications,
    onHideEditNotifications
  ] = useDialogState('show-edit-notifications');

  const [showEditParents, onShowEditParents, onHideEditParents] =
    useDialogState('show-edit-parents');

  const [
    showEditIntegrationPoints,
    onShowEditEditIntegrationPoints,
    onHideEditIntegrationPoints
  ] = useDialogState('show-edit-integration-points');

  const [showLogEntryForm, onShowLogEntry, onHideLogEntry] =
    useDialogState('show-log-entry');

  useEffect(() => {
    setFormData({
      ...FORM_DATA_INITIAL_STATE,
      name: selectedLocation.name,
      street: selectedLocation.street,
      nodeType: selectedLocation.nodeType,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
      buildingInfo: selectedLocation.buildingInfo ?? null,
      tags: selectedLocation.tags,
      grids: selectedLocation.grids
    });
    setHasChanges(false);
    setSelectedParentIds(selectedLocation.parentIds);
  }, [selectedLocation]);

  const formDataChanged = useCallback((newState: Partial<LocationFormData>) => {
    if (newState.grids != null && newState.grids.length === 0) {
      newState.grids = [GridType.Heating];
    }

    setHasChanges(true);
    setFormData((prevState) => ({
      ...prevState,
      ...newState
    }));
  }, []);

  const annotatedLocationObject = useCallback(() => {
    const {
      nodeId,
      name,
      longitude,
      latitude,
      street,
      tags,
      nodeType,
      buildingInfo
    } = selectedLocation;
    const parentIds = _.uniq(
      selectedParentIds.filter((x) => !x.startsWith(ROOT_NODE_ID))
    );
    const data = {
      nodeId,
      parentIds,
      name,
      longitude,
      latitude,
      street,
      tags,
      nodeType,
      buildingInfo,
      ...formData
    };

    if (nodeType !== NodeTypes.BUILDING) {
      delete data.buildingInfo;
    }

    return data;
  }, [formData, selectedLocation, selectedParentIds]);

  const { contextSettings } = useContext(TenantContext);

  const updateMutation = useMutation({
    mutationFn: (location: AddOrUpdateNodeRequestModel) => {
      return updateLocationPromise(contextSettings, location);
    },

    onSuccess: ([nodes, nodeTags]) => {
      dispatch(setNodeTags(nodeTags));
      dispatch(patchNodes(nodes));
      const updatedNode = _.head(nodes);
      toastStore.addSuccessToast(
        updatedNode.nodeType === NodeTypes.BUILDING
          ? T.admin.editbuilding.updated.building
          : T.admin.editsite.updated.site
      );
      setHasChanges(false);
      if (!updatedNode.grids.includes(selectedLocation?.grid)) {
        navigate(getLocationRoute(tenantId, updatedNode.nodeId), {
          replace: true
        });
      }
    },

    onError: (error) => {
      let failText;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((error as any)?.response.status === HttpStatus.CONFLICT) {
        failText = T.admin.editbuilding.conflict.facilityid;
      } else {
        failText =
          formData.nodeType === NodeTypes.BUILDING
            ? T.admin.editbuilding.update.failed
            : T.admin.editsite.updated.site;
      }

      toastStore.addErrorToast(failText);
    }
  });

  const deleteLocationMutation = useMutation({
    mutationFn: (nodeId: string) => {
      return deleteLocationPromise(contextSettings, nodeId, dispatch);
    },

    onSuccess: (_unused, deletedLocationNodeId) => {
      toastStore.addSuccessToast(T.admin.requests.deletelocation.success);
      updateNodeTreeIncrementallyFromDelete(deletedLocationNodeId, dispatch);
      onHideConfirmDelete();
      navigate(getLocationRoute(tenantId, selectedLocation.parentId), {
        replace: true
      });
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.requests.deletelocation.failure);
    }
  });

  const performUpdate = useCallback(() => {
    const location = annotatedLocationObject();

    if (location.grids.length === 0) {
      toastStore.addErrorToast(T.admin.editlocation.error.noparentlocation);
    } else {
      updateMutation.mutate(location);
    }
  }, [annotatedLocationObject, updateMutation]);

  const performDelete = useCallback(() => {
    const location = annotatedLocationObject();
    deleteLocationMutation.mutate(location.nodeId);
  }, [annotatedLocationObject, deleteLocationMutation]);

  const newType = useCallback(
    (type: string, createRootNode: boolean = false) => {
      dispatch(CreateNodeActions.resetForm());
      dispatch(CreateNodeActions.setType(type, createRootNode));
      dispatch(
        CreateNodeActions.setCoordinates(
          selectedLocation.latitude || DEFAULT_LAT,
          selectedLocation.longitude || DEFAULT_LNG
        )
      );
      showNodeForm();
    },
    [
      dispatch,
      selectedLocation.latitude,
      selectedLocation.longitude,
      showNodeForm
    ]
  );

  const onNewTypeSite = useCallback(() => newType(NodeTypes.SITE), [newType]);
  const onNewRootNode = useCallback(
    () => newType(NodeTypes.SITE, true),
    [newType]
  );

  const onNewTypeBuilding = useCallback(
    () => newType(NodeTypes.BUILDING),
    [newType]
  );

  const onConfirmDelete = useCallback(() => {
    if (showConfirmDelete) {
      performDelete();
    } else {
      onShowConfirmDelete();
    }
  }, [showConfirmDelete, performDelete, onShowConfirmDelete]);

  const showEditMeteorology = useCallback(() => {
    onShowEditMeteorologyDialog();
    dispatch(CreateNodeActions.resetMeteorologyForm());
  }, [dispatch, onShowEditMeteorologyDialog]);

  const hideEditMeteorology = useCallback(() => {
    onHideEditMeteorologyDialog();
    dispatch(CreateNodeActions.resetMeteorologyForm());
  }, [dispatch, onHideEditMeteorologyDialog]);

  const isRootNode = selectedLocation.nodeId.startsWith(ROOT_NODE_ID);

  const isEditNotificationsOpen = _.defaultTo(showEditNotifications, false);
  const buildingHasNoEquipment = selectedLocation?.equipments?.length === 0;
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);

  const parentLocation =
    selectedLocation && getNodeFromMap(nodeMap, selectedLocation.parentId);

  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );

  const { energyManagers } = useMemo(
    () =>
      selectedLocation?.equipments?.length > 0
        ? organizeEquipments(selectedLocation?.equipments, equipmentTypes)
        : { energyManagers: [] },
    [selectedLocation?.equipments, equipmentTypes]
  );

  const toolbarItems = useMemo(
    () => [
      <ToolbarFlexibleSpace key="space" />,
      selectedLocation.parentId == null && (
        <ToolbarItem key="add-root-node">
          <Button onClick={onNewRootNode}>
            <Icons.File />
            {T.admin.editlocation.addnewrootsite}
          </Button>
        </ToolbarItem>
      ),
      selectedLocation?.nodeType === NodeTypes.BUILDING &&
        buildingHasNoEquipment && (
          <ToolbarItem key="add-from-template">
            <Button
              onClick={() =>
                dispatch(
                  InstantiateBuildingActions.setLocation(selectedLocation)
                )
              }
            >
              <Icons.File /> {T.admin.equipment.addfromtemplate.title}
            </Button>
          </ToolbarItem>
        ),
      selectedLocation?.nodeType === NodeTypes.BUILDING && (
        <ToolbarItem key="add-equipment">
          <AddButton
            onClick={() =>
              dispatch(
                AddEquipmentFormActions.setShowDialog(
                  true,
                  buildingHasNoEquipment,
                  energyManagers,
                  equipmentTypes,
                  selectedLocation.nodeId
                )
              )
            }
          >
            {T.admin.equipment.add}
          </AddButton>
        </ToolbarItem>
      ),
      !isRootNode && (
        <ToolbarItem key="add-log-entry">
          <Button onClick={onShowLogEntry}>
            <Icons.File />
            {T.admin.editlocation.addlogentry}
          </Button>
        </ToolbarItem>
      ),
      !isRootNode && (
        <ToolbarItem key="save">
          <SaveButton
            disabled={!hasChanges || updateMutation.isPending}
            onClick={performUpdate}
            type="submit"
          >
            {selectedLocation.nodeType === NodeTypes.BUILDING
              ? T.admin.editbuilding.savelocation
              : T.admin.editsite.savelocation}
          </SaveButton>
        </ToolbarItem>
      )
    ],
    [
      buildingHasNoEquipment,
      dispatch,
      energyManagers,
      equipmentTypes,
      hasChanges,
      isRootNode,
      onNewRootNode,
      onShowLogEntry,
      performUpdate,
      selectedLocation,
      updateMutation.isPending
    ]
  );

  const onParentSelectedChanged = useCallback(
    (parentId: string, _isSelected: boolean) => {
      setSelectedParentIds([parentId]);
    },
    []
  );

  const onParentsEditCancelled = useCallback(() => {
    onHideEditParents();
    setSelectedParentIds(selectedLocation.parentIds);
  }, [onHideEditParents, selectedLocation.parentIds]);

  const onParentsEditDone = useCallback(() => {
    onHideEditParents();
    performUpdate();
  }, [onHideEditParents, performUpdate]);

  usePromptMessage(T.admin.form.unsavedstate, hasChanges);

  usePageTitleCallback({
    mainTitle: T.admin.tabs.locations,
    subTitle: '',
    onTitleChanged
  });
  if (!selectedLocation) {
    return <div />;
  }
  const createLocationDialogRoot = creatRootNodeForm
    ? FakeRootNode
    : selectedLocation;
  return (
    <LoadingContainer isLoading={updateMutation.isPending} showSpinner>
      <ToolbarContentPage
        title={T.admin.tabs.locations}
        toolbarItems={toolbarItems}
        helpPath={HelpPaths.docs.admin.manage.locations.locations}
        selectEquipment
        urlBuilder={adminHomeUrlBuilder}
      >
        <ActionModal
          compact
          onModalClose={onHideConfirmDelete}
          isOpen={showConfirmDelete}
          title={
            selectedLocation.nodeType === NodeTypes.BUILDING
              ? T.admin.editbuilding.delete.title
              : T.admin.editsite.delete.title
          }
          onConfirmClick={performDelete}
          isLoading={deleteLocationMutation.isPending}
        >
          {selectedLocation.nodeType === NodeTypes.BUILDING
            ? T.admin.editbuilding.deletelocation.text
            : T.admin.editsite.deletelocation.text}
        </ActionModal>

        <CreateLocationDialog
          parentLocation={createLocationDialogRoot}
          onModalClose={hideNodeForm}
          isOpen={isShowingNodeForm}
        />

        <AddLogEntryDialog
          isOpen={showLogEntryForm}
          onModalClose={onHideLogEntry}
          location={selectedLocation}
        />

        <EditIntegrationPointsForNode
          nodeId={selectedLocation.nodeId}
          isOpen={showEditIntegrationPoints}
          onModalClose={onHideEditIntegrationPoints}
        />

        <LocationForm
          isVirtualRootNode={selectedLocationArg == null}
          onEditIntegrations={onShowEditEditIntegrationPoints}
          onAddNewSite={onNewTypeSite}
          onAddNewBuilding={onNewTypeBuilding}
          onDeleteLocation={onConfirmDelete}
          onEditParents={onShowEditParents}
          onEditTools={onShowEditTools}
          onEditNotifications={onShowEditNotifications}
          onEditMeteorology={showEditMeteorology}
          onEditProcessMaps={onShowEditProcessMap}
          onEditDashboards={onShowEditDashboardCollection}
          onEditFiles={showSelectFiles}
          formData={formData}
          onFormDataChanged={formDataChanged}
          location={selectedLocation}
        />

        <EditLocationParentsDialog
          isOpen={showEditParents}
          selectedParentIds={selectedParentIds}
          onParentSelectedChanged={onParentSelectedChanged}
          onParentsEditCancelled={onParentsEditCancelled}
          onParentsEditDone={onParentsEditDone}
        />

        {selectedLocation.nodeType === NodeTypes.BUILDING && (
          <ManageNodeTools
            isOpen={showEditTools}
            onClose={onHideEditTools}
            nodeId={selectedLocation.nodeId}
          />
        )}

        <SelectDashboardRelationDialog
          nodeId={selectedLocation.nodeId}
          isOpen={showEditDashboardCollection}
          onModalClose={onHideEditDashboardCollection}
        />

        <EditMeteorologyPointDialog
          useForEditingExisting
          selectedLocation={selectedLocation}
          isOpen={showEditMeteorologyDialog}
          onModalClose={hideEditMeteorology}
        />

        <NotificationsDialog
          nodeId={selectedLocation.nodeId}
          isOpen={isEditNotificationsOpen}
          onModalClose={onHideEditNotifications}
        />
        <SelectFilesDialog
          onModalClose={hideSelectFiles}
          isOpen={isShowingSelectFiles}
          nodeId={selectedLocation.nodeId}
          canEditFiles
        />
        <SelectProcessMapDialog
          onModalClose={onHideEditProcessMap}
          isOpen={showEditProcessMap}
          nodeId={selectedLocation.nodeId}
        />

        <NewEquipment devices={energyManagers} />

        <InstantiateEmptyBuilding
          parentLocation={parentLocation}
          location={selectedLocation}
        />
      </ToolbarContentPage>
    </LoadingContainer>
  );
};

export default EditLocation;
