import React, { useCallback, useMemo, useState } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import useNodeTypes from 'ecto-common/lib/hooks/useNodeTypes';
import { searchByCaseInsensitive } from 'ecto-common/lib/utils/stringUtils';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';

export type NodeTypesItem = {
  type: string;
  name: string;
};

export const columns: DataTableColumnProps<NodeTypesItem>[] = [
  {
    label: null,
    dataKey: 'name',
    flexGrow: 1,
    width: 100,
    linkColumn: true
  }
];

type SelectPresentationDialog = (props: {
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  equipmentTypeId?: string;
  onConfirm?: (item: NodeTypesItem[]) => void;
  actionText?: React.ReactNode;
}) => JSX.Element;

const NodeTypeProcessMapsRelationsModal = ({
  isOpen,
  onModalClose,
  itemSelectComponent
}: {
  isOpen: boolean;
  onModalClose: () => void;
  itemSelectComponent: SelectPresentationDialog;
}) => {
  const [searchString, setSearchString] = useState<string>('');
  const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] =
    useState<string>(null);

  const onClickRow = useCallback((equipment: NodeTypesItem) => {
    setSelectedEquipmentTypeId(equipment.type);
  }, []);

  const nodeTypes = useNodeTypes();

  const filteredList = useMemo(() => {
    return searchByCaseInsensitive(nodeTypes, searchString, 'name');
  }, [nodeTypes, searchString]);

  const ItemSelectDialog = itemSelectComponent;
  return (
    <>
      <ActionModal
        withSearchField
        disableCancel
        onSearchTextChanged={setSearchString}
        isOpen={isOpen}
        onModalClose={onModalClose}
        onConfirmClick={onModalClose}
        headerIcon={Icons.Edit}
        title={T.admin.processmaps.editnodetyperelation}
      >
        <DataTable<NodeTypesItem>
          isLoading={false}
          disableHeader
          onClickRow={onClickRow}
          data={filteredList}
          columns={columns}
        />
        <ItemSelectDialog
          isOpen={selectedEquipmentTypeId != null}
          onModalClose={() => setSelectedEquipmentTypeId(null)}
          nodeId={null}
          equipmentTypeId={selectedEquipmentTypeId}
        />
      </ActionModal>
    </>
  );
};

export default NodeTypeProcessMapsRelationsModal;
