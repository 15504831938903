import React, { useCallback, useEffect, useState } from 'react';

import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import DraggableMarkerMap from 'ecto-common/lib/Map/DraggableMarkerMap';
import { CreateNodeActions } from 'js/modules/createNodeForm/createNodeForm';
import styles from 'js/components/EditLocation/CreateLocationDialog.module.css';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { MapLocation } from 'ecto-common/lib/Map/Map';

interface SelectMapPointDialogProps {
  isOpen: boolean;
  onModalClose: () => void;
  onMapsLoaded?(): void;
  onSelectedLocation?(location: MapLocation): void;
}

const SelectMapPointDialog = ({
  isOpen,
  onModalClose,
  onMapsLoaded,
  onSelectedLocation
}: SelectMapPointDialogProps) => {
  const dispatch = useAdminDispatch();
  const latitude = useAdminSelector((state) => state.createNodeForm.latitude);
  const longitude = useAdminSelector((state) => state.createNodeForm.longitude);

  const [editLatitude, setEditLatitude] = useState(latitude);
  const [editLongitude, setEditLongitude] = useState(longitude);

  useEffect(() => {
    setEditLatitude(latitude);
  }, [latitude, isOpen]);

  useEffect(() => {
    setEditLongitude(longitude);
  }, [longitude, isOpen]);

  const onCoordinateChanged = useCallback((lat: number, lng: number) => {
    setEditLatitude(lat);
    setEditLongitude(lng);
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(CreateNodeActions.setCoordinates(editLatitude, editLongitude));
    onModalClose();
  }, [dispatch, onModalClose, editLatitude, editLongitude]);

  const onSelectLocationFromSearch = useCallback(
    (location: MapLocation) => {
      setEditLatitude(location.lat);
      setEditLongitude(location.lng);
      onSelectedLocation(location);
    },
    [onSelectedLocation]
  );

  return (
    <Modal
      className={styles.mapDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={Icons.MapMarker}>
        {T.admin.createlocation.configurelocation.title}
      </ModalHeader>

      <ModalBody>
        <DraggableMarkerMap
          onCoordinateChanged={onCoordinateChanged}
          initialLatitude={latitude}
          initialLongitude={longitude}
          onMapsLoaded={onMapsLoaded}
          searchable
          onSelectLocationFromSearch={onSelectLocationFromSearch}
        />
      </ModalBody>

      <ModalFooter>
        <LocalizedButtons.Done onClick={onConfirm} />
        <LocalizedButtons.Cancel onClick={onModalClose} />
      </ModalFooter>
    </Modal>
  );
};

export default SelectMapPointDialog;
