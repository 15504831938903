import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';
import SelectPresentationItemDialog, {
  Item
} from 'ecto-common/lib/SelectPresentationItemDialog/SelectPresentationItemDialog';

const SelectDashboardRelationDialog = ({
  isOpen,
  onModalClose,
  nodeId,
  nodeType,
  equipmentTypeId,
  onConfirm = null,
  actionText = T.common.save,
  initialItems
}: {
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  nodeType?: string; // used when no specific node id is given
  equipmentTypeId?: string;
  onConfirm?: (item: Item[]) => void;
  actionText?: React.ReactNode;
  initialItems?: Item[];
}) => {
  return (
    <SelectPresentationItemDialog
      title={T.admin.dashboardcollection.selectdashboards}
      isOpen={isOpen}
      onModalClose={onModalClose}
      nodeId={nodeId}
      nodeType={nodeType}
      initialSelectedItems={initialItems}
      equipmentTypeId={equipmentTypeId}
      onConfirm={onConfirm}
      actionText={actionText}
      listItemsHook={
        PresentationAPIGen.TenantDashboards.listTenantDashboards.useQuery
      }
      getItemHook={PresentationAPIGen.Nodes.getDashboards.useQuery}
      saveItemPromise={PresentationAPIGen.Nodes.assignTenantDashboards.promise}
      deleteItemPromise={PresentationAPIGen.Nodes.unassignDashboard.promise}
    />
  );
};

export default SelectDashboardRelationDialog;
