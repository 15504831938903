import React from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import KeyValueInternalTextInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalTextInput';
import KeyValueInternalSelectableInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalSelectableInput';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import { Layout } from '../signalLayout';

import SignalCategories from 'js/utils/SignalCategories';
import SignalCategoriesPicker from 'js/components/SignalCategories/SignalCategoriesPicker';
import SignalTypePicker from 'ecto-common/lib/SignalTypePicker/SignalTypePicker';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import {
  SignalInput,
  SignalInputRenderProps
} from 'js/components/ManageTemplates/manageTemplatesTypes';
import {
  DataFormat,
  EquipmentSignalTemplateResponseModel,
  GetEnumsAndFixedConfigurationsResponseModel,
  GraphicalRepresentation,
  SignalDirection,
  SignalFunction
} from 'ecto-common/lib/API/APIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

export const EMPTY_SIGNAL_INPUT: EquipmentSignalTemplateResponseModel = {
  id: undefined,
  signalTypeId: undefined,
  name: '',
  description: '',
  isWritable: false,
  signalSettings: {
    signalFunction: SignalFunction.None
  },
  dataFormat: DataFormat.Continuous,
  signalDirection: SignalDirection.Output,
  graphicalRepresentation: GraphicalRepresentation.Step,
  signalCategoryIds: [SignalCategories.ENERGY_MANAGER]
};

const createOption = <ValueType,>({
  type,
  name,
  options,
  mustBeSet = false,
  className = null
}: {
  type: string;
  name?: string;
  options: GenericSelectOption<ValueType>[];
  mustBeSet?: boolean;
  className?: string;
}): SignalInput => {
  return {
    type,
    name,
    render: ({ signal, onChange, ...otherProps }: SignalInputRenderProps) => {
      const defaultOption = _.get(EMPTY_SIGNAL_INPUT, type);
      const defaultOptionValue = _.find(options, ['value', defaultOption]);
      const selectedValue = _.defaultTo(
        _.find(options, ['value', _.get(signal, type)]),
        defaultOptionValue
      );
      return (
        <KeyValueInternalSelectableInput
          value={selectedValue}
          options={options}
          isSearchable
          openMenuOnClick
          className={className}
          hasError={mustBeSet && selectedValue == null}
          onChange={({ value }) => onChange(type, value)}
          placeholder={selectedValue ? selectedValue.label : ''}
          {...otherProps}
        />
      );
    }
  };
};

const createSignalTypeInput = ({
  type,
  name,
  hasError
}: {
  type: string;
  name: string;
  hasError: (value: string) => boolean;
}): SignalInput => {
  return {
    type,
    name,
    render: ({ signal, onChange, disabled }: SignalInputRenderProps) => {
      const handler = (value: string) => {
        onChange(type, value);
      };

      const value = _.get(signal, type);
      return (
        <SignalTypePicker
          value={value}
          onChange={handler}
          disabled={disabled}
          hasError={hasError(value)}
          showIcon={false}
        />
      );
    }
  };
};

const createSignalCategoryInput = ({
  type,
  name
}: {
  type: string;
  name: string;
}): SignalInput => {
  return {
    type,
    name,
    render: ({ signal, onChange }: SignalInputRenderProps) => {
      return (
        <SignalCategoriesPicker
          onChange={(categoryIds: string[]) => onChange(type, categoryIds)}
          selectedCategoryIds={_.get(signal, type)}
        />
      );
    }
  };
};

const createInput = ({
  type,
  name,
  hasError = null
}: {
  type: string;
  name: string;
  hasError?: (value: string) => boolean;
}): SignalInput => {
  return {
    type,
    name,
    render: ({ signal, onChange, ...otherProps }: SignalInputRenderProps) => {
      return (
        <KeyValueInternalTextInput
          value={_.get(signal, type)}
          placeholder={name}
          onBlur={(e) => onChange(type, e.target.value)}
          hasError={hasError}
          {...otherProps}
        />
      );
    }
  };
};

export const createCheckbox = ({
  type,
  name
}: {
  type: string;
  name: string;
}): SignalInput => {
  return {
    type,
    name,
    preferredLayout: Layout.HORIZONTAL,
    // eslint-disable-next-line react/prop-types
    render: ({ signal, onChange, ...otherProps }: SignalInputRenderProps) => {
      return (
        <Checkbox
          checked={_.get(signal, type)}
          onChange={(checked) => onChange(type, checked)}
          {...otherProps}
        />
      );
    }
  };
};

export const equipmentSignalInputs = (
  enums: GetEnumsAndFixedConfigurationsResponseModel
): SignalInput[] => {
  const toOption = <ValueType,>(value: ValueType) => ({
    label: value as string,
    value
  });
  const signalFunctionValues = _.map(_.get(enums, 'signalFunctions'), toOption);
  const dataFormatValues = _.map(_.get(enums, 'dataFormat'), toOption);
  const signalDirectionValues = _.map(
    _.get(enums, 'signalDirection'),
    toOption
  );
  const graphicalRepresentationValues = _.map(
    GraphicalRepresentation,
    toOption
  );

  return [
    createSignalTypeInput({
      type: 'signalTypeId',
      name: T.admin.equipmenttemplates.signaltype,
      hasError: (value: string) => value == null
    }),
    createInput({
      type: 'name',
      name: T.admin.equipmenttemplates.displayname,
      hasError: isNullOrWhitespace
    }),
    createSignalCategoryInput({
      type: 'signalCategoryIds',
      name: T.admin.signalcategories.title
    }),
    createInput({
      type: 'description',
      name: T.admin.equipmenttemplates.description
    }),
    createCheckbox({
      type: 'isWritable',
      name: T.admin.equipmenttemplates.writable
    }),
    createOption({
      type: 'signalSettings.signalFunction',
      name: T.admin.equipmenttemplates.signalfunction,
      options: signalFunctionValues
    }),
    createInput({
      type: 'signalSettings.defaultValue',
      name: T.admin.equipmenttemplates.defaultvalue
    }),
    createOption({
      type: 'dataFormat',
      name: T.admin.equipmenttemplates.dataformat,
      options: dataFormatValues
    }),
    createOption({
      type: 'signalDirection',
      name: T.admin.equipmenttemplates.signaldirection,
      options: signalDirectionValues
    }),
    createOption({
      type: 'graphicalRepresentation',
      name: T.admin.equipmenttemplates.graphicalrepresentation,
      options: graphicalRepresentationValues
    })
  ];
};
