import React, { Dispatch, SetStateAction } from 'react';

import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import EditComfort from './EditComfort';
import EditComfortParameters from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortParameters';
import ModalActionFooter from 'ecto-common/lib/Modal/ModalActionFooter';
import Button from 'ecto-common/lib/Button/Button';
import HelpPaths from 'ecto-common/help/tocKeys';

import styles from './EditComfortDialog.module.css';
import {
  AddOrUpdateComfortToolSetResponseModel,
  ComfortHeatingProviderResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ComfortEqOrAdminSignalWithSignalProviderName } from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditComfortTool';

interface EditComfortDialogProps {
  hideEdit?(): void;
  editVisible?: boolean;
  save?(): void;
  isValidItem?: boolean;
  item?: ComfortHeatingProviderResponseModel;
  setItem: Dispatch<SetStateAction<ComfortHeatingProviderResponseModel>>;
  setIsValidItem(isValid: boolean): void;
  isLoading?: boolean;
  comfortToolsetParameters?: AddOrUpdateComfortToolSetResponseModel;
  isEditingParameters?: boolean;
  cancelEditParameters?(): void;
  onComfortToolsetParametersChanged: Dispatch<
    SetStateAction<AddOrUpdateComfortToolSetResponseModel>
  >;
  signalData?: Record<string, ComfortEqOrAdminSignalWithSignalProviderName>;
}

const EditComfortDialog = ({
  hideEdit,
  editVisible,
  save,
  isValidItem,
  item,
  setItem,
  setIsValidItem,
  isLoading,
  comfortToolsetParameters,
  onComfortToolsetParametersChanged,
  isEditingParameters,
  cancelEditParameters,
  signalData
}: EditComfortDialogProps) => {
  let content;

  if (isEditingParameters) {
    content = (
      <EditComfortParameters
        comfortToolsetParameters={comfortToolsetParameters}
        onComfortToolsetParametersChanged={onComfortToolsetParametersChanged}
        signalData={signalData}
      />
    );
  } else {
    content = (
      <EditComfort
        item={item}
        setItem={setItem}
        setIsValidItem={setIsValidItem}
      />
    );
  }

  const footer = (
    <ModalActionFooter
      onModalClose={hideEdit}
      isLoading={isLoading}
      onConfirm={save}
      confirmDisabled={!isValidItem}
      confirmText={
        isEditingParameters ? (
          <>
            <Icons.Save /> {T.common.save}
          </>
        ) : (
          <>
            {T.common.next}{' '}
            <Icons.NavigationArrowRight className={styles.leftArrow} />
          </>
        )
      }
      leftSide={
        <Button
          disabled={!isEditingParameters || isLoading}
          onClick={cancelEditParameters}
        >
          <Icons.NavigationArrowLeft />
          {T.common.back}
        </Button>
      }
    />
  );

  return (
    <ActionModal
      onModalClose={hideEdit}
      isOpen={editVisible}
      title={
        item?.providerId
          ? T.admin.comfort.dialog.edit.title
          : T.admin.comfort.dialog.create.title
      }
      headerIcon={Icons.Edit}
      isLoading={isLoading}
      disableActionButton={!isValidItem}
      onConfirmClick={save}
      footer={footer}
      className={styles.dialog}
      helpPath={HelpPaths.docs.admin.manage.equipment.tools.comfort}
    >
      {content}
    </ActionModal>
  );
};

export default React.memo(EditComfortDialog);
