/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ListNotificationsResponse {
  items?: NotificationModel[];
  continuationToken?: string;
}

export interface NotificationModel {
  /** @minLength 1 */
  id: string;
  description?: string;
  active?: boolean;
  communicationType?: CommunicationType;
  /** @format int32 */
  severityThresholdMin?: number;
  /** @format int32 */
  severityThresholdMax?: number;
  nodeFilters?: NodeFilterModel[];
  /** @minItems 1 */
  receivers: EmailReceiverModel[];
  subject?: string;
  message?: string;
}

export enum CommunicationType {
  Email = 'email'
}

export interface NodeFilterModel {
  nodeId?: string | null;
  nodeType?: string | null;
  strict?: boolean;
}

export interface EmailReceiverModel {
  email?: string;
  name?: string;
  enabled?: boolean;
}

export type NotificationModelResponse = NotificationModel & {
  eventId: EventIdEnum;
};

export enum EventIdEnum {
  Unknown = 'unknown',
  AlarmActivated = 'alarmActivated',
  AlarmInactivated = 'alarmInactivated'
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type NotificationModelRequest = NotificationModel & {
  eventId: EventIdEnum;
};

export interface NotificationsListNotificationsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface VersionVersionParams {
  'api-version'?: string | null;
}

/**
 * @title Communication API
 * @version v1
 * @baseUrl https://app-ec-communication-dev-weu-001-7xkz.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath
} from './APIUtils';

const apiTitle = APIGenType.CommunicationAPIGen;

export interface NotificationsGetNotificationPath {
  id: string;
}

export interface NotificationsDeleteNotificationPath {
  id: string;
}

const CommunicationAPIGen = {
  Notifications: {
    listNotifications: apiEndpointEmptyUsingQueryParams<
      NotificationsListNotificationsParams,
      ListNotificationsResponse,
      any
    >(
      Method.GET,
      `/api/v1/Notifications`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createNotification: apiEndpoint<
      NotificationModelRequest,
      NotificationModelResponse,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/Notifications`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateNotification: apiEndpoint<
      NotificationModelRequest,
      NotificationModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/v1/Notifications`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNotification: apiEndpointEmptyWithPath<
      NotificationModelResponse,
      ProblemDetails,
      NotificationsGetNotificationPath
    >(
      Method.GET,
      ({ id }: NotificationsGetNotificationPath) =>
        `/api/v1/Notifications/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteNotification: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NotificationsDeleteNotificationPath
    >(
      Method.DELETE,
      ({ id }: NotificationsDeleteNotificationPath) =>
        `/api/v1/Notifications/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmptyUsingQueryParams<VersionVersionParams, File, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default CommunicationAPIGen;
