import React from 'react';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import SelectEquipmentTemplate from 'js/components/InitEquipmentTemplate/SelectEquipmentTemplate';
import T from 'ecto-common/lib/lang/Language';
import HelpPaths from 'ecto-common/help/tocKeys';
import ModalHeaderHelpRightContent from 'ecto-common/lib/Modal/ModalHeaderHelpRightContent';
import styles from './InitEquipmentTemplate.module.css';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

interface ConfigureTemplateEquipmentDialogProps {
  parentLocation?: SingleGridNode;
  onConfirmClick?(): void;
  onModalClose: () => void;
  confirmText?: string;
  confirmIcon?: React.ReactNode;
  loadingText?: string;
  isLoading?: boolean;
}

const ConfigureTemplateEquipmentDialog = ({
  parentLocation,
  onConfirmClick,
  onModalClose,
  confirmText,
  confirmIcon,
  loadingText,
  isLoading
}: ConfigureTemplateEquipmentDialogProps) => {
  const isOpen = useAdminSelector(
    (state) => state.equipmentTemplateForm.showDialog
  );

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      disableClose={isLoading}
      onModalClose={onModalClose}
    >
      <ModalHeader
        titleIcon={Icons.File}
        rightContent={
          <ModalHeaderHelpRightContent
            helpPath={
              HelpPaths.docs.admin.manage.locations.building_add_from_template
            }
          />
        }
      >
        {T.admin.equipment.addfromtemplate.title}
      </ModalHeader>

      <ModalBody
        withWhiteBackground
        loading={isLoading}
        loadingText={loadingText}
      >
        <SelectEquipmentTemplate parentLocation={parentLocation} />
      </ModalBody>

      <ModalFooter>
        <ModalSpace />

        <Button disabled={isLoading} onClick={onConfirmClick}>
          {confirmIcon}

          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfigureTemplateEquipmentDialog;
