import {
  getDurationTimestring,
  getRelativeTimeString
} from 'ecto-common/lib/utils/relativeTimeUtil';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';

export const relativeDateString = (timestamp: string, format?: string) => {
  return (
    timestamp &&
    getRelativeTimeString(
      timestamp,
      format ?? getDefaultDateTimeFormat(TimeFormats.LONG_TIME)
    )
  );
};

export const uptimeString = (seconds: number) => {
  if (seconds == null) {
    return '';
  }
  const nowMilliseconds = new Date().getTime() + seconds * 1000;
  return getDurationTimestring(new Date(nowMilliseconds));
};
