import React from 'react';

import Grid from 'ecto-common/lib/Grid/Grid';
import T from 'ecto-common/lib/lang/Language';
import Heading from 'ecto-common/lib/Heading/Heading';

import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { models } from './SignalMappingModel';
import { MappingResponseModel } from 'ecto-common/lib/API/APIGen';

interface SignalMappingProps {
  values?: MappingResponseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateInput: (key: string[], value: any) => void;
}

const SignalMapping = ({ values, onUpdateInput }: SignalMappingProps) => {
  return (
    <ModelForm models={models} input={values} onUpdateInput={onUpdateInput}>
      {(renderChildModel) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderChildModel((input) => input.name)}
            </Grid>
            <Grid item xs={6}>
              {renderChildModel((input) => input.mappingAction)}
            </Grid>
            <Grid item xs={6}>
              {renderChildModel((input) => input.cycleTime)}
            </Grid>

            <Grid item xs={6} spacing={2}>
              <Grid item xs={12}>
                <Heading level={3}>
                  {T.admin.signalmapping.source.title}
                </Heading>
              </Grid>

              <Grid container spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    {renderChildModel((input) => input.sourceConfig.hoursBack)}
                  </Grid>
                  <Grid item xs={6}>
                    {renderChildModel(
                      (input) => input.sourceConfig.hoursForward
                    )}
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    {renderChildModel(
                      (input) => input.sourceConfig.samplingInterval
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {renderChildModel(
                      (input) => input.sourceConfig.aggregation
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {renderChildModel(
                    (input) => input.sourceConfig.useLastValueBeforeStartDate
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} spacing={2}>
              <Grid item xs={12}>
                <Heading level={3}>
                  {T.admin.signalmapping.destination.title}
                </Heading>
              </Grid>
              <Grid item xs={12}>
                {renderChildModel((input) => input.destinationConfig.ttl)}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid item xs={12}>
                <Heading level={3}>
                  {T.admin.signalmapping.sourcesignals.title}
                </Heading>
              </Grid>
              <Grid item xs={12}>
                {renderChildModel((input) => input.sourceConfig.configSignals)}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid item xs={12}>
                <Heading level={3}>
                  {T.admin.signalmapping.destinationsignals.title}
                </Heading>
              </Grid>
              <Grid item xs={12}>
                {renderChildModel(
                  (input) => input.destinationConfig.configSignals
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </ModelForm>
  );
};

export default React.memo(SignalMapping);
