import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import GreyButton from 'ecto-common/lib/Button/GreyButton';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { SignalProviderTypeText } from 'ecto-common/lib/constants';
import {
  ConnectionResponseModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';

import { SectionItem } from './ModbusInputComponents';
import {
  SignalPropertiesSection,
  DataTypeSection,
  DelaySection,
  WatchDogSection,
  ConnectionArea
} from './ModbusSections';
import styles from '../DialogStyles.module.css';
import { Layout } from '../signalLayout';
import { signalProviderInputs } from '../signalInputs';
import { SignalModbusConfigMode } from 'js/components/ModbusLayout/ModbusTypes';
import { isRegister } from 'js/components/ModbusLayout/ModbusEditUtils';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import Switch from 'ecto-common/lib/Switch/Switch';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import Heading from 'ecto-common/lib/Heading/Heading';
import HelpPaths from 'ecto-common/help/tocKeys';
import ModalHeaderHelpRightContent from 'ecto-common/lib/Modal/ModalHeaderHelpRightContent';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { AlarmOrEqSignalTemplateWithProviderAndConnectionIdType } from 'ecto-common/lib/utils/equipmentTypeUtils';
import { AdminEquipmentSignalWithConfigType } from 'js/components/ManageEquipment/EditEquipment/Util/editEquipmentUtil';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { KeyValueColumn } from 'ecto-common/lib/KeyValueInput/KeyValueColumn';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { SignalInput } from '../manageTemplatesTypes';

export type SignalProviderInputValue = {
  type: SignalProviderType;
  inputs: SignalInput[];
  // Each provider gets a reserved state name 'provider_' + provider type name
  signal: SignalProviderType;
  signalStateName: string;
};

interface ModbusSettingDialogProps {
  isOpen: boolean;
  onModalClose: () => void;
  onPrevClicked?(): void;
  onNextClicked?(): void;
  selectedSignal?: AlarmOrEqSignalTemplateWithProviderAndConnectionIdType;
  onChangeConfigType?(e: SignalModbusConfigMode): void;
  onChangeSignalType?(newType: GenericSelectOption<SignalProviderType>): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeModbusProperty(key: string, value: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeSignalProperty(key: string, value: any): void;
  disableConfigTypeSelector?: boolean;
  modbusMode?: string;
  editDescriptionInModbusDialog?: boolean;
  signals?: AdminEquipmentSignalWithConfigType[];
  connections?: Record<string, ConnectionResponseModel>;
  addSignal?(): void;
  isEditingSignal?: boolean;
  addSignalIsLoading?: boolean;
}

const ModbusSettingDialog = ({
  isOpen,
  onModalClose,
  onPrevClicked,
  onNextClicked,
  selectedSignal,
  onChangeConfigType,
  onChangeSignalType,
  onChangeModbusProperty,
  onChangeSignalProperty,
  disableConfigTypeSelector = false,
  modbusMode,
  editDescriptionInModbusDialog = true,
  signals,
  connections,
  addSignal,
  isEditingSignal,
  addSignalIsLoading
}: ModbusSettingDialogProps) => {
  const enums = useAdminSelector((state) => state.general.enums);
  const signalUnitTypes = useAdminSelector(
    (state) => state.general.signalUnitTypes
  );

  const providerType = selectedSignal?.type ?? SignalProviderType.Equipment;
  const title = selectedSignal && selectedSignal?.name;
  const modbus = _.get(selectedSignal, ['signalSettings', modbusMode], null);
  const signalInputs = useMemo(
    () => signalProviderInputs[providerType].inputs(enums),
    [enums, providerType]
  );

  const validateSignal = useCallback(
    (success: () => void) => {
      if (selectedSignal.signalTypeId == null) {
        toastStore.addErrorToast(T.admin.equipmenttemplates.error.missingtype);
      } else if (selectedSignal.name == null) {
        toastStore.addErrorToast(T.admin.equipmenttemplates.error.missingname);
      } else {
        success();
      }
    },
    [selectedSignal]
  );

  const _addSignal = useCallback(() => {
    validateSignal(() => {
      addSignal();
    });
  }, [addSignal, validateSignal]);

  const createInputProvider = useCallback(
    (type: SignalProviderType) => {
      return {
        type,
        inputs: signalProviderInputs[type].inputs(enums),
        // Each provider gets a reserved state name 'provider_' + provider type name
        signal: providerType,
        signalStateName: 'provider_' + type
      };
    },
    [enums, providerType]
  );

  const signalInputsProviders: SignalProviderInputValue[] = useMemo(
    () => Object.keys(signalProviderInputs).map(createInputProvider),
    [createInputProvider]
  );

  const signalProviderOptions: GenericSelectOption<SignalProviderType>[] =
    useMemo(
      () =>
        _.map(signalInputsProviders, ({ type }) => ({
          label: SignalProviderTypeText[type],
          value: type
        })),
      [signalInputsProviders]
    );

  const selectedProvider = useMemo(
    () => _.find(signalProviderOptions, { value: providerType }),
    [providerType, signalProviderOptions]
  );

  const connection = useMemo(() => {
    if (
      disableConfigTypeSelector &&
      selectedSignal &&
      selectedSignal?.connectionId != null
    ) {
      return connections[selectedSignal?.connectionId];
    }

    return null;
  }, [connections, disableConfigTypeSelector, selectedSignal]);

  const modbusIsDisabled = !modbus || modbus.disabled === true;

  const onDisableModbus = useCallback(() => {
    onChangeModbusProperty('disabled', !modbusIsDisabled);
  }, [onChangeModbusProperty, modbusIsDisabled]);

  return (
    <Modal
      className={styles.settingDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader
        titleIcon={isEditingSignal ? Icons.Edit : Icons.Add}
        rightContent={
          <ModalHeaderHelpRightContent
            helpPath={HelpPaths.docs.admin.manage.equipment.signals.modbus}
          />
        }
      >
        {isEditingSignal
          ? T.format(T.admin.modbussignal.editformat, title)
          : T.admin.equipmenttemplates.addsignal}
      </ModalHeader>

      <ModalBody className={styles.settingBody} loading={addSignalIsLoading}>
        {!isEditingSignal && onChangeSignalType && (
          <KeyValueLine>
            <KeyValueSelectableInput<GenericSelectOption<SignalProviderType>>
              keyText={T.admin.equipmenttemplates.type}
              value={selectedProvider}
              options={signalProviderOptions}
              onChange={onChangeSignalType}
              placeholder={selectedProvider.label}
            />
          </KeyValueLine>
        )}

        <div className={styles.editorArea}>
          {editDescriptionInModbusDialog && (
            <KeyValueColumn>
              {selectedSignal &&
                signalUnitTypes &&
                signalInputs.map((signalInput, signalInputIndex) => {
                  const signalTag = signalInput.render({
                    signal: selectedSignal,
                    onChange: onChangeSignalProperty,
                    disabled: false,
                    multiline: signalInput.type === 'description'
                  });

                  return (
                    signalTag && (
                      <KeyValueGeneric
                        key={`${signalInput.name}-${signalInputIndex}`}
                        keyText={signalInput.name}
                        isHorizontal={
                          signalInput.preferredLayout === Layout.HORIZONTAL
                        }
                      >
                        {signalTag}
                      </KeyValueGeneric>
                    )
                  );
                })}
            </KeyValueColumn>
          )}

          {!disableConfigTypeSelector && (
            <div className={styles.gridFlow}>
              <SectionItem
                title={T.admin.modbussignal.config}
                className={styles.configHeader}
              >
                <select
                  onChange={(e) =>
                    onChangeConfigType(e.target.value as SignalModbusConfigMode)
                  }
                  value={modbusMode}
                >
                  <option value={SignalModbusConfigMode.SLAVE}>
                    {T.admin.modbussignal.modbusslave}
                  </option>
                  <option value={SignalModbusConfigMode.MASTER}>
                    {T.admin.modbussignal.modbusmaster}
                  </option>
                </select>
              </SectionItem>
            </div>
          )}

          {selectedSignal && (
            <div className={styles.gridFlow}>
              <Heading level={4} className={styles.gridItemHeader}>
                <label className={styles.gridItemLabel}>
                  {T.admin.equipmenttemplates.usemodbus}
                </label>

                <Tooltip
                  text={
                    modbusIsDisabled
                      ? T.admin.modbussignal.enableconfig
                      : T.admin.modbussignal.disableconfig
                  }
                >
                  <Switch onClick={onDisableModbus} isOn={!modbusIsDisabled} />
                </Tooltip>
              </Heading>
            </div>
          )}

          {selectedSignal && modbus && !modbus.disabled && (
            <div>
              <SignalPropertiesSection
                modbus={modbus}
                onChangeProperty={onChangeModbusProperty}
              />

              {isRegister(modbus) && (
                <DataTypeSection
                  modbus={modbus}
                  onChangeProperty={onChangeModbusProperty}
                />
              )}

              <DelaySection
                onChangeProperty={onChangeModbusProperty}
                modbus={modbus}
              />

              <WatchDogSection
                modbus={modbus}
                signals={signals}
                onChangeProperty={onChangeModbusProperty}
              />
            </div>
          )}
        </div>
        {connection != null && (
          <ConnectionArea connection={connection} modbusMode={modbusMode} />
        )}
      </ModalBody>

      <ModalFooter>
        {isEditingSignal && (
          <>
            <GreyButton onClick={onPrevClicked}>&lt;</GreyButton>
            <GreyButton onClick={onNextClicked}>&gt;</GreyButton>
          </>
        )}

        <ModalSpace />

        {isEditingSignal ? (
          <LocalizedButtons.Done onClick={onModalClose} />
        ) : (
          <>
            <LocalizedButtons.Add
              disabled={addSignalIsLoading}
              onClick={_addSignal}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModbusSettingDialog;
