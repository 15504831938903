import React, { useContext } from 'react';
import _ from 'lodash';

import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  modelFormattedNodeLinks,
  modelFormattedValue
} from 'ecto-common/lib/ModelForm/modelValueUtil';
import Tag from 'ecto-common/lib/Tag/Tag';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

interface ModelDisplayProps<ObjectType extends object> {
  model: ModelDefinition<ObjectType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawValue?: any;
}

const ModelDisplay = <ObjectType extends object>({
  model,
  rawValue
}: ModelDisplayProps<ObjectType>) => {
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);

  const formattedValue = modelFormattedValue(model, rawValue, {});

  const { tenantId } = useContext(TenantContext);

  switch (model.modelType) {
    case ModelType.NODE: {
      return (
        <>{modelFormattedNodeLinks(model, [rawValue], nodeMap, tenantId)}</>
      );
    }
    case ModelType.NODE_LIST: {
      return <>{modelFormattedNodeLinks(model, rawValue, nodeMap, tenantId)}</>;
    }
    case ModelType.OPTIONS: {
      const items = _.isArray(rawValue) ? rawValue : [rawValue];
      // TODO: We should probably remove this component or supply relevant arguments so that dynamic functions work
      if (_.isFunction(model.options)) {
        throw new Error('ModelDisplay does not support dynamic functions');
      }
      const formattedItems = items.map(
        (item) =>
          (model.options as GenericSelectOption<unknown>[])?.find(
            (x) => x.value === item
          )?.label ?? item
      );
      return (
        <>
          {formattedItems.map((item) => (
            <Tag key={item}>{item}</Tag>
          ))}
        </>
      );
    }
    default:
      return <label>{formattedValue}</label>;
  }
};

export default ModelDisplay;
