import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import { filteredNodeTree } from 'ecto-common/lib/utils/locationUtils';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import { EditIcon } from 'ecto-common/lib/Icon';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';

import styles from 'js/components/EditLocation/LocationForm.module.css';
import { useAdminSelector } from 'js/reducers/storeAdmin';

interface EditLocationParentsDialogProps {
  isOpen: boolean;
  selectedParentIds: string[];
  onParentSelectedChanged(parentId: string, isSelected: boolean): void;
  onParentsEditCancelled(): void;
  onParentsEditDone(): void;
}

const EditLocationParentsDialog = ({
  isOpen,
  selectedParentIds,
  onParentSelectedChanged,
  onParentsEditCancelled,
  onParentsEditDone
}: EditLocationParentsDialogProps) => {
  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const nodeTree = useAdminSelector((state) => state.general.nodeTree);
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const filteredNodes = filteredNodeTree(nodeTree, (x) =>
    NodeTypes.isEqual(x.nodeType, NodeTypes.SITE)
  );

  return (
    <Modal
      className={styles.editParentsModal}
      onModalClose={onParentsEditCancelled}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={EditIcon}>
        {T.admin.editlocation.editparents.title}
      </ModalHeader>

      <ModalBody className={styles.editParentBody}>
        <div className={classNames(styles.treeSelector)}>
          <LocationTreeView
            multiSelect
            selectedIds={selectedParentIds}
            focusedId={_.head(selectedParentIds)}
            sidePadding={0}
            className={styles.treeContainer}
            allowSelectingRootNodes
            searchFilter={null}
            onChangeSelectedState={onParentSelectedChanged}
            nodeTree={filteredNodes}
            nodeMap={nodeMap}
            equipmentMap={equipmentMap}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <LocalizedButtons.Save onClick={onParentsEditDone} />

        <LocalizedButtons.Cancel onClick={onParentsEditCancelled} />
      </ModalFooter>
    </Modal>
  );
};

export default EditLocationParentsDialog;
