import { CreatingNodeState } from 'js/modules/createNodeForm/createNodeForm';
import T from 'ecto-common/lib/lang/Language';
import { CreateError } from 'js/modules/provisioningCommon/provisioningCommon';

export const translationsForNodeState: Record<string, string> = {
  [CreatingNodeState.CREATING_BUILDING]: T.admin.createbuilding.state.creating,
  [CreatingNodeState.CREATING_SITE]: T.admin.createsite.state.creating,
  [CreatingNodeState.ADDING_CONNECTION]:
    T.admin.createlocation.state.initconnection,
  [CreatingNodeState.ADDING_TEMPLATE]:
    T.admin.createlocation.state.addingequipment,
  [CreatingNodeState.ADDING_WEATHER]:
    T.admin.createlocation.state.addingweather,
  [CreatingNodeState.ADDING_DASHBOARD_COLLECTION_RELATION]:
    T.admin.createlocation.state.addingdashboardcollectionrelation,
  [CreatingNodeState.UPDATING_WEATHER]:
    T.admin.createlocation.state.updatingweather
};

export const errorTranslationsForBuildingError: Record<string, string> = {
  [CreateError.BUILDING_NAMING_CONFLICT]:
    T.admin.createbuilding.error.creatingsiblingnameconflict,
  [CreateError.MISSING_CONNECTION]:
    T.admin.createlocation.error.missingconnection,
  [CreateError.GENERIC]: T.admin.createbuilding.error.creating
};

export const errorTranslationsForSiteError: Record<string, string> = {
  [CreateError.SITE_NAMING_CONFLICT]:
    T.admin.createsite.error.creatingsiblingnameconflict,
  [CreateError.MISSING_CONNECTION]:
    T.admin.createlocation.error.missingconnection,
  [CreateError.GENERIC]: T.admin.createsite.error.creating
};
