import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import React from 'react';
import { KeyValueButton } from 'ecto-common/lib/KeyValueInput/KeyValueButton';
import { HardwareActionInputProps } from 'js/components/EnergyManagers/HardwareActionInput/HardwareActionInputTypes';

const HeartbeatInput = ({ isLoading, action }: HardwareActionInputProps) => {
  return (
    <KeyValueButton
      keyText={T.admin.iotdevicedetails.heartbeataction}
      title={T.admin.iotdevicedetails.heartbeataction}
      onClick={action}
      disabled={isLoading}
      icon={<Icons.Heartbeat />}
    />
  );
};

export default HeartbeatInput;
