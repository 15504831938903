import React, { useContext, useEffect, useMemo, useState } from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import APIGen from 'ecto-common/lib/API/APIGen';
import Monospace from 'ecto-common/lib/Monospace/Monospace';
import _ from 'lodash';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { checkboxColumn } from 'ecto-common/lib/utils/dataTableUtils';
import { TenantResponseModel } from 'ecto-common/lib/API/APIGen';
import dimensions from 'ecto-common/lib/styles/dimensions';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import {
  isNullOrWhitespace,
  searchByCaseInsensitive
} from 'ecto-common/lib/utils/stringUtils';

const DeployTemplates = () => {
  const [selectedTenantIds, setSelectedTenantIds] = useState<string[]>([]);
  const [infoMessages, setInfoMessages] = useState<string[]>(null);
  const [searchFilter, setSearchFilter] = useState(null);

  const { tenantId } = useContext(TenantContext);

  const getTenantsQuery = APIGen.AdminTemplates.getAllTenants.useQuery();

  const tenants = getTenantsQuery.data;

  if (tenants && selectedTenantIds.length === 0) {
    setSelectedTenantIds(
      _.map(
        _.filter(tenants, (tenant) => tenant.id !== tenantId),
        'id'
      )
    );
  }

  useEffect(() => {
    if (getTenantsQuery.error) {
      setInfoMessages([T.common.unknownerror]);
    }
  }, [getTenantsQuery.error]);

  const { mutate: publishToTenants, isPending: isPublishing } =
    APIGen.AdminTemplates.publishToTenants.useMutation({
      onSuccess: (response) => {
        if (response.success) {
          const deployedTenantNames = tenants
            .filter((tenant) => selectedTenantIds.includes(tenant.id))
            .map((tenant) => tenant.name);
          setInfoMessages(
            T.format(
              T.admin.deploytemplates.deploysuccessfulformat,
              deployedTenantNames.join(', ')
            )
          );
        } else {
          setInfoMessages(response.errorMessages);
        }
      },
      onError: () => {
        setInfoMessages([T.common.unknownerror]);
      }
    });

  const data = useMemo(() => {
    const _data = _.filter(tenants, (tenant) => tenant.id !== tenantId);
    if (isNullOrWhitespace(searchFilter)) {
      return _data;
    }

    return searchByCaseInsensitive(_data, searchFilter, 'name');
  }, [tenants, tenantId, searchFilter]);

  const publish = () => {
    setInfoMessages(null);
    publishToTenants({
      tenantIds: selectedTenantIds
    });
  };

  const toolbarItems = (
    <>
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <ToolbarSearch value={searchFilter} onChange={setSearchFilter} />
      </ToolbarItem>
      <ToolbarItem>
        <Button
          disabled={
            tenants == null ||
            selectedTenantIds?.length === 0 ||
            tenants?.length === 0 ||
            isPublishing
          }
          onClick={publish}
          loading={isPublishing}
        >
          <Icons.Download />
          {T.admin.deploytemplates.deploy}
        </Button>
      </ToolbarItem>
    </>
  );
  const checkAllIsChecked = selectedTenantIds.length === tenants?.length - 1;

  const columns: DataTableColumnProps<TenantResponseModel>[] = [
    checkboxColumn({
      checkAll: () =>
        setSelectedTenantIds((oldSelectedIds) => {
          if (checkAllIsChecked) {
            return [];
          }

          return _.uniq(oldSelectedIds.concat(_.map(data, 'id')));
        }),
      checkAllIsChecked,
      rowIsChecked: (item: TenantResponseModel) =>
        selectedTenantIds.includes(item.id)
    }),
    {
      dataKey: 'name',
      label: T.common.name
    }
  ];
  useEffect(() => {
    document.title = T.admin.deploytemplates.tenants;
  }, []);

  return (
    <ToolbarContentPage
      title={T.admin.deploytemplates.tenants}
      showLocationPicker={false}
      wrapContent={false}
      toolbarItems={toolbarItems}
    >
      {infoMessages && (
        <PlainBox
          style={{ marginBottom: dimensions.standardMargin, flexShrink: 0 }}
        >
          <Monospace>{infoMessages.join('\n')}</Monospace>
        </PlainBox>
      )}
      <DataTable<TenantResponseModel>
        columns={columns}
        isLoading={getTenantsQuery.isLoading || isPublishing}
        data={data}
        onClickRow={(row) => {
          setSelectedTenantIds((oldTenantIds) => {
            if (oldTenantIds.includes(row.id)) {
              return oldTenantIds.filter((id) => id !== row.id);
            }

            return oldTenantIds.concat(row.id);
          });
        }}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(DeployTemplates);
