import ModbusDefaults, {
  ModbusSignalSettings
} from 'js/components/ModbusLayout/ModbusDefaults';
import deepmerge from 'deepmerge';
import _ from 'lodash';

import { ModbusDataTypeExpanded, ModbusType } from './ModbusTypes';
import SignalCategories from 'js/utils/SignalCategories';
import {
  AddOrUpdateConnectionModbusConfigByTemplateRequestModel,
  ConnectionModbusConfigTemplateResponseModel,
  ModbusMode,
  SignalModbusConfigResponseModel,
  SignalModbusType,
  SignalProviderType,
  SignalSettingsResponseModel,
  SignalSettingsTemplateRequestModel,
  SignalSettingsTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import { SignalModbusConfigMode } from 'js/components/ModbusLayout/ModbusTypes';

export const modbusConfigTypeToMode = (configType: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (SignalModbusConfigMode as any)[configType.toUpperCase()];
};

export const modbusModeToConfigType = (mode: string) => {
  if (mode === SignalModbusConfigMode.MASTER) {
    return ModbusMode.Master;
  }

  return ModbusMode.Slave;
};

export function translateBitmaskTypes<SignalType>(signals: SignalType[]) {
  for (const signal of signals) {
    const signalSettings = _.get(
      signal,
      'signalSettings',
      _.get(signal, 'signalTemplateOverride.signalSettings')
    );

    if (!signalSettings) {
      continue;
    }

    const modes = enumValues(SignalModbusConfigMode);

    modes.forEach((mode) => {
      if (signalSettings[mode] != null) {
        if (
          signalSettings[mode].datatype === 'int' &&
          Object.prototype.hasOwnProperty.call(signalSettings[mode], 'bit')
        ) {
          signalSettings[mode].datatype = ModbusDataTypeExpanded.BITMASK;
        }
      }
    });
  }

  return signals;
}

export const isRegister = (modbus: { type: ModbusType }) =>
  modbus &&
  (modbus.type === ModbusType.INPUT_REGISTER ||
    modbus.type === ModbusType.HOLDING_REGISTER);

export type PossibleSignalSettingsType =
  | SignalSettingsResponseModel
  | SignalSettingsTemplateRequestModel
  | SignalSettingsTemplateResponseModel;

type ObjectWithSignalSettings = {
  signalSettings?: PossibleSignalSettingsType;
  signalTemplateOverride?: {
    signalSettings?: PossibleSignalSettingsType;
  };
};

export function transformModbusDataTypes<
  SignalType extends ObjectWithSignalSettings
>(signals: SignalType[]): SignalType[] {
  for (const signal of signals) {
    const signalSettings = _.get(
      signal,
      'signalSettings',
      _.get(signal, 'signalTemplateOverride.signalSettings')
    );
    if (!signalSettings) {
      continue;
    }

    const modes = enumValues(SignalModbusConfigMode);

    modes.forEach((mode) => {
      if (
        Object.prototype.hasOwnProperty.call(signalSettings, mode) &&
        signalSettings[mode] != null
      ) {
        if (isRegister(signalSettings[mode])) {
          if (
            signalSettings[mode].datatype === ModbusDataTypeExpanded.BITMASK
          ) {
            signalSettings[mode].datatype = 'int';
          } else if (
            Object.prototype.hasOwnProperty.call(signalSettings[mode], 'bit')
          ) {
            delete signalSettings[mode].bit;
          }
        }
      }
    });
  }

  return signals;
}

export function handleModbusConnectionChange(
  connection:
    | ConnectionModbusConfigTemplateResponseModel
    | AddOrUpdateConnectionModbusConfigByTemplateRequestModel,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) {
  if (value == null || value === '') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (connection as any)[key];
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (connection as any)[key] = value;
  }
}

export function handleModbusPropertyChange(
  modbus: SignalModbusConfigResponseModel,
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): SignalModbusConfigResponseModel {
  const additionalSettings: ModbusSignalSettings = {};

  if (name === 'disabled' && value === false) {
    // If modbus is enabled (disabled = false) then assign default type if it does not have one
    if (_.get(modbus, 'type') == null) {
      modbus = handleModbusPropertyChange(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _.defaultTo(modbus as any, {}),
        'type',
        ModbusDefaults.type
      );
    }
  }

  if (name === 'type') {
    if (value === ModbusType.COIL || value === ModbusType.HOLDING_REGISTER) {
      modbus.readwrite = modbus.readwrite || ModbusDefaults.readwrite;
    } else {
      delete modbus.readwrite;
    }

    if (
      value === SignalModbusType.InputRegister ||
      value === SignalModbusType.HoldingRegister
    ) {
      // Only update some default values if we change from holding or input register to another type
      if (
        modbus.type !== SignalModbusType.InputRegister &&
        modbus.type !== SignalModbusType.HoldingRegister
      ) {
        modbus.signed = modbus.signed || ModbusDefaults.signed;
        modbus.factor = modbus.factor || ModbusDefaults.factor;
        modbus.size = modbus.size || ModbusDefaults.size;
        modbus.step = modbus.step || ModbusDefaults.step;
        modbus.datatype = modbus.datatype || ModbusDefaults.datatype;
      }
    } else {
      delete modbus.datatype;
      delete modbus.signed;
      delete modbus.byteOrder;
      delete modbus.bit;
      delete modbus.factor;
      delete modbus.size;
      delete modbus.step;
    }
  }

  if (name === 'datatype') {
    if (value === ModbusDataTypeExpanded.BITMASK) {
      additionalSettings.signed = false;
      additionalSettings.size = 1;
      additionalSettings.bit = 0;
      delete modbus.byteOrder;
      delete modbus.factor;
      delete modbus.step;
    } else if (value === ModbusDataTypeExpanded.REAL) {
      additionalSettings.factor = modbus.factor || ModbusDefaults.factor;
      additionalSettings.step = modbus.step || ModbusDefaults.step;
      additionalSettings.size = ModbusDefaults.sizeReal;
      additionalSettings.byteOrder =
        modbus.byteOrder || ModbusDefaults.byteOrder;

      delete modbus.bit;
      delete modbus.signed;
      value = ModbusDataTypeExpanded.REAL;
    } else {
      additionalSettings.factor = modbus.factor || ModbusDefaults.factor;
      additionalSettings.step = modbus.step || ModbusDefaults.step;
      additionalSettings.signed = !value.startsWith('uint');
      additionalSettings.size = Number(value.split('int')[1]);
      additionalSettings.byteOrder =
        modbus.byteOrder || ModbusDefaults.byteOrder;
      delete modbus.bit;
      value = 'int';
    }
  }

  if (
    (name === 'step' || name === 'factor') &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (modbus as any).datatype === ModbusDataTypeExpanded.BITMASK &&
    value !== '0'
  ) {
    additionalSettings.bit = ModbusDefaults.bit;
  }

  if (name === 'bit' && value !== '0') {
    additionalSettings.step = 0;
    additionalSettings.factor = ModbusDefaults.factor;
  }

  if (name === 'signalModbusWatchdog') {
    if (value) {
      _.set(additionalSettings, name, _.cloneDeep(_.get(ModbusDefaults, name)));
    } else {
      modbus = _.omit(modbus, name);
    }
  } else {
    _.set(additionalSettings, name, value);
  }

  return deepmerge(modbus, additionalSettings);
}

export const validateSignalCategoryChange = (
  unusedSignal: unknown,
  providerType: SignalProviderType,
  name: string,
  value: unknown
) => {
  if (name !== 'signalCategoryIds') {
    return true;
  }

  const categoryIds = value as string[];

  if (
    providerType === SignalProviderType.Equipment &&
    !categoryIds.includes(SignalCategories.ENERGY_MANAGER)
  ) {
    toastStore.addErrorToast(
      T.admin.signalcategories.validationerror.energymanager
    );
    return false;
  } else if (
    providerType === SignalProviderType.Alarm &&
    !categoryIds.includes(SignalCategories.ALARM)
  ) {
    toastStore.addErrorToast(T.admin.signalcategories.validationerror.alarm);
    return false;
  }

  return true;
};
