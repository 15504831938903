import React, { useCallback, useEffect, useState } from 'react';
import { AddIcon } from 'ecto-common/lib/Icon';
import styles from './AddAlarmSignalGroupTemplateDialog.module.css';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import UUID from 'uuidjs';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { AdminAlarmSignalGroupTemplateResponseModel } from 'ecto-common/lib/API/APIGen';

interface AddAlarmSignalGroupTemplateDialogProps {
  onModalClose: () => void;
  onAddAlarmTemplate?(
    template: AdminAlarmSignalGroupTemplateResponseModel
  ): void;
  isOpen?: boolean;
  isLoading?: boolean;
}

const AddAlarmSignalGroupTemplateDialog = ({
  onModalClose,
  onAddAlarmTemplate,
  isOpen,
  isLoading
}: AddAlarmSignalGroupTemplateDialogProps) => {
  const [name, setName] = useState('');

  const addSignalGroupTemplate = useCallback(() => {
    if (_.isEmpty(_.trim(name))) {
      return;
    }

    onAddAlarmTemplate({
      name,
      alarmSignalGroupTemplateId: UUID.generate(),
      alarmSignalTemplates: []
    });
  }, [onAddAlarmTemplate, name]);

  useEffect(() => {
    if (!isOpen) {
      setName('');
    }
  }, [isOpen]);
  const _setName: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setName(e.target.value),
    []
  );

  return (
    <ActionModal
      className={styles.dialog}
      headerIcon={AddIcon}
      onModalClose={onModalClose}
      isOpen={isOpen}
      isLoading={isLoading}
      title={T.admin.alarmtemplates.addnew}
      onConfirmClick={addSignalGroupTemplate}
      actionText={T.common.iconbutton.add.title}
    >
      <TextInput
        onEnterKeyPressed={addSignalGroupTemplate}
        onChange={_setName}
        value={name}
        autoFocus
        placeholder={T.admin.alarmtemplates.templatename}
      />
    </ActionModal>
  );
};

export default AddAlarmSignalGroupTemplateDialog;
