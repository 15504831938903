import React from 'react';
import styles from './Monospace.module.css';

type MonospaceProps = {
  children: React.ReactNode;
};

const Monospace = ({ children }: MonospaceProps) => {
  return <div className={styles.container}>{children}</div>;
};

export default React.memo(Monospace);
