import { ModbusType } from './ModbusTypes';
import {
  ByteOrder,
  ModbusDataType,
  ReadWrite
} from 'ecto-common/lib/API/APIGen';

const ModbusDefaults: ModbusSignalSettings = {
  bit: 0,
  step: 0.1,
  factor: 1,
  connection: 0,
  type: ModbusType.INPUT_REGISTER,
  byteOrder: ByteOrder.Abcd,
  signed: true,
  size: 1,
  sizeReal: 2,
  delayDb: 60,
  delayModbus: 60,
  datatype: ModbusDataType.Int,
  signalModbusWatchdog: {
    period: 60,
    high: 60,
    alarmSignalTypeId: null,
    enableSignalTypeId: null
  },
  readwrite: ReadWrite.Read
};

export type ModbusSignalSettings = {
  bit?: number;
  step?: number;
  factor?: number;
  connection?: number;
  type?: ModbusType;
  byteOrder?: ByteOrder;
  signed?: boolean;
  size?: number;
  sizeReal?: number;
  delayDb?: number;
  delayModbus?: number;
  datatype?: ModbusDataType;
  signalModbusWatchdog?: {
    period?: number;
    high?: number;
    alarmSignalTypeId?: string;
    enableSignalTypeId?: string;
  };
  readwrite?: ReadWrite;
};

export default ModbusDefaults;
