import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import AdminPage from 'js/components/AdminPage';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { DEFAULT_TABLE_PAGE_SIZE_PAGE } from 'ecto-common/lib/utils/constants';
import PagedDataTable, {
  PagedDataTableDataType,
  PagedDataTableErrorResult,
  totalSizeToTotalPages
} from 'ecto-common/lib/PagedDataTable/PagedDataTable';
import Icons from 'ecto-common/lib/Icons/Icons';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import EditTenantDialog from 'js/components/EditTenantDialog/EditTenantDialog';
import ManageUsersDialog from 'js/components/ManageUsers/ManageUsersDialog';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import IdentityServiceAPIGen, {
  TenantModel
} from 'ecto-common/lib/API/IdentityServiceAPIGen';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';

const INITIAL_PAGING = {
  sortColumn: null as string,
  sortOrder: null as string,
  pageNumber: 0,
  filter: null as string,
  pageSize: DEFAULT_TABLE_PAGE_SIZE_PAGE
};

const emptyTenants: PagedDataTableDataType<TenantModel> = {
  result: [],
  totalPages: 0,
  hasError: false
};

const ManageTenantsContent = () => {
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [editTenant, setEditTenant] = useState<Partial<TenantModel>>(null);
  const [tenantToDelete, setTenantToDelete] = useState<TenantModel>(null);
  const [editUsersTenant, setEditUsersTenant] = useState<TenantModel>(null);
  const { reloadTenants } = useContext(TenantContext);

  const clearTenantToDelete = useCallback(() => {
    setTenantToDelete(null);
  }, []);

  const setSearchFilter = useCallback((filter: string) => {
    setPaging((oldPaging) => ({ ...oldPaging, filter }));
  }, []);

  const clearTenant = useCallback(() => {
    setEditTenant(null);
  }, []);

  const clearUsersTenant = useCallback(() => {
    setEditUsersTenant(null);
  }, []);

  const deleteMutation = IdentityServiceAPIGen.Tenants.deleteTenant.useMutation(
    {
      onSuccess: () => {
        reloadTenants?.();
      },
      onError: () => {
        toastStore.addErrorToastForDeletedItem(tenantToDelete?.name);
      }
    }
  );

  const loadTenantsQuery = IdentityServiceAPIGen.Tenants.listTenants.useQuery({
    Filter: paging.filter,
    OrderBy: paging.sortColumn,
    PageNumber: paging.pageNumber,
    PageSize: paging.pageSize
  });

  const tenants = useMemo(() => {
    if (loadTenantsQuery.error) {
      return PagedDataTableErrorResult;
    }

    if (loadTenantsQuery.data) {
      return {
        totalPages: totalSizeToTotalPages(
          loadTenantsQuery.data.totalSize,
          DEFAULT_TABLE_PAGE_SIZE_PAGE
        ),
        hasError: false,
        result: loadTenantsQuery.data.tenants
      };
    }

    return emptyTenants;
  }, [loadTenantsQuery.data, loadTenantsQuery.error]);

  const confirmDelete = useCallback(() => {
    deleteMutation.mutate({ tenantId: tenantToDelete.id });
  }, [deleteMutation, tenantToDelete?.id]);

  const onClickEditUsers = useCallback((_tenant: TenantModel) => {
    setEditUsersTenant(_tenant);
  }, []);

  const columns: DataTableColumnProps<TenantModel>[] = useMemo(
    () => [
      {
        label: T.admin.tenants.edit.fields.name,
        dataKey: 'name',
        width: 1,
        dataFormatter: (value: string) => (
          <>
            <Icons.Folder />
            {value}
          </>
        )
      },
      {
        label: T.admin.tenants.edit.fields.description,
        dataKey: 'description',
        width: 1
      },
      {
        label: T.admin.tenants.edit.fields.id,
        dataKey: 'id',
        width: 1
      },
      {
        label: T.admin.tenants.edit.fields.timezone,
        dataKey: 'timeZone',
        width: 1
      },
      {
        label: T.admin.tenants.edit.fields.environment,
        dataKey: 'environment',
        width: 1
      },
      ...standardColumns({
        onEdit: setEditTenant,
        onDelete: setTenantToDelete,
        onClickEditUsers
      })
    ],
    [setEditTenant, setTenantToDelete, onClickEditUsers]
  );

  const onPageChange = useCallback((pageNumber: number) => {
    setPaging((oldPaging) => ({ ...oldPaging, pageNumber }));
  }, []);

  const onClickAdd = useCallback(() => {
    setEditTenant({
      id: null,
      resources: []
    });
  }, []);

  useEffect(() => {
    document.title = T.admin.tenants.manage;
  }, []);

  const toolbarItems = (
    <>
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <AddButton onClick={onClickAdd}>
          {T.admin.tenants.edit.addtitle}
        </AddButton>
      </ToolbarItem>
      <ToolbarSearch value={paging.filter} onChange={setSearchFilter} />
    </>
  );

  return (
    <ToolbarContentPage
      title={T.admin.tenants.manage}
      showLocationPicker={false}
      toolbarItems={toolbarItems}
      wrapContent={false}
    >
      <PagedDataTable<TenantModel>
        columns={columns}
        data={tenants}
        page={paging.pageNumber}
        useAllAvailableHeight
        pageSize={DEFAULT_TABLE_PAGE_SIZE_PAGE}
        isLoading={loadTenantsQuery.isLoading}
        onPageChange={onPageChange}
      />
      <EditTenantDialog onModalClose={clearTenant} tenant={editTenant} />
      <ManageUsersDialog
        tenant={editUsersTenant}
        onModalClose={clearUsersTenant}
      />
      <ConfirmDeleteDialog
        isOpen={tenantToDelete != null}
        onModalClose={clearTenantToDelete}
        onDelete={confirmDelete}
        itemName={tenantToDelete?.name}
        isLoading={deleteMutation.isPending}
      />
    </ToolbarContentPage>
  );
};

const ManageTenants = () => {
  return <AdminPage content={<ManageTenantsContent />} />;
};

export default React.memo(ManageTenants);
