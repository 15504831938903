import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Icons from 'ecto-common/lib/Icons/Icons';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import T from 'ecto-common/lib/lang/Language';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';

import SelectSignalTypesTable from 'js/components/SelectSignalsFromNodeTableDialog/SelectSignalTypesTable';
import styles from './SelectSignalsDialog.module.css';
import APIGen from 'ecto-common/lib/API/APIGen';

export const SelectSignalsFromNodeSignalType = {
  NOTIFICATION_ALARM_SIGNALS: 'NotificationAlarmSignals'
} as const;

export interface ItemWithSignalTypeId {
  signalTypeId: string;
}

interface SelectSignalTypesFromNodeTableDialogProps {
  nodeId?: string;
  isOpen?: boolean;
  onModalClose: () => void;
  onSignalsSelected?(signalTypeIds: string[]): void;
  selectedSignals?: ItemWithSignalTypeId[];
}

type ObjectWithSignalTypeId = {
  signalTypeId: string;
};

const SelectSignalTypesFromNodeTableDialog = ({
  nodeId,
  onModalClose,
  isOpen,
  onSignalsSelected,
  selectedSignals
}: SelectSignalTypesFromNodeTableDialogProps) => {
  const [tempSelectedSignalTypeIds, setTempSelectedSignalTypeIds] =
    useState<ObjectWithSignalTypeId[]>(selectedSignals);

  useEffect(() => {
    setTempSelectedSignalTypeIds(selectedSignals);
  }, [selectedSignals, isOpen]);

  const getQuery =
    APIGen.AdminNotifications.getAlarmSignalTypeIdsForNodes.useQuery({
      NodeIds: [nodeId]
    });

  const signalTypeIds: ItemWithSignalTypeId[] = useMemo(() => {
    if (getQuery.data == null) {
      return [];
    }

    return _.map(getQuery.data.signalTypeIds, (signalTypeId) => ({
      signalTypeId
    }));
  }, [getQuery.data]);

  const selectDone = useCallback(() => {
    onSignalsSelected(
      tempSelectedSignalTypeIds.map((signal) => signal.signalTypeId)
    );
    onModalClose();
  }, [onModalClose, onSignalsSelected, tempSelectedSignalTypeIds]);

  return (
    <Modal
      onModalClose={onModalClose}
      isOpen={isOpen}
      className={styles.selectSignalsDialog}
    >
      <ModalHeader titleIcon={Icons.Signal}>
        {T.admin.selectsignalsdialog.title}
      </ModalHeader>
      <ModalBody withWhiteBackground withoutPadding>
        <div>
          <SelectSignalTypesTable
            isLoading={getQuery.isLoading}
            data={signalTypeIds}
            selectedData={tempSelectedSignalTypeIds}
            onMarkedData={setTempSelectedSignalTypeIds}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <LocalizedButtons.Ok onClick={selectDone} />
        <LocalizedButtons.Cancel onClick={onModalClose} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectSignalTypesFromNodeTableDialog);
