import React, { useMemo } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import T from 'ecto-common/lib/lang/Language';
import styles from 'js/components/EnergyManagers/EditIoTDevice.module.css';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import Icons from 'ecto-common/lib/Icons/Icons';
import SegmentedContentView from 'ecto-common/lib/SegmentControl/SegmentedContentView';

import IoTHardwareActions from 'js/components/EnergyManagers/IoTHardwareActions';
import IoTDeviceInfo from 'js/components/EnergyManagers/IoTDeviceInfo';
import DeviceDetails from 'js/components/EnergyManagers/DeviceDetails';
import {
  IoTDeviceViewResponseModel,
  ModuleResponseModel
} from 'ecto-common/lib/API/APIGen';
import { UseEditIoTDeviceLoadingInfo } from 'js/components/EnergyManagers/useEditIoTDevice';

interface EditIoTDeviceProps {
  editIoTDevice?: IoTDeviceViewResponseModel;
  unlinkButton?: React.ReactNode;
  actionButtons?: React.ReactNode;
  useMinimumHeight?: boolean;
  loadingInfo?: UseEditIoTDeviceLoadingInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeviceDataChanged?(key: string[], value: any): void;
}

export type EditIoTDeviceDataType = IoTDeviceViewResponseModel & {
  moduleInfo?: ModuleResponseModel[];
};

const EditIoTDevice = ({
  editIoTDevice,
  useMinimumHeight,
  actionButtons,
  unlinkButton,
  loadingInfo,
  onDeviceDataChanged
}: EditIoTDeviceProps) => {
  const sections = useMemo(
    () => [
      {
        icon: <Icons.Settings />,
        title: T.admin.iotdevicedetails.tab.details,
        view: (
          <DeviceDetails
            device={editIoTDevice}
            onDeviceDataChanged={onDeviceDataChanged}
            actionButtons={actionButtons}
            loadingInfo={loadingInfo}
            unlinkButton={unlinkButton}
          />
        )
      },
      {
        icon: <Icons.Hardware />,
        title: T.admin.iotdevicedetails.tab.hardware,
        view: <IoTDeviceInfo iotDeviceId={editIoTDevice?.ioTDevice?.id} />
      },
      {
        icon: <Icons.Actions />,
        title: T.admin.iotdevicedetails.tab.hardwareactions,
        view: <IoTHardwareActions device={editIoTDevice} />
      }
    ],
    [
      editIoTDevice,
      onDeviceDataChanged,
      actionButtons,
      loadingInfo,
      unlinkButton
    ]
  );

  return (
    <div className={classNames(useMinimumHeight && styles.useMinimumHeight)}>
      <LoadingContainer isLoading={loadingInfo.isSaving}>
        <SegmentedContentView sections={sections} expanding />
      </LoadingContainer>
    </div>
  );
};

export default EditIoTDevice;
