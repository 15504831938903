import React, { useMemo } from 'react';
import pageStyles from '../PageStyle.module.css';
import zindex from 'ecto-common/lib/styles/variables/zindex';
import Select, {
  GenericSelectOption,
  SelectProps
} from 'ecto-common/lib/Select/Select';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { getValidAlarmSignalGroupTemplates } from 'js/utils/alarmSignalGroupTemplateUtils';
import { EquipmentTypes } from 'ecto-common/lib/utils/equipmentTypeUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { AdminSignalTemplates } from 'ecto-common/lib/types/EctoCommonTypes';

type AlarmSelectorProps = Omit<
  SelectProps<GenericSelectOption<string>, false>,
  'value'
> & {
  signalTemplates: AdminSignalTemplates;
  equipmentTypeId?: string;
  value: string;
};

const AlarmSelector = ({
  signalTemplates,
  value,
  equipmentTypeId,
  ...props
}: AlarmSelectorProps) => {
  const options = useMemo(
    () =>
      sortByLocaleCompare(
        getValidAlarmSignalGroupTemplates(
          signalTemplates.alarmSignalTemplates,
          equipmentTypeId
        ),
        'label'
      ),
    [equipmentTypeId, signalTemplates.alarmSignalTemplates]
  );
  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );
  const otherProps = _.omit(props, 'signalTemplates', 'value');

  return (
    <Select<GenericSelectOption<string>, false>
      name="alarm-signal-group-template"
      value={selectedOption}
      placeholder={T.admin.alarmselector.placeholder}
      className={pageStyles.smallSelector}
      maxMenuHeight={250}
      isClearable
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: zindex.modalOverlayZIndex
        })
      }}
      menuPortalTarget={document.body}
      options={options}
      disabled={equipmentTypeId === EquipmentTypes.ENERGY_MANAGER}
      {...otherProps}
    />
  );
};

export default AlarmSelector;
