import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';

import {
  Action,
  ActionText
} from 'js/components/SignalMapping/Components/types';
import { AggregationText } from 'ecto-common/lib/types/Aggregation';
import { SamplingIntervalText } from 'ecto-common/lib/types/SamplingInterval';
import {
  AggregationType,
  DestinationConfigSignalResponseModel,
  MappingResponseModel,
  SamplingInterval,
  SourceConfigSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  text,
  bool,
  number,
  option as formOption,
  getPathStringFromModelKeyFunc
} from 'ecto-common/lib/ModelForm/formUtils';
import SignalSectionModelEditor from 'js/components/SignalMapping/SignalSectionModelEditor';
import { SignalSectionModelDefinitionWithOnChange } from 'js/components/SignalMapping/Components/SignalSection';
import {
  ModelDefinition,
  ModelDynamicBoolProperty,
  ModelDynamicStringProperty,
  ModelStringFunctionProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

const isValidTTL = (value: number) => value != null && value >= 60;

export const signalsInput = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: (input: MappingResponseModel) => any,
  label: string,
  signalInputs: SignalSectionModelDefinitionWithOnChange[],
  hasError: ModelDynamicBoolProperty<MappingResponseModel, object, object>,
  errorText: ModelDynamicStringProperty<MappingResponseModel, object, object>
): ModelDefinition<MappingResponseModel> => ({
  key,
  label,
  modelType: ModelType.CUSTOM,
  render: (props, model, input) => (
    <SignalSectionModelEditor
      {...props}
      model={model}
      signalInputs={signalInputs}
      mappingId={input.id}
    />
  ),
  hasError,
  errorText
});

const mappingText = text<MappingResponseModel>;
const mappingNumber = number<MappingResponseModel>;
const mappingBool = bool<MappingResponseModel>;

export const models: ModelDefinition<MappingResponseModel>[] = [
  mappingText(
    (input) => input.name,
    T.admin.signalmapping.common.name,
    (value: string) => _.isEmpty(value)
  ),
  formOption(
    (input) => input.mappingAction,
    T.admin.signalmapping.source.option.action,
    _.map(ActionText, (label, value) => ({ label, value })),
    null,
    (_name, value) => {
      if (value === Action.SEND_ON_PUSH) {
        // SendOnPush should only have RAW and NONE
        return [
          [
            (input) => input.sourceConfig.samplingInterval,
            SamplingInterval.Raw
          ],
          [(input) => input.sourceConfig.aggregation, AggregationType.None]
        ];
      }
      return [];
    },
    true,
    T.admin.signalmapping.help.action
  ),
  mappingNumber(
    (input) => input.cycleTime,
    T.admin.signalmapping.source.option.cycletime,
    (value) => value < 0 || value >= 60,
    1,
    T.admin.signalmapping.help.cycletime
  ),
  mappingNumber(
    (input) => input.sourceConfig.hoursBack,
    T.admin.signalmapping.source.option.hoursback,
    null,
    1,
    T.admin.signalmapping.help.hoursback
  ),
  mappingNumber(
    (input) => input.sourceConfig.hoursForward,
    T.admin.signalmapping.source.option.hoursforward,
    (value: number, object) => {
      if (object?.sourceConfig?.aggregation !== AggregationType.None) {
        return value == null || value <= 0;
      }

      return false;
    },
    1,
    T.admin.signalmapping.help.hoursforward
  ),
  formOption(
    (input) => input.sourceConfig.samplingInterval,
    T.admin.signalmapping.source.option.samplinginterval,
    _.map(SamplingIntervalText, (label, value) => ({ label, value })),
    // Already translated, no need to transform text
    null,
    (_key, value: SamplingInterval) => {
      if (value === SamplingInterval.Raw) {
        return [
          [(input) => input.sourceConfig.aggregation, AggregationType.None]
        ];
      }
      return [];
    },
    (input) => input.mappingAction !== Action.SEND_ON_PUSH
  ),
  formOption(
    (input) => input.sourceConfig.aggregation,
    T.admin.signalmapping.source.option.aggregation,
    _.map(AggregationText, (label, value: AggregationType) => ({
      label,
      value
    })), // TODO: Why does this cast work?
    // Already translated, no need to transform text
    (value, input) => {
      if (input?.mappingAction === Action.SEND_ON_PUSH) {
        return false;
      }

      return (
        (input?.sourceConfig?.samplingInterval === SamplingInterval.Raw &&
          value !== AggregationType.None) ||
        (input?.sourceConfig?.samplingInterval !== SamplingInterval.Raw &&
          value === AggregationType.None)
      );
    },
    null,
    (input) =>
      input.mappingAction !== Action.SEND_ON_PUSH &&
      input?.sourceConfig?.samplingInterval !== SamplingInterval.Raw
  ),
  mappingBool(
    (input) => input.sourceConfig.useLastValueBeforeStartDate,
    T.admin.signalmapping.source.option.uselastvalue,
    T.admin.signalmapping.help.uselastvaluebeforestartdate
  ),
  signalsInput(
    (input) => input.sourceConfig.configSignals,
    T.admin.signalmapping.source.selectsignals,
    null,
    (value, input, environment, model) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (
        (
          model.errorText as ModelStringFunctionProperty<
            MappingResponseModel,
            object,
            object
          >
        )(value, input, environment, model) != null
      );
    },
    (value: SourceConfigSignalResponseModel[], input) => {
      if ((value ?? []).length === 0) {
        return T.admin.signalmapping.error.nosignals;
      } else if (
        (value ?? []).length > 1 &&
        input.sourceConfig.aggregation === AggregationType.None
      ) {
        return T.admin.signalmapping.error.missingaggregation;
      }

      return null;
    }
  ),
  mappingNumber(
    (input) => input.destinationConfig.ttl,
    T.admin.signalmapping.destination.option.timetolive,
    (value) => {
      return !isValidTTL(value);
    },
    1,
    T.admin.signalmapping.help.ttl,
    (value) => {
      return isValidTTL(value) ? null : T.admin.signalmapping.error.ttl;
    }
  ),
  signalsInput(
    (input) => input.destinationConfig.configSignals,
    T.admin.signalmapping.destination.selectsignals,
    [
      {
        key: 'timeOffset',
        label: T.admin.signalmapping.signalselection.timeoffset
      },
      {
        key: 'defaultValue',
        label: T.admin.signalmapping.signalselection.defaultvalue
      }
    ],
    (value, input, environment, model) => {
      return (
        (
          model.errorText as ModelStringFunctionProperty<
            MappingResponseModel,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any,
            object
          >
        )(value, input, environment, model) != null
      );
    },
    (value: DestinationConfigSignalResponseModel[], input) => {
      if ((value ?? []).length === 0) {
        return T.admin.signalmapping.error.nosignals;
      } else if (
        _.intersectionBy(value, input.sourceConfig.configSignals, 'signalId')
          .length !== 0
      ) {
        return T.admin.signalmapping.error.destsourceoverlap;
      }

      return null;
    }
  )
];

// Mapped model to 'key'
export const modelKeyed = _.keyBy(models, (model) =>
  getPathStringFromModelKeyFunc(model.key)
);
