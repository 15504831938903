import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';

import SignalMappingList from './SignalMappingList';
import SignalMappingDialog from './SignalMappingDialog';
import {
  AggregationType,
  MappingAction,
  MappingResponseModel,
  SamplingInterval
} from 'ecto-common/lib/API/APIGen';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';

const defaultValues: MappingResponseModel = {
  id: undefined,
  cycleTime: undefined,
  mappingAction: MappingAction.NoAction,
  sourceConfig: {
    id: undefined,
    useLastValueBeforeStartDate: false,
    aggregation: AggregationType.None,
    samplingInterval: SamplingInterval.Raw
  }
};

interface SignalMappingPageProps {
  onTitleChanged?: (title: string[]) => void;
  selectedLocation?: SingleGridNode;
}

const SignalMappingPage = ({
  onTitleChanged,
  selectedLocation
}: SignalMappingPageProps) => {
  const [isCreateDialogVisible, showCreateDialog, _hideCreateDialog] =
    useSimpleDialogState();
  const [reloadTrigger, triggerReload] = useReloadTrigger();

  // Need clone deep, else frozen/constant values will not be changed.
  const [selectedItem, _setSelectedItem] = useState<MappingResponseModel>(() =>
    _.cloneDeep(defaultValues)
  );
  const hideCreateDialog = useCallback(() => {
    // Need clone deep, else frozen/constant values will not be changed.
    _setSelectedItem(_.cloneDeep(defaultValues));
    _hideCreateDialog();
  }, [_setSelectedItem, _hideCreateDialog]);

  const setSelectedItem = useCallback(
    (item: MappingResponseModel) => {
      // Need to clone item, so the original item is not changed for sub.sub properties
      _setSelectedItem(_.cloneDeep(item));
      if (item == null) {
        _hideCreateDialog();
      } else {
        showCreateDialog();
      }
    },
    [_setSelectedItem, showCreateDialog, _hideCreateDialog]
  );

  const toolbarItems = [
    <ToolbarFlexibleSpace key="space" />,
    <ToolbarItem key="create">
      <Button onClick={showCreateDialog}>
        <Icons.Add />
        {T.admin.signalmapping.createmapping}
      </Button>
    </ToolbarItem>
  ];

  usePageTitleCallback({
    mainTitle: T.admin.tabs.signalmapping,
    subTitle: '',
    onTitleChanged
  });

  return (
    <ToolbarContentPage
      title={T.admin.tabs.signalmapping}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.admin.manage.signal_mappings}
      wrapContent={false}
    >
      <SignalMappingDialog
        item={selectedItem}
        isOpen={isCreateDialogVisible}
        onSuccess={triggerReload}
        onModalClose={hideCreateDialog}
      />

      <SignalMappingList
        selectedLocation={selectedLocation}
        onItemSelected={setSelectedItem}
        reloadTrigger={reloadTrigger}
      />
    </ToolbarContentPage>
  );
};

export default SignalMappingPage;
