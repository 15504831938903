import {
  AdminAlarmSignalGroupTemplateResponseModel,
  EquipmentSignalProviderTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  PATCH_SIGNAL_TEMPLATES,
  SET_SIGNAL_TEMPLATES
} from 'js/actions/actionTypes';
import { AdminAction } from 'js/reducers/admin';

export function setSignalTemplates(
  responses: [
    AdminAlarmSignalGroupTemplateResponseModel[],
    EquipmentSignalProviderTemplateResponseModel[]
  ]
): AdminAction {
  return {
    type: SET_SIGNAL_TEMPLATES,
    payload: {
      alarmSignalTemplates: responses[0],
      equipmentSignalTemplates: responses[1]
    }
  };
}

export function patchSignalTemplates(
  payload:
    | EquipmentSignalProviderTemplateResponseModel[]
    | AdminAlarmSignalGroupTemplateResponseModel[]
): AdminAction {
  return { type: PATCH_SIGNAL_TEMPLATES, payload };
}
