import React, {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import styles from './DeployEnergyManager.module.css';
import T from 'ecto-common/lib/lang/Language';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import StatusCircle from 'ecto-common/lib/StatusCircle/StatusCircle';
import {
  STATUS_CIRCLE_ERROR,
  STATUS_CIRCLE_IDLE,
  STATUS_CIRCLE_OK,
  STATUS_CIRCLE_WARNING
} from 'ecto-common/lib/StatusCircle/StatusCircle';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { NavLink } from 'react-router-dom';

import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import useTimeout from 'ecto-common/lib/hooks/useTimeout';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';

import { getEquipmentPageUrl } from 'js/utils/linkUtil';
import { EnergyManagerSubpage } from 'js/utils/LocationEndpoints';
import EnergyManagerFilesModal from 'js/components/ManageEquipment/DeployEnergyManager/EnergyManagerFilesModal';
import EnergyManagerVersionsModal from 'js/components/ManageEquipment/DeployEnergyManager/EnergyManagerVersionsModal';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import APIGen, {
  DeviceStatusResponseModel,
  IoTDeviceViewResponseModel,
  NodeEquipmentResponseModel
} from 'ecto-common/lib/API/APIGen';
import { getEquipmentNode } from 'ecto-common/lib/utils/locationUtils';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const PROCESS_WAIT_TIME_MS = 1000 * 10;

type DeployEnergyManagerHeaderData = {
  deploymentState: DeploymentState;
  deploymentMessage: React.ReactNode;
  formattedDeploymentTime: string;
  deploymentTime: string;
  isLoading: boolean;
};

type DeployEnergyManagerData = {
  file: string;
  currentMD5: string;
  deployMD5: string;
  reportedMD5: string;
};

enum DeploymentState {
  LOADING = 'LOADING',
  PENDING = 'PENDING',
  PENDING_DEPLOY_DONE = 'PENDING_DEPLOY_DONE',
  ERROR = 'ERROR',
  NO_IOT_DEVICE = 'NO_IOT_DEVICE',
  NO_IOT_HUB_DEVICE = 'NO_IOT_HUB_DEVICE',
  UP_TO_DATE = 'UP_TO_DATE',
  UP_TO_DATE_WAITING_FOR_PROCESS_RELOAD = 'UP_TO_DATE_WAITING_FOR_PROCESS_RELOAD',
  UP_TO_DATE_WITH_CHANGES = 'UP_TO_DATE_WITH_CHANGES',
  READY_TO_DEPLOY = 'READY_TO_DEPLOY'
}

const deploymentStateToStatusCircleType = (
  deploymentState: DeploymentState
) => {
  switch (deploymentState) {
    case DeploymentState.PENDING_DEPLOY_DONE:
    case DeploymentState.PENDING:
    case DeploymentState.LOADING:
    case DeploymentState.UP_TO_DATE_WAITING_FOR_PROCESS_RELOAD:
    case DeploymentState.UP_TO_DATE_WITH_CHANGES:
      return STATUS_CIRCLE_WARNING;
    case DeploymentState.NO_IOT_HUB_DEVICE:
    case DeploymentState.NO_IOT_DEVICE:
    case DeploymentState.ERROR:
      return STATUS_CIRCLE_ERROR;
    case DeploymentState.READY_TO_DEPLOY:
      return STATUS_CIRCLE_IDLE;
    case DeploymentState.UP_TO_DATE:
      return STATUS_CIRCLE_OK;
    default:
      return STATUS_CIRCLE_OK;
  }
};

const deploymentStateToMessage = (
  deploymentState: DeploymentState,
  noIotHubInfoLink: React.ReactNode
) => {
  switch (deploymentState) {
    case DeploymentState.LOADING:
      return T.admin.energymanager.loading;
    case DeploymentState.PENDING:
      return T.admin.energymanager.pending;
    case DeploymentState.NO_IOT_DEVICE:
      return noIotHubInfoLink;
    case DeploymentState.ERROR:
      return T.common.unknownerror;
    case DeploymentState.NO_IOT_HUB_DEVICE:
      return T.admin.energymanager.noiothubdevice;
    case DeploymentState.UP_TO_DATE_WITH_CHANGES:
      return T.admin.energymanager.uptodatewithchanges;
    case DeploymentState.UP_TO_DATE_WAITING_FOR_PROCESS_RELOAD:
      return T.admin.energymanager.uptodatewaitingforprocessreload;
    case DeploymentState.UP_TO_DATE:
      return T.admin.energymanager.uptodate;
    case DeploymentState.READY_TO_DEPLOY:
      return T.admin.energymanager.readytodeploy;
    case DeploymentState.PENDING_DEPLOY_DONE:
      return T.admin.energymanager.pending;
    default:
      return '';
  }
};

const headerColumns: DataTableColumnProps<DeployEnergyManagerHeaderData>[] = [
  {
    label: null,
    dataKey: 'deploymentMessage',
    minWidth: 120,
    dataFormatter: (
      deploymentMessage: string,
      { deploymentState, formattedDeploymentTime, deploymentTime }
    ) => {
      return (
        <div className={styles.deploymentContainer}>
          <StatusCircle
            status={deploymentStateToStatusCircleType(deploymentState)}
          />
          <span className={styles.deploymentStatus}>
            <div>{deploymentMessage}</div>
            {deploymentTime && <div>{formattedDeploymentTime}</div>}
          </span>
        </div>
      );
    }
  },
  {
    label: null,
    dataKey: 'isLoading',
    flexGrow: 0,
    flexShrink: 0,
    minWidth: SpinnerSize.SMALL,
    width: SpinnerSize.SMALL,
    dataFormatter: (isLoading) => {
      return isLoading && <Spinner size={SpinnerSize.SMALL} />;
    }
  }
];

const detailColumns: DataTableColumnProps<DeployEnergyManagerData>[] = [
  {
    label: T.common.file,
    dataKey: 'file',
    width: '25%',
    minWidth: 120,
    dataFormatter: (value: string) => {
      return value;
    }
  },
  {
    label: T.admin.energymanager.currentmd5,
    dataKey: 'currentMD5',
    width: '25%',
    minWidth: 120,
    dataFormatter: (value: string) => {
      return <span className={styles.md5Text}>{value}</span>;
    }
  },
  {
    label: T.admin.energymanager.deploymd5,
    dataKey: 'deployMD5',
    width: '25%',
    minWidth: 120,
    dataFormatter: (value: string) => {
      return <span className={styles.md5Text}>{value}</span>;
    }
  },
  {
    label: T.admin.energymanager.reportedmd5,
    dataKey: 'reportedMD5',
    width: '25%',
    minWidth: 120,
    dataFormatter: (value: string) => {
      return <span className={styles.md5Text}>{value}</span>;
    }
  }
];

const getHash = (
  deviceStatus: DeviceStatusResponseModel,
  category: 'current' | 'desired' | 'reported',
  type: 'deviceConfigHash' | 'signalsHash' | 'toolsHash'
) => {
  if (deviceStatus && deviceStatus[category]) {
    return deviceStatus[category][type] || '';
  }

  return '';
};

const dataItem = (
  deviceStatus: DeviceStatusResponseModel,
  translation: string,
  name: 'deviceConfigHash' | 'signalsHash' | 'toolsHash'
) => {
  return {
    file: translation,
    currentMD5: getHash(deviceStatus, 'current', name),
    deployMD5: getHash(deviceStatus, 'desired', name),
    reportedMD5: getHash(deviceStatus, 'reported', name)
  };
};

const getDeploymentState = (
  deviceStatus: DeviceStatusResponseModel,
  isLoadingInitialData: boolean,
  deployDeviceConfigIsLoading: boolean,
  iotDevice: IoTDeviceViewResponseModel,
  hasError: boolean,
  hasWaitedForProcessReload: boolean,
  prevDeploymentState: DeploymentState
) => {
  if (isLoadingInitialData) {
    return DeploymentState.LOADING;
  } else if (deployDeviceConfigIsLoading) {
    return DeploymentState.PENDING;
  } else if (iotDevice == null) {
    return DeploymentState.NO_IOT_DEVICE;
  } else if (hasError) {
    return DeploymentState.ERROR;
  } else if (deviceStatus) {
    const inSync =
      deviceStatus.desired &&
      !isNullOrWhitespace(deviceStatus.desired.deviceConfigHash) &&
      _.isEqual(deviceStatus.desired, deviceStatus.reported);

    const hasPendingChanges =
      deviceStatus.reported &&
      !_.isEqual(deviceStatus.current, deviceStatus.reported);

    if (!deviceStatus.hasTwin) {
      return DeploymentState.NO_IOT_HUB_DEVICE;
    } else if (inSync && hasPendingChanges) {
      return DeploymentState.UP_TO_DATE_WITH_CHANGES;
    } else if (inSync && !hasPendingChanges) {
      const secondsSinceLastDeploy = moment().diff(
        moment(deviceStatus.deploymentTime),
        'seconds'
      );

      // We want to give the EM time to update its databases after the config files
      // have been deployed. Unfortunately, we won't get a signal from it once this has
      // been done. So, we do a rough estimation which covers most cases. After we have
      // confirmed that the files are in sync, we would like to start a timer for X
      // seconds. hasWaitedForProcessReload indicates if this timer has completed.
      //
      // If we went from pending to up to date, or if the deploy was initiated in the last minute
      // (to handle the case of quickly closing the dialog and then reopening it - no pending state
      // then, just LOADING => UP_TO_DATE) - then wait for the timer to complete before showing
      // the up-to-date state.
      if (
        (prevDeploymentState === DeploymentState.PENDING ||
          secondsSinceLastDeploy < 60) &&
        !hasWaitedForProcessReload
      ) {
        return DeploymentState.UP_TO_DATE_WAITING_FOR_PROCESS_RELOAD;
      }

      return DeploymentState.UP_TO_DATE;
    } else if (
      deviceStatus.desired &&
      deviceStatus.desired.signalsHash === '' &&
      deviceStatus.reported.signalsHash === ''
    ) {
      return DeploymentState.READY_TO_DEPLOY;
    }
    return DeploymentState.PENDING_DEPLOY_DONE;
  }

  return DeploymentState.READY_TO_DEPLOY;
};

/**
 *  Determines which device owns the equipment and then fetches relevant data for that device.
 */
const getDeviceDataPromise = (
  contextSettings: ApiContextSettings,
  deviceIds: string[],
  signal: AbortSignal
) => {
  // const ids = _.map(deviceInfo, 'deviceId');
  return Promise.all([
    APIGen.AdminDevices.getConnectionsByDeviceIds.promise(
      contextSettings,
      {
        deviceIds
      },
      signal
    ),
    APIGen.AdminIoTDevices.getIoTDeviceViewByDeviceId.promise(
      contextSettings,
      {
        DeviceIds: deviceIds
      },
      signal
    )
  ] as const);
};

interface DeployEnergyManagerProps {
  equipment: NodeEquipmentResponseModel;
  showFileHashes?: boolean;
  showInfoButtons?: boolean;
  setDeviceInSync?: React.Dispatch<SetStateAction<boolean>>;
  deviceStatusReloadTrigger?: number;
}

const emptyStatusRes: Awaited<ReturnType<typeof getDeviceDataPromise>> = [
  [],
  null
] as const;

/**
 *  Manage deployment of config files to an Energy Manager.
 *
 *  @param equipment An equipment that is owned by the device that you wish to manage. Can be any equipment, i.e. Radiator or EM.
 *  @param showFileHashes Whether or not to show a table with detailed information about file checksums.
 *  @param showInfoButtons Whether or not to show the information buttons (config files / versions)
 *  @param setDeviceInSync Callback method that is called whenever the device is in sync (config files up-to-date) or when not.
 *  @param deviceStatusReloadTrigger Use this to trigger a status refresh (when you have performed an action that affects the configs)
 */
const DeployEnergyManager = ({
  equipment,
  showFileHashes = true,
  showInfoButtons = true,
  setDeviceInSync = null,
  deviceStatusReloadTrigger
}: DeployEnergyManagerProps) => {
  const [isShowingFilesModal, showFilesModal, hideFilesModal] =
    useDialogState('show-em-files');
  const [shouldPoll, setShouldPoll] = useState(false);

  const [isShowingVersionsModal, showVersionModal, hideVersionsModal] =
    useDialogState('show-em-versions');

  const [hasError, setHasError] = useState(false);
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const { equipmentId } = equipment;

  const deviceInfoQuery =
    APIGen.AdminDevices.getDeviceEquipmentInfoFromEquipmentIds.useQuery({
      equipmentIds: [equipmentId]
    });

  const { contextSettings } = useContext(TenantContext);

  const deviceIds = useMemo(() => {
    return _.map(deviceInfoQuery.data, 'deviceId');
  }, [deviceInfoQuery.data]);

  const getDeviceDataQuery = useQuery({
    queryKey: ['deviceGetStatus', contextSettings, equipmentId],

    queryFn: ({ signal }) => {
      return getDeviceDataPromise(contextSettings, deviceIds, signal);
    },

    enabled: deviceIds.length > 0
  });

  const [deviceConnections, fetchedIotDevices] =
    getDeviceDataQuery.data ?? emptyStatusRes;

  const iotDevice = _.head(fetchedIotDevices);
  const deviceId = _.head(deviceIds);

  const getDeviceStatusQuery =
    APIGen.AdminDevices.getDeviceStatusFromDeviceIds.useQuery(
      {
        deviceIds
      },
      {
        enabled: deviceIds.length > 0,
        refetchInterval: shouldPoll ? 1000 * 10 : undefined
      }
    );
  const deviceStatus = _.head(getDeviceStatusQuery.data);
  const isLoadingDeviceStatus =
    getDeviceStatusQuery.data == null && getDeviceStatusQuery.isLoading;

  const deploymentTime = deviceStatus?.deploymentTime;
  const formattedDeploymentTime = `${T.admin.lastdeployed} ${moment(deploymentTime).format('YYYY-MM-DD HH:mm:ss')}.`;
  const [hasWaitedForProcessReload, setHasWaitedForProcessReload] =
    useState(false);
  const [startProcessWaitTimer] = useTimeout(
    useCallback(() => setHasWaitedForProcessReload(true), []),
    PROCESS_WAIT_TIME_MS
  );

  const queryClient = useQueryClient();

  const deployDeviceMutation =
    APIGen.AdminDevices.deployModbusDeviceConfigs.useMutation({
      onSuccess: (statuses) => {
        const newDeviceStatus = _.head(
          statuses.deployDeviceConfig
        )?.deviceStatus;
        const path = [
          ...APIGen.AdminDevices.getDeviceStatusFromDeviceIds.path(
            contextSettings
          ),
          { deviceIds }
        ];
        if (newDeviceStatus) {
          queryClient.setQueryData<DeviceStatusResponseModel[]>(path, [
            newDeviceStatus
          ]);
        } else {
          queryClient.invalidateQueries({
            queryKey: path
          });
        }
      },
      onError: () => {
        setHasError(true);
      }
    });

  const prevDeploymentState = useRef<DeploymentState>(
    DeploymentState.READY_TO_DEPLOY
  );
  const deploymentState = getDeploymentState(
    deviceStatus,
    isLoadingDeviceStatus || getDeviceDataQuery.isLoading,
    deployDeviceMutation.isPending,
    iotDevice,
    hasError,
    hasWaitedForProcessReload,
    prevDeploymentState.current
  );

  const deviceInSync = deploymentState === DeploymentState.UP_TO_DATE;
  const nextShouldPoll =
    deploymentState === DeploymentState.PENDING ||
    deploymentState === DeploymentState.PENDING_DEPLOY_DONE;
  if (nextShouldPoll !== shouldPoll) {
    setShouldPoll(nextShouldPoll);
  }

  const getSignalsConfigQuery =
    APIGen.AdminDevices.getModbusSignalsConfig.useQuery(
      {
        DeviceId: deviceId
      },
      {
        enabled: !!deviceId
      }
    );

  const configSignals = getSignalsConfigQuery?.data;

  const hasInvalidSignals = useMemo(
    () =>
      _.some(
        configSignals,
        (signal) => signal?.modbus && !signal?.modbus.address
      ),
    [configSignals]
  );

  const { tenantId } = useContext(TenantContext);

  const noIotHubInfoLink = useMemo(() => {
    // Connection ID is equivalent to Equipment ID of Energy Manager
    const energyManagerEquipmentId = _.head(deviceConnections)?.id;
    const parentNode = getEquipmentNode(
      energyManagerEquipmentId,
      nodeMap,
      equipmentMap
    );

    if (parentNode == null) {
      return T.admin.energymanager.noiothubdevice;
    }

    return T.format(
      T.admin.energymanager.deploynopairinginfoformat,
      <NavLink
        key="devicelink"
        to={getEquipmentPageUrl(
          tenantId,
          parentNode.nodeId,
          energyManagerEquipmentId,
          EnergyManagerSubpage.IOTDEVICE
        )}
      >
        {T.admin.equipment.section.iotdevice}
      </NavLink>
    );
  }, [deviceConnections, nodeMap, equipmentMap, tenantId]);

  const deploymentMessage = deploymentStateToMessage(
    deploymentState,
    noIotHubInfoLink
  );

  useEffect(() => {
    prevDeploymentState.current = deploymentState;
  }, [deploymentState]);

  useEffect(() => {
    if (
      deploymentState === DeploymentState.UP_TO_DATE_WAITING_FOR_PROCESS_RELOAD
    ) {
      setHasWaitedForProcessReload(false);
      startProcessWaitTimer();
    }
  }, [deploymentState, startProcessWaitTimer]);

  const showSpinner =
    getSignalsConfigQuery.isLoading ||
    getDeviceDataQuery.isLoading ||
    isLoadingDeviceStatus ||
    deployDeviceMutation.isPending ||
    shouldPoll ||
    deploymentState === DeploymentState.UP_TO_DATE_WAITING_FOR_PROCESS_RELOAD;

  useEffect(() => {
    if (setDeviceInSync) {
      setDeviceInSync(deviceInSync);
    }
  }, [deviceInSync, setDeviceInSync]);

  useEffect(() => {
    if (equipmentId != null) {
      if (setDeviceInSync) {
        setDeviceInSync(false);
      }
    }
  }, [equipmentId, deviceStatusReloadTrigger, setDeviceInSync]);

  const headerData: DeployEnergyManagerHeaderData[] = useMemo(() => {
    return [
      {
        deploymentState,
        deploymentMessage,
        formattedDeploymentTime,
        deploymentTime,
        isLoading: showSpinner
      }
    ];
  }, [
    deploymentState,
    deploymentMessage,
    formattedDeploymentTime,
    deploymentTime,
    showSpinner
  ]);

  const onDeployConfig = useCallback(() => {
    deployDeviceMutation.mutate({
      deviceIds: [deviceId]
    });
  }, [deployDeviceMutation, deviceId]);

  const detaildata: DeployEnergyManagerData[] = useMemo(
    () => [
      dataItem(
        deviceStatus,
        T.admin.energymanager.devicefile,
        'deviceConfigHash'
      ),
      dataItem(deviceStatus, T.admin.energymanager.signalsfile, 'signalsHash'),
      dataItem(deviceStatus, T.admin.energymanager.toolsfile, 'toolsHash')
    ],
    [deviceStatus]
  );

  const disableDeployButton =
    getDeviceDataQuery.isLoading ||
    isLoadingDeviceStatus ||
    deployDeviceMutation.isPending ||
    getSignalsConfigQuery.isLoading ||
    _.isNull(configSignals) ||
    hasError;

  return (
    <div>
      <KeyValueGeneric keyText={T.admin.energymanager.deployment}>
        <DataTable<DeployEnergyManagerHeaderData>
          disableHeader
          data={headerData}
          columns={headerColumns}
        />

        {!isLoadingDeviceStatus &&
          !getDeviceDataQuery.isLoading &&
          !hasError &&
          showFileHashes && (
            <DataTable<DeployEnergyManagerData>
              className={styles.marginTopTable}
              data={detaildata}
              columns={detailColumns}
            />
          )}
      </KeyValueGeneric>

      {hasInvalidSignals && !getSignalsConfigQuery.isLoading && (
        <ErrorNotice>
          {T.admin.energymanager.missingmodbusaddressess}
        </ErrorNotice>
      )}

      {getSignalsConfigQuery.isError && (
        <ErrorNotice>
          {T.admin.energymanagers.failedtofetchsignalsconfig}
        </ErrorNotice>
      )}

      <div className={styles.buttonFooter}>
        {showInfoButtons && (
          <Button
            onClick={showVersionModal}
            disabled={isLoadingDeviceStatus || getDeviceDataQuery.isLoading}
          >
            <Icons.EnergyManager />
            {T.admin.energymanager.versions}
          </Button>
        )}

        {showInfoButtons && (
          <Button
            onClick={showFilesModal}
            disabled={isLoadingDeviceStatus || getDeviceDataQuery.isLoading}
          >
            <Icons.File />
            {T.admin.equipment.files.button}
          </Button>
        )}

        <Button disabled={disableDeployButton} onClick={onDeployConfig}>
          <Icons.Deploy />
          {T.admin.energymanager.deploy}
        </Button>
      </div>
      {deviceId && (
        <EnergyManagerFilesModal
          isOpen={isShowingFilesModal}
          onModalClose={hideFilesModal}
          deviceId={deviceId}
        />
      )}
      {deviceStatus &&
        !isLoadingDeviceStatus &&
        !getDeviceDataQuery.isLoading && (
          <EnergyManagerVersionsModal
            isOpen={isShowingVersionsModal}
            onModalClose={hideVersionsModal}
            versionsData={deviceStatus.reportedVersions}
            isLoading={false}
          />
        )}
    </div>
  );
};

export default React.memo(DeployEnergyManager);
