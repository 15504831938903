import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';

import {
  EditState,
  EditStateData
} from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import {
  ToolSignalProviders,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import EditToolDialog from 'js/components/ManageEquipment/EditEquipment/Util/EditToolDialog';
import UUID from 'uuidjs';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { AdminToolImplementationType } from 'js/components/ManageEquipment/EditEquipment/useEquipmentTools';
import { useMutation } from '@tanstack/react-query';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const DEFAULT_ID_KEY = 'providerId';

interface EditToolProps<ObjectType extends object> {
  tool?: AdminToolImplementationType<ObjectType>;
  setEditState: (toolType: ToolSignalProviders, data: EditStateData) => void;
  editState?: EditStateData;
  nodeId?: string;
  equipmentId?: string;
  currentTool?: object;
  reloadTools?(): void;
  idKey?: string;
  includeDeviceId?: boolean;
}

const EditTool = <ObjectType extends object>({
  tool,
  setEditState,
  editState,
  nodeId,
  equipmentId,
  currentTool,
  reloadTools,
  idKey = DEFAULT_ID_KEY,
  includeDeviceId = true
}: EditToolProps<ObjectType>) => {
  const deviceId = useAdminSelector(
    (state) => state.editEquipmentTools.deviceId
  );

  const [editVisible, setEditVisible] = useState(false);
  const [item, setItem] = useState<ObjectType>(null);
  const [isValidItem, setIsValidItem] = useState(false);

  const edit = useCallback((_item: ObjectType) => {
    setItem({ ..._item });
    setEditVisible(true);
    setIsValidItem(true);
  }, []);

  const resetEditState = useCallback(
    () => setEditState(tool.type, { state: EditState.IDLE, data: null }),
    [tool.type, setEditState]
  );

  // Workaround for useMutation, it only accepts a single argument
  const savePromise = useCallback(
    ({
      contextSettings,
      args
    }: {
      contextSettings: ApiContextSettings;
      args: ObjectType[];
    }) => {
      return tool.save(contextSettings, args, null);
    },
    [tool]
  );

  const deletePromise = useCallback(
    ({
      contextSettings,
      providerIds
    }: {
      contextSettings: ApiContextSettings;
      providerIds: string[];
    }) => {
      return tool.delete(contextSettings, providerIds, null);
    },
    [tool]
  );

  const saveProvidersMutation = useMutation({
    mutationFn: savePromise,
    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.equipment.request.updatedtool);
      resetEditState();
      reloadTools();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
    }
  });

  const { contextSettings } = useContext(TenantContext);

  const save = useCallback(() => {
    const saveItem: ObjectType & {
      nodeId: string;
      deviceId?: string;
    } = {
      ...item,
      nodeId: equipmentId
    };

    if (includeDeviceId) {
      saveItem.deviceId = deviceId;
    }

    const oldId = _.get(item, idKey);
    // If we are creating a new item we must generate an id
    _.set(saveItem, idKey, oldId ?? UUID.generate());

    saveProvidersMutation.mutate({
      contextSettings,
      args: [saveItem]
    });
  }, [
    item,
    equipmentId,
    includeDeviceId,
    idKey,
    saveProvidersMutation,
    contextSettings,
    deviceId
  ]);

  const hideEdit = useCallback(() => {
    setEditVisible(false);
    resetEditState();
  }, [resetEditState]);

  const providerId = _.get(currentTool, idKey);
  const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);

  const confirmRemove = useCallback(() => setConfirmRemoveVisible(true), []);

  const cancelConfirmRemove = useCallback(() => {
    setConfirmRemoveVisible(false);
    resetEditState();
  }, [resetEditState]);

  const removeProviderMutation = useMutation({
    mutationFn: deletePromise,
    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.equipment.request.updatedtool);
      resetEditState();
      reloadTools();
      setConfirmRemoveVisible(false);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
    }
  });

  const remove = useCallback(
    () =>
      removeProviderMutation.mutate({
        contextSettings,
        providerIds: [providerId]
      }),
    [contextSettings, providerId, removeProviderMutation]
  );

  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);

  const equipment = getNodeFromMap(equipmentMap, equipmentId);

  const emptyInput = useMemo(() => {
    return (
      (_.isFunction(tool.emptyInput) && tool.emptyInput(equipment)) ||
      tool.emptyInput
    );
  }, [equipment, tool]);

  useEffect(() => {
    switch (editState.state) {
      case EditState.IDLE:
        setEditVisible(false);
        setIsValidItem(false);
        setItem(null);
        break;
      case EditState.EDIT:
        edit(editState.data);
        break;
      case EditState.CREATE:
        edit({ ...(emptyInput as ObjectType) });
        break;
      case EditState.DELETE:
        confirmRemove();
        break;
      default:
        break;
    }
  }, [
    editState,
    edit,
    nodeId,
    equipmentId,
    deviceId,
    confirmRemove,
    emptyInput
  ]);

  const DialogComponent = tool.dialog || EditToolDialog;

  return (
    <>
      <DialogComponent
        tool={tool}
        equipmentId={equipmentId}
        hideEdit={hideEdit}
        editVisible={editVisible}
        save={save}
        isValidItem={isValidItem}
        item={item}
        setItem={setItem}
        setIsValidItem={setIsValidItem}
        isLoading={saveProvidersMutation.isPending}
      />

      <ConfirmDeleteDialog
        isOpen={confirmRemoveVisible}
        isLoading={removeProviderMutation.isPending}
        onModalClose={cancelConfirmRemove}
        onDelete={remove}
      >
        {ToolSignalProviderTranslations[tool.type]}
      </ConfirmDeleteDialog>
    </>
  );
};

export default EditTool;
