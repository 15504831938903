import T from 'ecto-common/lib/lang/Language';
import {
  ByteOrder,
  SignalModbusConfigResponseModel,
  SignalModbusConfigTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';

export enum SignalModbusConfigMode {
  MASTER = 'signalModbusConfigMaster',
  SLAVE = 'signalModbusConfigSlave'
}

export enum ModbusType {
  INPUT_REGISTER = 'input register',
  HOLDING_REGISTER = 'holding register',
  DISCRETE_INPUT = 'discrete input',
  COIL = 'coil'
}

export const RTUParity = Object.freeze({
  NONE: 'N',
  EVEN: 'E',
  ODD: 'O'
});

export const ModbusTypeTranslations = Object.freeze({
  [ModbusType.INPUT_REGISTER]: T.admin.modbussignal.inputregister,
  [ModbusType.HOLDING_REGISTER]: T.admin.modbussignal.holdingregister,
  [ModbusType.DISCRETE_INPUT]: T.admin.modbussignal.discreteinput,
  [ModbusType.COIL]: T.admin.modbussignal.coil
});

export const ModbusConnectionType = Object.freeze({
  TCP: 'TCP',
  RTU: 'RTU'
});

export enum ModbusDataTypeExpanded {
  INT1 = 'int1',
  INT2 = 'int2',
  INT4 = 'int4',
  UINT1 = 'uint1',
  UINT2 = 'uint2',
  UINT4 = 'uint4',
  BITMASK = 'bitmask',
  REAL = 'real'
}

// When serializing, we collapse bitmask enum to int. When editing, we use bitmask
// instead.
export type SignalModbusConfigTemplateWithBitmaskResponseModel = (
  | Omit<SignalModbusConfigTemplateResponseModel, 'datatype'>
  | Omit<SignalModbusConfigResponseModel, 'datatype'>
) & {
  datatype: ModbusDataTypeExpanded;
};

export enum ModbusReadwriteType {
  READ = 'Read',
  WRITE = 'Write'
}

export const ModbusReadwriteTypeTranslations = Object.freeze({
  [ModbusReadwriteType.READ]: T.admin.modbussignal.read,
  [ModbusReadwriteType.WRITE]: T.admin.modbussignal.write
});

export const ModbusDataTypeTranslations = Object.freeze({
  [ModbusDataTypeExpanded.INT1]: T.admin.modbussignal.datatype.int16,
  [ModbusDataTypeExpanded.INT2]: T.admin.modbussignal.datatype.int32,
  [ModbusDataTypeExpanded.INT4]: T.admin.modbussignal.datatype.int64,
  [ModbusDataTypeExpanded.UINT1]: T.admin.modbussignal.datatype.uint16,
  [ModbusDataTypeExpanded.UINT2]: T.admin.modbussignal.datatype.uint32,
  [ModbusDataTypeExpanded.UINT4]: T.admin.modbussignal.datatype.uint64,
  [ModbusDataTypeExpanded.BITMASK]: T.admin.modbussignal.datatype.bitmask,
  [ModbusDataTypeExpanded.REAL]: T.admin.modbussignal.datatype.real
});

export const ModbusByteorderTranslations: Record<string, string> = {
  [ByteOrder.Abcd]: T.admin.modbussignal.byteorder.abcd,
  [ByteOrder.Badc]: T.admin.modbussignal.byteorder.badc,
  [ByteOrder.Cdab]: T.admin.modbussignal.byteorder.cdab,
  [ByteOrder.Dcba]: T.admin.modbussignal.byteorder.dcba
};
