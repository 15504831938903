import React from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './EditComfortDeployTools.module.css';
import EditComfortDeployTools from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortDeployTools';
import { ComfortHeatingProviderResponseModel } from 'ecto-common/lib/API/APIGen';

interface EditComfortDeployDialogProps {
  deviceStatusReloadTrigger?: number;
  isOpen?: boolean;
  tools?: ComfortHeatingProviderResponseModel[];
  onModalClose: () => void;
}

const EditComfortDeployDialog = ({
  isOpen,
  tools,
  onModalClose,
  deviceStatusReloadTrigger
}: EditComfortDeployDialogProps) => {
  return (
    <ActionModal
      isOpen={isOpen}
      disableCancel
      onModalClose={onModalClose}
      title={T.admin.comfort.deploydialogtitle}
      actionText={T.common.done}
      onConfirmClick={onModalClose}
      headerIcon={Icons.Deploy}
      className={styles.dialog}
    >
      <div />
      <EditComfortDeployTools
        deviceStatusReloadTrigger={deviceStatusReloadTrigger}
        tools={tools}
      />
    </ActionModal>
  );
};

export default EditComfortDeployDialog;
