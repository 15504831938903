import React, { useEffect, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import NotificationTable from './NotificationTable';
import HelpPaths from 'ecto-common/help/tocKeys';

const Notifications = () => {
  const [searchString, setSearchString] = useState<string>(null);
  const [editItemId, setEditItemId] = useSearchParamState(
    'add-edit-notification',
    null
  );

  useEffect(() => {
    document.title = T.admin.notifications.title;
  }, []);

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      title={T.admin.notifications.title}
      onSearchInput={setSearchString}
      searchString={searchString}
      addAction={() => setEditItemId('new')}
      addActionTitle={T.admin.notifications.addnotification}
      helpPath={HelpPaths.docs.admin.manage.notifications}
    >
      <NotificationTable
        editItemId={editItemId}
        setEditItemId={setEditItemId}
        searchString={searchString}
      />
    </ToolbarContentPage>
  );
};

export default Notifications;
