import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import Grid from 'ecto-common/lib/Grid/Grid';
import EditIotDeviceNetworkStatusHeader from 'js/components/EnergyManagers/EditIotDeviceNetworkStatusHeader';
import {
  DefaultWiredNetworkInterfaceModel,
  getHardwareWiredSettings,
  WiredInterface
} from 'js/components/EnergyManagers/Models/HardwareSettingsModel';
import styles from 'js/components/EnergyManagers/EditIoTDevice.module.css';
import { IoTDeviceSettingsResponseModel } from 'ecto-common/lib/API/APIGen';

const WiredNetworkSection = ({
  wiredInterface,
  formState,
  onUpdateInput
}: {
  wiredInterface: WiredInterface;
  formState: IoTDeviceSettingsResponseModel;
  onUpdateInput: (dataKey: string[], value: unknown) => void;
}) => {
  return (
    <>
      <EditIotDeviceNetworkStatusHeader
        header={
          T.admin.iotdevicedetails.wiredconnection + ` (${wiredInterface})`
        }
        isConnected={
          formState?.reportedSystem?.defaultNetworkInterface === wiredInterface
        }
      />

      <div className={styles.adjustedSection}>
        <div>
          <ModelForm
            models={DefaultWiredNetworkInterfaceModel(wiredInterface)}
            onUpdateInput={onUpdateInput}
            input={formState}
          />
        </div>

        <div className={styles.network}>
          <ModelForm
            models={getHardwareWiredSettings(wiredInterface)}
            onUpdateInput={onUpdateInput}
            input={formState}
          >
            {(renderChildModel) => (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) =>
                      formInput.desiredSettings[wiredInterface].type
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) =>
                      formInput.reportedSettings[wiredInterface].type
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) =>
                      formInput.desiredSettings[wiredInterface].ipAddress
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) =>
                      formInput.reportedSystem[wiredInterface].ipAddress
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) =>
                      formInput.desiredSettings[wiredInterface].gateway
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) =>
                      formInput.reportedSystem[wiredInterface].gateway
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) =>
                      formInput.desiredSettings[wiredInterface].mask
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderChildModel(
                    (formInput) => formInput.reportedSystem[wiredInterface].mask
                  )}
                </Grid>
              </Grid>
            )}
          </ModelForm>
        </div>
      </div>
    </>
  );
};

export default WiredNetworkSection;
