import React, { Fragment, useContext } from 'react';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { findInTree } from 'ecto-common/lib/utils/locationUtils';
import styles from './InitEquipmentTemplate.module.css';
import Heading from 'ecto-common/lib/Heading/Heading';
import Button from 'ecto-common/lib/Button/Button';
import EditButton from 'ecto-common/lib/Button/EditButton';
import ModbusConnectionDialog from 'js/components/EditBuildingData/ModbusConnectionDialog';
import { handleModbusConnectionChange } from 'js/components/ModbusLayout/ModbusEditUtils';
import SelectDeviceDialog from 'js/components/EditLocation/SelectDeviceDialog';

import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import ModalInnerHeader from 'ecto-common/lib/Modal/ModalInnerHeader';
import {
  getEquipmentName,
  isEquipmentEnergyManager
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { EquipmentTemplateFormActions } from 'js/modules/equipmentTemplateForm/equipmentTemplateForm';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import {
  EquipmentResponseModel,
  EquipmentTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { EquipmentTemplateInstanceOverrideRequestModel } from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';

interface SelectEquipmentTemplateProps {
  parentLocation: SingleGridNode;
}

const SelectEquipmentTemplate = ({
  parentLocation
}: SelectEquipmentTemplateProps) => {
  const nodeTree = useAdminSelector((state) => state.general.nodeTree);
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const equipmentGroupTemplates = useAdminSelector(
    (state) => state.admin.equipmentGroupTemplates
  );
  const existingEnergyManagerNodeId = useAdminSelector(
    (state) => state.equipmentTemplateForm.existingEnergyManagerNodeId
  );
  const equipmentTemplateGroupId = useAdminSelector(
    (state) => state.equipmentTemplateForm.equipmentTemplateGroupId
  );
  const showDeviceDialog = useAdminSelector(
    (state) => state.equipmentTemplateForm.showDeviceDialog
  );
  const equipmentTemplateInstanceOverrides = useAdminSelector(
    (state) => state.equipmentTemplateForm.equipmentTemplateInstanceOverrides
  );
  const connectionModbusConfigOverride = useAdminSelector(
    (state) => state.equipmentTemplateForm.connectionModbusConfigOverride
  );
  const showConnectionDialog = useAdminSelector(
    (state) => state.equipmentTemplateForm.showConnectionDialog
  );
  const dispatch = useAdminDispatch();
  const { contextSettings } = useContext(TenantContext);

  const initTemplate = (
    _equipmentTemplateGroupId: string,
    _existingEnergyManagerNodeId: string
  ) => {
    const equipmentGroupTemplate = equipmentGroupTemplates.find(
      (b) => b.equipmentTemplateGroupId === _equipmentTemplateGroupId
    );

    let _connectionModbusConfigOverride = connectionModbusConfigOverride;
    if (
      !_existingEnergyManagerNodeId &&
      equipmentGroupTemplate &&
      equipmentGroupTemplate.connectionModbusConfig
    ) {
      _connectionModbusConfigOverride = _.cloneDeep(
        equipmentGroupTemplate.connectionModbusConfig
      );
      delete _connectionModbusConfigOverride.id;
    }

    let _equipmentTemplateInstanceOverrides =
      equipmentTemplateInstanceOverrides;
    if (equipmentGroupTemplate) {
      _equipmentTemplateInstanceOverrides = (
        equipmentGroupTemplate.equipmentTemplates || []
      ).map((e) => ({
        enabled: true,
        equipmentTemplateId: e.id
      }));
    }

    dispatch(
      EquipmentTemplateFormActions.setConnectionModbusConfigOverride(
        _connectionModbusConfigOverride
      )
    );
    dispatch(
      EquipmentTemplateFormActions.setEquipmentTemplateOverrides(
        _equipmentTemplateInstanceOverrides
      )
    );
    dispatch(
      EquipmentTemplateFormActions.setEquipmentTemplateGroupId(
        _equipmentTemplateGroupId
      )
    );
  };

  const renderConnections = () => {
    return (
      <Fragment>
        <ModbusConnectionDialog
          selectedConnection={connectionModbusConfigOverride}
          onChangeProperty={onChangeConnectionProperty}
          onModalClose={() =>
            dispatch(EquipmentTemplateFormActions.showConnectionDialog(false))
          }
          isOpen={showConnectionDialog}
        />
        <EditButton
          onClick={() =>
            dispatch(EquipmentTemplateFormActions.showConnectionDialog(true))
          }
        >
          {' '}
          {T.admin.modbusconnection.titleformat}{' '}
        </EditButton>
      </Fragment>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeConnectionProperty = (key: string, value: any) => {
    const _connectionModbusConfigOverride = _.cloneDeep(
      connectionModbusConfigOverride
    );
    handleModbusConnectionChange(_connectionModbusConfigOverride, key, value);
    dispatch(
      EquipmentTemplateFormActions.setConnectionModbusConfigOverride(
        _connectionModbusConfigOverride
      )
    );
  };

  const _handleSelectedEnergyManagerChanged = (
    selectedEm: EquipmentResponseModel
  ) => {
    dispatch(
      EquipmentTemplateFormActions.setExistingEnergyManagerDeviceId(null)
    );

    if (selectedEm) {
      const _existingEnergyManagerNodeId = selectedEm.equipmentId;
      dispatch(
        EquipmentTemplateFormActions.setExistingEnergyManagerNodeId(
          contextSettings,
          _existingEnergyManagerNodeId
        )
      );
    } else {
      dispatch(
        EquipmentTemplateFormActions.setExistingEnergyManagerNodeId(
          contextSettings,
          null
        )
      );

      initTemplate(equipmentTemplateGroupId, null);
    }
  };

  const onEquipmentChange = (
    idx: number,
    property: keyof EquipmentTemplateInstanceOverrideRequestModel,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    const _equipmentTemplateInstanceOverrides = _.cloneDeep(
      equipmentTemplateInstanceOverrides
    );
    _.set(_equipmentTemplateInstanceOverrides, [idx, property], value);
    dispatch(
      EquipmentTemplateFormActions.setEquipmentTemplateOverrides(
        _equipmentTemplateInstanceOverrides
      )
    );
  };

  const renderEquipmentConfiguration = () => {
    const equipmentGroupTemplate =
      equipmentGroupTemplates &&
      equipmentGroupTemplates.find(
        (b) => b.equipmentTemplateGroupId === equipmentTemplateGroupId
      );

    if (equipmentGroupTemplate == null) {
      return <div />;
    }

    const eqTypeCounter: Record<string, number> = {};
    const equipmentNames: string[] = [];

    equipmentGroupTemplate.equipmentTemplates.forEach(
      (equipment: EquipmentTemplateResponseModel, idx: number) => {
        if (equipmentTemplateInstanceOverrides[idx].enabled) {
          const value = Object.prototype.hasOwnProperty.call(
            eqTypeCounter,
            equipment.equipmentTypeId
          )
            ? eqTypeCounter[equipment.equipmentTypeId] + 1
            : 1;
          eqTypeCounter[equipment.equipmentTypeId] = value;

          if (isEquipmentEnergyManager(equipment, equipmentTypes)) {
            const name = getEquipmentName(
              equipment.equipmentTypeId,
              equipmentTypes
            );
            equipmentNames.push(equipment?.name ?? name);
          } else {
            const eqName =
              getEquipmentName(equipment.equipmentTypeId, equipmentTypes) +
              ' ' +
              value;
            equipmentNames.push(equipment.name || eqName);
          }
        } else {
          equipmentNames.push('');
        }
      }
    );

    const columns: DataTableColumnProps<EquipmentTemplateResponseModel>[] = [
      {
        label: T.admin.createlocation.equipment.name,
        dataKey: '_unused0',

        dataFormatter: (_key, _obj, idx) => {
          return (
            <TextInput
              value={equipmentTemplateInstanceOverrides[idx].name}
              disabled={!equipmentTemplateInstanceOverrides[idx].enabled}
              onChange={(e) => onEquipmentChange(idx, 'name', e.target.value)}
              placeholder={equipmentNames[idx]}
            />
          );
        }
      },
      {
        label: T.admin.createlocation.equipment.type,
        dataKey: 'equipmentTypeId',

        dataFormatter: (equipmentTypeId: string) => {
          return getEquipmentName(equipmentTypeId, equipmentTypes);
        }
      },
      {
        label: T.admin.createlocation.equipment.enable,
        dataKey: '_unused1',
        align: HorizontalAlignments.CENTER,
        width: 70,
        flexGrow: 0,
        dataFormatter: (_key, _obj, idx) => {
          return (
            <Checkbox
              onChange={(checked) => onEquipmentChange(idx, 'enabled', checked)}
              checked={equipmentTemplateInstanceOverrides[idx].enabled}
            />
          );
        }
      }
    ];

    return (
      <Fragment>
        <ModalInnerHeader>
          <Heading level={4}>
            {T.admin.createlocation.configurequipment.title}
          </Heading>
        </ModalInnerHeader>

        <DataTable<EquipmentTemplateResponseModel>
          columns={columns}
          className={styles.equipmentTable}
          data={equipmentGroupTemplate.equipmentTemplates}
        />
      </Fragment>
    );
  };

  const equipmentGroupTemplate =
    equipmentGroupTemplates &&
    equipmentGroupTemplates.find(
      (b) => b.equipmentTemplateGroupId === equipmentTemplateGroupId
    );

  let templateOptions: GenericSelectOption<string>[] = [
    {
      value: '',
      label: T.admin.createlocation.none
    }
  ];

  //
  if (equipmentGroupTemplates) {
    templateOptions = templateOptions.concat(
      equipmentGroupTemplates.map((template) => ({
        value: template.equipmentTemplateGroupId,
        label: (
          <div>
            <Icons.File withRightMargin />
            {template.name}
          </div>
        )
      }))
    );
  }

  const optionMatchValue =
    equipmentTemplateGroupId == null ? '' : equipmentTemplateGroupId;
  let selectedOption = templateOptions.find(
    (x) => x.value === optionMatchValue
  );

  if (!selectedOption) {
    selectedOption = templateOptions[0];
  }

  let deviceInfoText = T.admin.createlocation.createnewdevice;

  if (existingEnergyManagerNodeId) {
    const parent = findInTree(
      nodeTree,
      (n) =>
        (n as SingleGridNode).equipments != null &&
        (n as SingleGridNode).equipments.find(
          (eq) => eq.equipmentId === existingEnergyManagerNodeId
        ) != null
    );
    if (parent) {
      deviceInfoText = parent.name;
    }
  }

  const hasValidConnectionState = equipmentGroupTemplate != null;

  return (
    <Fragment>
      <ModalInnerHeader>
        <Heading level={4}>{T.admin.createlocation.choosetemplate}</Heading>
      </ModalInnerHeader>
      <div className={styles.configContainer}>
        <label className={styles.topLabel}>
          {T.admin.createlocation.equipmentgrouptemplate}
        </label>
        <div className={styles.selectContainer}>
          <Select
            isSearchable={false}
            name="form-field-name"
            maxMenuHeight={200}
            value={selectedOption}
            menuPlacement="auto"
            onChange={(val: GenericSelectOption) =>
              initTemplate(
                val.value === '' ? null : val.value,
                existingEnergyManagerNodeId
              )
            }
            options={templateOptions}
          />
        </div>
      </div>
      {equipmentGroupTemplate && (
        <div className={styles.configBlock}>
          <div className={styles.configContainer}>
            <label className={styles.topLabel}>
              {T.admin.createlocation.devicelabel}
            </label>
            <div className={styles.topValue}>
              <span>
                <strong>{deviceInfoText}</strong>
              </span>
              <Button
                disabled={!hasValidConnectionState}
                onClick={() =>
                  dispatch(EquipmentTemplateFormActions.showDeviceDialog(true))
                }
              >
                <Icons.Connect />
                {T.admin.createlocation.selectexistingdevice}
              </Button>
            </div>
          </div>
        </div>
      )}
      {equipmentGroupTemplate && renderConnections()}
      {renderEquipmentConfiguration()}

      <SelectDeviceDialog
        onModalClose={() =>
          dispatch(EquipmentTemplateFormActions.showDeviceDialog(false))
        }
        isOpen={showDeviceDialog}
        initialNode={parentLocation}
        selectedEnergyManagerEquipmentId={existingEnergyManagerNodeId}
        onChangedSelectedEnergyManager={_handleSelectedEnergyManagerChanged}
        onConfirmClick={() =>
          dispatch(EquipmentTemplateFormActions.showDeviceDialog(false))
        }
      />
    </Fragment>
  );
};

export default React.memo(SelectEquipmentTemplate);
