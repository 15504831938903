import React, { Fragment, useCallback, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import Heading from 'ecto-common/lib/Heading/Heading';
import T from 'ecto-common/lib/lang/Language';
import TextInput from 'ecto-common/lib/TextInput/TextInput';

import styles from '../DialogStyles.module.css';
import SignalTypePicker from 'ecto-common/lib/SignalTypePicker/SignalTypePicker';
import { SignalModbusConfigTemplateWithBitmaskResponseModel } from 'js/components/ModbusLayout/ModbusTypes';

export const toNum = (str: string, defaultVal: number) => {
  if (str != null) {
    const ret = Number(str.replace(',', '.'));
    return isNaN(ret) ? defaultVal : ret;
  }

  return defaultVal;
};

interface SectionHeaderProps {
  title: string;
}

export const SectionHeader = ({ title }: SectionHeaderProps) => {
  return (
    <div className={styles.gridItemHeader}>
      <Heading level={4}>{title}</Heading>
    </div>
  );
};

interface SectionItemProps {
  title: string;
  children?: React.ReactNode;
  className?: string;
  htmlFor?: string;
  isDoubleWidth?: boolean;
}

export const SectionItem = ({
  title,
  children,
  className,
  htmlFor,
  isDoubleWidth
}: SectionItemProps) => {
  return (
    <div
      className={classNames(
        styles.gridItem,
        isDoubleWidth && styles.doubleGridItem,
        className
      )}
    >
      <label htmlFor={htmlFor}>{title}</label>
      {children}
    </div>
  );
};

interface ModbusBoolInputProps {
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  title: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
  children?: React.ReactNode;
}

export const ModbusBoolInput = ({
  modbus,
  title,
  name,
  children,
  onChangeProperty
}: ModbusBoolInputProps) => {
  const value = _.get(modbus, name);
  return (
    <Fragment>
      <SectionHeader title={title} />
      <SectionItem title={title}>
        <select
          value={value ? 'enabled' : 'disabled'}
          onChange={(e) => onChangeProperty(name, e.target.value === 'enabled')}
        >
          <option value="disabled">{T.common.disabled}</option>
          <option value="enabled">{T.common.enabled}</option>
        </select>
      </SectionItem>
      {value && <Fragment>{children}</Fragment>}
    </Fragment>
  );
};

interface ModbusNumberInputProps {
  title: string;
  name: string;
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
  placeholder?: string;
}

export const ModbusNumberInput = ({
  title,
  name,
  modbus,
  onChangeProperty,
  disabled,
  placeholder
}: ModbusNumberInputProps) => {
  const value = _.get(modbus, name);

  const toStrObj = (obj: object, defaultVal: string) => {
    return obj != null ? obj.toString().replace(',', '.') : defaultVal;
  };

  // Use a temporary value to keep "0." from being directly parsed to 0 and then back to "0" in the input field.
  // Then, once the focus leaves the input field we confirm the value.
  const [tempValue, setTempValue] = useState<number>(value);

  const confirmValue = useCallback(() => {
    onChangeProperty(name, tempValue);
  }, [tempValue, onChangeProperty, name]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = e.target.value ? toNum(e.target.value, null) : null;
      setTempValue(newValue);
    },
    [setTempValue]
  );

  return (
    <SectionItem title={title}>
      <TextInput
        value={toStrObj(value, '')}
        onBlur={confirmValue}
        onChange={onChange}
        disabled={disabled}
        wrapperClassName={styles.gridInputField}
        placeholder={placeholder}
      />
    </SectionItem>
  );
};

interface ModbusSignalTypeInputProps {
  name: string;
  title: string;
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
}

export const ModbusSignalTypeInput = ({
  title,
  name,
  modbus,
  onChangeProperty
}: ModbusSignalTypeInputProps) => {
  const value = _.get(modbus, name);

  return (
    <SectionItem title={title} isDoubleWidth>
      <div className={styles.doubleGridItemValue}>
        <SignalTypePicker
          value={value}
          onChange={(val) => onChangeProperty(name, val)}
        />
      </div>
    </SectionItem>
  );
};

interface ModbusSelectProps {
  title: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  values: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
}

export const ModbusSelect = ({
  title,
  name,
  value,
  values,
  modbus,
  onChangeProperty
}: ModbusSelectProps) => {
  const modbusValue = value ? value : _.get(modbus, name);
  const selectValue = _.isFunction(modbusValue) ? modbusValue() : modbusValue;
  return (
    <SectionItem title={title}>
      <select
        value={selectValue}
        onChange={(e) => onChangeProperty(name, e.target.value)}
      >
        {Object.entries(values).map((item, index) => (
          <option key={index} value={item[0]}>
            {item[1]}
          </option>
        ))}
      </select>
    </SectionItem>
  );
};
