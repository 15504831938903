import React, { useMemo } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import SegmentedContentView from 'ecto-common/lib/SegmentControl/SegmentedContentView';
import ManageTemperatureImpact from 'js/components/EditLocation/Tools/ManageTemperatureImpact';
import ManageComfortTools from 'js/components/EditLocation/Tools/ManageComfortTools';
import styles from './ManageNodeTools.module.css';

interface ManageNodeToolsProps {
  nodeId?: string;
  isOpen?: boolean;
  onClose?(): void;
}

const ManageNodeTools = ({ isOpen, onClose, nodeId }: ManageNodeToolsProps) => {
  const sections = useMemo(() => {
    return [
      {
        title: T.admin.editlocation.tools.comfort.title,
        view: <ManageComfortTools nodeId={nodeId} />
      },
      {
        title: T.admin.editlocation.tools.temperatureimpact,
        view: <ManageTemperatureImpact nodeId={nodeId} />
      }
    ];
  }, [nodeId]);

  return (
    <ActionModal
      title={T.admin.editlocation.edittools}
      headerIcon={Icons.Tool}
      isOpen={isOpen}
      onModalClose={() => {
        onClose();
      }}
      onConfirmClick={onClose}
      className={styles.dialog}
    >
      <SegmentedContentView sections={sections} />
    </ActionModal>
  );
};

export default React.memo(ManageNodeTools);
