import EditMeteorologicalPoint from 'js/components/Meteorology/EditMeteorologicalPoint';
import { ItemPageParams } from 'js/utils/routeConstants';
import React from 'react';
import { useParams } from 'react-router-dom';
import ManageMeteorology from './ManageMeteorology';

const MeteorologyPage = () => {
  const params = useParams<ItemPageParams>();
  return params.itemId ? <EditMeteorologicalPoint /> : <ManageMeteorology />;
};

export default React.memo(MeteorologyPage);
