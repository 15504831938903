export enum IoTQueryTypes {
  UNPAIRED = 'Unpaired',
  PAIRED = 'Paired',
  ALL = 'All'
}

export enum IoTStatusQueryTypes {
  ALL = 'All',
  ONLINE = 'Online',
  OFFLINE = 'Offline'
}
