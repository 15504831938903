import React, { useContext, useMemo } from 'react';
import { mapReqStateToProp } from 'ecto-common/lib/utils/requestStatus';
import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { ProvisionDeviceActions } from 'js/modules/provisionDevice/provisionDevice';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { IoTDeviceViewResponseModel } from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface RelinkDialogProps {
  iotDevice?: IoTDeviceViewResponseModel;
}

/**
 * Dialog for relinking device for the current energy manager to a new iot device
 */
const RelinkDialog = ({ iotDevice }: RelinkDialogProps) => {
  const dispatch = useAdminDispatch();
  const pendingNewIotDevice = useAdminSelector(
    (state) => state.provisionDevice.pendingNewIotDevice
  );
  const { contextSettings } = useContext(TenantContext);

  const pairRequestReq = useAdminSelector(
    (state) => state.provisionDevice.pairRequest
  );
  const pairRequest = useMemo(() => {
    return mapReqStateToProp(pairRequestReq);
  }, [pairRequestReq]);

  let body;
  let footer = null;

  if (!pendingNewIotDevice) {
    body = <span />;
  } else if (iotDevice == null) {
    body = T.format(
      T.admin.energymanager.pairwithdeviceformat,
      pendingNewIotDevice.deviceName
    );
  } else {
    body = T.format(
      T.admin.energymanager.pairwithotherdevicerelinkformat,
      iotDevice.ioTDevice.deviceName,
      pendingNewIotDevice.deviceName
    );
    footer = (
      <ModalFooter>
        <ModalSpace />
        <Button
          onClick={() =>
            dispatch(
              ProvisionDeviceActions.confirmNewDevice(contextSettings, true)
            )
          }
          disabled={pairRequest.isLoading}
        >
          <Icons.Copy />
          {T.admin.energymanager.pairwithdeviceandcopybutton}
        </Button>
        <Button
          onClick={() =>
            dispatch(
              ProvisionDeviceActions.confirmNewDevice(contextSettings, false)
            )
          }
          disabled={pairRequest.isLoading}
        >
          <Icons.Connect />
          {T.admin.energymanager.pairwithdevicebutton}
        </Button>
        <GreyButton
          onClick={() =>
            dispatch(ProvisionDeviceActions.setNewDeviceToConfirm(null))
          }
          disabled={pairRequest.isLoading}
        >
          {T.common.actionmodal.cancel}
        </GreyButton>
      </ModalFooter>
    );
  }

  return (
    <ActionModal
      isOpen={pendingNewIotDevice != null}
      onModalClose={() =>
        dispatch(ProvisionDeviceActions.setNewDeviceToConfirm(null))
      }
      title={T.admin.energymanager.pairwithdevicetitle}
      isLoading={pairRequest.isLoading}
      onConfirmClick={() =>
        dispatch(ProvisionDeviceActions.confirmNewDevice(contextSettings, true))
      }
      footer={footer}
    >
      {body}
      <span />
    </ActionModal>
  );
};

export default React.memo(RelinkDialog);
