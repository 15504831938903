import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import {
  EditState,
  EditStateData
} from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import EditComfortDialog from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortDialog';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { EMPTY_INPUT } from 'js/components/ManageEquipment/EditEquipment/Comfort/useComfortTools';
import _ from 'lodash';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import EditComfortDeployDialog from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortDeployDialog';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import {
  ToolSignalProviders,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import UUID from 'uuidjs';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import APIGen, {
  AddOrUpdateAlarmSignalResponseModel,
  AddOrUpdateComfortToolSetResponseModel,
  AddOrUpdateEquipmentSignalResponseModel,
  ComfortHeatingProviderResponseModel,
  CreateComfortHeatingByTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const EmptyComfortParameters = {
  id: undefined as string
};

// Sort the input arrays so that they are shown in a consistent order in the UI.
export const sortComfortParameterArrays = (
  parameters: CreateComfortHeatingByTemplateResponseModel
): CreateComfortHeatingByTemplateResponseModel => {
  const comfortToolSet =
    parameters?.addOrUpdateComfortHeatingProvider?.addOrUpdateComfortToolSet;

  if (comfortToolSet == null) {
    return parameters;
  }
  comfortToolSet.alarmConfigurations = _(comfortToolSet.alarmConfigurations)
    .sortBy('comfortAlarmType')
    .map((alarmConfig) => {
      // Unfortunately the best we have to sort by is the ID of the signal. At least it is consistent.
      alarmConfig.inputSignalConfigurations = _.sortBy(
        alarmConfig.inputSignalConfigurations,
        'id'
      );
      return alarmConfig;
    })
    .value();

  return parameters;
};

export const saveAndFetchComfortTools = (
  contextSettings: ApiContextSettings,
  tools: CreateComfortHeatingByTemplateResponseModel[]
) => {
  return APIGen.AdminComfort.addOrUpdateComfortHeatingByTemplates
    .promise(contextSettings, tools, null)
    .then((results) => {
      const providerIds = _.map(
        results,
        'addOrUpdateComfortHeatingProvider.providerId'
      );
      return APIGen.AdminComfort.getComfortHeatingProvidersByProviderId.promise(
        contextSettings,
        { providerIds },
        null
      );
    });
};

interface EditComfortToolProps {
  setEditState: (toolType: ToolSignalProviders, data: EditStateData) => void;
  editState?: EditStateData;
  nodeId?: string;
  equipmentId?: string;
  reloadTools?(): void;
}

type ComfortEqOrAdminSignal =
  | AddOrUpdateEquipmentSignalResponseModel
  | AddOrUpdateAlarmSignalResponseModel;
export type ComfortEqOrAdminSignalWithSignalProviderName =
  ComfortEqOrAdminSignal & {
    signalProviderName: string;
  };

const EditComfortTool = ({
  setEditState,
  editState,
  nodeId,
  equipmentId,
  reloadTools
}: EditComfortToolProps) => {
  const [showingDeployDialog, showDeployDialog, hideDeployDialog] =
    useSimpleDialogState();
  const [showingEditDialog, showEditDialog, hideEditDialog] =
    useSimpleDialogState();

  const [askToDeployItem, setAskToDeployItem] =
    useState<ComfortHeatingProviderResponseModel>(null);
  const [item, setItem] = useState<ComfortHeatingProviderResponseModel>(null);
  const [deleteItem, setDeleteItem] =
    useState<ComfortHeatingProviderResponseModel>(null);
  const [isValidItem, setIsValidItem] = useState(false);
  const [editParameters, setEditParameters] =
    useState<CreateComfortHeatingByTemplateResponseModel>(null);
  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const equipment = getNodeFromMap(equipmentMap, equipmentId);
  const [reloadTrigger, triggerReload] = useReloadTrigger();

  // EditComfortParameters changes a sub structure within editParameters. Whenever it performs a change we
  // update a separate object in order to keep track of the changes and to avoid re-initializing of state.
  const [editedComfortToolsetParameters, setEditedComfortToolsetParameters] =
    useState<AddOrUpdateComfortToolSetResponseModel>(null);

  const cancelEditParameters = useCallback(() => {
    setEditParameters(null);
  }, []);

  const edit = useCallback(
    (comfortToolItem: ComfortHeatingProviderResponseModel) => {
      cancelEditParameters();
      setItem({ ...comfortToolItem });
      setEditedComfortToolsetParameters(null);
      setIsValidItem(true);
    },
    [cancelEditParameters, setItem]
  );

  const getParametersMutation =
    APIGen.AdminComfort.getAddOrUpdateComfortHeatingByTemplateRequestModel.useMutation(
      {
        onSuccess: (parameters) => {
          setEditedComfortToolsetParameters(null);
          setEditParameters(sortComfortParameterArrays(_.head(parameters)));
        },
        onError: () => {
          toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
        }
      }
    );

  const { contextSettings } = useContext(TenantContext);

  const saveToolMutation = useMutation({
    mutationFn: (args: CreateComfortHeatingByTemplateResponseModel) =>
      saveAndFetchComfortTools(contextSettings, [args]),

    onSuccess: (newProviders) => {
      setAskToDeployItem(_.head(newProviders));
      hideEditDialog();
      setEditState(ToolSignalProviders.COMFORT, {
        state: EditState.IDLE,
        data: null
      });
      reloadTools();
      triggerReload();
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
    }
  });

  const isEditingParameters = editParameters !== null;

  const save = useCallback(() => {
    if (isEditingParameters) {
      const objectToSave = _.cloneDeep(editParameters);
      objectToSave.addOrUpdateComfortHeatingProvider.addOrUpdateComfortToolSet =
        editedComfortToolsetParameters;
      saveToolMutation.mutate(objectToSave);
    } else {
      getParametersMutation.mutate([item]);
    }
  }, [
    isEditingParameters,
    editParameters,
    editedComfortToolsetParameters,
    saveToolMutation,
    getParametersMutation,
    item
  ]);

  const onEditCancelled = useCallback(() => {
    setEditState(ToolSignalProviders.COMFORT, {
      state: EditState.IDLE,
      data: null
    });
    hideEditDialog();
    hideDeployDialog();
  }, [setEditState, hideDeployDialog, hideEditDialog]);

  const deleteProviderId = deleteItem?.providerId;
  const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);

  const confirmRemove = useCallback(
    (data: ComfortHeatingProviderResponseModel) => {
      setDeleteItem(data);
      setConfirmRemoveVisible(true);
    },
    [setConfirmRemoveVisible]
  );

  const cancelConfirmRemove = useCallback(() => {
    setConfirmRemoveVisible(false);
    setDeleteItem(null);
    setEditState(ToolSignalProviders.COMFORT, {
      state: EditState.IDLE,
      data: null
    });
  }, [setConfirmRemoveVisible, setEditState]);

  const deleteToolMutation =
    APIGen.AdminComfort.deleteComfortHeatingProviders.useMutation({
      onSuccess: () => {
        toastStore.addSuccessToast(T.admin.equipment.request.updatedtool);
        setEditState(ToolSignalProviders.COMFORT, {
          state: EditState.IDLE,
          data: null
        });
        reloadTools();
        setConfirmRemoveVisible(false);
        setDeleteItem(null);
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
      }
    });

  const onDelete = useCallback(
    () => deleteToolMutation.mutate({ providerIds: [deleteProviderId] }),
    [deleteProviderId, deleteToolMutation]
  );

  useEffect(() => {
    switch (editState.state) {
      case EditState.IDLE:
        setItem(null);
        setIsValidItem(false);
        break;
      case EditState.EDIT:
        edit(editState.data as unknown as ComfortHeatingProviderResponseModel);
        showEditDialog();
        break;
      case EditState.DEPLOY:
        edit(editState.data as unknown as ComfortHeatingProviderResponseModel);
        showDeployDialog();
        break;
      case EditState.CREATE:
        edit({
          ...EMPTY_INPUT,
          nodeId,
          equipmentId,
          desiredTemperatureSignalId: undefined,
          providerId: UUID.generate()
        });
        showEditDialog();
        break;
      case EditState.DELETE:
        confirmRemove(
          editState.data as unknown as ComfortHeatingProviderResponseModel
        );
        break;
      default:
        break;
    }
  }, [
    editState,
    edit,
    nodeId,
    equipmentId,
    confirmRemove,
    showEditDialog,
    showDeployDialog
  ]);

  const comfortToolsetParameters: AddOrUpdateComfortToolSetResponseModel =
    editParameters?.addOrUpdateComfortHeatingProvider
      .addOrUpdateComfortToolSet ?? EmptyComfortParameters;

  const signalData: Record<
    string,
    ComfortEqOrAdminSignalWithSignalProviderName
  > = useMemo(() => {
    const alarmSignals: ComfortEqOrAdminSignalWithSignalProviderName[] = _.map(
      editParameters?.addOrUpdateAlarmSignalProvider?.signals,
      (signal) => ({
        ...signal,
        signalProviderName:
          editParameters.addOrUpdateAlarmSignalProvider?.name ?? ''
      })
    );

    const eqSignals: ComfortEqOrAdminSignalWithSignalProviderName[] = _.map(
      editParameters?.addOrUpdateComfortHeatingProvider
        ?.addOrUpdateComfortToolSet?.addOrUpdateEquipmentSignals,
      (signal) => ({
        ...signal,
        signalProviderName: equipment?.name ?? ''
      })
    );

    return _.keyBy([...alarmSignals, ...eqSignals], 'id');
  }, [editParameters, equipment]);

  const cancelAskToDeployItem = useCallback(() => {
    setAskToDeployItem(null);
  }, []);

  const confirmAskToDeploy = useCallback(() => {
    setAskToDeployItem(null);
    setEditState(ToolSignalProviders.COMFORT, {
      state: EditState.DEPLOY,
      data: askToDeployItem
    });
  }, [askToDeployItem, setEditState]);

  const tools = useMemo(() => [item], [item]);

  return (
    <>
      <EditComfortDeployDialog
        isOpen={item != null && showingDeployDialog}
        onModalClose={onEditCancelled}
        tools={tools}
        deviceStatusReloadTrigger={reloadTrigger}
      />
      <EditComfortDialog
        hideEdit={onEditCancelled}
        editVisible={item != null && showingEditDialog}
        save={save}
        isValidItem={isValidItem}
        item={item}
        setItem={setItem}
        setIsValidItem={setIsValidItem}
        isLoading={
          getParametersMutation.isPending || saveToolMutation.isPending
        }
        comfortToolsetParameters={comfortToolsetParameters}
        onComfortToolsetParametersChanged={setEditedComfortToolsetParameters}
        isEditingParameters={isEditingParameters}
        cancelEditParameters={cancelEditParameters}
        signalData={signalData}
      />
      <ConfirmDeleteDialog
        isOpen={confirmRemoveVisible}
        isLoading={deleteToolMutation.isPending}
        onModalClose={cancelConfirmRemove}
        onDelete={onDelete}
        itemName={ToolSignalProviderTranslations[ToolSignalProviders.COMFORT]}
      />
      <ActionModal
        isOpen={askToDeployItem != null}
        onModalClose={cancelAskToDeployItem}
        title={T.admin.comfort.askdeploytitle}
        onConfirmClick={confirmAskToDeploy}
      >
        {T.admin.comfort.askdeploymessage}
      </ActionModal>
    </>
  );
};

export default React.memo(EditComfortTool);
