import { useEffect, useRef, MutableRefObject } from 'react';

/**
 * This hook is useful for dealing with React 18:s concurrent rendering mode.
 *
 * A typical use case is when you have a modal with input fields, and a background
 * view which is slow to render. If the user types in the input field, and then
 * quickly closes the modal, and performs work on the updated object in the
 * modal close handler, the captured object in the handler might not be updated yet.
 *
 * This is because React can opt to cancel the current render and start a new one
 * due to the immediately succeeding state change. That in turn can mean that the
 * new object is not propagated to the modal close handler.
 *
 * By using this hook you can instead use a reference to the object inside the
 * handler. The effect will make sure that the reference is always up to date with
 * the latest value, regardless of the rendering flow.
 */
function useSyncedRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

export default useSyncedRef;
