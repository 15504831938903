import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import Select, { GenericSelectOption } from '../Select/Select';
import classNames from 'classnames';
// TODO: styling for select error should probably be in a more 'common' place than specific to key value input
import keyValueStyle from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import useNodeTypes from '../hooks/useNodeTypes';

const NodeTypeOptions = ({
  className,
  placeholder,
  nodeTypes,
  onNodeTypesChanged,
  hasError
}: {
  className?: string;
  placeholder?: string;
  nodeTypes?: string[];
  onNodeTypesChanged: (nodeTypes: string[]) => void;
  hasError?: boolean;
}) => {
  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      onNodeTypesChanged(_.map(e, 'value'));
    },
    [onNodeTypesChanged]
  );

  const nodeTypeNames = useNodeTypes();

  const nodeTypesOptions = useMemo(
    () =>
      _.map(nodeTypeNames, (nodeType) => ({
        value: nodeType.type,
        label: nodeType.name
      })),
    [nodeTypeNames]
  );

  const value = useMemo(
    () =>
      nodeTypes?.map((nodeType) => {
        return nodeTypesOptions.find((option) => option.value === nodeType);
      }),
    [nodeTypes, nodeTypesOptions]
  );

  return (
    <Select<GenericSelectOption<string>, true>
      className={classNames(
        hasError ? keyValueStyle.errorSelect : undefined,
        className
      )}
      options={nodeTypesOptions}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      isMulti
    />
  );
};

export default NodeTypeOptions;
