import React from 'react';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { useParams } from 'react-router-dom';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import EditLocation from 'js/components/EditLocation/EditLocation';
import Equipments from 'js/components/Equipments/Equipments';

type EditLocationOrEquipmentProps = {
  onTitleChanged: (title: string[]) => void;
  selectedLocation: SingleGridNode;
};

const EditLocationOrEquipment = ({
  onTitleChanged,
  selectedLocation
}: EditLocationOrEquipmentProps) => {
  const params = useParams<NodeParams>();

  if (params.itemId != null) {
    return (
      <Equipments
        onTitleChanged={onTitleChanged}
        selectedLocation={selectedLocation}
        equipments={(selectedLocation && selectedLocation.equipments) || []}
      />
    );
  }

  return (
    <EditLocation
      onTitleChanged={onTitleChanged}
      selectedLocation={selectedLocation}
    />
  );
};

export default React.memo(EditLocationOrEquipment);
