import { TenantUserModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';

/**
 * Returns a function that can be used to filter user properties.
 * @param searchString
 * @returns {function(*): *} filter function for user
 */
export const filterUsers = (searchString: string) => {
  if (searchString == null) {
    return () => true;
  }

  const lowerCaseSearch = searchString.toLowerCase();
  return (user: TenantUserModel) =>
    user.email.toLowerCase().search(lowerCaseSearch) !== -1 ||
    user.displayName.toLowerCase().search(lowerCaseSearch) !== -1;
};

export const UserAccountTypes = {
  External: 'External',
  KID: 'KID'
} as const;

export type UserAccountType = ObjectValues<typeof UserAccountTypes>;

export const EonKidDomainSuffix = '@eon.com';

export const USER_MAXIMUM_EMAIL_LENGTH = 100;
