import React, { useCallback, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import DeviceAPIGen from 'ecto-common/lib/API/DeviceAPIGen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';

interface AddDeviceModalProps {
  isOpen?: boolean;
  onModalClose: () => void;
}

const AddDeviceModal = ({ isOpen, onModalClose }: AddDeviceModalProps) => {
  const [deviceId, setDeviceId] = useState('');

  const onDeviceIdChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setDeviceId(event.target.value);
    }, []);

  const addDeviceMutation = DeviceAPIGen.Devices.provisionDevice.useMutation({
    onError: (_unused) => {
      toastStore.addErrorToastForUpdatedItem(deviceId, true);
    },
    onSuccess: (_unused) => {
      toastStore.addSuccessToastForUpdatedItem(deviceId, true);
      onModalClose?.();
    }
  });

  const onConfirmClick = useCallback(() => {
    addDeviceMutation.mutate({ deviceId });
  }, [addDeviceMutation, deviceId]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={onConfirmClick}
      actionText={T.common.add}
      title={T.admin.iotdevicedetails.provisiondevice}
      headerIcon={Icons.EnergyManager}
      isLoading={addDeviceMutation.isPending}
    >
      <TextInput
        value={deviceId}
        placeholder={T.admin.energymanagers.devicename}
        onChange={onDeviceIdChange}
        maxLength={500}
      />
    </ActionModal>
  );
};

export default AddDeviceModal;
