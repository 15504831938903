/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  extensions?: Record<string, any>;
  [key: string]: any;
}

export interface AddOrUpdateAccountRequest {
  /**
   * Id of the account.
   * @format guid
   */
  id?: string;
  /** The description of the account. */
  description?: string | null;
  /**
   * The unique username of the account.
   * This will be used for getting the token from Netmore.
   * @minLength 1
   */
  username: string;
  /**
   * The password of the account.
   * A password is mandatory when creating a new account
   * If a password is supplied for an existing account the password will be overridden.
   * This will be used for getting the token from Netmore.
   */
  password?: string | null;
}

export interface PagedResultResponseOfAccountResponse {
  /** Gets the items. */
  items?: AccountResponse[];
  /**
   * Gets the total size of all the entities.
   * @format int32
   */
  totalSize?: number;
}

export interface AccountResponse {
  /**
   * The id of the account.
   * @format guid
   */
  id?: string;
  /** The username of the account. */
  username?: string;
  /** The description of the account. */
  description?: string | null;
  /** The country of the account. */
  country?: string;
}

export interface GetAllAccountsRequest {
  /**
   * If set, specifies the name of the column to sort by.
   * Value is one of:
   *
   *
   *    name
   *
   *
   *    description
   *
   *
   *    userid
   *
   */
  sortColumn?: string | null;
  /**
   * If set, specifies the sort order.
   * Value is one of:
   *
   *
   *  asc
   *
   *
   *  desc
   *
   */
  sortOrder?: string | null;
  /**
   * Page number to return from the results. The value is 0 based.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  pageNumber: number;
  /**
   * Maximum number of items per page.
   * @format int32
   * @min 1
   * @max 100
   */
  pageSize: number;
}

export interface GetAccountsRequest {
  /** A list of ids for the accounts to fetch. */
  accountIds?: string[];
}

export interface AddOrUpdateProviderRequest {
  /**
   * The id of the provider.
   * @format guid
   */
  id?: string;
  /**
   * The id of the account connected this provider.
   * @format guid
   */
  accountId?: string;
  /**
   * The id of the node the provider is connected to.
   * @format guid
   */
  nodeId?: string;
  /**
   * The name of the provider.
   * @minLength 1
   */
  name: string;
  /** The description of the provider. */
  description?: string | null;
  /** The Netmore sensors which the provider should fetch values for. */
  signals?: AddOrUpdateSignalRequest[];
}

export interface AddOrUpdateSignalRequest {
  /**
   * The DevEUI of the sensor.
   * @minLength 1
   */
  devEUI: string;
  /** The serial number of the sensor */
  serialNumber?: string | null;
  /** The description of the sensor */
  description?: string | null;
}

export interface ProviderResponse {
  /**
   * The id of the provider.
   * @format guid
   */
  id?: string;
  /**
   * The id of the node the provider is connected to.
   * @format guid
   */
  nodeId?: string;
  /**
   * The id of the account for the provider.
   * @format guid
   */
  accountId?: string;
  /** The name of the provider. */
  name?: string;
  /** The description of the provider. */
  description?: string | null;
  /**
   * The id of the mean temperature value signal.
   * @format guid
   */
  meanTemperatureSignalId?: string;
  /**
   * The id of the mean humidity value signal.
   * @format guid
   */
  meanHumiditySignalId?: string;
  /** The Netmore signals of the provider. */
  signals?: SignalResponse[];
}

export interface SignalResponse {
  /**
   * The signal id.
   * @format guid
   */
  id?: string;
  /** The devEUI of the sensor. */
  devEUI?: string | null;
  /** The description of the sensor. */
  description?: string | null;
  /** The serial number of the sensor. */
  serialNumber?: string | null;
}

export interface GetProvidersByIdRequest {
  /** A list of ids for the providers to fetch. */
  providerIds?: string[];
}

export interface PagedResultResponseOfProviderResponse {
  /** Gets the items. */
  items?: ProviderResponse[];
  /**
   * Gets the total size of all the entities.
   * @format int32
   */
  totalSize?: number;
}

export interface GetAllProvidersRequest {
  /**
   * If set, specifies the name of the column to sort by.
   * Value is one of:
   *
   *
   *    name
   *
   *
   *    description
   *
   */
  sortColumn?: string | null;
  /**
   * If set, specifies the sort order.
   * Value is one of:
   *
   *
   *  asc
   *
   *
   *  desc
   *
   */
  sortOrder?: string | null;
  /**
   * Page number to return from the results. The value is 0 based.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  pageNumber: number;
  /**
   * Maximum number of items per page.
   * @format int32
   * @min 1
   * @max 100
   */
  pageSize: number;
}

export interface GetProvidersByNodeIdRequest {
  /** A list of ids of the nodes to fetch Netmore providers from. */
  nodeIds?: string[];
}

export interface GetProvidersByMeanValueSignalIdsRequest {
  /** Gets or sets the mean value signal ids of the providers to retrieve. */
  meanValueSignalIds?: string[];
}

export interface DeleteProvidersRequest {
  /** A list of ids of providers to be deleted. */
  providerIds?: string[];
}

export interface AccountSensorResponse {
  /**
   * Account id
   * @format guid
   */
  accountId?: string;
  /** The DevEUI of the sensor. */
  devEUI?: string;
  /** The description of the sensor. */
  description?: string;
  /** The serialNumber of the sensor. */
  serialNumber?: string;
}

export interface SensorSearchFilterModel {
  /** DevEUIs to search for */
  devEUIs?: string[] | null;
  /** Serial numbers to search for */
  serialNumbers?: string[] | null;
}

/** This model will contain all the values for a specific signal. */
export interface SignalValuesResponse {
  /**
   * Gets or sets the signal identifier.
   * @format guid
   */
  signalId?: string;
  /** Gets or sets the values for the signal. */
  values?: SignalValueResponse[];
}

/** This model will contain a specific data point. */
export interface SignalValueResponse {
  /**
   * Gets or sets the time.
   * @format date-time
   */
  time?: string;
  /**
   * Gets or sets the value.
   * @format double
   */
  value?: number | null;
}

export interface GetSignalValuesByTimeRangeRequest {
  /** Gets or sets the signal identifiers to retrieve values for. */
  signalIds?: string[];
  /**
   * Gets or sets the start date. The value is inclusive.
   * @format date-time
   * @minLength 1
   */
  startDate: string;
  /**
   * Gets or sets the end date. The value is inclusive.
   * @format date-time
   * @minLength 1
   */
  endDate: string;
}

export interface DeleteSignalsRequest {
  /** Gets or sets the ids of the signals to delete. */
  signalIds?: string[];
}

/**
 * @title Netmore API
 * @version 1.0.0
 * @baseUrl https://app-ec-netmore-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.NetmoreAPIGen;

export interface MetersGetAccountSensorsPath {
  /**
   * Account id to list sensors
   * @format guid
   */
  accountId: string;
}

const NetmoreAPIGen = {
  Accounts: {
    /**
     * @summary Adds or updates the supplied Netmore accounts.
     */
    addOrUpdateAccounts: apiEndpoint<
      AddOrUpdateAccountRequest[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/accounts`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns the requested Netmore accounts using the supplied list of id's.
     */
    getAccounts: apiEndpoint<
      GetAccountsRequest,
      AccountResponse[],
      ProblemDetails
    >(
      Method.POST,
      `/api/accounts`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Returns all the Netmore accounts, with possibility for paging. If no sort order or sort column is specified,
the result will be sorted by name in descending order by default.
*/
    getAllAccounts: apiEndpoint<
      GetAllAccountsRequest,
      PagedResultResponseOfAccountResponse,
      ProblemDetails
    >(
      Method.POST,
      `/api/accounts/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Development: {
    /**
     * @summary Resets the database for the service.
     */
    resetService: apiEndpointEmpty<void, ProblemDetails>(
      Method.POST,
      `/api/admin/resetService`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Jobs: {
    triggerRefreshTokensJob: apiEndpointEmpty<void, any>(
      Method.POST,
      `/api/job/trigger-refresh-tokens`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    triggerCalculateMeanValuesJob: apiEndpointEmpty<void, any>(
      Method.POST,
      `/api/job/trigger-calculate-mean-values`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Providers: {
    /**
     * @summary Adds or updates the supplied Netmore providers.
     */
    addOrUpdateProviders: apiEndpoint<
      AddOrUpdateProviderRequest[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns the requested Netmore providers using the supplied list of id's.
     */
    getProvidersByIds: apiEndpoint<
      GetProvidersByIdRequest,
      ProviderResponse[],
      ProblemDetails
    >(
      Method.POST,
      `/api/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the selected providers
     */
    deleteProviders: apiEndpoint<DeleteProvidersRequest, void, ProblemDetails>(
      Method.DELETE,
      `/api/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns the Netmore providers, with possibility for paging.
     */
    getAllProviders: apiEndpoint<
      GetAllProvidersRequest,
      PagedResultResponseOfProviderResponse,
      ProblemDetails
    >(
      Method.POST,
      `/api/providers/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns the Netmore providers for the supplied nodeIds.
     */
    getProvidersByNodeIds: apiEndpoint<
      GetProvidersByNodeIdRequest,
      ProviderResponse[],
      ProblemDetails
    >(
      Method.POST,
      `/api/providers/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns Netmore providers using the supplied list of mean value signal id's.
     */
    getProvidersByMeanValueSignalId: apiEndpoint<
      GetProvidersByMeanValueSignalIdsRequest,
      ProviderResponse[],
      ProblemDetails
    >(
      Method.POST,
      `/api/providers/byMeanValueSignalId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description This method can be removed when https://dev.azure.com/eonectocloud/Ectocloud/_workitems/edit/910 has been deployed to all environments. * @summary Migrates existing providers and creates a mean humidity signal provider + signal for existing providers
     */
    migrateProviders: apiEndpointEmpty<void, any>(
      Method.PUT,
      `/api/providers/migrateProviders`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Meters: {
    /**
     * @summary Returns all the Netmore sensors for an account
     */
    getAccountSensors: apiEndpointWithPath<
      SensorSearchFilterModel,
      AccountSensorResponse[],
      ProblemDetails,
      MetersGetAccountSensorsPath
    >(
      Method.POST,
      ({ accountId }: MetersGetAccountSensorsPath) =>
        `/api/sensors/${accountId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Signals: {
    /**
     * @description The supplied time range is inclusive. * @summary Gets the values for the supplied signal identifiers and time range.
     */
    getSignalValuesByTimeRange: apiEndpoint<
      GetSignalValuesByTimeRangeRequest,
      SignalValuesResponse[],
      ProblemDetails
    >(
      Method.POST,
      `/api/signals/values/range`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the selected signals and their values.
     */
    deleteSignals: apiEndpoint<DeleteSignalsRequest, void, ProblemDetails>(
      Method.DELETE,
      `/api/signals`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  }
};

export default NetmoreAPIGen;
