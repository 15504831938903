import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext
} from 'react';
import _ from 'lodash';

import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { USER_MAXIMUM_EMAIL_LENGTH, UserAccountType } from './userUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import { RoleOptions } from 'ecto-common/lib/constants';
import T from 'ecto-common/lib/lang/Language';
import { TenantUserModel } from 'ecto-common/lib/API/IdentityServiceAPIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { UserAccountTypes, EonKidDomainSuffix } from './userUtils';
import KeyValueErrorNotice from 'ecto-common/lib/KeyValueInput/KeyValueErrorNotice';

const options: GenericSelectOption<UserAccountType>[] = [
  {
    value: UserAccountTypes.KID,
    label: T.admin.tenants.user.usertypes.kid
  },
  {
    value: UserAccountTypes.External,
    label: T.admin.tenants.user.usertypes.external
  }
];

/**
 * Creates standard input views for creating and editing a user.
 */

type UserDetailsViewProps = {
  editUser: TenantUserModel;
  setEditUser: Dispatch<SetStateAction<TenantUserModel>>;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
  userAccountType: UserAccountType;
  setAccountType: (accountType: UserAccountType) => void;
  errorText: string;
};

const UserDetailsView = ({
  setEditUser,
  editUser,
  setHasChanges,
  userAccountType,
  errorText,
  setAccountType
}: UserDetailsViewProps) => {
  const { availableTenantRoles } = useContext(TenantContext);

  const relevantRole = _.head(editUser?.roles);
  const selectedOption =
    _.find(RoleOptions, ['value', relevantRole?.name]) ?? null;

  const updateUser = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      setHasChanges(true);
      setEditUser((_editUser) => ({
        ..._editUser,
        [key]: value
      }));
    },
    [setEditUser, setHasChanges]
  );

  const updateRole = useCallback(
    (option: GenericSelectOption<string>) => {
      const rule = _.find(availableTenantRoles, ['name', option.value]);
      updateUser('roles', [rule]);
    },
    [updateUser, availableTenantRoles]
  );

  const setDescription: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => updateUser('description', e.target.value), [updateUser]);
  const setUsername: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => updateUser('email', e.target.value),
    [updateUser]
  );
  const keyAndPlaceholder =
    userAccountType === UserAccountTypes.KID
      ? T.admin.tenants.user.fields.kid
      : T.admin.tenants.user.fields.username;
  const helpText =
    userAccountType === UserAccountTypes.KID
      ? T.admin.tenants.user.helptext.kid
      : T.admin.tenants.user.helptext.external;
  const placeHolder =
    userAccountType === UserAccountTypes.KID ? 'A12345' : 'user@domain.com';

  return (
    <React.Fragment>
      <form autoComplete="off">
        <KeyValueLine>
          <KeyValueSelectableInput
            options={options}
            value={options.find((o) => o.value === userAccountType)}
            onChange={(o) => setAccountType(o.value)}
            keyText={T.admin.tenants.user.fields.accounttype}
          />
        </KeyValueLine>
        <KeyValueLine>
          <KeyValueInput
            onChange={setUsername}
            value={editUser.email}
            maxLength={USER_MAXIMUM_EMAIL_LENGTH}
            keyText={keyAndPlaceholder}
            placeholder={placeHolder}
            hasError={errorText != null}
            autoComplete="off"
            unit={
              userAccountType === UserAccountTypes.KID
                ? EonKidDomainSuffix
                : undefined
            }
            help={helpText}
          />
        </KeyValueLine>
        <KeyValueLine>
          {errorText && (
            <KeyValueErrorNotice> {errorText} </KeyValueErrorNotice>
          )}
        </KeyValueLine>
        <KeyValueLine>
          <KeyValueSelectableInput
            keyText={T.admin.tenants.user.fields.role}
            options={RoleOptions}
            value={selectedOption}
            onChange={updateRole}
            hasError={selectedOption == null}
          />
          <KeyValueInput
            onChange={setDescription}
            value={editUser.description}
            keyText={T.admin.tenants.user.fields.description}
            placeholder={T.admin.tenants.user.fields.description}
            autoComplete="off"
          />
        </KeyValueLine>
      </form>
    </React.Fragment>
  );
};

export default React.memo(UserDetailsView);
