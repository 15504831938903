import React, {
  Fragment,
  useEffect,
  useMemo,
  useContext,
  useState,
  useCallback
} from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import { EditEquipmentToolsActions } from 'js/modules/editEquipmentTools/editEquipmentTools';
import { DataTableFooter } from 'ecto-common/lib/DataTable/DataTableFooter';
import { AdminToolType, useEquipmentTools } from './useEquipmentTools';
import AddEquipmentTool from './AddEquipmentTool';
import NodeIdContext from 'ecto-common/lib/hooks/NodeIdContext';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { ToolSignalProviders } from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { NodeEquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';

const EQUIPMENT_TOOL_COLUMNS = [
  {
    label: T.admin.equipmenttools.tooltype,
    dataKey: 'name',
    minWidth: 120,
    dataFormatter: (value: string) => (
      <Fragment>
        <Icons.Tool />
        {value}
      </Fragment>
    )
  }
];

interface EditEquipmentToolsProps {
  equipment: NodeEquipmentResponseModel;
  energyManagerEquipmentId?: string;
}

/**
 * Holds edit equipment tools content, provides data source for the content
 * @param equipment
 * @param energyManagerEquipmentId
 * @returns {*}
 * @constructor
 */
const EditEquipmentTools = ({
  equipment,
  energyManagerEquipmentId
}: EditEquipmentToolsProps) => {
  // Convert value functions to use equipment and nodeId as parameters, so we can load providers from server and
  // se if they are available. E.g get comfort provider by node id
  const params = useParams<NodeParams>();
  const { nodeId } = params;
  const currentNode = useMemo(() => ({ nodeId }), [nodeId]);

  return (
    <NodeIdContext.Provider value={currentNode}>
      <EditEquipmentToolsContent
        equipment={equipment}
        energyManagerEquipmentId={energyManagerEquipmentId}
      />
    </NodeIdContext.Provider>
  );
};

/**
 * Show a list of current equipment tools and handles the creation of new tools
 * @param equipment
 * @param energyManagerEquipmentId
 * @returns {*}
 * @constructor
 */

export enum EditState {
  IDLE = 'IDLE',
  EDIT = 'EDIT',
  DEPLOY = 'DEPLOY',
  CREATE = 'CREATE',
  DELETE = 'DELETE'
}

export type EditStateData = {
  state: EditState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

interface EditEquipmentToolsContentProps {
  equipment: NodeEquipmentResponseModel;
  energyManagerEquipmentId?: string;
}

const EditEquipmentToolsContent = ({
  equipment,
  energyManagerEquipmentId
}: EditEquipmentToolsContentProps) => {
  const [editStates, setEditStates] = useState<Record<string, EditStateData>>(
    _.reduce(
      enumValues(ToolSignalProviders),
      (dict, item) => {
        dict[item] = { state: EditState.IDLE, data: null };
        return dict;
      },
      {} as Record<string, EditStateData>
    )
  );

  const setEditState = useCallback(
    (toolType: ToolSignalProviders, value: EditStateData) => {
      setEditStates((oldValue) => ({ ...oldValue, [toolType]: value }));
    },
    [setEditStates]
  );

  const loading = useAdminSelector((state) => state.editEquipmentTools.loading);
  const errorText = useAdminSelector(
    (state) => state.editEquipmentTools.errorText
  );
  const deviceId = useAdminSelector(
    (state) => state.editEquipmentTools.deviceId
  );
  const dispatch = useAdminDispatch();
  const { nodeId } = useContext(NodeIdContext);
  const { tenantResources, contextSettings } = useContext(TenantContext);

  const { currentTools, availableTools, loadingTools } = useEquipmentTools(
    nodeId,
    equipment.equipmentId,
    editStates,
    setEditState
  );

  useEffect(() => {
    dispatch(
      EditEquipmentToolsActions.setEquipment(
        contextSettings,
        equipment,
        energyManagerEquipmentId,
        tenantResources
      )
    );
    // NOTE: dependency on equipment.equipmentId is needed instead of the entire equipment object,
    //       keeps it from reloading page when we save, but that should probably change in the future.
    //       This is to keep it consistent with previous Component implementation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    equipment.equipmentId,
    energyManagerEquipmentId,
    tenantResources
  ]);

  // All available columns
  const tableColumns: DataTableColumnProps<AdminToolType>[] = useMemo(
    () => [
      ...EQUIPMENT_TOOL_COLUMNS,
      ...standardColumns<AdminToolType>({
        createEditOptions: (tool) =>
          _.compact([
            {
              icon: <Icons.Edit />,
              label: T.common.edit,
              action: () =>
                setEditStates((oldValues) => ({
                  ...oldValues,
                  [tool.type]: { state: EditState.EDIT, data: tool.data }
                }))
            },
            tool.allowDeploy && {
              icon: <Icons.Deploy />,
              label: T.admin.equipment.deploytool,
              action: () =>
                setEditStates((oldValues) => ({
                  ...oldValues,
                  [tool.type]: { state: EditState.DEPLOY, data: tool.data }
                }))
            }
          ]),
        onDelete: (tool) =>
          setEditStates((oldValues) => ({
            ...oldValues,
            [tool.type]: { state: EditState.DELETE, data: tool.data }
          })),
        shouldDisableDelete: (tool) => !tool.allowDelete
      })
    ],
    []
  );

  const isLoading = loading || loadingTools;
  const showAddEquipmentTool = !isLoading && deviceId;
  const additionalComponents = useMemo(
    () =>
      _.map([...currentTools, ...availableTools], (tool) => {
        return tool?.component;
      }),
    [currentTools, availableTools]
  );

  return (
    <div>
      <DataTable
        disableHeader
        isLoading={isLoading}
        errorText={errorText}
        hasError={errorText != null}
        noDataText={T.admin.equipment.notools}
        data={currentTools}
        columns={tableColumns}
      />
      {showAddEquipmentTool && (
        <DataTableFooter alignRight>
          <AddEquipmentTool
            setEditStates={setEditStates}
            availableTools={availableTools}
          />
        </DataTableFooter>
      )}
      {additionalComponents}
    </div>
  );
};

export default React.memo(EditEquipmentTools);
