import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { signal, text } from 'ecto-common/lib/ModelForm/formUtils';
import T from 'ecto-common/lib/lang/Language';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { modelFormSectionsAreValid } from 'ecto-common/lib/ModelForm/validateForm';
import { SignalTypeIds } from 'ecto-common/lib/utils/constants';
import { ComfortEquipmentWithToolData } from 'js/components/EditLocation/Tools/ManageComfortTools';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { BatchEditEquipmentParameters } from './batchEditComfortUtils';

const errorIfEmpty = _.isEmpty;

interface BatchEditComfortToolsStep1Props {
  equipments?: ComfortEquipmentWithToolData[];
  input?: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateInput(key: string[], value: any): void;
  setConfigIsValid: Dispatch<SetStateAction<boolean>>;
}

const BatchEditComfortToolsStep1 = ({
  equipments,
  input,
  onUpdateInput,
  setConfigIsValid
}: BatchEditComfortToolsStep1Props) => {
  const sections: ModelFormSectionType<BatchEditEquipmentParameters>[] =
    useMemo(() => {
      return [
        {
          label: T.admin.editlocation.tools.comfort.batchcommonsection.title,
          lines: [
            {
              models: [
                {
                  modelType: ModelType.SIGNAL,
                  key: (keyInput) => keyInput.outTemperatureSignalId,
                  label: T.admin.comfort.signals.outdoortemperature,
                  placeholder: T.admin.comfort.signals.outdoortemperature,
                  hasError: _.isEmpty,
                  selectFromCurrentNodeOnly: true,
                  validSignalTypeIds: [
                    SignalTypeIds.OUTDOOR_SENSOR_TEMPERATURE_SIGNAL_TYPE_ID
                  ]
                }
              ]
            },
            {
              models: [
                {
                  modelType: ModelType.SIGNAL,
                  key: (keyInput) => keyInput.windSpeedSignalId,
                  label: T.admin.comfort.signals.windspeed,
                  placeholder: T.admin.comfort.signals.windspeed
                }
              ]
            }
          ]
        },
        ..._.map(equipments, (equipment, idx) => ({
          label: equipment.name,
          lines: [
            {
              models: [
                text(
                  (inputObj: BatchEditEquipmentParameters) =>
                    inputObj.equipmentSettings[idx].displayName,
                  T.admin.comfort.name,
                  errorIfEmpty
                )
              ]
            },
            {
              models: [
                signal(
                  (inputObj: BatchEditEquipmentParameters) =>
                    inputObj.equipmentSettings[idx].meanTemperatureSignalId,
                  T.admin.comfort.signals.roomtemperature,
                  errorIfEmpty
                )
              ]
            }
          ]
        }))
      ];
    }, [equipments]);

  useEffect(() => {
    setConfigIsValid(modelFormSectionsAreValid(sections, input, {}));
  }, [input, setConfigIsValid, sections]);

  return (
    <ModelForm
      input={input}
      sections={sections}
      onUpdateInput={onUpdateInput}
    />
  );
};

export default BatchEditComfortToolsStep1;
