import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import { useSignalUpdateEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import Switch from 'ecto-common/lib/Switch/Switch';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import _ from 'lodash';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import APIGen, {
  ComfortHeatingProviderResponseModel,
  SetSignalsRequestModel
} from 'ecto-common/lib/API/APIGen';
import T from 'ecto-common/lib/lang/Language';
import { ComfortSignalTypeIds } from 'ecto-common/lib/utils/constants';
import {
  SignalWithProvider,
  SignalWithProviderAndSignalInfo
} from 'ecto-common/lib/types/EctoCommonTypes';
import { SignalInfoResponseModel } from 'ecto-common/lib/API/APIGen';
import { SignalValueType } from 'ecto-common/lib/hooks/useLatestSignalValues';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ValueOfCollection } from 'ecto-common/lib/utils/typescriptUtils';
import { useQuery } from '@tanstack/react-query';

const signalTypesToEdit = _.keyBy(_.values(ComfortSignalTypeIds));

const getSignalDataPromise = (
  contextSettings: ApiContextSettings,
  equipmentIds: string[],
  signal: AbortSignal
) => {
  return Promise.all([
    APIGen.SignalViews.getNodeSignalViews.promise(
      contextSettings,
      { nodeIds: equipmentIds },
      signal
    ),
    APIGen.AdminSignals.getSignalsByNode.promise(
      contextSettings,
      { nodesIds: equipmentIds },
      signal
    )
  ] as const);
};

const getTableData = (
  signals: Record<string, SignalWithProviderAndSignalInfo>,
  _signalTypesToEdit: Record<string, string>,
  signalData: Record<string, SignalValueType>,
  pendingSignalValues: Record<string, PendingSignalEntry>,
  onToggleItem: (signalId: string, isOn: boolean) => void,
  initialValuesFetched: boolean
) => {
  return _(signals)
    .pickBy((value) => value.signalTypeId in _signalTypesToEdit)
    .map((signal) => ({
      ...signal,
      ...signalData[signal.signalId],
      ...pendingSignalValues[signal.signalId],
      onToggle: onToggleItem,
      initialValuesFetched
    }))
    .value();
};

const triggerLiveTelemetry = (
  contextSettings: ApiContextSettings,
  tool: ComfortHeatingProviderResponseModel
) => {
  if (tool?.equipmentId != null) {
    // Fire and forget
    APIGen.Devices.liveTelemetry
      .promise(contextSettings, { equipmentId: tool.equipmentId }, null)
      .catch(_.noop);
  }
};

interface EditComfortSignalsProps {
  tools?: ComfortHeatingProviderResponseModel[];
}

interface PendingSignalEntry {
  pending: boolean;
}

const EditComfortSignals = ({ tools }: EditComfortSignalsProps) => {
  const [pendingSignalValues, setPendingSignalValues] = useState<
    Record<string, PendingSignalEntry>
  >({});
  const [signalData, setSignalData] = useState<Record<string, SignalValueType>>(
    {}
  );
  const [initialValuesFetched, setInitialValuesFetched] = useState(false);
  const { contextSettings } = useContext(TenantContext);

  useEffect(() => {
    triggerLiveTelemetry(contextSettings, _.head(tools));
  }, [contextSettings, tools]);

  useInterval(
    useCallback(() => {
      triggerLiveTelemetry(contextSettings, _.head(tools));
    }, [contextSettings, tools]),
    60 * 1000 * 5
  );

  const setSignalValuesMutation = APIGen.Devices.setSignalAll.useMutation({});

  const setSignalValues = useCallback(
    (values: SetSignalsRequestModel) => {
      setSignalValuesMutation.mutate(values, {
        onSuccess: (setSignalResponses) => {
          if (
            setSignalResponses.find((item) => item.status !== HttpStatus.OK) !=
            null
          ) {
            const errorSignalIds = _(setSignalResponses)
              .reject({ status: HttpStatus.OK })
              .map('signalId')
              .value();

            if (errorSignalIds.length > 0) {
              toastStore.addErrorToast(
                T.admin.comfort.signals.error.failedtosetsignal
              );
              setPendingSignalValues((oldPendingSignalValues) =>
                _.pickBy(
                  oldPendingSignalValues,
                  (_unused, key) => !errorSignalIds.includes(key)
                )
              );
            }
          }
        },
        onError: (_err, setSignalRequests) => {
          setPendingSignalValues((oldPendingSignalValues) => {
            const signalIds = setSignalRequests.signalValues.map(
              (s) => s.equipmentSignalId
            );
            return _.pickBy(
              oldPendingSignalValues,
              (_unused, key) => !signalIds.includes(key)
            );
          });
          toastStore.addErrorToast(
            T.admin.comfort.signals.error.failedtosetsignal
          );
        }
      });
    },
    [setSignalValuesMutation]
  );

  const getSignalDataIds = useMemo(() => _.map(tools, 'equipmentId'), [tools]);

  const getSignalDataQuery = useQuery({
    queryKey: ['comfort-signals-data', getSignalDataIds],

    queryFn: ({ signal }) => {
      return getSignalDataPromise(contextSettings, getSignalDataIds, signal);
    },

    enabled: getSignalDataIds.length > 0
  });
  const signals: Record<string, SignalWithProviderAndSignalInfo> =
    useMemo(() => {
      if (getSignalDataQuery.data == null) {
        return {};
      }
      const [signalViews, providers] = getSignalDataQuery.data;

      const eqProviderSignals: SignalWithProvider[] = _.flatMap(
        providers,
        (provider) =>
          provider.signals.map((signal) => ({
            ...signal,
            provider
          }))
      );
      const eqSignalViewSignals: SignalInfoResponseModel[] = _.flatMap(
        signalViews,
        'equipmentSignals'
      );
      const signalMetadata = _.keyBy(eqSignalViewSignals, 'signalId');

      const signalsToUse: Record<string, SignalWithProviderAndSignalInfo> = _(
        eqProviderSignals
      )
        .filter((x) => x.signalTypeId in signalTypesToEdit)
        .keyBy('signalId')
        .mapValues((x) => _.merge({}, signalMetadata[x.signalId] ?? {}, x))
        .value();

      return signalsToUse;
    }, [getSignalDataQuery.data]);

  const onToggleItem = useCallback(
    (signalId: string, isOn: boolean) => {
      setPendingSignalValues((oldPending) => ({
        ...oldPending,
        [signalId]: {
          pending: true
        }
      }));

      setSignalValues({
        signalValues: [
          {
            equipmentSignalId: signalId,
            value: isOn ? 1.0 : 0.0
          }
        ]
      });
    },
    [setSignalValues]
  );

  const signalIds = useMemo(() => _.map(signals, 'signalId'), [signals]);

  useEffect(() => {
    setInitialValuesFetched(false);
  }, [signalIds]);

  const tableData = useMemo(
    () =>
      getTableData(
        signals,
        signalTypesToEdit,
        signalData,
        pendingSignalValues,
        onToggleItem,
        initialValuesFetched
      ),
    [
      signalData,
      signals,
      pendingSignalValues,
      onToggleItem,
      initialValuesFetched
    ]
  );

  const everythingEnabled = _.every(
    signals,
    (signal) => signalData[signal.signalId]?.value === 1
  );
  const hasPendingValues = _.keys(pendingSignalValues).length > 0;

  const onClickToggleAll = useCallback(() => {
    let signalIdsToSet;

    let valueToSet: number;

    if (everythingEnabled) {
      valueToSet = 0.0;
      signalIdsToSet = signalIds;
    } else {
      signalIdsToSet = _(signals)
        .filter((signal) => (signalData[signal.signalId]?.value ?? 0) === 0)
        .map('signalId')
        .value();

      valueToSet = 1.0;
    }

    setSignalValues({
      signalValues: _.map(signalIdsToSet, (signalId) => ({
        equipmentSignalId: signalId,
        value: valueToSet
      }))
    });

    const newPending = _(signalIdsToSet)
      .keyBy()
      .mapValues(() => ({ pending: true }))
      .value();

    setPendingSignalValues((oldPending) => ({
      ...oldPending,
      ...newPending
    }));
  }, [everythingEnabled, signals, signalIds, signalData, setSignalValues]);

  const columns: DataTableColumnProps<ValueOfCollection<typeof tableData>>[] =
    useMemo(
      () => [
        {
          label: T.admin.comfort.signals.signalnamecolumn,
          dataKey: 'name',
          dataFormatter: (value, signal) => {
            return signal.provider.signalProviderName + ' - ' + value;
          }
        },
        {
          label: (
            <Switch
              isOn={everythingEnabled}
              onClick={onClickToggleAll}
              isLoading={hasPendingValues}
            />
          ),
          dataKey: '_unused0',
          flexGrow: 0,
          minWidth: 58,
          dataFormatter: (_unused, signal) => {
            const isOn = signal.value === 1;
            return (
              <Switch
                key={signal.signalId}
                isOn={isOn}
                onClick={() => signal.onToggle(signal.signalId, !isOn)}
                isLoading={signal.pending || !signal.initialValuesFetched}
              />
            );
          }
        }
      ],
      [onClickToggleAll, everythingEnabled, hasPendingValues]
    );

  const onValuesChanged = useCallback((data: SignalValueType[]) => {
    const keyedData = _.keyBy(data, 'signalId');
    setInitialValuesFetched(true);

    setPendingSignalValues((oldPending) => {
      return _.pickBy(oldPending, (_val, key) => !(key in keyedData));
    });
    setSignalData((oldSignalData) => _.merge({}, oldSignalData, keyedData));
  }, []);

  useSignalUpdateEventHubSubscription(null, signalIds, onValuesChanged);

  return (
    <DataTable
      isLoading={getSignalDataQuery.isLoading}
      columns={columns}
      data={tableData}
    />
  );
};

export default EditComfortSignals;
