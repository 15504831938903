import { createAction } from 'ecto-common/lib/utils/actionUtils';
import UUID from 'uuidjs';
import {
  deleteNodesWithIds,
  patchNodes
} from 'ecto-common/lib/actions/getNodes';
import { CommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import APIGen, {
  CreateBuildingByTemplateDataResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AdminRootState } from 'js/reducers/storeAdmin';
export const SET_PROVISIONING_DEVICE_DATA_CHANGED =
  'SET_PROVISIONING_DEVICE_DATA_CHANGED';

export const ProvisioningActions = {
  notifyDeviceDataChanged: createAction(SET_PROVISIONING_DEVICE_DATA_CHANGED)
};

export enum CreateError {
  BUILDING_NAMING_CONFLICT = 'BUILDING_NAMING_CONFLICT',
  SITE_NAMING_CONFLICT = 'SITE_NAMING_CONFLICT',
  MISSING_CONNECTION = 'MISSING_CONNECTION',
  GENERIC = 'GENERIC'
}

export const instantiateBuildingTemplate = async (
  contextSettings: ApiContextSettings,
  node: SingleGridNode,
  { equipmentTemplateForm }: AdminRootState
): Promise<CreateBuildingByTemplateDataResponseModel[]> => {
  const {
    equipmentTemplateGroupId,
    equipmentTemplateInstanceOverrides,
    connectionModbusConfigOverride,
    existingEnergyManagerDeviceId
  } = equipmentTemplateForm;

  const templateData = {
    nodeId: node.nodeId,
    equipmentTemplateGroupId,
    connectionModbusConfigOverride,
    equipmentTemplateInstanceOverrides,
    deviceId: existingEnergyManagerDeviceId ?? UUID.generate()
  };

  const instructions =
    await APIGen.AdminBuildings.getAddOrUpdateBuildingsByTemplates.promise(
      contextSettings,
      [templateData],
      null
    );

  return await APIGen.AdminBuildings.addOrUpdateBuildingsByTemplates.promise(
    contextSettings,
    instructions,
    null
  );
};

export const updateNodeTreeIncrementallyFromDelete = (
  deletedNodeId: string,
  dispatch: CommonDispatch
) => {
  dispatch(deleteNodesWithIds([deletedNodeId]));
};

export const updateNodeTreeIncrementally = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  dispatch: CommonDispatch
) => {
  const nodes = await APIGen.AdminNodes.getNodes.promise(
    contextSettings,
    {
      NodeId: nodeId
    },
    null
  );
  dispatch(patchNodes(nodes));
  return nodes[0];
};
