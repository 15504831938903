import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import SignalSection, {
  DestOrSourceMappingSignal,
  SignalSectionModelDefinitionWithOnChange
} from 'js/components/SignalMapping/Components/SignalSection';
import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import APIGen, {
  FullSignalProviderResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SignalWithProvider } from 'ecto-common/lib/types/EctoCommonTypes';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';

const mapSignalMappingResult = (
  result: FullSignalProviderResponseModel[]
): Record<string, SignalWithProvider> => {
  // Map all signal arrays to dictionary of { <signal id>: <signal info> } and add signalProvider object to each signal
  // So final structure will be:
  // {
  //  [signalId1]: { ...<signal info 1>, signalProvider: <signal provider for signalId1> }
  //  [signalId2]: { ...<signal info 2>, signalProvider: <signal provider for signalId2> }
  //  ..
  // }
  return _.keyBy(
    _.flatMap(result, (signalProvider) => {
      // Set signalProvider on all signals
      return _.map(signalProvider.signals, (signal) => ({
        ...signal,
        provider: signalProvider
      }));
    }),
    'signalId'
  );
};

type SignalSectionModelEditorProps = Omit<
  CustomModelEditorProps,
  'rawValue'
> & {
  rawValue: DestOrSourceMappingSignal[];
  mappingId?: string;
  signalInputs?: SignalSectionModelDefinitionWithOnChange[];
};

const SignalSectionModelEditor = ({
  model,
  mappingId,
  signalInputs,
  updateItem,
  rawValue
}: SignalSectionModelEditorProps) => {
  const getQuery = APIGen.AdminSignals.getSignalsByMapIds.useQuery(
    {
      mapIds: [mappingId]
    },
    {
      enabled: !isNullOrWhitespace(mappingId)
    }
  );
  const signalInfo = useMemo(() => {
    return mapSignalMappingResult(getQuery.data || []);
  }, [getQuery.data]);

  const _updateItem = useCallback(
    (values: DestOrSourceMappingSignal[]) => {
      updateItem(values);
    },
    [updateItem]
  );

  return (
    <SignalSection
      title={model.label}
      signalInputs={signalInputs}
      initialSignals={rawValue}
      onSignalsChanged={_updateItem}
      isLoading={getQuery.isLoading}
      signalInfo={signalInfo}
    />
  );
};

export default React.memo(SignalSectionModelEditor);
