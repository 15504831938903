import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useContext,
  useMemo
} from 'react';
import { calculateDataTableMinHeight } from 'ecto-common/lib/utils/dataTableUtils';
import T from 'ecto-common/lib/lang/Language';
import PagedDataTable, {
  getTotalPagesFromPagedResult,
  PagedDataTableErrorResult
} from 'ecto-common/lib/PagedDataTable/PagedDataTable';
import { resetScroll } from 'ecto-common/lib/utils/scrollUtils';
import {
  ASC,
  SortDirectionType
} from 'ecto-common/lib/DataTable/SortDirection';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { GetAllAccountsRequest } from 'ecto-common/lib/API/NetmoreAPIGen';
import { PagedResultResponseOfAccountResponse } from 'ecto-common/lib/API/NetmoreAPIGen';

const INITIAL_PAGING: GetAllAccountsRequest = {
  sortColumn: null,
  sortOrder: ASC,
  pageNumber: 0,
  pageSize: 10
};

interface IntegrationAccountListProps<AccountType> {
  onSelectAccount?(account: AccountType): void;
  reloadAccounts?: number;
  pageSize?: number;
  promise?(
    contextSettings: ApiContextSettings,
    pagingParams: GetAllAccountsRequest,
    abortSignal: AbortSignal
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<PagedResultResponseOfAccountResponse>;
  columns?: DataTableColumnProps<AccountType>[];
  sortColumn?: string;
  name: string;
}

function IntegrationAccountList<AccountType extends object>({
  onSelectAccount,
  reloadAccounts,
  pageSize = 20,
  promise,
  columns,
  sortColumn,
  name
}: IntegrationAccountListProps<AccountType>) {
  const [paging, setPaging] = useState<GetAllAccountsRequest>({
    ...INITIAL_PAGING,
    pageSize: pageSize,
    sortColumn
  });

  const scrollNode = useRef(null);

  const { contextSettings } = useContext(TenantContext);

  const getAccountsQuery = useQuery({
    queryKey: ['integrationAccountList', name, paging],

    queryFn: ({ signal }) => {
      return promise(contextSettings, paging, signal);
    }
  });

  const accounts = useMemo(() => {
    if (getAccountsQuery.isError) {
      return PagedDataTableErrorResult;
    } else if (!getAccountsQuery.isLoading && getAccountsQuery.data) {
      return {
        result: getAccountsQuery?.data?.items,
        totalPages: getTotalPagesFromPagedResult(
          getAccountsQuery.data,
          pageSize
        ),
        hasError: false
      };
    }
    return { result: [], totalPages: 0, hasError: false };
  }, [
    getAccountsQuery.data,
    getAccountsQuery.isError,
    getAccountsQuery.isLoading,
    pageSize
  ]);

  const onSortClick = useCallback((_sortColumn: string, sortOrder: string) => {
    setPaging((oldPaging) => ({
      ...oldPaging,
      sortColumn: _sortColumn,
      sortOrder
    }));
  }, []);

  const queryClient = useQueryClient();

  // This is an ugly workaround to force account reload. Will disappear when we move to react-query.
  const lastReload = useRef(reloadAccounts);

  useEffect(() => {
    resetScroll(scrollNode.current);
    queryClient.invalidateQueries({
      queryKey: ['integrationAccountList']
    });
    setPaging((oldPaging) => {
      if (oldPaging.pageNumber !== 0 || lastReload.current !== reloadAccounts) {
        lastReload.current = reloadAccounts;
        return { ...oldPaging, pageNumber: 0 };
      }

      return oldPaging;
    });
  }, [queryClient, reloadAccounts]);

  const onPageChange = useCallback((newPage: number) => {
    resetScroll(scrollNode.current);
    setPaging((oldPaging) => ({ ...oldPaging, pageNumber: newPage }));
  }, []);

  return (
    <PagedDataTable<AccountType>
      innerRef={scrollNode}
      data={accounts}
      page={paging.pageNumber}
      columns={columns}
      isLoading={getAccountsQuery.isLoading}
      onSortChange={onSortClick}
      onPageChange={onPageChange}
      pageSize={pageSize}
      sortBy={paging.sortColumn}
      sortDirection={paging.sortOrder as SortDirectionType}
      onClickRow={onSelectAccount}
      noDataText={T.admin.integration.generic.get.accounts.empty}
      useAllAvailableHeight
      minHeight={calculateDataTableMinHeight({ pageSize })}
    />
  );
}

export default typedMemo(IntegrationAccountList);
