import React from 'react';
import PagingFooter from 'ecto-common/lib/PagingFooter/PagingFooter';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import styles from 'ecto-common/lib/PagedDataTable/PagedDataTable.module.css';
import classNames from 'classnames';
import { BaseDataTableProps } from '../DataTable/DataTable';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import _ from 'lodash';

export const totalSizeToTotalPages = (totalSize: number, pageSize: number) =>
  Math.ceil(totalSize / pageSize);

/**
 * Unfortunately We have slightly different API:s for paging. Some return the
 * total number of pages, while others only return a "totalSize". In that case,
 * calculate the total number of pages using the page size.
 */
export const getTotalPagesFromPagedResult = (
  result: object,
  pageSize: number
) => {
  if ('totalPages' in result && _.isNumber(result.totalPages)) {
    return result.totalPages;
  }

  if ('totalSize' in result && _.isNumber(result.totalSize)) {
    return totalSizeToTotalPages(result.totalSize, pageSize);
  }

  return null;
};

export interface PagedDataTableDataType<ObjectType> {
  hasError: boolean;
  result: ObjectType[];
  totalPages: number;
  totalRecords?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PagedDataTableErrorResult: PagedDataTableDataType<any> = {
  totalPages: 0,
  hasError: true,
  result: [],
  totalRecords: 0
};

interface PagedDataTableProps<ObjectType>
  extends BaseDataTableProps<ObjectType> {
  data: PagedDataTableDataType<ObjectType>;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
}

const PagedDataTable = <ObjectType extends object>({
  columns,
  data,
  page,
  pageSize,
  onPageChange,
  isLoading = false,
  useAllAvailableHeight = false,
  innerRef = null,
  ...otherProps
}: PagedDataTableProps<ObjectType>) => {
  const { result, hasError, totalPages } = data;
  const noMoreRecords = result.length < pageSize;

  return (
    <div
      className={classNames(
        styles.container,
        useAllAvailableHeight && styles.useAllAvailableHeight
      )}
    >
      <DataTable<ObjectType>
        innerRef={innerRef}
        columns={columns}
        data={result}
        hasError={hasError}
        isLoading={isLoading}
        useAllAvailableHeight={useAllAvailableHeight}
        {...otherProps}
      />

      <PagingFooter
        disableBackNavigation={page <= 0 || isLoading}
        disableForwardNavigation={
          (totalPages != null && page + 1 >= totalPages) ||
          isLoading ||
          noMoreRecords
        }
        onPageChange={onPageChange}
        page={page}
        className={styles.footer}
        totalPages={totalPages}
      />
    </div>
  );
};

export default typedMemo(PagedDataTable);
