import React from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import KeyValueInternalSelectableInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalSelectableInput';
import styles from './EditAlarmSignalGroupTemplate.module.css';
import {
  equipmentSignalInputs,
  EMPTY_SIGNAL_INPUT as EMPTY_EQUIPMENT_SIGNAL_INPUT,
  createCheckbox
} from '../EquipmentSignalTemplates/equipmentSignalInputs';
import {
  AllAlarmSeverities,
  AlarmSeverity,
  AlarmSeverityText,
  AlarmTypeText
} from 'ecto-common/lib/constants';
import SignalCategories from 'js/utils/SignalCategories';
import {
  SignalInput,
  SignalInputRenderProps
} from 'js/components/ManageTemplates/manageTemplatesTypes';
import {
  AdminAlarmSignalTemplateResponseModel,
  AlarmType,
  GetEnumsAndFixedConfigurationsResponseModel
} from 'ecto-common/lib/API/APIGen';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

// These signal properties are fixed for alarm signals
const excludedInputs = Object.freeze([
  'isWritable',
  'signalSettings.signalFunction',
  'signalSettings.defaultValue'
]);

const onlyAlarmSignalInputs: SignalInput[] = [
  {
    type: 'severity',
    name: T.admin.alarmtemplates.severity,
    render: ({ signal, onChange, ...otherProps }: SignalInputRenderProps) => {
      const createOption = (severity: number): GenericSelectOption<number> => ({
        label: _.defaultTo(AlarmSeverityText[severity], _.toString(severity)),
        value: severity
      });

      return (
        <KeyValueInternalSelectableInput
          value={createOption(
            (signal as AdminAlarmSignalTemplateResponseModel).severity
          )}
          options={AllAlarmSeverities.map(createOption)}
          onChange={({ value }) => onChange('severity', value)}
          placeholder={T.admin.alarmtemplates.severity}
          {...otherProps}
        />
      );
    },
    defaultValue: AlarmSeverity.MEDIUM
  },
  {
    type: 'alarmType',
    name: T.admin.alarmtemplates.type,
    render: ({ signal, onChange, ...otherProps }: SignalInputRenderProps) => {
      const values: GenericSelectOption[] = enumValues(AlarmType)
        .filter((type) => type !== AlarmType.Unknown)
        .map((type) => ({ label: AlarmTypeText[type], value: type }));
      const selectedValue = values.find(
        ({ value }) =>
          value === (signal as AdminAlarmSignalTemplateResponseModel).alarmType
      );
      return (
        <KeyValueInternalSelectableInput
          className={styles.type}
          value={selectedValue}
          options={values}
          isSearchable
          openMenuOnClick
          onChange={({ value }) => onChange('alarmType', value)}
          placeholder={selectedValue ? selectedValue.label : ''}
          {...otherProps}
        />
      );
    },
    defaultValue: AlarmType.Stateful
  },
  {
    ...createCheckbox({
      type: 'needAcknowledgment',
      name: T.alarms.columns.needsacknowledgement
    }),
    defaultValue: true
  }
];

export const alarmSignalInputs = (
  enums: GetEnumsAndFixedConfigurationsResponseModel
): SignalInput[] => {
  return [
    // Almost the same signal inputs as equipment, except for excludedInputs.
    ...equipmentSignalInputs(enums).filter(
      ({ type }) => !excludedInputs.includes(type)
    ),
    ...onlyAlarmSignalInputs
  ];
};

export const EMPTY_SIGNAL_INPUT: AdminAlarmSignalTemplateResponseModel = {
  ...EMPTY_EQUIPMENT_SIGNAL_INPUT,
  signalCategoryIds: [SignalCategories.ENERGY_MANAGER, SignalCategories.ALARM],
  // Need to specify these explicitly unfortunately, Typescript doesn't understand this expression
  // ..._.reduce(onlyAlarmSignalInputs, (dict, { type, defaultValue }) => _.set(dict, type, defaultValue), {}),
  severity: AlarmSeverity.MEDIUM,
  alarmType: AlarmType.Stateful,
  needAcknowledgment: true
};
