import React, { useCallback, useEffect, useState } from 'react';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';

import { validateFormModel } from 'ecto-common/lib/ModelForm/validateForm';
import { modelKeyed } from 'js/components/SignalMapping/SignalMappingModel';
import SignalMapping from './SignalMapping';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import ModalActionFooter from 'ecto-common/lib/Modal/ModalActionFooter';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import DeleteSignalMappingModal from 'js/components/SignalMapping/DeleteSignalMappingModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useUpdateModelFormInput } from 'ecto-common/lib/ModelForm/formUtils';
import styles from './SignalMappingDialog.module.css';
import HelpPaths from 'ecto-common/help/tocKeys';
import APIGen, {
  AddOrUpdateMappingRequestModel,
  MappingResponseModel
} from 'ecto-common/lib/API/APIGen';

const initialState: MappingResponseModel = {
  cycleTime: 0,
  id: '',
  mappingAction: undefined
};

interface SignalMappingDialogProps {
  isOpen: boolean;
  onModalClose: () => void;
  onSuccess?(): void;
  item?: MappingResponseModel;
}

const SignalMappingDialog = ({
  isOpen,
  onModalClose,
  item,
  onSuccess
}: SignalMappingDialogProps) => {
  const [formValues, setFormValues] = useState<MappingResponseModel>(item);
  const [isValidForm, setValidForm] = useState(false);
  const [deleteItem, setDeleteItem] = useState<MappingResponseModel>(null);

  const showConfirmDelete = useCallback(() => setDeleteItem(item), [item]);

  const onUpdateInput = useUpdateModelFormInput(setFormValues);

  useEffect(() => {
    setValidForm(
      validateFormModel({
        models: modelKeyed,
        values: formValues,
        environment: {}
      }).isValid
    );
  }, [formValues]);

  useEffect(() => {
    setFormValues(item || initialState);
  }, [item]);

  // Reset state on modal close
  const _onModalClose = useCallback(() => {
    setFormValues(initialState);
    onModalClose();
  }, [onModalClose]);

  const isEditing = item.id != null;
  const onSuccessClose = useCallback(() => {
    onSuccess?.();
    _onModalClose();
  }, [onSuccess, _onModalClose]);

  const addOrEditMutation =
    APIGen.AdminSignals.addOrUpdateSignalMappings.useMutation({
      onSuccess: () => {
        toastStore.addSuccessToast(
          isEditing
            ? T.admin.signalmapping.request.update.success
            : T.admin.signalmapping.request.add.success
        );
        onSuccessClose();
      },
      onError: () => {
        toastStore.addErrorToast(
          isEditing
            ? T.admin.signalmapping.request.update.failed
            : T.admin.signalmapping.request.add.failed
        );
      }
    });

  const createMapping = useCallback(() => {
    // Cast is due to formValues having optional values (from response). Determined to be valid by form.
    addOrEditMutation.mutate([formValues as AddOrUpdateMappingRequestModel]);
  }, [addOrEditMutation, formValues]);

  const title = isEditing
    ? T.admin.signalmapping.editdialog.edit.title
    : T.admin.signalmapping.editdialog.add.title;
  const actionText = isEditing ? T.common.save : T.common.add;

  const footer = (
    <ModalActionFooter
      isLoading={addOrEditMutation.isPending}
      onModalClose={_onModalClose}
      confirmText={actionText}
      onConfirm={createMapping}
      confirmDisabled={!isValidForm}
      leftSide={
        (isEditing && (
          <DeleteButton
            disabled={addOrEditMutation.isPending}
            onClick={showConfirmDelete}
          >
            {T.common.delete}
          </DeleteButton>
        )) ||
        null
      }
    />
  );

  return (
    <>
      <ActionModal
        className={styles.dialog}
        large
        isLoading={addOrEditMutation.isPending}
        isOpen={isOpen}
        disableActionButton={!isValidForm}
        onModalClose={_onModalClose}
        title={title}
        actionText={actionText}
        onConfirmClick={createMapping}
        footer={footer}
        headerIcon={isEditing ? Icons.Edit : Icons.Add}
        helpPath={HelpPaths.docs.admin.manage.signal_mappings}
      >
        <SignalMapping values={formValues} onUpdateInput={onUpdateInput} />
      </ActionModal>

      <DeleteSignalMappingModal
        deleteItem={deleteItem}
        onSuccess={onSuccessClose}
        onModalClose={() => setDeleteItem(null)}
      />
    </>
  );
};

export default React.memo(SignalMappingDialog);
