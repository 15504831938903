import React, { memo } from 'react';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import styles from './Buildings.module.css';
import DateInput from 'ecto-common/lib/DateInput/DateInput';
import { Moment } from 'moment';
import { BuildingStatusTimestampComparisonType } from 'ecto-common/lib/utils/buildingStatusUtil';

interface BuildingsDatePickerProps {
  buildingStatusTimestamp?: Moment;
  onChangeTimeStamp?(newDate: Moment): void;
  timeStampTypeOptions: GenericSelectOption<BuildingStatusTimestampComparisonType>[];
  selectedTimeStampType?: GenericSelectOption<BuildingStatusTimestampComparisonType>;
  onChangeTimeStampType(
    type: GenericSelectOption<BuildingStatusTimestampComparisonType>
  ): void;
  isDisabled?: boolean;
}

const BuildingsDatePicker = ({
  buildingStatusTimestamp,
  onChangeTimeStamp,
  timeStampTypeOptions,
  selectedTimeStampType,
  onChangeTimeStampType,
  isDisabled = false
}: BuildingsDatePickerProps) => {
  return (
    <div className={styles.dateFilter}>
      <div className={styles.timeStampType}>
        <Select
          onChange={onChangeTimeStampType}
          options={timeStampTypeOptions}
          placeholder={T.common.tags}
          value={selectedTimeStampType}
          disabled={isDisabled}
        />
      </div>

      <div className={styles.datePicker}>
        <DateInput
          compact={false}
          placeholder={T.admin.buildings.column.statusdate}
          onChange={onChangeTimeStamp}
          value={buildingStatusTimestamp}
          enabled={selectedTimeStampType.value != null}
          clearButton
          allowEmptyValue
        />
      </div>
    </div>
  );
};

export default memo(BuildingsDatePicker);
