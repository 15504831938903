import React from 'react';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import styles from '../ManageTemplates/DialogStyles.module.css';
import Heading from 'ecto-common/lib/Heading/Heading';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import {
  ModbusConnectionType,
  RTUParity
} from 'js/components/ModbusLayout/ModbusTypes';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import {
  AddOrUpdateConnectionModbusConfigByTemplateRequestModel,
  ConnectionDefaultModbusConfigResponseModel,
  ConnectionModbusConfigResponseModel,
  ConnectionModbusConfigTemplateResponseModel,
  ModbusMode
} from 'ecto-common/lib/API/APIGen';

function toNum(num: string) {
  if (num == null || num === '' || isNaN(Number(num))) {
    return null;
  }

  return Number(num);
}

function safeStr(str: string) {
  return str === '' ? null : str;
}

interface ModbusConnectionDialogProps {
  isOpen: boolean;
  onModalClose: () => void;
  selectedConnection?:
    | ConnectionModbusConfigResponseModel
    | ConnectionModbusConfigTemplateResponseModel
    | AddOrUpdateConnectionModbusConfigByTemplateRequestModel;
  disableClose?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty?(key: string, value: any): void;
  onSave?(): void;
  showSaveButton?: boolean;
  saveButtonEnabled?: boolean;
  isLoading?: boolean;
}

const ModbusConnectionDialog = ({
  isLoading = false,
  onModalClose,
  saveButtonEnabled = false,
  showSaveButton = false,
  disableClose,
  isOpen,
  selectedConnection,
  onChangeProperty,
  onSave
}: ModbusConnectionDialogProps) => {
  const connectionModbusConfigDefaults = useAdminSelector(
    (state) => state.general.enums.connectionModbusConfigDefaults
  );

  const modbusType =
    selectedConnection.modbusType || connectionModbusConfigDefaults.modbusType;

  const inputRow = function (
    key: keyof ConnectionDefaultModbusConfigResponseModel,
    label: string
  ) {
    const parentObj = selectedConnection;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isString = isNaN(connectionModbusConfigDefaults[key] as any);
    const defaultValue =
      connectionModbusConfigDefaults[key] === undefined
        ? ''
        : connectionModbusConfigDefaults[key];

    return (
      <div className={styles.gridItem}>
        <label>{label}</label>
        <TextInput
          value={
            parentObj && parentObj[key] !== undefined
              ? parentObj[key].toString()
              : ''
          }
          placeholder={defaultValue?.toString()}
          wrapperClassName={styles.gridInputField}
          onChange={(e) =>
            onChangeProperty(
              key,
              isString ? safeStr(e.target.value) : toNum(e.target.value)
            )
          }
        />
      </div>
    );
  };

  return (
    <Modal
      className={styles.settingDialogShort}
      onModalClose={onModalClose}
      isOpen={isOpen}
      disableClose={disableClose}
    >
      <ModalHeader titleIcon={Icons.Edit}>
        {T.admin.modbusconnection.titleformat}
      </ModalHeader>
      {selectedConnection && (
        <ModalBody className={styles.settingBody} loading={isLoading}>
          <div className={styles.editorArea}>
            <div className={styles.gridFlow}>
              <div className={styles.gridItemHeader}>
                <Heading level={4}>
                  {T.admin.modbusconnection.properties}
                </Heading>
              </div>
              <div className={styles.gridItem}>
                <label>{T.admin.modbusconnection.type.title}</label>
                <select
                  value={modbusType}
                  onChange={(e) =>
                    onChangeProperty('modbusType', e.target.value)
                  }
                >
                  <option value={ModbusConnectionType.RTU}>
                    {T.admin.modbusconnection.type.rtu}
                  </option>
                  <option value={ModbusConnectionType.TCP}>
                    {T.admin.modbusconnection.type.tcp}
                  </option>
                </select>
              </div>
              <div className={styles.gridItem}>
                <label>{T.admin.modbusconnection.mode.title}</label>
                <select
                  value={
                    selectedConnection.modbusMode ||
                    connectionModbusConfigDefaults.modbusMode
                  }
                  onChange={(e) =>
                    onChangeProperty('modbusMode', e.target.value)
                  }
                >
                  <option value={ModbusMode.Slave}>
                    {T.admin.modbusconnection.mode.slave}
                  </option>
                  <option value={ModbusMode.Master}>
                    {T.admin.modbusconnection.mode.master}
                  </option>
                </select>
              </div>
              {inputRow(
                'connectionTimeout',
                T.admin.modbusconnection.connection_timeout
              )}
              {inputRow(
                'byteTimeoutSeconds',
                T.admin.modbusconnection.byte_timeout_seconds
              )}
              {inputRow(
                'byteTimeoutMicroseconds',
                T.admin.modbusconnection.byte_timeout_microseconds
              )}
              {inputRow(
                'responseTimeoutSeconds',
                T.admin.modbusconnection.response_timeout_seconds
              )}
              {inputRow(
                'responseTimeoutMicroseconds',
                T.admin.modbusconnection.response_timeout_microseconds
              )}
              {inputRow('slaveId', T.admin.modbusconnection.slave_id)}
              {inputRow(
                'watchdogModbusTimeout',
                T.admin.modbusconnection.watchdog_modbus_timeout
              )}
              {inputRow(
                'delayReadModbus',
                T.admin.modbusconnection.delay_read_modbus
              )}
              {inputRow('delayReadDb', T.admin.modbusconnection.delay_read_db)}
              {inputRow(
                'delayWriteModbus',
                T.admin.modbusconnection.delay_write_modbus
              )}
              {inputRow(
                'delayWriteDb',
                T.admin.modbusconnection.delay_write_db
              )}
              {inputRow(
                'queueSizeFactor',
                T.admin.modbusconnection.queue_size_factor
              )}
            </div>
            {modbusType === ModbusConnectionType.TCP && (
              <div className={styles.gridFlow}>
                <div className={styles.gridItemHeader}>
                  <Heading level={4}>
                    {T.admin.modbusconnection.tcpsettings}
                  </Heading>
                </div>
                {inputRow('ipAddress', T.admin.modbusconnection.tcpipaddress)}
                {inputRow('port', T.admin.modbusconnection.tcpport)}
              </div>
            )}
            {modbusType === ModbusConnectionType.RTU && (
              <div className={styles.gridFlow}>
                <div className={styles.gridItemHeader}>
                  <Heading level={4}>
                    {T.admin.modbusconnection.rtusettings}
                  </Heading>
                </div>
                {inputRow('serialName', T.admin.modbusconnection.rtuserialname)}
                {inputRow('baudrate', T.admin.modbusconnection.rtubaudrate)}
                {inputRow('databits', T.admin.modbusconnection.rtudatabits)}
                {inputRow('stopbits', T.admin.modbusconnection.rtustopbits)}
                <div className={styles.gridItem}>
                  <label>{T.admin.modbusconnection.rtuparity}</label>
                  <select
                    value={
                      selectedConnection.parity ||
                      connectionModbusConfigDefaults.parity
                    }
                    onChange={(e) => onChangeProperty('parity', e.target.value)}
                  >
                    <option value={RTUParity.NONE}>
                      {T.admin.modbusconnection.rtuparitynone}
                    </option>
                    <option value={RTUParity.EVEN}>
                      {T.admin.modbusconnection.rtuparityeven}
                    </option>
                    <option value={RTUParity.ODD}>
                      {T.admin.modbusconnection.rtuparityodd}
                    </option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      )}
      <ModalFooter>
        <ModalSpace />
        {showSaveButton && (
          <LocalizedButtons.Save
            disabled={!saveButtonEnabled || isLoading}
            onClick={onSave}
          />
        )}
        {showSaveButton && (
          <LocalizedButtons.Cancel
            disabled={isLoading}
            onClick={onModalClose}
          />
        )}
        {!showSaveButton && (
          <LocalizedButtons.Done disabled={isLoading} onClick={onModalClose} />
        )}
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(ModbusConnectionDialog);
