import React, { useMemo, memo } from 'react';
import _ from 'lodash';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';

import T from 'ecto-common/lib/lang/Language';

const createOptions = (tag: string): GenericSelectOption<string> => ({
  value: tag,
  label: tag
});

interface TagsSelectorProps {
  onChange?(tags: GenericSelectOption<string>[]): void;
  selectedTags: string[];
  availableTags: string[];
  isDisabled?: boolean;
}

const TagsSelector = (props: TagsSelectorProps) => {
  const { availableTags, selectedTags } = props;
  const tagsOptions = useMemo(
    () => availableTags.map(createOptions),
    [availableTags]
  );
  const selectedTagsOptions = useMemo(
    () => _.map(selectedTags, createOptions),
    [selectedTags]
  );

  return (
    <div style={{ minWidth: '220px' }}>
      <Select<GenericSelectOption<string>, true>
        isMulti
        onChange={props.onChange}
        options={tagsOptions}
        placeholder={T.common.tags}
        value={selectedTagsOptions}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

export default memo(TagsSelector);
