import { useMemo } from 'react';
import { useCommonSelector } from '../reducers/storeCommon';
import { NodeType } from '../API/APIGen';
import { beautifyEquipmentName } from '../utils/equipmentTypeUtils';
import T from '../lang/Language';
import _ from 'lodash';

const useNodeTypes = () => {
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );

  return useMemo(
    () => [
      { type: NodeType.Building, name: T.nodetypes.building },
      { type: NodeType.Site, name: T.nodetypes.site },
      { type: NodeType.Equipment, name: T.nodetypes.equipment },

      ..._.map(equipmentTypes, (equipmentType) => ({
        type: equipmentType.equipmentTypeId,
        name: beautifyEquipmentName(equipmentType.name)
      }))
    ],
    [equipmentTypes]
  );
};
export default useNodeTypes;
