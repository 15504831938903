import React, { useMemo } from 'react';
import EditTool from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditTool';
import useToolLoader from 'js/components/ManageEquipment/EditEquipment/Util/useToolLoader';
import {
  AdminToolImplementationType,
  AdminToolType,
  ToolProvider
} from 'js/components/ManageEquipment/EditEquipment/useEquipmentTools';
import { EditStateData } from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import { ToolSignalProviders } from 'js/components/ManageEquipment/EditEquipment/toolTypes';

export type UseToolProps<ObjectType extends object> = {
  tool: AdminToolImplementationType<ObjectType>;
  nodeId: string;
  equipmentId: string;
  editStates: Record<string, EditStateData>;
  setEditState: (toolType: ToolSignalProviders, data: EditStateData) => void;
};

type UseToolResult<ObjectType extends object> =
  AdminToolImplementationType<ObjectType> & {
    isLoading: boolean;
    currentTool: ToolProvider;
    isActive: (arg: AdminToolType) => boolean;
  };

export const useTool = <ObjectType extends object>({
  tool: toolDefinition,
  nodeId,
  equipmentId,
  editStates,
  setEditState
}: UseToolProps<ObjectType>): UseToolResult<ObjectType> => {
  const [currentTool, isLoading, reloadTool] = useToolLoader(
    toolDefinition,
    nodeId,
    equipmentId
  );
  const tool = useMemo(
    () => ({
      ...toolDefinition,
      component: (
        <EditTool
          key={toolDefinition.type}
          tool={toolDefinition}
          equipmentId={equipmentId}
          nodeId={nodeId}
          editState={editStates[toolDefinition.type]}
          setEditState={setEditState}
          currentTool={currentTool}
          reloadTools={reloadTool}
          idKey={toolDefinition.idKey}
          includeDeviceId={toolDefinition.includeDeviceId}
        />
      )
    }),
    [
      currentTool,
      editStates,
      equipmentId,
      nodeId,
      reloadTool,
      setEditState,
      toolDefinition
    ]
  );

  return useMemo(
    () => ({
      ...tool,
      isLoading,
      currentTool,
      isActive: (otherTool: AdminToolType) =>
        currentTool && otherTool.type === toolDefinition.type
    }),
    [currentTool, isLoading, tool, toolDefinition.type]
  );
};
